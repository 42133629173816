import {Link} from "react-router-dom";
import React from "react";
import {space} from "../../compiled";
import IBusinessLocationProto = space.kenko.proto.IBusinessLocationProto;

type Props = {
  businessId: string,
  shortUrl: string,
  businessName: string,
  location: IBusinessLocationProto
}

export const SearchResult = (props: Props) => {

  return <div className={'MapSearchResult'}>
    <div className={'MapSearchResultTopRow'}>

      <div className={'MapSearchResultImageContainer'}>
        <img className={'MapSearchResultImage'}
             src={props.location.imageUrl ? 
            props.location.imageUrl[0] as string : ''}/>
      </div>

      <div className={'MapSearchResultInfo'}>
        <div><b>{props.businessName}{props.location.locationName && ` - ${props.location.locationName}`}</b></div>
        <div>★ 5.0 | 10 reviews</div>
        <div style={{height: 10}}/>
        <div>{props.location.address}</div>
      </div>

    </div>

    <div className={'MapSearchResultBottomRow'}>
      <Link target={'_blank'} key={props.businessId} to={`/p/${props.shortUrl}`}>
        <button className={'MapSearchResultButton'} style={{width: '100%', borderRadius: 5}}>
          SEE WEBSITE</button>
      </Link>

    </div>

  </div>;
}