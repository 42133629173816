import {useState} from "react";
import {MapSearchOptionsChecklist} from "./MapSearchOptionsChecklist";
import Select from "react-select";
import {ServiceTypes} from "../../util/ServiceTypes";
import {PlaceAutocomplete} from "./PlaceAutocomplete";


type Props = {

  onServiceTypeChange: (value: string | undefined) => void;
  onTextSearchChange: (value: string | null | undefined) => void;
  onLocationChange: (name: string, lat: number, lng: number) => void;
}


export const MapSearchOptions = (props: Props) => {

  const [textSearch, setTextSearch] = useState('');

  return <div className={'AppSection MapSearchHeader'}>
    <div className={'AppSectionContent MapSearchOptions'}>

      <div className={'MapSearchOption'}>
        <div><b>Location:</b></div>
        <PlaceAutocomplete
            styles={{
              fontFamily: 'Nunito',
              fontWeight: 300,
              fontSize: 16,
              border: 'none',
              borderRadius: 5,
              padding: 8,
            }}
            onPlaceSelect={place => {
              if (place?.name && place.geometry?.location) {
                props.onLocationChange(place.name, place.geometry.location.lat(), place.geometry.location.lng());
              }
            }}/>

      </div>

      <div className={'MapSearchOption'}>
        <div><b>Service type:</b></div>
        <Select
            defaultValue={undefined}
            name="servicetypes"
            placeholder={"Any"}
            options={[{label: 'Any', value: ''}, ...ServiceTypes]}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={v => props.onServiceTypeChange(v?.value)}
            styles={{
              menu: (baseStyles, state) => ({
                ...baseStyles,
                color: 'black',
              }),
              control: (baseStyles, state) => ({
                ...baseStyles,
                flexWrap: 'nowrap',
                border: 'none'
              }),
              valueContainer: (baseStyles, state) => ({
                ...baseStyles,
                minWidth: 'min(200px, 30vw)'
              }),
            }}/>
      </div>
    </div>
  </div>
}