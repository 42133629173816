/* global google */

import React, {useEffect, useState} from 'react';
import {useSearchParams} from "react-router-dom";
import {ProviderSearchResultsMap} from "./ProviderSearchResultsMap";
import {useApiIsLoaded, useMap} from "@vis.gl/react-google-maps";
import {App2NoScroll} from "../App2";
import '../App2.css'
import {AskForLocation} from "./AskForLocation";
import {MapSearchOptions} from "./MapSearchOptions";
import {ProviderSearchResultsList} from "./ProviderSearchResultsList";

/**
 * This contains the search results list on the left side and the map on the right side.
 *
 * The map is in a separate component so it never re-renders. Re-rendering is a jarring and
 * expensive operation. This is accomplished by never using state which would cause a rerender
 * in either this or the child map component.
 *
 * The list component below this IS allowed to re-render. When it has new data for the map,
 * it interacts through the javascript api rather than editing the react hierarchy.
 */
export const ProviderSearchResults = () => {

  // Initial values -- these are used to c
  const apiIsLoaded = useApiIsLoaded();

  const map = useMap()

  // Javascript is required to handle small screens here because we make
  // one of the two tabs close, and add a button to switch back and forth.
  const [width, setWidth] = useState(window.innerWidth);
  const largeWindow = width > 800;

  const [mapSelected, setMapSelected] = useState(false);

  const [serviceTypeFilter, setServiceTypeFilter] = useState<string>();

  const [searchParams] = useSearchParams();
  const initialLat = searchParams.get('lat');
  const initialLng = searchParams.get('lng');

  const [mapLocation, setMapLocation] = useState<google.maps.LatLngLiteral | undefined>(
      (initialLat && initialLng) ? {
        lat: parseFloat(initialLat),
        lng: parseFloat(initialLng),
      } : undefined
  );

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {

    // The initial mapLocation may have been set if the lat/lng params were there.
    // If they weren't, request geolocation.

    if (!mapLocation && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
          position => {
            setMapLocation({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });
          },
          error => {
            console.log('error retrieving loc', error.message)
          }
      );
    } else {
      console.log('no geolocation available');
    }
  }, []);

  // Initial state - asking for location.
  // Next state - results shown. 

  const getContent = () => {
    if (!mapLocation) {
      return <AskForLocation onChange={v => {
        setMapLocation(v);
      }}/>;
    }

    // The list and the map are ALWAYS PRESENT -- they are just set to display:none. 
    // If they are removed from the tree they lose the state, which is the list of providers.

    return <div className={'AppSectionContent MapSearchBottom'}>

      <div className={'MapSearchLeft'}
           style={{display: largeWindow || !mapSelected ? 'inline' : 'none'}}>
        <ProviderSearchResultsList defaultLocation={mapLocation}
                                   serviceCategory={serviceTypeFilter}/>
      </div>

      <div className={'MapSearchRight'}
           style={{display: largeWindow || mapSelected ? 'inline' : 'none'}}>

        <ProviderSearchResultsMap defaultCenter={mapLocation}/>
      </div>

      {!largeWindow &&
          <div className={'MapSearchOverlayContainer'}>
            <button className={'MapSearchOverlayButton'}
                    onClick={() => setMapSelected(s => !s)}>
              {mapSelected ? 'List' : 'Map'}
            </button>
          </div>
      }

    </div>
  };

  if (!apiIsLoaded) {
    return <>Loading maps api...</>
  }

  return <App2NoScroll>
    <div className={'MapSearchPage'}>

      <MapSearchOptions
          onServiceTypeChange={v => {
            console.log('new filter', v);
            setServiceTypeFilter(v);
          }}
          onTextSearchChange={() => {
          }}
          onLocationChange={(name, lat, lng) => {
            setMapSelected(true);
            setMapLocation({lat: lat, lng: lng});
            map && map.setCenter({lat: lat, lng: lng});
          }}
      />

      <div className={'AppSection MapBodySection'}>
        {getContent()}
      </div>

    </div>
  </App2NoScroll>
}

export default ProviderSearchResults;
