import React, {useEffect, useMemo, useRef, useState} from 'react';
import {SendRpc} from "../../rpcSender";
import {FindProviderRequest, FindProviderResponse, IApiBusinessProfileProto, space,} from "../../compiled";
import {useApiIsLoaded, useMap, useMapsLibrary} from '@vis.gl/react-google-maps';
import {useAuth0} from "@auth0/auth0-react";
import './MapSearch.css'
import {SearchResult} from "./SearchResult";
import IBusinessLocationProto = space.kenko.proto.IBusinessLocationProto;
import {MapMarkerPopup} from "./MapMarkerPopup";

interface Props {

  defaultLocation: google.maps.LatLngLiteral

  serviceCategory: string | undefined
}

/**
 * @constructor
 */
export const ProviderSearchResultsList = (props: Props) => {

  const {getIdTokenClaims} = useAuth0();

  const [providers, setProviders] = useState<Map<string, IApiBusinessProfileProto>>(
      new Map<string, IApiBusinessProfileProto>());

  const [rpcLoading, setRpcLoading] = useState(true);
  const [rpcError, setRpcError] = useState('');

  // NOTE - map bounds are definitely NOT state because it causes a zillion re-renders
  const initialLoadDone = useRef(false);

  const map = useMap()

  const apiLoaded = useApiIsLoaded();
  const markerLibrary = useMapsLibrary('marker');

  const infoWindow = useRef(new google.maps.InfoWindow({
    content: 'hmmm',
    ariaLabel: "hummmm",
    headerDisabled: true,
    // minWidth: 300,
  }));

  // All POI markers currently out on the map. Since these are map elements so they are
  // persisted across renders. This is a dictionary so we can do a fast lookup by id
  // and keep any markers that already exist. This eliminates what would otherwise be
  // a discernible flicker in the markers.
  const markers = useRef<Map<string, google.maps.marker.AdvancedMarkerElement>>(
      new Map<string, google.maps.marker.AdvancedMarkerElement>());

  const boundsListenerRef = useRef<google.maps.MapsEventListener>();
  const dragListenerRef = useRef<google.maps.MapsEventListener>();
  const zoomListenerRef = useRef<google.maps.MapsEventListener>();
  const clickListenerRef = useRef<google.maps.MapsEventListener>();


  useEffect(() => {
    if (map) {

      // Awkward conversation about closures... if the service category is not included
      // in the dependency list, closures are created for the updateSearchResults and the
      // service category never actually got reflected in the RPC that was sent out. So
      // I've added clean uppable listeners and refs and a useEffecy dependency on the category,

      boundsListenerRef.current = map.addListener('bounds_changed', () => {
        // This listener sets the INITIAL bounds which are determined once the map initializes.
        if (!initialLoadDone.current) {
          console.log('initial load done', initialLoadDone)
          initialLoadDone.current = true;
          updateSearchResults();
        }
      });

      dragListenerRef.current = map.addListener('dragend', updateSearchResults)
      zoomListenerRef.current = map.addListener('zoom_changed', updateSearchResults)
      clickListenerRef.current = map.addListener('click', closeInfoWindow);

      // Cleanup function to remove the listener
      return () => {
        boundsListenerRef.current && window.google.maps.event.removeListener(boundsListenerRef.current);
        dragListenerRef.current && window.google.maps.event.removeListener(dragListenerRef.current);
        zoomListenerRef.current && window.google.maps.event.removeListener(zoomListenerRef.current);
      };

    }
  }, [map, props.serviceCategory])

  useEffect(() => {
    if (map) {
      updateSearchResults();
    }
  }, [props.serviceCategory, props.defaultLocation])

  const closeInfoWindow = () => {
    infoWindow?.current.close();
  }

  /**
   * Every business location in range gets a search result.
   */
  const getAllLocations = () => {
    const locations: { business: IApiBusinessProfileProto, location: IBusinessLocationProto }[] = [];
    providers.forEach(provider => {
      provider.locations?.forEach(location => {
        if (!location.disabled) {
          locations.push({business: provider, location: location});
        }
      })
    });

    return locations;
  }

  const updateSearchResults = () => {

    let mapBounds = map?.getBounds();
    if (!mapBounds) {
      console.log('map bounds not available yet');
      return;
    }

    setRpcLoading(true);
    setRpcError('');

    let request = new FindProviderRequest({
      category: props.serviceCategory,
      north: mapBounds.getNorthEast().lat(),
      east: mapBounds.getNorthEast().lng(),
      south: mapBounds.getSouthWest().lat(),
      west: mapBounds.getSouthWest().lng(),
    });


    console.log('sending request', request);

    SendRpc(getIdTokenClaims, "find_provider", FindProviderRequest.encode(request).finish())
        .then(r => {
              let response = FindProviderResponse.decode(r);

              if (response.viewportTooLarge) {
                setRpcError('Map viewport is too large - try zooming in!')
              }

              let updatedProviders: Map<string, IApiBusinessProfileProto> = new Map<string, IApiBusinessProfileProto>();
              response.providers.forEach(provider => {
                console.log('provider', provider);
                if (provider.businessId) {
                  updatedProviders.set(provider.businessId, provider);
                }
              });

              setProviders(updatedProviders);

              // Clear any markers no longer in the search results.
              markers.current.forEach((marker, id, googleMap) => {
                if (!updatedProviders.has(id)) {
                  marker.map = null;
                  markers.current.delete(id); // Supposedly deleting while iterating is ok...
                }
              });

              // Add markers for ones still there.
              updatedProviders?.forEach((provider, id, googleMap) => {

                provider.locations?.forEach((location, locIndex) => {

                  const markerId = provider.businessId + '.' + locIndex;

                  if (!markers.current.has(id)) {

                    if (!location.lat || !location.lng || !provider) {
                      // Shouldn't happen but this check makes typescript happy.
                      return;
                    }

                    // Not existent, make a new one.
                    let marker = new google.maps.marker.AdvancedMarkerElement({
                      position: {lat: location.lat, lng: location.lng},
                      title: provider.proto?.businessName,
                      map: map,
                      gmpClickable: true,
                      content: new google.maps.marker.PinElement({
                        background: '#96695e',
                        glyphColor: 'white',
                        borderColor: 'black',
                      }).element
                    });

                    // Add a click listener for each marker, and set up the info window.
                    marker.addListener('click', () => {
                      
                      
                      infoWindow.current.close();
                      infoWindow.current.setContent(MapMarkerPopup({
                        business: provider.proto,
                        location: location
                      }));
                      infoWindow.current.open(marker.map, marker);
                    });

                    markers.current.set(markerId, marker);
                  }
                })
              })
            }
        )
        .catch(e => {
          console.log(e)
          setRpcError('Error searching, so sorry!')
        })
        .finally(() => {
          setRpcLoading(false);
        })
  };

  if (!apiLoaded) {
    return <div>Loading maps api...</div>
  }

  if (!markerLibrary) {
    return <div>Loading marker library...</div>
  }

  if (rpcError) {
    return (<div style={{color: 'red'}}>{rpcError}</div>);
  }

  if (providers.size == 0) {
    if (rpcLoading) {
      return <div style={{padding: 20}}>Loading...</div>
    } else {
      return <div style={{padding: 20}}>No providers found, sorry!</div>
    }
  }

  /**
   *
   {props.pois?.map((poi: Poi) => (
        <AdvancedMarker key={poi.key} position={poi.location}>
          <Pin background={'gray'} glyphColor={'#fff'} borderColor={'black'}/>
        </AdvancedMarker>
    ))}
   */


// The left side of the search results. Interacts directly with the map so it doesn't
// cause a re-render.
  return <div className={'MapSearchLocations'}>
    {getAllLocations().map(({business, location}) => {
      return <SearchResult key={location.locationId}
                           businessId={business.businessId as string}
                           shortUrl={business.shortUrl as string}
                           businessName={business.proto?.businessName as string}
                           location={location}/>
    })}

    {rpcLoading && <div style={{color: 'gray'}}>refreshing results...</div>}
  </div>;
}

