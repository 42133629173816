import {createClient, Entry} from "contentful";
import {useEffect, useRef, useState} from "react";
import {RpcState} from "../../util/RpcState";
import Select from "react-select";

type Props = {

  // The article category ID is the value
  onChange: (id: string | undefined | null) => void;
}

/**
 * TODO - categories seriously need to get cached
 */
export const CategoryDropdown = (props: Props) => {

  const all = {
    label: 'All topics',
    value: ''
  };

  const [articleCategories, setArticleCategories] = useState<{ label: string, value: string }[]>([all]);

  const [rpcState, setRpcState] = useState<RpcState>({state: 'pending'})
  const rpcSent = useRef(false);

  const contentful = createClient({
    space: 'zrbrzjug66a4',
    accessToken: '9QNFQ-iadIryADYUrJbiv1llDAUVJJ6iBdT80w9ZD_c'
  })


  // Download the articles
  useEffect(() => {
    if (rpcSent.current) {
      return;
    }

    rpcSent.current = true;

    let query = {
      content_type: 'articleCategory',
      select: ['sys.id', 'fields.displayName'],
      order: "fields.displayName"
    }

    contentful.getEntries(query).then((entries) => {
      console.log("loaded " + entries.items.length + ' categories')
      setArticleCategories([all,
        ...entries.items.map(item => {
          return {
            label: item.fields.displayName as string,
            value: item.sys.id
          }
        })]);
      setRpcState({state: 'success'})
    }).catch(e => {
      console.warn("Error fetching categories", e)
      setRpcState({state: 'error'})
    });
  }, []);


  return <Select
      defaultValue={undefined}
      name="colors"
      placeholder={"Topics"}
      options={articleCategories}
      className="basic-multi-select"
      classNamePrefix="select"
      onChange={v => props.onChange(v?.value)}
      styles={{
        menu: (baseStyles, state) => ({
          ...baseStyles,
          color: 'black',
        }),
        control: (baseStyles, state) => ({
          ...baseStyles,
          flexWrap: 'nowrap',
          border: 'none'
        }),
        valueContainer: (baseStyles, state) => ({
          ...baseStyles,
          minWidth: 'min(200px, 30vw)'
        }),
      }}
  />

}