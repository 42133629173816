import {space} from "../../compiled";
import IBusinessLocationProto = space.kenko.proto.IBusinessLocationProto;
import IBusinessProto = space.kenko.proto.IBusinessProto;

type Props = {
  business: IBusinessProto | null | undefined,
  location: IBusinessLocationProto
}

// This is an html string cause the maps javascript API can't do typescript
// ReactElements.
export const MapMarkerPopup = (props: Props) => {

  return `
  <div class='MapMarkerPopup'>
    <div class='MapMarkerPopupImageContainer'>
      <a target="_blank" href="/p/${props.business?.shortUrl}">
        <img class='MapMarkerPopupImage' src="${props.location.imageUrl}" alt="Office image"/>
      </a>
    </div>
    <div class="MapMarkerPopupBusinessName">
      <a target="_blank" href="/p/${props.business?.shortUrl}">
        <b>${props.business?.businessName}</b>
      </a>
    </div>
    <div class='MapMarkerPopupReviewsRow'>
      <a target="_blank" href="/p/${props.business?.shortUrl}">
        <div><b>4.2</b> (54 reviews)</div>
     </a>
    </div>
  </div>
</a>
`;
}