import {useEffect, useState} from "react";
import {Entry} from "contentful";
import {Route, Routes, useParams} from "react-router-dom";
import * as contentful from "contentful";
import {RpcState} from "../../util/RpcState";
import {App2} from "../App2";
import {documentToReactComponents} from "@contentful/rich-text-react-renderer";
import {Document} from '@contentful/rich-text-types';
import {ZhouYueZi} from "../../Waitlist/Content/ZhouYueZi";
import {BenefitsOfAcupuncture} from "../../Waitlist/Content/BenefitsOfAcupuncture";
import {InvestingInSelfCare} from "../../Waitlist/Content/InvestingInSelfCare";
import {SocialLinks} from "../SocialLinks";
import {EmailSignup} from "../../Waitlist/EmailSignup";

export const ArticleView = () => {

  // It's a url like /article/ID
  const {articleId} = useParams();

  const [entry, setEntry] = useState<Entry>()
  const [rpcState, setRpcState] = useState<RpcState>({state: 'pending'});

  const client = contentful.createClient({
    space: 'zrbrzjug66a4',
    accessToken: '9QNFQ-iadIryADYUrJbiv1llDAUVJJ6iBdT80w9ZD_c'
  });

  useEffect(() => {
    if (!articleId) {
      setRpcState({state: 'none'})
      return;
    }

    client.getEntry(articleId)
        .then(e => {
          setRpcState({state: 'success'});
          setEntry(e);
        })
        .catch(e => {
          setRpcState({state: 'error'});
          console.warn('Error fetching from contentful', e)
        });
  }, [articleId]);


  // return <App2>
  //   {documentToReactComponents(entry?.fields.body as Document)}
  // </App2>

  if (!entry) {
    return <div>Loading</div>
  }

  // @ts-ignore
  const coverImageUrl = entry.fields.coverImage?.fields?.file?.url + '?w=1000';

  // @ts-ignore
  return (<App2>
    <div className={'AppSection WhiteSection'}>
      <div className={'AppSectionContent ArticleSection'}>
        <div style={{display: 'flex', flexDirection: 'column'}}>

          <div className={'ArticleCategoryText'}>
            {entry.fields.articleCategory as string}
          </div>

          <h1>{entry.fields.title as string}</h1>

          {coverImageUrl && <div className={'ArticleImage'} style={{backgroundImage: `url(${coverImageUrl})`}}/>}

          <SocialLinks lightBackground={true}/>

          {documentToReactComponents(entry?.fields.body as Document)}

        </div>
      </div>
    </div>

    <div className={'AppSection WhiteSection'}>
      <div className={'AppSectionContent ArticleSocialLinks'}>
        <SocialLinks lightBackground={true}/>
      </div>
    </div>

    <div className={'AppSection LightRedSection'}>
      <div className={'AppSectionContent AboutKenkoSection'}>

        <h2>About Kensu</h2>
        <div>
          We’re blending ancient healing wisdom with modern AI to craft personalized paths to wellness—invest in your
          wellbeing and transform your future health.
        </div>
        <div style={{marginTop: 30}}>
          <EmailSignup/>
        </div>
      </div>
    </div>
    <div className={'AppSection WhiteSection'}>
      <div className={'AppSectionContent DisclaimerSection'}>
        The information, including but not limited to, text, graphics,
        images and other material contained on this website are for
        informational purposes only. No material on this site is intended to
        be a substitute for professional medical advice, diagnosis or
        treatment. Always seek the advice of your physician or other
        qualified healthcare provider, and never disregard professional
        medical advice or delay in seeking it because of something you have
        read on this site. Use of this site is subject to our Terms of Use
        and Privacy Policy.
      </div>
    </div>

  </App2>);
}