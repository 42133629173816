import {Link} from "react-router-dom";
import React from "react";

interface Props {
  link: string;
  category: string;
  imgSource: any;
  title: string;
}

export const LibraryArticlePreview = (props: Props) => {
  return <Link to={props.link} className={'LibraryArticlePreview'}>
    <div className={'LibraryArticlePreviewImageContainer'}>
      <img src={props.imgSource} className={'LibraryArticlePreviewImage'}/>
    </div>
    <div className={'LibraryArticlePreviewCaption'}>
      <p className={'LibraryArticlePreviewTinyText'}>{props.category}</p>
      <p className={'LibraryArticlePreviewText'}>{props.title}</p>
    </div>
  </Link>
}
