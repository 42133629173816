import {Entry} from "contentful";

export const getArticleCategory = (article: Entry, selectedCategory?: string | null): string => {

  if (selectedCategory && article.fields.articleCategories) {
// @ts-ignore
    for (let i = 0; i < article.fields.articleCategories.length; i++) {
// @ts-ignore
      if (article.fields.articleCategories[i].sys.id == selectedCategory) {
// @ts-ignore
        return article.fields.articleCategories[i].fields.displayName
      }
    }
  }
  
  // @ts-ignore
  return article.fields.articleCategories &&
  // @ts-ignore
  article.fields.articleCategories[0] &&
  // @ts-ignore
      
      // to show multiple categories
      // article.fields.articleCategories.map(cat => cat.fields.displayName).join(' / ');
  
  article.fields.articleCategories[0].fields ?
  //     @ts-ignore
      article.fields.articleCategories[0].fields.displayName : 'Uncategorized'

}