import {Link} from 'react-router-dom';
import React from "react";
import './Dashboard.css'

interface Props {
  icon: string,
  link: string,
  text: string,

  // Could be that the profile is awaiting review 
  isBlocked?: boolean,
}

export const DashboardNavIcon = (props: Props) => {

  return <div className={'DashboardNavItem'}>
    <Link to={props.link}><img className={'DashboardNavIcon'} src={props.icon}/></Link>
    <Link to={props.link}>{props.text}</Link>
  </div>
};