import {App2} from "./App2";
import React from "react";
import {Link} from "react-router-dom";

export const Error404 = () => {

  return <App2>
    <div style={{margin: 100}}>
      <h1>404 Page Not Found</h1>
      
      Try heading back to the <Link to={'/'}>Kensu home page</Link>. 
    
    </div>
  </App2>
}