import React from "react";

type Props = {
  pages: number,
  currentPage: number, // 0-indexed
  onChange: (page: number) => void
}

// Always show 5 pages around the current page.

// If page count <= 6... show all pages.
// If page count > 6... show ellipsis at least one side. 

export const Pager = (props: Props) => {
  
  return <div className={'AppSectionContent LibraryPageControl'}>
    <button
        disabled={props.currentPage <= 0}
        onClick={() => props.onChange(props.currentPage - 1)}
        className={'ActionButton Inverted'}>Previous
    </button>

    {pagesArray(props).map(page => {
      const currentPage = page == props.currentPage;
      return <button className={currentPage ? 'PagerPageNumberCurrent' : 'PagerPageNumber'}
          onClick={() => (props.onChange(page))}>
        {page + 1}
      </button>
    })}
    
    <button
        disabled={props.currentPage >= props.pages - 1}
        onClick={() => props.onChange(props.currentPage + 1)}
        className={'ActionButton Inverted'}>Next
    </button>
  </div>
}

// An array of pages from min to max.
const pagesArray = (props: Props) => {
  const pages = [];
  const minMax = minMaxPage(props);
  for (let i = minMax.min; i <= minMax.max; i++) {
    pages.push(i);
  }
  return pages;
}

const minMaxPage = (props: Props) => {
  
  // Small is an easy one.
  if (props.pages <= 7) {
    return {
      min: 0,
      max: props.pages - 1
    }
  }
  
  // At the beginning of the results. Dots on right side only.
  if (props.currentPage <= 2) {
    return {
      min: 0,
      max: 4,
    }
  }
  
  // All the way to the end of the results. Dots on left side only
  if (props.currentPage >= props.pages - 3) {
    return {
      min: props.pages - 5,
      max: props.pages - 1,
    }
  }
  
  // In the middle somewhere
  return {
    min: props.currentPage - 2,
    max: props.currentPage + 2,
  }
}