import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {UserProfileProvider} from "./UserProfileProvider";
import {BrowserRouter} from "react-router-dom";
import {APIProvider} from '@vis.gl/react-google-maps';
import {Auth0ProviderWithNavigate} from "./components/auth0-provider-with-navigate";
import {WaitlistRouter} from "./Waitlist/WaitlistRouter";
import {AppRouter} from "./App/AppRouter";

const API_KEY = ("AIzaSyCFJhMw3rXpVRXvhxjFTmELaqdHigbQ2nw");

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const showWaitlist = 0;

if (!showWaitlist) {
  root.render(
      <React.StrictMode>
        <BrowserRouter>
          <Auth0ProviderWithNavigate>
            <UserProfileProvider>
              <APIProvider apiKey={API_KEY} onLoad={() => console.log('Maps API has loaded.')}>
                <AppRouter/>
              </APIProvider>
            </UserProfileProvider>
          </Auth0ProviderWithNavigate>
        </BrowserRouter>
      </React.StrictMode>,
  );
} else {
  root.render(
      <React.StrictMode>
        <BrowserRouter>
          <WaitlistRouter/>
        </BrowserRouter>
      </React.StrictMode>,
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(null);
