import {Map} from "@vis.gl/react-google-maps";
import React from "react";

interface Props {
  defaultBounds?: google.maps.LatLngBounds
  defaultCenter: google.maps.LatLngLiteral
}

/**
 * This is in a separate component so it can be memoized and does not have to
 * be re-rendered when search results change.
 *
 * It's important that the parent of this also does not re-render on any changes
 * because that will also force a re-render here.
 *
 * @param props
 * @constructor
 */
export const ProviderSearchResultsMap = (props: Props) => {

  return <Map
      mapId={'f59161af6029d466'}
      reuseMaps={false}
      style={{width: '100%', height: '100%'}}
      defaultCenter={props.defaultCenter}
      defaultZoom={12}
      // defaultBounds={props.defaultBounds.toJSON()}
      maxZoom={15}
      gestureHandling={'greedy'}
      disableDefaultUI={true}
      clickableIcons={false}
  >
  </Map>
}
