import {Routes} from "react-router";
import {Route} from "react-router-dom";
import {HomePage} from "./Home/HomePage";
import {AiChatPage} from "./Home/AiChatPage";
import {WellnessSurvey} from "./Home/WellnessSurvey/WellnessSurvey";
import {RedeemInvite} from "./RedeemInvite/RedeemInvite";
import ProviderSearchResults from "./Map/ProviderSearchResults";
import {ProviderMain} from "./ProviderWebsite/ProviderMain";
import React from "react";
import {PrivateAlpha} from "./PrivateAlpha";
import {useAuth0} from "@auth0/auth0-react";
import {App2} from "./App2";
import {useProfile} from "../UserProfileProvider";
import {Library} from "./Library/Library";
import {Error404} from "./Error404";
import {ArticleView} from "./ArticleView/ArticleView";
import {Dashboard} from "./Dashboard/Dashboard";

/**
 * This is top level again nesting routes within deeper functional components
 * got too complicated.
 */
export const AppRouter = () => {

  const {user, isLoading} = useAuth0();
  const {profile, profileError, profileLoading} = useProfile();

  // During the consumer alpha, everything is behind a private alpha gate
  // so there must be a user with an alpha invite.

  // Later on, browsing kenko unauthenticated will be supported to the user
  // gate gets dropped. Unauthenticated people will get a lock icon for
  // much of the content. Unauthenticated, unsubscribed people will also see
  // that lock icon.

  if (isLoading) {
    return <App2>
      <div style={{margin: 100}}>Loading user...</div>
    </App2>;
  }
  
  if (!user) {
    return <Routes>
      {/* provider landing pages are accessible even without a user! */}
      <Route path='/p/:providerShortUrl/*' element={<ProviderMain/>}/>
      <Route path="*" element={<PrivateAlpha/>}/>;
    </Routes>
  }

  // These checks need to go away post-alpha; loading without a profile is fine.
  // In the mean time there has to be something that happens while loading.
  if (profileLoading) {
    return <App2>
      <div style={{margin: 100}}>Loading profile...</div>
    </App2>;
  }

  if (profileError) {
    return <App2>
      <div style={{margin: 100}}>
        <h2>Oops! Something went wrong</h2>
        Please try refreshing the page.
      </div>
    </App2>;
  }

  return <Routes>
    <Route path="/" element={<HomePage/>}/>
    <Route path="/library" element={<Library/>}/>
    <Route path="/library/article/:articleId" element={<ArticleView/>}/>
    <Route path="/chat" element={<AiChatPage/>}/>
    <Route path="/quiz" element={<WellnessSurvey/>}/>
    <Route path="/redeemInvite/:inviteCode" element={<RedeemInvite/>}/>
    <Route path='/search' element={<ProviderSearchResults/>}/>
    <Route path='/dashboard/*' element={<Dashboard/>}/>
    <Route path='/p/:providerShortUrl/*' element={<ProviderMain/>}/>
    <Route path="*" element={<Error404/>}/>
  </Routes>
}