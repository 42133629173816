/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

export const GetNetworkConfigurationRequestProto = $root.GetNetworkConfigurationRequestProto = (() => {

    /**
     * Properties of a GetNetworkConfigurationRequestProto.
     * @exports IGetNetworkConfigurationRequestProto
     * @interface IGetNetworkConfigurationRequestProto
     */

    /**
     * Constructs a new GetNetworkConfigurationRequestProto.
     * @exports GetNetworkConfigurationRequestProto
     * @classdesc Represents a GetNetworkConfigurationRequestProto.
     * @implements IGetNetworkConfigurationRequestProto
     * @constructor
     * @param {IGetNetworkConfigurationRequestProto=} [properties] Properties to set
     */
    function GetNetworkConfigurationRequestProto(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Creates a new GetNetworkConfigurationRequestProto instance using the specified properties.
     * @function create
     * @memberof GetNetworkConfigurationRequestProto
     * @static
     * @param {IGetNetworkConfigurationRequestProto=} [properties] Properties to set
     * @returns {GetNetworkConfigurationRequestProto} GetNetworkConfigurationRequestProto instance
     */
    GetNetworkConfigurationRequestProto.create = function create(properties) {
        return new GetNetworkConfigurationRequestProto(properties);
    };

    /**
     * Encodes the specified GetNetworkConfigurationRequestProto message. Does not implicitly {@link GetNetworkConfigurationRequestProto.verify|verify} messages.
     * @function encode
     * @memberof GetNetworkConfigurationRequestProto
     * @static
     * @param {IGetNetworkConfigurationRequestProto} message GetNetworkConfigurationRequestProto message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetNetworkConfigurationRequestProto.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        return writer;
    };

    /**
     * Encodes the specified GetNetworkConfigurationRequestProto message, length delimited. Does not implicitly {@link GetNetworkConfigurationRequestProto.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GetNetworkConfigurationRequestProto
     * @static
     * @param {IGetNetworkConfigurationRequestProto} message GetNetworkConfigurationRequestProto message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetNetworkConfigurationRequestProto.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetNetworkConfigurationRequestProto message from the specified reader or buffer.
     * @function decode
     * @memberof GetNetworkConfigurationRequestProto
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GetNetworkConfigurationRequestProto} GetNetworkConfigurationRequestProto
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetNetworkConfigurationRequestProto.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.GetNetworkConfigurationRequestProto();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GetNetworkConfigurationRequestProto message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GetNetworkConfigurationRequestProto
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GetNetworkConfigurationRequestProto} GetNetworkConfigurationRequestProto
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetNetworkConfigurationRequestProto.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetNetworkConfigurationRequestProto message.
     * @function verify
     * @memberof GetNetworkConfigurationRequestProto
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetNetworkConfigurationRequestProto.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        return null;
    };

    /**
     * Creates a GetNetworkConfigurationRequestProto message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GetNetworkConfigurationRequestProto
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GetNetworkConfigurationRequestProto} GetNetworkConfigurationRequestProto
     */
    GetNetworkConfigurationRequestProto.fromObject = function fromObject(object) {
        if (object instanceof $root.GetNetworkConfigurationRequestProto)
            return object;
        return new $root.GetNetworkConfigurationRequestProto();
    };

    /**
     * Creates a plain object from a GetNetworkConfigurationRequestProto message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GetNetworkConfigurationRequestProto
     * @static
     * @param {GetNetworkConfigurationRequestProto} message GetNetworkConfigurationRequestProto
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetNetworkConfigurationRequestProto.toObject = function toObject() {
        return {};
    };

    /**
     * Converts this GetNetworkConfigurationRequestProto to JSON.
     * @function toJSON
     * @memberof GetNetworkConfigurationRequestProto
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetNetworkConfigurationRequestProto.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetNetworkConfigurationRequestProto
     * @function getTypeUrl
     * @memberof GetNetworkConfigurationRequestProto
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetNetworkConfigurationRequestProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GetNetworkConfigurationRequestProto";
    };

    return GetNetworkConfigurationRequestProto;
})();

export const GetNetworkConfigurationResponseProto = $root.GetNetworkConfigurationResponseProto = (() => {

    /**
     * Properties of a GetNetworkConfigurationResponseProto.
     * @exports IGetNetworkConfigurationResponseProto
     * @interface IGetNetworkConfigurationResponseProto
     * @property {boolean|null} [okay] GetNetworkConfigurationResponseProto okay
     * @property {string|null} [networkName] GetNetworkConfigurationResponseProto networkName
     * @property {string|null} [networkDescription] GetNetworkConfigurationResponseProto networkDescription
     * @property {string|null} [primaryColor] GetNetworkConfigurationResponseProto primaryColor
     * @property {string|null} [backgroundColor] GetNetworkConfigurationResponseProto backgroundColor
     * @property {string|null} [cardColor] GetNetworkConfigurationResponseProto cardColor
     */

    /**
     * Constructs a new GetNetworkConfigurationResponseProto.
     * @exports GetNetworkConfigurationResponseProto
     * @classdesc Represents a GetNetworkConfigurationResponseProto.
     * @implements IGetNetworkConfigurationResponseProto
     * @constructor
     * @param {IGetNetworkConfigurationResponseProto=} [properties] Properties to set
     */
    function GetNetworkConfigurationResponseProto(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetNetworkConfigurationResponseProto okay.
     * @member {boolean} okay
     * @memberof GetNetworkConfigurationResponseProto
     * @instance
     */
    GetNetworkConfigurationResponseProto.prototype.okay = false;

    /**
     * GetNetworkConfigurationResponseProto networkName.
     * @member {string} networkName
     * @memberof GetNetworkConfigurationResponseProto
     * @instance
     */
    GetNetworkConfigurationResponseProto.prototype.networkName = "";

    /**
     * GetNetworkConfigurationResponseProto networkDescription.
     * @member {string} networkDescription
     * @memberof GetNetworkConfigurationResponseProto
     * @instance
     */
    GetNetworkConfigurationResponseProto.prototype.networkDescription = "";

    /**
     * GetNetworkConfigurationResponseProto primaryColor.
     * @member {string} primaryColor
     * @memberof GetNetworkConfigurationResponseProto
     * @instance
     */
    GetNetworkConfigurationResponseProto.prototype.primaryColor = "";

    /**
     * GetNetworkConfigurationResponseProto backgroundColor.
     * @member {string} backgroundColor
     * @memberof GetNetworkConfigurationResponseProto
     * @instance
     */
    GetNetworkConfigurationResponseProto.prototype.backgroundColor = "";

    /**
     * GetNetworkConfigurationResponseProto cardColor.
     * @member {string} cardColor
     * @memberof GetNetworkConfigurationResponseProto
     * @instance
     */
    GetNetworkConfigurationResponseProto.prototype.cardColor = "";

    /**
     * Creates a new GetNetworkConfigurationResponseProto instance using the specified properties.
     * @function create
     * @memberof GetNetworkConfigurationResponseProto
     * @static
     * @param {IGetNetworkConfigurationResponseProto=} [properties] Properties to set
     * @returns {GetNetworkConfigurationResponseProto} GetNetworkConfigurationResponseProto instance
     */
    GetNetworkConfigurationResponseProto.create = function create(properties) {
        return new GetNetworkConfigurationResponseProto(properties);
    };

    /**
     * Encodes the specified GetNetworkConfigurationResponseProto message. Does not implicitly {@link GetNetworkConfigurationResponseProto.verify|verify} messages.
     * @function encode
     * @memberof GetNetworkConfigurationResponseProto
     * @static
     * @param {IGetNetworkConfigurationResponseProto} message GetNetworkConfigurationResponseProto message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetNetworkConfigurationResponseProto.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        if (message.networkName != null && Object.hasOwnProperty.call(message, "networkName"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.networkName);
        if (message.networkDescription != null && Object.hasOwnProperty.call(message, "networkDescription"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.networkDescription);
        if (message.primaryColor != null && Object.hasOwnProperty.call(message, "primaryColor"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.primaryColor);
        if (message.backgroundColor != null && Object.hasOwnProperty.call(message, "backgroundColor"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.backgroundColor);
        if (message.cardColor != null && Object.hasOwnProperty.call(message, "cardColor"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.cardColor);
        return writer;
    };

    /**
     * Encodes the specified GetNetworkConfigurationResponseProto message, length delimited. Does not implicitly {@link GetNetworkConfigurationResponseProto.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GetNetworkConfigurationResponseProto
     * @static
     * @param {IGetNetworkConfigurationResponseProto} message GetNetworkConfigurationResponseProto message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetNetworkConfigurationResponseProto.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetNetworkConfigurationResponseProto message from the specified reader or buffer.
     * @function decode
     * @memberof GetNetworkConfigurationResponseProto
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GetNetworkConfigurationResponseProto} GetNetworkConfigurationResponseProto
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetNetworkConfigurationResponseProto.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.GetNetworkConfigurationResponseProto();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            case 2: {
                    message.networkName = reader.string();
                    break;
                }
            case 3: {
                    message.networkDescription = reader.string();
                    break;
                }
            case 4: {
                    message.primaryColor = reader.string();
                    break;
                }
            case 5: {
                    message.backgroundColor = reader.string();
                    break;
                }
            case 6: {
                    message.cardColor = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GetNetworkConfigurationResponseProto message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GetNetworkConfigurationResponseProto
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GetNetworkConfigurationResponseProto} GetNetworkConfigurationResponseProto
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetNetworkConfigurationResponseProto.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetNetworkConfigurationResponseProto message.
     * @function verify
     * @memberof GetNetworkConfigurationResponseProto
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetNetworkConfigurationResponseProto.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        if (message.networkName != null && message.hasOwnProperty("networkName"))
            if (!$util.isString(message.networkName))
                return "networkName: string expected";
        if (message.networkDescription != null && message.hasOwnProperty("networkDescription"))
            if (!$util.isString(message.networkDescription))
                return "networkDescription: string expected";
        if (message.primaryColor != null && message.hasOwnProperty("primaryColor"))
            if (!$util.isString(message.primaryColor))
                return "primaryColor: string expected";
        if (message.backgroundColor != null && message.hasOwnProperty("backgroundColor"))
            if (!$util.isString(message.backgroundColor))
                return "backgroundColor: string expected";
        if (message.cardColor != null && message.hasOwnProperty("cardColor"))
            if (!$util.isString(message.cardColor))
                return "cardColor: string expected";
        return null;
    };

    /**
     * Creates a GetNetworkConfigurationResponseProto message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GetNetworkConfigurationResponseProto
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GetNetworkConfigurationResponseProto} GetNetworkConfigurationResponseProto
     */
    GetNetworkConfigurationResponseProto.fromObject = function fromObject(object) {
        if (object instanceof $root.GetNetworkConfigurationResponseProto)
            return object;
        let message = new $root.GetNetworkConfigurationResponseProto();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        if (object.networkName != null)
            message.networkName = String(object.networkName);
        if (object.networkDescription != null)
            message.networkDescription = String(object.networkDescription);
        if (object.primaryColor != null)
            message.primaryColor = String(object.primaryColor);
        if (object.backgroundColor != null)
            message.backgroundColor = String(object.backgroundColor);
        if (object.cardColor != null)
            message.cardColor = String(object.cardColor);
        return message;
    };

    /**
     * Creates a plain object from a GetNetworkConfigurationResponseProto message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GetNetworkConfigurationResponseProto
     * @static
     * @param {GetNetworkConfigurationResponseProto} message GetNetworkConfigurationResponseProto
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetNetworkConfigurationResponseProto.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.okay = false;
            object.networkName = "";
            object.networkDescription = "";
            object.primaryColor = "";
            object.backgroundColor = "";
            object.cardColor = "";
        }
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        if (message.networkName != null && message.hasOwnProperty("networkName"))
            object.networkName = message.networkName;
        if (message.networkDescription != null && message.hasOwnProperty("networkDescription"))
            object.networkDescription = message.networkDescription;
        if (message.primaryColor != null && message.hasOwnProperty("primaryColor"))
            object.primaryColor = message.primaryColor;
        if (message.backgroundColor != null && message.hasOwnProperty("backgroundColor"))
            object.backgroundColor = message.backgroundColor;
        if (message.cardColor != null && message.hasOwnProperty("cardColor"))
            object.cardColor = message.cardColor;
        return object;
    };

    /**
     * Converts this GetNetworkConfigurationResponseProto to JSON.
     * @function toJSON
     * @memberof GetNetworkConfigurationResponseProto
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetNetworkConfigurationResponseProto.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetNetworkConfigurationResponseProto
     * @function getTypeUrl
     * @memberof GetNetworkConfigurationResponseProto
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetNetworkConfigurationResponseProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GetNetworkConfigurationResponseProto";
    };

    return GetNetworkConfigurationResponseProto;
})();

export const FindProviderRequest = $root.FindProviderRequest = (() => {

    /**
     * Properties of a FindProviderRequest.
     * @exports IFindProviderRequest
     * @interface IFindProviderRequest
     * @property {string|null} [category] FindProviderRequest category
     * @property {number|null} [north] FindProviderRequest north
     * @property {number|null} [south] FindProviderRequest south
     * @property {number|null} [east] FindProviderRequest east
     * @property {number|null} [west] FindProviderRequest west
     * @property {number|null} [page] FindProviderRequest page
     */

    /**
     * Constructs a new FindProviderRequest.
     * @exports FindProviderRequest
     * @classdesc Represents a FindProviderRequest.
     * @implements IFindProviderRequest
     * @constructor
     * @param {IFindProviderRequest=} [properties] Properties to set
     */
    function FindProviderRequest(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * FindProviderRequest category.
     * @member {string} category
     * @memberof FindProviderRequest
     * @instance
     */
    FindProviderRequest.prototype.category = "";

    /**
     * FindProviderRequest north.
     * @member {number} north
     * @memberof FindProviderRequest
     * @instance
     */
    FindProviderRequest.prototype.north = 0;

    /**
     * FindProviderRequest south.
     * @member {number} south
     * @memberof FindProviderRequest
     * @instance
     */
    FindProviderRequest.prototype.south = 0;

    /**
     * FindProviderRequest east.
     * @member {number} east
     * @memberof FindProviderRequest
     * @instance
     */
    FindProviderRequest.prototype.east = 0;

    /**
     * FindProviderRequest west.
     * @member {number} west
     * @memberof FindProviderRequest
     * @instance
     */
    FindProviderRequest.prototype.west = 0;

    /**
     * FindProviderRequest page.
     * @member {number} page
     * @memberof FindProviderRequest
     * @instance
     */
    FindProviderRequest.prototype.page = 0;

    /**
     * Creates a new FindProviderRequest instance using the specified properties.
     * @function create
     * @memberof FindProviderRequest
     * @static
     * @param {IFindProviderRequest=} [properties] Properties to set
     * @returns {FindProviderRequest} FindProviderRequest instance
     */
    FindProviderRequest.create = function create(properties) {
        return new FindProviderRequest(properties);
    };

    /**
     * Encodes the specified FindProviderRequest message. Does not implicitly {@link FindProviderRequest.verify|verify} messages.
     * @function encode
     * @memberof FindProviderRequest
     * @static
     * @param {IFindProviderRequest} message FindProviderRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    FindProviderRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.category != null && Object.hasOwnProperty.call(message, "category"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.category);
        if (message.north != null && Object.hasOwnProperty.call(message, "north"))
            writer.uint32(/* id 2, wireType 1 =*/17).double(message.north);
        if (message.south != null && Object.hasOwnProperty.call(message, "south"))
            writer.uint32(/* id 3, wireType 1 =*/25).double(message.south);
        if (message.east != null && Object.hasOwnProperty.call(message, "east"))
            writer.uint32(/* id 4, wireType 1 =*/33).double(message.east);
        if (message.west != null && Object.hasOwnProperty.call(message, "west"))
            writer.uint32(/* id 5, wireType 1 =*/41).double(message.west);
        if (message.page != null && Object.hasOwnProperty.call(message, "page"))
            writer.uint32(/* id 10, wireType 0 =*/80).int32(message.page);
        return writer;
    };

    /**
     * Encodes the specified FindProviderRequest message, length delimited. Does not implicitly {@link FindProviderRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof FindProviderRequest
     * @static
     * @param {IFindProviderRequest} message FindProviderRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    FindProviderRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a FindProviderRequest message from the specified reader or buffer.
     * @function decode
     * @memberof FindProviderRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {FindProviderRequest} FindProviderRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    FindProviderRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.FindProviderRequest();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.category = reader.string();
                    break;
                }
            case 2: {
                    message.north = reader.double();
                    break;
                }
            case 3: {
                    message.south = reader.double();
                    break;
                }
            case 4: {
                    message.east = reader.double();
                    break;
                }
            case 5: {
                    message.west = reader.double();
                    break;
                }
            case 10: {
                    message.page = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a FindProviderRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof FindProviderRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {FindProviderRequest} FindProviderRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    FindProviderRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a FindProviderRequest message.
     * @function verify
     * @memberof FindProviderRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    FindProviderRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.category != null && message.hasOwnProperty("category"))
            if (!$util.isString(message.category))
                return "category: string expected";
        if (message.north != null && message.hasOwnProperty("north"))
            if (typeof message.north !== "number")
                return "north: number expected";
        if (message.south != null && message.hasOwnProperty("south"))
            if (typeof message.south !== "number")
                return "south: number expected";
        if (message.east != null && message.hasOwnProperty("east"))
            if (typeof message.east !== "number")
                return "east: number expected";
        if (message.west != null && message.hasOwnProperty("west"))
            if (typeof message.west !== "number")
                return "west: number expected";
        if (message.page != null && message.hasOwnProperty("page"))
            if (!$util.isInteger(message.page))
                return "page: integer expected";
        return null;
    };

    /**
     * Creates a FindProviderRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof FindProviderRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {FindProviderRequest} FindProviderRequest
     */
    FindProviderRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.FindProviderRequest)
            return object;
        let message = new $root.FindProviderRequest();
        if (object.category != null)
            message.category = String(object.category);
        if (object.north != null)
            message.north = Number(object.north);
        if (object.south != null)
            message.south = Number(object.south);
        if (object.east != null)
            message.east = Number(object.east);
        if (object.west != null)
            message.west = Number(object.west);
        if (object.page != null)
            message.page = object.page | 0;
        return message;
    };

    /**
     * Creates a plain object from a FindProviderRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof FindProviderRequest
     * @static
     * @param {FindProviderRequest} message FindProviderRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    FindProviderRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.category = "";
            object.north = 0;
            object.south = 0;
            object.east = 0;
            object.west = 0;
            object.page = 0;
        }
        if (message.category != null && message.hasOwnProperty("category"))
            object.category = message.category;
        if (message.north != null && message.hasOwnProperty("north"))
            object.north = options.json && !isFinite(message.north) ? String(message.north) : message.north;
        if (message.south != null && message.hasOwnProperty("south"))
            object.south = options.json && !isFinite(message.south) ? String(message.south) : message.south;
        if (message.east != null && message.hasOwnProperty("east"))
            object.east = options.json && !isFinite(message.east) ? String(message.east) : message.east;
        if (message.west != null && message.hasOwnProperty("west"))
            object.west = options.json && !isFinite(message.west) ? String(message.west) : message.west;
        if (message.page != null && message.hasOwnProperty("page"))
            object.page = message.page;
        return object;
    };

    /**
     * Converts this FindProviderRequest to JSON.
     * @function toJSON
     * @memberof FindProviderRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    FindProviderRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for FindProviderRequest
     * @function getTypeUrl
     * @memberof FindProviderRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    FindProviderRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/FindProviderRequest";
    };

    return FindProviderRequest;
})();

export const FindProviderResponse = $root.FindProviderResponse = (() => {

    /**
     * Properties of a FindProviderResponse.
     * @exports IFindProviderResponse
     * @interface IFindProviderResponse
     * @property {boolean|null} [okay] FindProviderResponse okay
     * @property {Array.<IApiBusinessProfileProto>|null} [providers] FindProviderResponse providers
     * @property {boolean|null} [more] FindProviderResponse more
     * @property {boolean|null} [viewportTooLarge] FindProviderResponse viewportTooLarge
     */

    /**
     * Constructs a new FindProviderResponse.
     * @exports FindProviderResponse
     * @classdesc Represents a FindProviderResponse.
     * @implements IFindProviderResponse
     * @constructor
     * @param {IFindProviderResponse=} [properties] Properties to set
     */
    function FindProviderResponse(properties) {
        this.providers = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * FindProviderResponse okay.
     * @member {boolean} okay
     * @memberof FindProviderResponse
     * @instance
     */
    FindProviderResponse.prototype.okay = false;

    /**
     * FindProviderResponse providers.
     * @member {Array.<IApiBusinessProfileProto>} providers
     * @memberof FindProviderResponse
     * @instance
     */
    FindProviderResponse.prototype.providers = $util.emptyArray;

    /**
     * FindProviderResponse more.
     * @member {boolean} more
     * @memberof FindProviderResponse
     * @instance
     */
    FindProviderResponse.prototype.more = false;

    /**
     * FindProviderResponse viewportTooLarge.
     * @member {boolean} viewportTooLarge
     * @memberof FindProviderResponse
     * @instance
     */
    FindProviderResponse.prototype.viewportTooLarge = false;

    /**
     * Creates a new FindProviderResponse instance using the specified properties.
     * @function create
     * @memberof FindProviderResponse
     * @static
     * @param {IFindProviderResponse=} [properties] Properties to set
     * @returns {FindProviderResponse} FindProviderResponse instance
     */
    FindProviderResponse.create = function create(properties) {
        return new FindProviderResponse(properties);
    };

    /**
     * Encodes the specified FindProviderResponse message. Does not implicitly {@link FindProviderResponse.verify|verify} messages.
     * @function encode
     * @memberof FindProviderResponse
     * @static
     * @param {IFindProviderResponse} message FindProviderResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    FindProviderResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        if (message.providers != null && message.providers.length)
            for (let i = 0; i < message.providers.length; ++i)
                $root.ApiBusinessProfileProto.encode(message.providers[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.more != null && Object.hasOwnProperty.call(message, "more"))
            writer.uint32(/* id 3, wireType 0 =*/24).bool(message.more);
        if (message.viewportTooLarge != null && Object.hasOwnProperty.call(message, "viewportTooLarge"))
            writer.uint32(/* id 4, wireType 0 =*/32).bool(message.viewportTooLarge);
        return writer;
    };

    /**
     * Encodes the specified FindProviderResponse message, length delimited. Does not implicitly {@link FindProviderResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof FindProviderResponse
     * @static
     * @param {IFindProviderResponse} message FindProviderResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    FindProviderResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a FindProviderResponse message from the specified reader or buffer.
     * @function decode
     * @memberof FindProviderResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {FindProviderResponse} FindProviderResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    FindProviderResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.FindProviderResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            case 2: {
                    if (!(message.providers && message.providers.length))
                        message.providers = [];
                    message.providers.push($root.ApiBusinessProfileProto.decode(reader, reader.uint32()));
                    break;
                }
            case 3: {
                    message.more = reader.bool();
                    break;
                }
            case 4: {
                    message.viewportTooLarge = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a FindProviderResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof FindProviderResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {FindProviderResponse} FindProviderResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    FindProviderResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a FindProviderResponse message.
     * @function verify
     * @memberof FindProviderResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    FindProviderResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        if (message.providers != null && message.hasOwnProperty("providers")) {
            if (!Array.isArray(message.providers))
                return "providers: array expected";
            for (let i = 0; i < message.providers.length; ++i) {
                let error = $root.ApiBusinessProfileProto.verify(message.providers[i]);
                if (error)
                    return "providers." + error;
            }
        }
        if (message.more != null && message.hasOwnProperty("more"))
            if (typeof message.more !== "boolean")
                return "more: boolean expected";
        if (message.viewportTooLarge != null && message.hasOwnProperty("viewportTooLarge"))
            if (typeof message.viewportTooLarge !== "boolean")
                return "viewportTooLarge: boolean expected";
        return null;
    };

    /**
     * Creates a FindProviderResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof FindProviderResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {FindProviderResponse} FindProviderResponse
     */
    FindProviderResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.FindProviderResponse)
            return object;
        let message = new $root.FindProviderResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        if (object.providers) {
            if (!Array.isArray(object.providers))
                throw TypeError(".FindProviderResponse.providers: array expected");
            message.providers = [];
            for (let i = 0; i < object.providers.length; ++i) {
                if (typeof object.providers[i] !== "object")
                    throw TypeError(".FindProviderResponse.providers: object expected");
                message.providers[i] = $root.ApiBusinessProfileProto.fromObject(object.providers[i]);
            }
        }
        if (object.more != null)
            message.more = Boolean(object.more);
        if (object.viewportTooLarge != null)
            message.viewportTooLarge = Boolean(object.viewportTooLarge);
        return message;
    };

    /**
     * Creates a plain object from a FindProviderResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof FindProviderResponse
     * @static
     * @param {FindProviderResponse} message FindProviderResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    FindProviderResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.providers = [];
        if (options.defaults) {
            object.okay = false;
            object.more = false;
            object.viewportTooLarge = false;
        }
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        if (message.providers && message.providers.length) {
            object.providers = [];
            for (let j = 0; j < message.providers.length; ++j)
                object.providers[j] = $root.ApiBusinessProfileProto.toObject(message.providers[j], options);
        }
        if (message.more != null && message.hasOwnProperty("more"))
            object.more = message.more;
        if (message.viewportTooLarge != null && message.hasOwnProperty("viewportTooLarge"))
            object.viewportTooLarge = message.viewportTooLarge;
        return object;
    };

    /**
     * Converts this FindProviderResponse to JSON.
     * @function toJSON
     * @memberof FindProviderResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    FindProviderResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for FindProviderResponse
     * @function getTypeUrl
     * @memberof FindProviderResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    FindProviderResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/FindProviderResponse";
    };

    return FindProviderResponse;
})();

export const GetProviderProfileRequest = $root.GetProviderProfileRequest = (() => {

    /**
     * Properties of a GetProviderProfileRequest.
     * @exports IGetProviderProfileRequest
     * @interface IGetProviderProfileRequest
     * @property {string|null} [shortUrl] GetProviderProfileRequest shortUrl
     * @property {string|null} [businessId] GetProviderProfileRequest businessId
     * @property {string|null} [version] GetProviderProfileRequest version
     */

    /**
     * Constructs a new GetProviderProfileRequest.
     * @exports GetProviderProfileRequest
     * @classdesc Represents a GetProviderProfileRequest.
     * @implements IGetProviderProfileRequest
     * @constructor
     * @param {IGetProviderProfileRequest=} [properties] Properties to set
     */
    function GetProviderProfileRequest(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetProviderProfileRequest shortUrl.
     * @member {string} shortUrl
     * @memberof GetProviderProfileRequest
     * @instance
     */
    GetProviderProfileRequest.prototype.shortUrl = "";

    /**
     * GetProviderProfileRequest businessId.
     * @member {string} businessId
     * @memberof GetProviderProfileRequest
     * @instance
     */
    GetProviderProfileRequest.prototype.businessId = "";

    /**
     * GetProviderProfileRequest version.
     * @member {string} version
     * @memberof GetProviderProfileRequest
     * @instance
     */
    GetProviderProfileRequest.prototype.version = "";

    /**
     * Creates a new GetProviderProfileRequest instance using the specified properties.
     * @function create
     * @memberof GetProviderProfileRequest
     * @static
     * @param {IGetProviderProfileRequest=} [properties] Properties to set
     * @returns {GetProviderProfileRequest} GetProviderProfileRequest instance
     */
    GetProviderProfileRequest.create = function create(properties) {
        return new GetProviderProfileRequest(properties);
    };

    /**
     * Encodes the specified GetProviderProfileRequest message. Does not implicitly {@link GetProviderProfileRequest.verify|verify} messages.
     * @function encode
     * @memberof GetProviderProfileRequest
     * @static
     * @param {IGetProviderProfileRequest} message GetProviderProfileRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetProviderProfileRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.shortUrl != null && Object.hasOwnProperty.call(message, "shortUrl"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.shortUrl);
        if (message.businessId != null && Object.hasOwnProperty.call(message, "businessId"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.businessId);
        if (message.version != null && Object.hasOwnProperty.call(message, "version"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.version);
        return writer;
    };

    /**
     * Encodes the specified GetProviderProfileRequest message, length delimited. Does not implicitly {@link GetProviderProfileRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GetProviderProfileRequest
     * @static
     * @param {IGetProviderProfileRequest} message GetProviderProfileRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetProviderProfileRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetProviderProfileRequest message from the specified reader or buffer.
     * @function decode
     * @memberof GetProviderProfileRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GetProviderProfileRequest} GetProviderProfileRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetProviderProfileRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.GetProviderProfileRequest();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.shortUrl = reader.string();
                    break;
                }
            case 2: {
                    message.businessId = reader.string();
                    break;
                }
            case 3: {
                    message.version = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GetProviderProfileRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GetProviderProfileRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GetProviderProfileRequest} GetProviderProfileRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetProviderProfileRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetProviderProfileRequest message.
     * @function verify
     * @memberof GetProviderProfileRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetProviderProfileRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.shortUrl != null && message.hasOwnProperty("shortUrl"))
            if (!$util.isString(message.shortUrl))
                return "shortUrl: string expected";
        if (message.businessId != null && message.hasOwnProperty("businessId"))
            if (!$util.isString(message.businessId))
                return "businessId: string expected";
        if (message.version != null && message.hasOwnProperty("version"))
            if (!$util.isString(message.version))
                return "version: string expected";
        return null;
    };

    /**
     * Creates a GetProviderProfileRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GetProviderProfileRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GetProviderProfileRequest} GetProviderProfileRequest
     */
    GetProviderProfileRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.GetProviderProfileRequest)
            return object;
        let message = new $root.GetProviderProfileRequest();
        if (object.shortUrl != null)
            message.shortUrl = String(object.shortUrl);
        if (object.businessId != null)
            message.businessId = String(object.businessId);
        if (object.version != null)
            message.version = String(object.version);
        return message;
    };

    /**
     * Creates a plain object from a GetProviderProfileRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GetProviderProfileRequest
     * @static
     * @param {GetProviderProfileRequest} message GetProviderProfileRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetProviderProfileRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.shortUrl = "";
            object.businessId = "";
            object.version = "";
        }
        if (message.shortUrl != null && message.hasOwnProperty("shortUrl"))
            object.shortUrl = message.shortUrl;
        if (message.businessId != null && message.hasOwnProperty("businessId"))
            object.businessId = message.businessId;
        if (message.version != null && message.hasOwnProperty("version"))
            object.version = message.version;
        return object;
    };

    /**
     * Converts this GetProviderProfileRequest to JSON.
     * @function toJSON
     * @memberof GetProviderProfileRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetProviderProfileRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetProviderProfileRequest
     * @function getTypeUrl
     * @memberof GetProviderProfileRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetProviderProfileRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GetProviderProfileRequest";
    };

    return GetProviderProfileRequest;
})();

export const GetProviderProfileResponse = $root.GetProviderProfileResponse = (() => {

    /**
     * Properties of a GetProviderProfileResponse.
     * @exports IGetProviderProfileResponse
     * @interface IGetProviderProfileResponse
     * @property {boolean|null} [okay] GetProviderProfileResponse okay
     * @property {IApiBusinessProfileProto|null} [business] GetProviderProfileResponse business
     */

    /**
     * Constructs a new GetProviderProfileResponse.
     * @exports GetProviderProfileResponse
     * @classdesc Represents a GetProviderProfileResponse.
     * @implements IGetProviderProfileResponse
     * @constructor
     * @param {IGetProviderProfileResponse=} [properties] Properties to set
     */
    function GetProviderProfileResponse(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetProviderProfileResponse okay.
     * @member {boolean} okay
     * @memberof GetProviderProfileResponse
     * @instance
     */
    GetProviderProfileResponse.prototype.okay = false;

    /**
     * GetProviderProfileResponse business.
     * @member {IApiBusinessProfileProto|null|undefined} business
     * @memberof GetProviderProfileResponse
     * @instance
     */
    GetProviderProfileResponse.prototype.business = null;

    /**
     * Creates a new GetProviderProfileResponse instance using the specified properties.
     * @function create
     * @memberof GetProviderProfileResponse
     * @static
     * @param {IGetProviderProfileResponse=} [properties] Properties to set
     * @returns {GetProviderProfileResponse} GetProviderProfileResponse instance
     */
    GetProviderProfileResponse.create = function create(properties) {
        return new GetProviderProfileResponse(properties);
    };

    /**
     * Encodes the specified GetProviderProfileResponse message. Does not implicitly {@link GetProviderProfileResponse.verify|verify} messages.
     * @function encode
     * @memberof GetProviderProfileResponse
     * @static
     * @param {IGetProviderProfileResponse} message GetProviderProfileResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetProviderProfileResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        if (message.business != null && Object.hasOwnProperty.call(message, "business"))
            $root.ApiBusinessProfileProto.encode(message.business, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified GetProviderProfileResponse message, length delimited. Does not implicitly {@link GetProviderProfileResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GetProviderProfileResponse
     * @static
     * @param {IGetProviderProfileResponse} message GetProviderProfileResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetProviderProfileResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetProviderProfileResponse message from the specified reader or buffer.
     * @function decode
     * @memberof GetProviderProfileResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GetProviderProfileResponse} GetProviderProfileResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetProviderProfileResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.GetProviderProfileResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            case 2: {
                    message.business = $root.ApiBusinessProfileProto.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GetProviderProfileResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GetProviderProfileResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GetProviderProfileResponse} GetProviderProfileResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetProviderProfileResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetProviderProfileResponse message.
     * @function verify
     * @memberof GetProviderProfileResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetProviderProfileResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        if (message.business != null && message.hasOwnProperty("business")) {
            let error = $root.ApiBusinessProfileProto.verify(message.business);
            if (error)
                return "business." + error;
        }
        return null;
    };

    /**
     * Creates a GetProviderProfileResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GetProviderProfileResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GetProviderProfileResponse} GetProviderProfileResponse
     */
    GetProviderProfileResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.GetProviderProfileResponse)
            return object;
        let message = new $root.GetProviderProfileResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        if (object.business != null) {
            if (typeof object.business !== "object")
                throw TypeError(".GetProviderProfileResponse.business: object expected");
            message.business = $root.ApiBusinessProfileProto.fromObject(object.business);
        }
        return message;
    };

    /**
     * Creates a plain object from a GetProviderProfileResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GetProviderProfileResponse
     * @static
     * @param {GetProviderProfileResponse} message GetProviderProfileResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetProviderProfileResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.okay = false;
            object.business = null;
        }
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        if (message.business != null && message.hasOwnProperty("business"))
            object.business = $root.ApiBusinessProfileProto.toObject(message.business, options);
        return object;
    };

    /**
     * Converts this GetProviderProfileResponse to JSON.
     * @function toJSON
     * @memberof GetProviderProfileResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetProviderProfileResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetProviderProfileResponse
     * @function getTypeUrl
     * @memberof GetProviderProfileResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetProviderProfileResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GetProviderProfileResponse";
    };

    return GetProviderProfileResponse;
})();

export const ApiBusinessProfileProto = $root.ApiBusinessProfileProto = (() => {

    /**
     * Properties of an ApiBusinessProfileProto.
     * @exports IApiBusinessProfileProto
     * @interface IApiBusinessProfileProto
     * @property {string|null} [versionId] ApiBusinessProfileProto versionId
     * @property {number|Long|null} [lastModifiedTimestamp] ApiBusinessProfileProto lastModifiedTimestamp
     * @property {number|Long|null} [reviewTimestamp] ApiBusinessProfileProto reviewTimestamp
     * @property {space.kenko.proto.IProfileReviewProto|null} [review] ApiBusinessProfileProto review
     * @property {space.kenko.proto.IBusinessProto|null} [proto] ApiBusinessProfileProto proto
     * @property {Array.<space.kenko.proto.IBusinessLocationProto>|null} [locations] ApiBusinessProfileProto locations
     * @property {Array.<space.kenko.proto.IProviderBioProto>|null} [providers] ApiBusinessProfileProto providers
     * @property {string|null} [businessId] ApiBusinessProfileProto businessId
     * @property {string|null} [shortUrl] ApiBusinessProfileProto shortUrl
     * @property {Array.<space.kenko.proto.IErrorProto>|null} [errors] ApiBusinessProfileProto errors
     * @property {space.kenko.proto.IPracticeDetailsProto|null} [practice] ApiBusinessProfileProto practice
     */

    /**
     * Constructs a new ApiBusinessProfileProto.
     * @exports ApiBusinessProfileProto
     * @classdesc Represents an ApiBusinessProfileProto.
     * @implements IApiBusinessProfileProto
     * @constructor
     * @param {IApiBusinessProfileProto=} [properties] Properties to set
     */
    function ApiBusinessProfileProto(properties) {
        this.locations = [];
        this.providers = [];
        this.errors = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ApiBusinessProfileProto versionId.
     * @member {string} versionId
     * @memberof ApiBusinessProfileProto
     * @instance
     */
    ApiBusinessProfileProto.prototype.versionId = "";

    /**
     * ApiBusinessProfileProto lastModifiedTimestamp.
     * @member {number|Long} lastModifiedTimestamp
     * @memberof ApiBusinessProfileProto
     * @instance
     */
    ApiBusinessProfileProto.prototype.lastModifiedTimestamp = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * ApiBusinessProfileProto reviewTimestamp.
     * @member {number|Long} reviewTimestamp
     * @memberof ApiBusinessProfileProto
     * @instance
     */
    ApiBusinessProfileProto.prototype.reviewTimestamp = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * ApiBusinessProfileProto review.
     * @member {space.kenko.proto.IProfileReviewProto|null|undefined} review
     * @memberof ApiBusinessProfileProto
     * @instance
     */
    ApiBusinessProfileProto.prototype.review = null;

    /**
     * ApiBusinessProfileProto proto.
     * @member {space.kenko.proto.IBusinessProto|null|undefined} proto
     * @memberof ApiBusinessProfileProto
     * @instance
     */
    ApiBusinessProfileProto.prototype.proto = null;

    /**
     * ApiBusinessProfileProto locations.
     * @member {Array.<space.kenko.proto.IBusinessLocationProto>} locations
     * @memberof ApiBusinessProfileProto
     * @instance
     */
    ApiBusinessProfileProto.prototype.locations = $util.emptyArray;

    /**
     * ApiBusinessProfileProto providers.
     * @member {Array.<space.kenko.proto.IProviderBioProto>} providers
     * @memberof ApiBusinessProfileProto
     * @instance
     */
    ApiBusinessProfileProto.prototype.providers = $util.emptyArray;

    /**
     * ApiBusinessProfileProto businessId.
     * @member {string} businessId
     * @memberof ApiBusinessProfileProto
     * @instance
     */
    ApiBusinessProfileProto.prototype.businessId = "";

    /**
     * ApiBusinessProfileProto shortUrl.
     * @member {string} shortUrl
     * @memberof ApiBusinessProfileProto
     * @instance
     */
    ApiBusinessProfileProto.prototype.shortUrl = "";

    /**
     * ApiBusinessProfileProto errors.
     * @member {Array.<space.kenko.proto.IErrorProto>} errors
     * @memberof ApiBusinessProfileProto
     * @instance
     */
    ApiBusinessProfileProto.prototype.errors = $util.emptyArray;

    /**
     * ApiBusinessProfileProto practice.
     * @member {space.kenko.proto.IPracticeDetailsProto|null|undefined} practice
     * @memberof ApiBusinessProfileProto
     * @instance
     */
    ApiBusinessProfileProto.prototype.practice = null;

    /**
     * Creates a new ApiBusinessProfileProto instance using the specified properties.
     * @function create
     * @memberof ApiBusinessProfileProto
     * @static
     * @param {IApiBusinessProfileProto=} [properties] Properties to set
     * @returns {ApiBusinessProfileProto} ApiBusinessProfileProto instance
     */
    ApiBusinessProfileProto.create = function create(properties) {
        return new ApiBusinessProfileProto(properties);
    };

    /**
     * Encodes the specified ApiBusinessProfileProto message. Does not implicitly {@link ApiBusinessProfileProto.verify|verify} messages.
     * @function encode
     * @memberof ApiBusinessProfileProto
     * @static
     * @param {IApiBusinessProfileProto} message ApiBusinessProfileProto message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ApiBusinessProfileProto.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.versionId != null && Object.hasOwnProperty.call(message, "versionId"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.versionId);
        if (message.lastModifiedTimestamp != null && Object.hasOwnProperty.call(message, "lastModifiedTimestamp"))
            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.lastModifiedTimestamp);
        if (message.reviewTimestamp != null && Object.hasOwnProperty.call(message, "reviewTimestamp"))
            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.reviewTimestamp);
        if (message.review != null && Object.hasOwnProperty.call(message, "review"))
            $root.space.kenko.proto.ProfileReviewProto.encode(message.review, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
        if (message.proto != null && Object.hasOwnProperty.call(message, "proto"))
            $root.space.kenko.proto.BusinessProto.encode(message.proto, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
        if (message.locations != null && message.locations.length)
            for (let i = 0; i < message.locations.length; ++i)
                $root.space.kenko.proto.BusinessLocationProto.encode(message.locations[i], writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
        if (message.providers != null && message.providers.length)
            for (let i = 0; i < message.providers.length; ++i)
                $root.space.kenko.proto.ProviderBioProto.encode(message.providers[i], writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
        if (message.businessId != null && Object.hasOwnProperty.call(message, "businessId"))
            writer.uint32(/* id 13, wireType 2 =*/106).string(message.businessId);
        if (message.shortUrl != null && Object.hasOwnProperty.call(message, "shortUrl"))
            writer.uint32(/* id 14, wireType 2 =*/114).string(message.shortUrl);
        if (message.errors != null && message.errors.length)
            for (let i = 0; i < message.errors.length; ++i)
                $root.space.kenko.proto.ErrorProto.encode(message.errors[i], writer.uint32(/* id 50, wireType 2 =*/402).fork()).ldelim();
        if (message.practice != null && Object.hasOwnProperty.call(message, "practice"))
            $root.space.kenko.proto.PracticeDetailsProto.encode(message.practice, writer.uint32(/* id 100, wireType 2 =*/802).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified ApiBusinessProfileProto message, length delimited. Does not implicitly {@link ApiBusinessProfileProto.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ApiBusinessProfileProto
     * @static
     * @param {IApiBusinessProfileProto} message ApiBusinessProfileProto message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ApiBusinessProfileProto.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an ApiBusinessProfileProto message from the specified reader or buffer.
     * @function decode
     * @memberof ApiBusinessProfileProto
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ApiBusinessProfileProto} ApiBusinessProfileProto
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ApiBusinessProfileProto.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ApiBusinessProfileProto();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.versionId = reader.string();
                    break;
                }
            case 2: {
                    message.lastModifiedTimestamp = reader.int64();
                    break;
                }
            case 3: {
                    message.reviewTimestamp = reader.int64();
                    break;
                }
            case 4: {
                    message.review = $root.space.kenko.proto.ProfileReviewProto.decode(reader, reader.uint32());
                    break;
                }
            case 10: {
                    message.proto = $root.space.kenko.proto.BusinessProto.decode(reader, reader.uint32());
                    break;
                }
            case 11: {
                    if (!(message.locations && message.locations.length))
                        message.locations = [];
                    message.locations.push($root.space.kenko.proto.BusinessLocationProto.decode(reader, reader.uint32()));
                    break;
                }
            case 12: {
                    if (!(message.providers && message.providers.length))
                        message.providers = [];
                    message.providers.push($root.space.kenko.proto.ProviderBioProto.decode(reader, reader.uint32()));
                    break;
                }
            case 13: {
                    message.businessId = reader.string();
                    break;
                }
            case 14: {
                    message.shortUrl = reader.string();
                    break;
                }
            case 50: {
                    if (!(message.errors && message.errors.length))
                        message.errors = [];
                    message.errors.push($root.space.kenko.proto.ErrorProto.decode(reader, reader.uint32()));
                    break;
                }
            case 100: {
                    message.practice = $root.space.kenko.proto.PracticeDetailsProto.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an ApiBusinessProfileProto message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ApiBusinessProfileProto
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ApiBusinessProfileProto} ApiBusinessProfileProto
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ApiBusinessProfileProto.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an ApiBusinessProfileProto message.
     * @function verify
     * @memberof ApiBusinessProfileProto
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ApiBusinessProfileProto.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.versionId != null && message.hasOwnProperty("versionId"))
            if (!$util.isString(message.versionId))
                return "versionId: string expected";
        if (message.lastModifiedTimestamp != null && message.hasOwnProperty("lastModifiedTimestamp"))
            if (!$util.isInteger(message.lastModifiedTimestamp) && !(message.lastModifiedTimestamp && $util.isInteger(message.lastModifiedTimestamp.low) && $util.isInteger(message.lastModifiedTimestamp.high)))
                return "lastModifiedTimestamp: integer|Long expected";
        if (message.reviewTimestamp != null && message.hasOwnProperty("reviewTimestamp"))
            if (!$util.isInteger(message.reviewTimestamp) && !(message.reviewTimestamp && $util.isInteger(message.reviewTimestamp.low) && $util.isInteger(message.reviewTimestamp.high)))
                return "reviewTimestamp: integer|Long expected";
        if (message.review != null && message.hasOwnProperty("review")) {
            let error = $root.space.kenko.proto.ProfileReviewProto.verify(message.review);
            if (error)
                return "review." + error;
        }
        if (message.proto != null && message.hasOwnProperty("proto")) {
            let error = $root.space.kenko.proto.BusinessProto.verify(message.proto);
            if (error)
                return "proto." + error;
        }
        if (message.locations != null && message.hasOwnProperty("locations")) {
            if (!Array.isArray(message.locations))
                return "locations: array expected";
            for (let i = 0; i < message.locations.length; ++i) {
                let error = $root.space.kenko.proto.BusinessLocationProto.verify(message.locations[i]);
                if (error)
                    return "locations." + error;
            }
        }
        if (message.providers != null && message.hasOwnProperty("providers")) {
            if (!Array.isArray(message.providers))
                return "providers: array expected";
            for (let i = 0; i < message.providers.length; ++i) {
                let error = $root.space.kenko.proto.ProviderBioProto.verify(message.providers[i]);
                if (error)
                    return "providers." + error;
            }
        }
        if (message.businessId != null && message.hasOwnProperty("businessId"))
            if (!$util.isString(message.businessId))
                return "businessId: string expected";
        if (message.shortUrl != null && message.hasOwnProperty("shortUrl"))
            if (!$util.isString(message.shortUrl))
                return "shortUrl: string expected";
        if (message.errors != null && message.hasOwnProperty("errors")) {
            if (!Array.isArray(message.errors))
                return "errors: array expected";
            for (let i = 0; i < message.errors.length; ++i) {
                let error = $root.space.kenko.proto.ErrorProto.verify(message.errors[i]);
                if (error)
                    return "errors." + error;
            }
        }
        if (message.practice != null && message.hasOwnProperty("practice")) {
            let error = $root.space.kenko.proto.PracticeDetailsProto.verify(message.practice);
            if (error)
                return "practice." + error;
        }
        return null;
    };

    /**
     * Creates an ApiBusinessProfileProto message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ApiBusinessProfileProto
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ApiBusinessProfileProto} ApiBusinessProfileProto
     */
    ApiBusinessProfileProto.fromObject = function fromObject(object) {
        if (object instanceof $root.ApiBusinessProfileProto)
            return object;
        let message = new $root.ApiBusinessProfileProto();
        if (object.versionId != null)
            message.versionId = String(object.versionId);
        if (object.lastModifiedTimestamp != null)
            if ($util.Long)
                (message.lastModifiedTimestamp = $util.Long.fromValue(object.lastModifiedTimestamp)).unsigned = false;
            else if (typeof object.lastModifiedTimestamp === "string")
                message.lastModifiedTimestamp = parseInt(object.lastModifiedTimestamp, 10);
            else if (typeof object.lastModifiedTimestamp === "number")
                message.lastModifiedTimestamp = object.lastModifiedTimestamp;
            else if (typeof object.lastModifiedTimestamp === "object")
                message.lastModifiedTimestamp = new $util.LongBits(object.lastModifiedTimestamp.low >>> 0, object.lastModifiedTimestamp.high >>> 0).toNumber();
        if (object.reviewTimestamp != null)
            if ($util.Long)
                (message.reviewTimestamp = $util.Long.fromValue(object.reviewTimestamp)).unsigned = false;
            else if (typeof object.reviewTimestamp === "string")
                message.reviewTimestamp = parseInt(object.reviewTimestamp, 10);
            else if (typeof object.reviewTimestamp === "number")
                message.reviewTimestamp = object.reviewTimestamp;
            else if (typeof object.reviewTimestamp === "object")
                message.reviewTimestamp = new $util.LongBits(object.reviewTimestamp.low >>> 0, object.reviewTimestamp.high >>> 0).toNumber();
        if (object.review != null) {
            if (typeof object.review !== "object")
                throw TypeError(".ApiBusinessProfileProto.review: object expected");
            message.review = $root.space.kenko.proto.ProfileReviewProto.fromObject(object.review);
        }
        if (object.proto != null) {
            if (typeof object.proto !== "object")
                throw TypeError(".ApiBusinessProfileProto.proto: object expected");
            message.proto = $root.space.kenko.proto.BusinessProto.fromObject(object.proto);
        }
        if (object.locations) {
            if (!Array.isArray(object.locations))
                throw TypeError(".ApiBusinessProfileProto.locations: array expected");
            message.locations = [];
            for (let i = 0; i < object.locations.length; ++i) {
                if (typeof object.locations[i] !== "object")
                    throw TypeError(".ApiBusinessProfileProto.locations: object expected");
                message.locations[i] = $root.space.kenko.proto.BusinessLocationProto.fromObject(object.locations[i]);
            }
        }
        if (object.providers) {
            if (!Array.isArray(object.providers))
                throw TypeError(".ApiBusinessProfileProto.providers: array expected");
            message.providers = [];
            for (let i = 0; i < object.providers.length; ++i) {
                if (typeof object.providers[i] !== "object")
                    throw TypeError(".ApiBusinessProfileProto.providers: object expected");
                message.providers[i] = $root.space.kenko.proto.ProviderBioProto.fromObject(object.providers[i]);
            }
        }
        if (object.businessId != null)
            message.businessId = String(object.businessId);
        if (object.shortUrl != null)
            message.shortUrl = String(object.shortUrl);
        if (object.errors) {
            if (!Array.isArray(object.errors))
                throw TypeError(".ApiBusinessProfileProto.errors: array expected");
            message.errors = [];
            for (let i = 0; i < object.errors.length; ++i) {
                if (typeof object.errors[i] !== "object")
                    throw TypeError(".ApiBusinessProfileProto.errors: object expected");
                message.errors[i] = $root.space.kenko.proto.ErrorProto.fromObject(object.errors[i]);
            }
        }
        if (object.practice != null) {
            if (typeof object.practice !== "object")
                throw TypeError(".ApiBusinessProfileProto.practice: object expected");
            message.practice = $root.space.kenko.proto.PracticeDetailsProto.fromObject(object.practice);
        }
        return message;
    };

    /**
     * Creates a plain object from an ApiBusinessProfileProto message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ApiBusinessProfileProto
     * @static
     * @param {ApiBusinessProfileProto} message ApiBusinessProfileProto
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ApiBusinessProfileProto.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults) {
            object.locations = [];
            object.providers = [];
            object.errors = [];
        }
        if (options.defaults) {
            object.versionId = "";
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.lastModifiedTimestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.lastModifiedTimestamp = options.longs === String ? "0" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.reviewTimestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.reviewTimestamp = options.longs === String ? "0" : 0;
            object.review = null;
            object.proto = null;
            object.businessId = "";
            object.shortUrl = "";
            object.practice = null;
        }
        if (message.versionId != null && message.hasOwnProperty("versionId"))
            object.versionId = message.versionId;
        if (message.lastModifiedTimestamp != null && message.hasOwnProperty("lastModifiedTimestamp"))
            if (typeof message.lastModifiedTimestamp === "number")
                object.lastModifiedTimestamp = options.longs === String ? String(message.lastModifiedTimestamp) : message.lastModifiedTimestamp;
            else
                object.lastModifiedTimestamp = options.longs === String ? $util.Long.prototype.toString.call(message.lastModifiedTimestamp) : options.longs === Number ? new $util.LongBits(message.lastModifiedTimestamp.low >>> 0, message.lastModifiedTimestamp.high >>> 0).toNumber() : message.lastModifiedTimestamp;
        if (message.reviewTimestamp != null && message.hasOwnProperty("reviewTimestamp"))
            if (typeof message.reviewTimestamp === "number")
                object.reviewTimestamp = options.longs === String ? String(message.reviewTimestamp) : message.reviewTimestamp;
            else
                object.reviewTimestamp = options.longs === String ? $util.Long.prototype.toString.call(message.reviewTimestamp) : options.longs === Number ? new $util.LongBits(message.reviewTimestamp.low >>> 0, message.reviewTimestamp.high >>> 0).toNumber() : message.reviewTimestamp;
        if (message.review != null && message.hasOwnProperty("review"))
            object.review = $root.space.kenko.proto.ProfileReviewProto.toObject(message.review, options);
        if (message.proto != null && message.hasOwnProperty("proto"))
            object.proto = $root.space.kenko.proto.BusinessProto.toObject(message.proto, options);
        if (message.locations && message.locations.length) {
            object.locations = [];
            for (let j = 0; j < message.locations.length; ++j)
                object.locations[j] = $root.space.kenko.proto.BusinessLocationProto.toObject(message.locations[j], options);
        }
        if (message.providers && message.providers.length) {
            object.providers = [];
            for (let j = 0; j < message.providers.length; ++j)
                object.providers[j] = $root.space.kenko.proto.ProviderBioProto.toObject(message.providers[j], options);
        }
        if (message.businessId != null && message.hasOwnProperty("businessId"))
            object.businessId = message.businessId;
        if (message.shortUrl != null && message.hasOwnProperty("shortUrl"))
            object.shortUrl = message.shortUrl;
        if (message.errors && message.errors.length) {
            object.errors = [];
            for (let j = 0; j < message.errors.length; ++j)
                object.errors[j] = $root.space.kenko.proto.ErrorProto.toObject(message.errors[j], options);
        }
        if (message.practice != null && message.hasOwnProperty("practice"))
            object.practice = $root.space.kenko.proto.PracticeDetailsProto.toObject(message.practice, options);
        return object;
    };

    /**
     * Converts this ApiBusinessProfileProto to JSON.
     * @function toJSON
     * @memberof ApiBusinessProfileProto
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ApiBusinessProfileProto.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ApiBusinessProfileProto
     * @function getTypeUrl
     * @memberof ApiBusinessProfileProto
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ApiBusinessProfileProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ApiBusinessProfileProto";
    };

    return ApiBusinessProfileProto;
})();

export const GetAvailableAppointmentsRequest = $root.GetAvailableAppointmentsRequest = (() => {

    /**
     * Properties of a GetAvailableAppointmentsRequest.
     * @exports IGetAvailableAppointmentsRequest
     * @interface IGetAvailableAppointmentsRequest
     * @property {string|null} [sku] GetAvailableAppointmentsRequest sku
     * @property {string|null} [businessId] GetAvailableAppointmentsRequest businessId
     * @property {string|null} [providerId] GetAvailableAppointmentsRequest providerId
     * @property {number|Long|null} [fromTimestampMs] GetAvailableAppointmentsRequest fromTimestampMs
     * @property {number|Long|null} [toTimestampMs] GetAvailableAppointmentsRequest toTimestampMs
     */

    /**
     * Constructs a new GetAvailableAppointmentsRequest.
     * @exports GetAvailableAppointmentsRequest
     * @classdesc Represents a GetAvailableAppointmentsRequest.
     * @implements IGetAvailableAppointmentsRequest
     * @constructor
     * @param {IGetAvailableAppointmentsRequest=} [properties] Properties to set
     */
    function GetAvailableAppointmentsRequest(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetAvailableAppointmentsRequest sku.
     * @member {string} sku
     * @memberof GetAvailableAppointmentsRequest
     * @instance
     */
    GetAvailableAppointmentsRequest.prototype.sku = "";

    /**
     * GetAvailableAppointmentsRequest businessId.
     * @member {string} businessId
     * @memberof GetAvailableAppointmentsRequest
     * @instance
     */
    GetAvailableAppointmentsRequest.prototype.businessId = "";

    /**
     * GetAvailableAppointmentsRequest providerId.
     * @member {string} providerId
     * @memberof GetAvailableAppointmentsRequest
     * @instance
     */
    GetAvailableAppointmentsRequest.prototype.providerId = "";

    /**
     * GetAvailableAppointmentsRequest fromTimestampMs.
     * @member {number|Long} fromTimestampMs
     * @memberof GetAvailableAppointmentsRequest
     * @instance
     */
    GetAvailableAppointmentsRequest.prototype.fromTimestampMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * GetAvailableAppointmentsRequest toTimestampMs.
     * @member {number|Long} toTimestampMs
     * @memberof GetAvailableAppointmentsRequest
     * @instance
     */
    GetAvailableAppointmentsRequest.prototype.toTimestampMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * Creates a new GetAvailableAppointmentsRequest instance using the specified properties.
     * @function create
     * @memberof GetAvailableAppointmentsRequest
     * @static
     * @param {IGetAvailableAppointmentsRequest=} [properties] Properties to set
     * @returns {GetAvailableAppointmentsRequest} GetAvailableAppointmentsRequest instance
     */
    GetAvailableAppointmentsRequest.create = function create(properties) {
        return new GetAvailableAppointmentsRequest(properties);
    };

    /**
     * Encodes the specified GetAvailableAppointmentsRequest message. Does not implicitly {@link GetAvailableAppointmentsRequest.verify|verify} messages.
     * @function encode
     * @memberof GetAvailableAppointmentsRequest
     * @static
     * @param {IGetAvailableAppointmentsRequest} message GetAvailableAppointmentsRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetAvailableAppointmentsRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.sku != null && Object.hasOwnProperty.call(message, "sku"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.sku);
        if (message.businessId != null && Object.hasOwnProperty.call(message, "businessId"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.businessId);
        if (message.providerId != null && Object.hasOwnProperty.call(message, "providerId"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.providerId);
        if (message.fromTimestampMs != null && Object.hasOwnProperty.call(message, "fromTimestampMs"))
            writer.uint32(/* id 4, wireType 0 =*/32).int64(message.fromTimestampMs);
        if (message.toTimestampMs != null && Object.hasOwnProperty.call(message, "toTimestampMs"))
            writer.uint32(/* id 5, wireType 0 =*/40).int64(message.toTimestampMs);
        return writer;
    };

    /**
     * Encodes the specified GetAvailableAppointmentsRequest message, length delimited. Does not implicitly {@link GetAvailableAppointmentsRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GetAvailableAppointmentsRequest
     * @static
     * @param {IGetAvailableAppointmentsRequest} message GetAvailableAppointmentsRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetAvailableAppointmentsRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetAvailableAppointmentsRequest message from the specified reader or buffer.
     * @function decode
     * @memberof GetAvailableAppointmentsRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GetAvailableAppointmentsRequest} GetAvailableAppointmentsRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetAvailableAppointmentsRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.GetAvailableAppointmentsRequest();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.sku = reader.string();
                    break;
                }
            case 2: {
                    message.businessId = reader.string();
                    break;
                }
            case 3: {
                    message.providerId = reader.string();
                    break;
                }
            case 4: {
                    message.fromTimestampMs = reader.int64();
                    break;
                }
            case 5: {
                    message.toTimestampMs = reader.int64();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GetAvailableAppointmentsRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GetAvailableAppointmentsRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GetAvailableAppointmentsRequest} GetAvailableAppointmentsRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetAvailableAppointmentsRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetAvailableAppointmentsRequest message.
     * @function verify
     * @memberof GetAvailableAppointmentsRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetAvailableAppointmentsRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.sku != null && message.hasOwnProperty("sku"))
            if (!$util.isString(message.sku))
                return "sku: string expected";
        if (message.businessId != null && message.hasOwnProperty("businessId"))
            if (!$util.isString(message.businessId))
                return "businessId: string expected";
        if (message.providerId != null && message.hasOwnProperty("providerId"))
            if (!$util.isString(message.providerId))
                return "providerId: string expected";
        if (message.fromTimestampMs != null && message.hasOwnProperty("fromTimestampMs"))
            if (!$util.isInteger(message.fromTimestampMs) && !(message.fromTimestampMs && $util.isInteger(message.fromTimestampMs.low) && $util.isInteger(message.fromTimestampMs.high)))
                return "fromTimestampMs: integer|Long expected";
        if (message.toTimestampMs != null && message.hasOwnProperty("toTimestampMs"))
            if (!$util.isInteger(message.toTimestampMs) && !(message.toTimestampMs && $util.isInteger(message.toTimestampMs.low) && $util.isInteger(message.toTimestampMs.high)))
                return "toTimestampMs: integer|Long expected";
        return null;
    };

    /**
     * Creates a GetAvailableAppointmentsRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GetAvailableAppointmentsRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GetAvailableAppointmentsRequest} GetAvailableAppointmentsRequest
     */
    GetAvailableAppointmentsRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.GetAvailableAppointmentsRequest)
            return object;
        let message = new $root.GetAvailableAppointmentsRequest();
        if (object.sku != null)
            message.sku = String(object.sku);
        if (object.businessId != null)
            message.businessId = String(object.businessId);
        if (object.providerId != null)
            message.providerId = String(object.providerId);
        if (object.fromTimestampMs != null)
            if ($util.Long)
                (message.fromTimestampMs = $util.Long.fromValue(object.fromTimestampMs)).unsigned = false;
            else if (typeof object.fromTimestampMs === "string")
                message.fromTimestampMs = parseInt(object.fromTimestampMs, 10);
            else if (typeof object.fromTimestampMs === "number")
                message.fromTimestampMs = object.fromTimestampMs;
            else if (typeof object.fromTimestampMs === "object")
                message.fromTimestampMs = new $util.LongBits(object.fromTimestampMs.low >>> 0, object.fromTimestampMs.high >>> 0).toNumber();
        if (object.toTimestampMs != null)
            if ($util.Long)
                (message.toTimestampMs = $util.Long.fromValue(object.toTimestampMs)).unsigned = false;
            else if (typeof object.toTimestampMs === "string")
                message.toTimestampMs = parseInt(object.toTimestampMs, 10);
            else if (typeof object.toTimestampMs === "number")
                message.toTimestampMs = object.toTimestampMs;
            else if (typeof object.toTimestampMs === "object")
                message.toTimestampMs = new $util.LongBits(object.toTimestampMs.low >>> 0, object.toTimestampMs.high >>> 0).toNumber();
        return message;
    };

    /**
     * Creates a plain object from a GetAvailableAppointmentsRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GetAvailableAppointmentsRequest
     * @static
     * @param {GetAvailableAppointmentsRequest} message GetAvailableAppointmentsRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetAvailableAppointmentsRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.sku = "";
            object.businessId = "";
            object.providerId = "";
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.fromTimestampMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.fromTimestampMs = options.longs === String ? "0" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.toTimestampMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.toTimestampMs = options.longs === String ? "0" : 0;
        }
        if (message.sku != null && message.hasOwnProperty("sku"))
            object.sku = message.sku;
        if (message.businessId != null && message.hasOwnProperty("businessId"))
            object.businessId = message.businessId;
        if (message.providerId != null && message.hasOwnProperty("providerId"))
            object.providerId = message.providerId;
        if (message.fromTimestampMs != null && message.hasOwnProperty("fromTimestampMs"))
            if (typeof message.fromTimestampMs === "number")
                object.fromTimestampMs = options.longs === String ? String(message.fromTimestampMs) : message.fromTimestampMs;
            else
                object.fromTimestampMs = options.longs === String ? $util.Long.prototype.toString.call(message.fromTimestampMs) : options.longs === Number ? new $util.LongBits(message.fromTimestampMs.low >>> 0, message.fromTimestampMs.high >>> 0).toNumber() : message.fromTimestampMs;
        if (message.toTimestampMs != null && message.hasOwnProperty("toTimestampMs"))
            if (typeof message.toTimestampMs === "number")
                object.toTimestampMs = options.longs === String ? String(message.toTimestampMs) : message.toTimestampMs;
            else
                object.toTimestampMs = options.longs === String ? $util.Long.prototype.toString.call(message.toTimestampMs) : options.longs === Number ? new $util.LongBits(message.toTimestampMs.low >>> 0, message.toTimestampMs.high >>> 0).toNumber() : message.toTimestampMs;
        return object;
    };

    /**
     * Converts this GetAvailableAppointmentsRequest to JSON.
     * @function toJSON
     * @memberof GetAvailableAppointmentsRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetAvailableAppointmentsRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetAvailableAppointmentsRequest
     * @function getTypeUrl
     * @memberof GetAvailableAppointmentsRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetAvailableAppointmentsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GetAvailableAppointmentsRequest";
    };

    return GetAvailableAppointmentsRequest;
})();

export const ClientServiceProto = $root.ClientServiceProto = (() => {

    /**
     * Properties of a ClientServiceProto.
     * @exports IClientServiceProto
     * @interface IClientServiceProto
     * @property {string|null} [sku] ClientServiceProto sku
     * @property {string|null} [name] ClientServiceProto name
     * @property {string|null} [description] ClientServiceProto description
     * @property {number|null} [lengthMinutes] ClientServiceProto lengthMinutes
     */

    /**
     * Constructs a new ClientServiceProto.
     * @exports ClientServiceProto
     * @classdesc Represents a ClientServiceProto.
     * @implements IClientServiceProto
     * @constructor
     * @param {IClientServiceProto=} [properties] Properties to set
     */
    function ClientServiceProto(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ClientServiceProto sku.
     * @member {string} sku
     * @memberof ClientServiceProto
     * @instance
     */
    ClientServiceProto.prototype.sku = "";

    /**
     * ClientServiceProto name.
     * @member {string} name
     * @memberof ClientServiceProto
     * @instance
     */
    ClientServiceProto.prototype.name = "";

    /**
     * ClientServiceProto description.
     * @member {string} description
     * @memberof ClientServiceProto
     * @instance
     */
    ClientServiceProto.prototype.description = "";

    /**
     * ClientServiceProto lengthMinutes.
     * @member {number} lengthMinutes
     * @memberof ClientServiceProto
     * @instance
     */
    ClientServiceProto.prototype.lengthMinutes = 0;

    /**
     * Creates a new ClientServiceProto instance using the specified properties.
     * @function create
     * @memberof ClientServiceProto
     * @static
     * @param {IClientServiceProto=} [properties] Properties to set
     * @returns {ClientServiceProto} ClientServiceProto instance
     */
    ClientServiceProto.create = function create(properties) {
        return new ClientServiceProto(properties);
    };

    /**
     * Encodes the specified ClientServiceProto message. Does not implicitly {@link ClientServiceProto.verify|verify} messages.
     * @function encode
     * @memberof ClientServiceProto
     * @static
     * @param {IClientServiceProto} message ClientServiceProto message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ClientServiceProto.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.sku != null && Object.hasOwnProperty.call(message, "sku"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.sku);
        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
        if (message.description != null && Object.hasOwnProperty.call(message, "description"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.description);
        if (message.lengthMinutes != null && Object.hasOwnProperty.call(message, "lengthMinutes"))
            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.lengthMinutes);
        return writer;
    };

    /**
     * Encodes the specified ClientServiceProto message, length delimited. Does not implicitly {@link ClientServiceProto.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ClientServiceProto
     * @static
     * @param {IClientServiceProto} message ClientServiceProto message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ClientServiceProto.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ClientServiceProto message from the specified reader or buffer.
     * @function decode
     * @memberof ClientServiceProto
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ClientServiceProto} ClientServiceProto
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ClientServiceProto.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ClientServiceProto();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.sku = reader.string();
                    break;
                }
            case 2: {
                    message.name = reader.string();
                    break;
                }
            case 3: {
                    message.description = reader.string();
                    break;
                }
            case 4: {
                    message.lengthMinutes = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a ClientServiceProto message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ClientServiceProto
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ClientServiceProto} ClientServiceProto
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ClientServiceProto.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ClientServiceProto message.
     * @function verify
     * @memberof ClientServiceProto
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ClientServiceProto.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.sku != null && message.hasOwnProperty("sku"))
            if (!$util.isString(message.sku))
                return "sku: string expected";
        if (message.name != null && message.hasOwnProperty("name"))
            if (!$util.isString(message.name))
                return "name: string expected";
        if (message.description != null && message.hasOwnProperty("description"))
            if (!$util.isString(message.description))
                return "description: string expected";
        if (message.lengthMinutes != null && message.hasOwnProperty("lengthMinutes"))
            if (!$util.isInteger(message.lengthMinutes))
                return "lengthMinutes: integer expected";
        return null;
    };

    /**
     * Creates a ClientServiceProto message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ClientServiceProto
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ClientServiceProto} ClientServiceProto
     */
    ClientServiceProto.fromObject = function fromObject(object) {
        if (object instanceof $root.ClientServiceProto)
            return object;
        let message = new $root.ClientServiceProto();
        if (object.sku != null)
            message.sku = String(object.sku);
        if (object.name != null)
            message.name = String(object.name);
        if (object.description != null)
            message.description = String(object.description);
        if (object.lengthMinutes != null)
            message.lengthMinutes = object.lengthMinutes | 0;
        return message;
    };

    /**
     * Creates a plain object from a ClientServiceProto message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ClientServiceProto
     * @static
     * @param {ClientServiceProto} message ClientServiceProto
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ClientServiceProto.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.sku = "";
            object.name = "";
            object.description = "";
            object.lengthMinutes = 0;
        }
        if (message.sku != null && message.hasOwnProperty("sku"))
            object.sku = message.sku;
        if (message.name != null && message.hasOwnProperty("name"))
            object.name = message.name;
        if (message.description != null && message.hasOwnProperty("description"))
            object.description = message.description;
        if (message.lengthMinutes != null && message.hasOwnProperty("lengthMinutes"))
            object.lengthMinutes = message.lengthMinutes;
        return object;
    };

    /**
     * Converts this ClientServiceProto to JSON.
     * @function toJSON
     * @memberof ClientServiceProto
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ClientServiceProto.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ClientServiceProto
     * @function getTypeUrl
     * @memberof ClientServiceProto
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ClientServiceProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ClientServiceProto";
    };

    return ClientServiceProto;
})();

export const GetAvailableAppointmentsResponse = $root.GetAvailableAppointmentsResponse = (() => {

    /**
     * Properties of a GetAvailableAppointmentsResponse.
     * @exports IGetAvailableAppointmentsResponse
     * @interface IGetAvailableAppointmentsResponse
     * @property {boolean|null} [okay] GetAvailableAppointmentsResponse okay
     * @property {IClientServiceProto|null} [service] GetAvailableAppointmentsResponse service
     * @property {Array.<IAppointmentTimesForProvider>|null} [availableTimes] GetAvailableAppointmentsResponse availableTimes
     */

    /**
     * Constructs a new GetAvailableAppointmentsResponse.
     * @exports GetAvailableAppointmentsResponse
     * @classdesc Represents a GetAvailableAppointmentsResponse.
     * @implements IGetAvailableAppointmentsResponse
     * @constructor
     * @param {IGetAvailableAppointmentsResponse=} [properties] Properties to set
     */
    function GetAvailableAppointmentsResponse(properties) {
        this.availableTimes = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetAvailableAppointmentsResponse okay.
     * @member {boolean} okay
     * @memberof GetAvailableAppointmentsResponse
     * @instance
     */
    GetAvailableAppointmentsResponse.prototype.okay = false;

    /**
     * GetAvailableAppointmentsResponse service.
     * @member {IClientServiceProto|null|undefined} service
     * @memberof GetAvailableAppointmentsResponse
     * @instance
     */
    GetAvailableAppointmentsResponse.prototype.service = null;

    /**
     * GetAvailableAppointmentsResponse availableTimes.
     * @member {Array.<IAppointmentTimesForProvider>} availableTimes
     * @memberof GetAvailableAppointmentsResponse
     * @instance
     */
    GetAvailableAppointmentsResponse.prototype.availableTimes = $util.emptyArray;

    /**
     * Creates a new GetAvailableAppointmentsResponse instance using the specified properties.
     * @function create
     * @memberof GetAvailableAppointmentsResponse
     * @static
     * @param {IGetAvailableAppointmentsResponse=} [properties] Properties to set
     * @returns {GetAvailableAppointmentsResponse} GetAvailableAppointmentsResponse instance
     */
    GetAvailableAppointmentsResponse.create = function create(properties) {
        return new GetAvailableAppointmentsResponse(properties);
    };

    /**
     * Encodes the specified GetAvailableAppointmentsResponse message. Does not implicitly {@link GetAvailableAppointmentsResponse.verify|verify} messages.
     * @function encode
     * @memberof GetAvailableAppointmentsResponse
     * @static
     * @param {IGetAvailableAppointmentsResponse} message GetAvailableAppointmentsResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetAvailableAppointmentsResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        if (message.service != null && Object.hasOwnProperty.call(message, "service"))
            $root.ClientServiceProto.encode(message.service, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.availableTimes != null && message.availableTimes.length)
            for (let i = 0; i < message.availableTimes.length; ++i)
                $root.AppointmentTimesForProvider.encode(message.availableTimes[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified GetAvailableAppointmentsResponse message, length delimited. Does not implicitly {@link GetAvailableAppointmentsResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GetAvailableAppointmentsResponse
     * @static
     * @param {IGetAvailableAppointmentsResponse} message GetAvailableAppointmentsResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetAvailableAppointmentsResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetAvailableAppointmentsResponse message from the specified reader or buffer.
     * @function decode
     * @memberof GetAvailableAppointmentsResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GetAvailableAppointmentsResponse} GetAvailableAppointmentsResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetAvailableAppointmentsResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.GetAvailableAppointmentsResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            case 2: {
                    message.service = $root.ClientServiceProto.decode(reader, reader.uint32());
                    break;
                }
            case 3: {
                    if (!(message.availableTimes && message.availableTimes.length))
                        message.availableTimes = [];
                    message.availableTimes.push($root.AppointmentTimesForProvider.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GetAvailableAppointmentsResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GetAvailableAppointmentsResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GetAvailableAppointmentsResponse} GetAvailableAppointmentsResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetAvailableAppointmentsResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetAvailableAppointmentsResponse message.
     * @function verify
     * @memberof GetAvailableAppointmentsResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetAvailableAppointmentsResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        if (message.service != null && message.hasOwnProperty("service")) {
            let error = $root.ClientServiceProto.verify(message.service);
            if (error)
                return "service." + error;
        }
        if (message.availableTimes != null && message.hasOwnProperty("availableTimes")) {
            if (!Array.isArray(message.availableTimes))
                return "availableTimes: array expected";
            for (let i = 0; i < message.availableTimes.length; ++i) {
                let error = $root.AppointmentTimesForProvider.verify(message.availableTimes[i]);
                if (error)
                    return "availableTimes." + error;
            }
        }
        return null;
    };

    /**
     * Creates a GetAvailableAppointmentsResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GetAvailableAppointmentsResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GetAvailableAppointmentsResponse} GetAvailableAppointmentsResponse
     */
    GetAvailableAppointmentsResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.GetAvailableAppointmentsResponse)
            return object;
        let message = new $root.GetAvailableAppointmentsResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        if (object.service != null) {
            if (typeof object.service !== "object")
                throw TypeError(".GetAvailableAppointmentsResponse.service: object expected");
            message.service = $root.ClientServiceProto.fromObject(object.service);
        }
        if (object.availableTimes) {
            if (!Array.isArray(object.availableTimes))
                throw TypeError(".GetAvailableAppointmentsResponse.availableTimes: array expected");
            message.availableTimes = [];
            for (let i = 0; i < object.availableTimes.length; ++i) {
                if (typeof object.availableTimes[i] !== "object")
                    throw TypeError(".GetAvailableAppointmentsResponse.availableTimes: object expected");
                message.availableTimes[i] = $root.AppointmentTimesForProvider.fromObject(object.availableTimes[i]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from a GetAvailableAppointmentsResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GetAvailableAppointmentsResponse
     * @static
     * @param {GetAvailableAppointmentsResponse} message GetAvailableAppointmentsResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetAvailableAppointmentsResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.availableTimes = [];
        if (options.defaults) {
            object.okay = false;
            object.service = null;
        }
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        if (message.service != null && message.hasOwnProperty("service"))
            object.service = $root.ClientServiceProto.toObject(message.service, options);
        if (message.availableTimes && message.availableTimes.length) {
            object.availableTimes = [];
            for (let j = 0; j < message.availableTimes.length; ++j)
                object.availableTimes[j] = $root.AppointmentTimesForProvider.toObject(message.availableTimes[j], options);
        }
        return object;
    };

    /**
     * Converts this GetAvailableAppointmentsResponse to JSON.
     * @function toJSON
     * @memberof GetAvailableAppointmentsResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetAvailableAppointmentsResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetAvailableAppointmentsResponse
     * @function getTypeUrl
     * @memberof GetAvailableAppointmentsResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetAvailableAppointmentsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GetAvailableAppointmentsResponse";
    };

    return GetAvailableAppointmentsResponse;
})();

export const AppointmentTimesForProvider = $root.AppointmentTimesForProvider = (() => {

    /**
     * Properties of an AppointmentTimesForProvider.
     * @exports IAppointmentTimesForProvider
     * @interface IAppointmentTimesForProvider
     * @property {space.kenko.proto.IProviderBioProto|null} [provider] AppointmentTimesForProvider provider
     * @property {space.kenko.proto.IPriceProto|null} [price] AppointmentTimesForProvider price
     * @property {Array.<number|Long>|null} [availableTimes] AppointmentTimesForProvider availableTimes
     */

    /**
     * Constructs a new AppointmentTimesForProvider.
     * @exports AppointmentTimesForProvider
     * @classdesc Represents an AppointmentTimesForProvider.
     * @implements IAppointmentTimesForProvider
     * @constructor
     * @param {IAppointmentTimesForProvider=} [properties] Properties to set
     */
    function AppointmentTimesForProvider(properties) {
        this.availableTimes = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * AppointmentTimesForProvider provider.
     * @member {space.kenko.proto.IProviderBioProto|null|undefined} provider
     * @memberof AppointmentTimesForProvider
     * @instance
     */
    AppointmentTimesForProvider.prototype.provider = null;

    /**
     * AppointmentTimesForProvider price.
     * @member {space.kenko.proto.IPriceProto|null|undefined} price
     * @memberof AppointmentTimesForProvider
     * @instance
     */
    AppointmentTimesForProvider.prototype.price = null;

    /**
     * AppointmentTimesForProvider availableTimes.
     * @member {Array.<number|Long>} availableTimes
     * @memberof AppointmentTimesForProvider
     * @instance
     */
    AppointmentTimesForProvider.prototype.availableTimes = $util.emptyArray;

    /**
     * Creates a new AppointmentTimesForProvider instance using the specified properties.
     * @function create
     * @memberof AppointmentTimesForProvider
     * @static
     * @param {IAppointmentTimesForProvider=} [properties] Properties to set
     * @returns {AppointmentTimesForProvider} AppointmentTimesForProvider instance
     */
    AppointmentTimesForProvider.create = function create(properties) {
        return new AppointmentTimesForProvider(properties);
    };

    /**
     * Encodes the specified AppointmentTimesForProvider message. Does not implicitly {@link AppointmentTimesForProvider.verify|verify} messages.
     * @function encode
     * @memberof AppointmentTimesForProvider
     * @static
     * @param {IAppointmentTimesForProvider} message AppointmentTimesForProvider message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AppointmentTimesForProvider.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.provider != null && Object.hasOwnProperty.call(message, "provider"))
            $root.space.kenko.proto.ProviderBioProto.encode(message.provider, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        if (message.price != null && Object.hasOwnProperty.call(message, "price"))
            $root.space.kenko.proto.PriceProto.encode(message.price, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.availableTimes != null && message.availableTimes.length) {
            writer.uint32(/* id 3, wireType 2 =*/26).fork();
            for (let i = 0; i < message.availableTimes.length; ++i)
                writer.int64(message.availableTimes[i]);
            writer.ldelim();
        }
        return writer;
    };

    /**
     * Encodes the specified AppointmentTimesForProvider message, length delimited. Does not implicitly {@link AppointmentTimesForProvider.verify|verify} messages.
     * @function encodeDelimited
     * @memberof AppointmentTimesForProvider
     * @static
     * @param {IAppointmentTimesForProvider} message AppointmentTimesForProvider message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AppointmentTimesForProvider.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AppointmentTimesForProvider message from the specified reader or buffer.
     * @function decode
     * @memberof AppointmentTimesForProvider
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {AppointmentTimesForProvider} AppointmentTimesForProvider
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AppointmentTimesForProvider.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.AppointmentTimesForProvider();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.provider = $root.space.kenko.proto.ProviderBioProto.decode(reader, reader.uint32());
                    break;
                }
            case 2: {
                    message.price = $root.space.kenko.proto.PriceProto.decode(reader, reader.uint32());
                    break;
                }
            case 3: {
                    if (!(message.availableTimes && message.availableTimes.length))
                        message.availableTimes = [];
                    if ((tag & 7) === 2) {
                        let end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.availableTimes.push(reader.int64());
                    } else
                        message.availableTimes.push(reader.int64());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an AppointmentTimesForProvider message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof AppointmentTimesForProvider
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {AppointmentTimesForProvider} AppointmentTimesForProvider
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AppointmentTimesForProvider.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AppointmentTimesForProvider message.
     * @function verify
     * @memberof AppointmentTimesForProvider
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AppointmentTimesForProvider.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.provider != null && message.hasOwnProperty("provider")) {
            let error = $root.space.kenko.proto.ProviderBioProto.verify(message.provider);
            if (error)
                return "provider." + error;
        }
        if (message.price != null && message.hasOwnProperty("price")) {
            let error = $root.space.kenko.proto.PriceProto.verify(message.price);
            if (error)
                return "price." + error;
        }
        if (message.availableTimes != null && message.hasOwnProperty("availableTimes")) {
            if (!Array.isArray(message.availableTimes))
                return "availableTimes: array expected";
            for (let i = 0; i < message.availableTimes.length; ++i)
                if (!$util.isInteger(message.availableTimes[i]) && !(message.availableTimes[i] && $util.isInteger(message.availableTimes[i].low) && $util.isInteger(message.availableTimes[i].high)))
                    return "availableTimes: integer|Long[] expected";
        }
        return null;
    };

    /**
     * Creates an AppointmentTimesForProvider message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof AppointmentTimesForProvider
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {AppointmentTimesForProvider} AppointmentTimesForProvider
     */
    AppointmentTimesForProvider.fromObject = function fromObject(object) {
        if (object instanceof $root.AppointmentTimesForProvider)
            return object;
        let message = new $root.AppointmentTimesForProvider();
        if (object.provider != null) {
            if (typeof object.provider !== "object")
                throw TypeError(".AppointmentTimesForProvider.provider: object expected");
            message.provider = $root.space.kenko.proto.ProviderBioProto.fromObject(object.provider);
        }
        if (object.price != null) {
            if (typeof object.price !== "object")
                throw TypeError(".AppointmentTimesForProvider.price: object expected");
            message.price = $root.space.kenko.proto.PriceProto.fromObject(object.price);
        }
        if (object.availableTimes) {
            if (!Array.isArray(object.availableTimes))
                throw TypeError(".AppointmentTimesForProvider.availableTimes: array expected");
            message.availableTimes = [];
            for (let i = 0; i < object.availableTimes.length; ++i)
                if ($util.Long)
                    (message.availableTimes[i] = $util.Long.fromValue(object.availableTimes[i])).unsigned = false;
                else if (typeof object.availableTimes[i] === "string")
                    message.availableTimes[i] = parseInt(object.availableTimes[i], 10);
                else if (typeof object.availableTimes[i] === "number")
                    message.availableTimes[i] = object.availableTimes[i];
                else if (typeof object.availableTimes[i] === "object")
                    message.availableTimes[i] = new $util.LongBits(object.availableTimes[i].low >>> 0, object.availableTimes[i].high >>> 0).toNumber();
        }
        return message;
    };

    /**
     * Creates a plain object from an AppointmentTimesForProvider message. Also converts values to other types if specified.
     * @function toObject
     * @memberof AppointmentTimesForProvider
     * @static
     * @param {AppointmentTimesForProvider} message AppointmentTimesForProvider
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AppointmentTimesForProvider.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.availableTimes = [];
        if (options.defaults) {
            object.provider = null;
            object.price = null;
        }
        if (message.provider != null && message.hasOwnProperty("provider"))
            object.provider = $root.space.kenko.proto.ProviderBioProto.toObject(message.provider, options);
        if (message.price != null && message.hasOwnProperty("price"))
            object.price = $root.space.kenko.proto.PriceProto.toObject(message.price, options);
        if (message.availableTimes && message.availableTimes.length) {
            object.availableTimes = [];
            for (let j = 0; j < message.availableTimes.length; ++j)
                if (typeof message.availableTimes[j] === "number")
                    object.availableTimes[j] = options.longs === String ? String(message.availableTimes[j]) : message.availableTimes[j];
                else
                    object.availableTimes[j] = options.longs === String ? $util.Long.prototype.toString.call(message.availableTimes[j]) : options.longs === Number ? new $util.LongBits(message.availableTimes[j].low >>> 0, message.availableTimes[j].high >>> 0).toNumber() : message.availableTimes[j];
        }
        return object;
    };

    /**
     * Converts this AppointmentTimesForProvider to JSON.
     * @function toJSON
     * @memberof AppointmentTimesForProvider
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AppointmentTimesForProvider.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AppointmentTimesForProvider
     * @function getTypeUrl
     * @memberof AppointmentTimesForProvider
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AppointmentTimesForProvider.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/AppointmentTimesForProvider";
    };

    return AppointmentTimesForProvider;
})();

export const GetUserProfileRequest = $root.GetUserProfileRequest = (() => {

    /**
     * Properties of a GetUserProfileRequest.
     * @exports IGetUserProfileRequest
     * @interface IGetUserProfileRequest
     */

    /**
     * Constructs a new GetUserProfileRequest.
     * @exports GetUserProfileRequest
     * @classdesc Represents a GetUserProfileRequest.
     * @implements IGetUserProfileRequest
     * @constructor
     * @param {IGetUserProfileRequest=} [properties] Properties to set
     */
    function GetUserProfileRequest(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Creates a new GetUserProfileRequest instance using the specified properties.
     * @function create
     * @memberof GetUserProfileRequest
     * @static
     * @param {IGetUserProfileRequest=} [properties] Properties to set
     * @returns {GetUserProfileRequest} GetUserProfileRequest instance
     */
    GetUserProfileRequest.create = function create(properties) {
        return new GetUserProfileRequest(properties);
    };

    /**
     * Encodes the specified GetUserProfileRequest message. Does not implicitly {@link GetUserProfileRequest.verify|verify} messages.
     * @function encode
     * @memberof GetUserProfileRequest
     * @static
     * @param {IGetUserProfileRequest} message GetUserProfileRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetUserProfileRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        return writer;
    };

    /**
     * Encodes the specified GetUserProfileRequest message, length delimited. Does not implicitly {@link GetUserProfileRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GetUserProfileRequest
     * @static
     * @param {IGetUserProfileRequest} message GetUserProfileRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetUserProfileRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetUserProfileRequest message from the specified reader or buffer.
     * @function decode
     * @memberof GetUserProfileRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GetUserProfileRequest} GetUserProfileRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetUserProfileRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.GetUserProfileRequest();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GetUserProfileRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GetUserProfileRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GetUserProfileRequest} GetUserProfileRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetUserProfileRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetUserProfileRequest message.
     * @function verify
     * @memberof GetUserProfileRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetUserProfileRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        return null;
    };

    /**
     * Creates a GetUserProfileRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GetUserProfileRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GetUserProfileRequest} GetUserProfileRequest
     */
    GetUserProfileRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.GetUserProfileRequest)
            return object;
        return new $root.GetUserProfileRequest();
    };

    /**
     * Creates a plain object from a GetUserProfileRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GetUserProfileRequest
     * @static
     * @param {GetUserProfileRequest} message GetUserProfileRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetUserProfileRequest.toObject = function toObject() {
        return {};
    };

    /**
     * Converts this GetUserProfileRequest to JSON.
     * @function toJSON
     * @memberof GetUserProfileRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetUserProfileRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetUserProfileRequest
     * @function getTypeUrl
     * @memberof GetUserProfileRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetUserProfileRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GetUserProfileRequest";
    };

    return GetUserProfileRequest;
})();

export const GetUserProfileResponse = $root.GetUserProfileResponse = (() => {

    /**
     * Properties of a GetUserProfileResponse.
     * @exports IGetUserProfileResponse
     * @interface IGetUserProfileResponse
     * @property {boolean|null} [okay] GetUserProfileResponse okay
     * @property {IClientProfileProto|null} [profile] GetUserProfileResponse profile
     */

    /**
     * Constructs a new GetUserProfileResponse.
     * @exports GetUserProfileResponse
     * @classdesc Represents a GetUserProfileResponse.
     * @implements IGetUserProfileResponse
     * @constructor
     * @param {IGetUserProfileResponse=} [properties] Properties to set
     */
    function GetUserProfileResponse(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetUserProfileResponse okay.
     * @member {boolean} okay
     * @memberof GetUserProfileResponse
     * @instance
     */
    GetUserProfileResponse.prototype.okay = false;

    /**
     * GetUserProfileResponse profile.
     * @member {IClientProfileProto|null|undefined} profile
     * @memberof GetUserProfileResponse
     * @instance
     */
    GetUserProfileResponse.prototype.profile = null;

    /**
     * Creates a new GetUserProfileResponse instance using the specified properties.
     * @function create
     * @memberof GetUserProfileResponse
     * @static
     * @param {IGetUserProfileResponse=} [properties] Properties to set
     * @returns {GetUserProfileResponse} GetUserProfileResponse instance
     */
    GetUserProfileResponse.create = function create(properties) {
        return new GetUserProfileResponse(properties);
    };

    /**
     * Encodes the specified GetUserProfileResponse message. Does not implicitly {@link GetUserProfileResponse.verify|verify} messages.
     * @function encode
     * @memberof GetUserProfileResponse
     * @static
     * @param {IGetUserProfileResponse} message GetUserProfileResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetUserProfileResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        if (message.profile != null && Object.hasOwnProperty.call(message, "profile"))
            $root.ClientProfileProto.encode(message.profile, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified GetUserProfileResponse message, length delimited. Does not implicitly {@link GetUserProfileResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GetUserProfileResponse
     * @static
     * @param {IGetUserProfileResponse} message GetUserProfileResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetUserProfileResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetUserProfileResponse message from the specified reader or buffer.
     * @function decode
     * @memberof GetUserProfileResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GetUserProfileResponse} GetUserProfileResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetUserProfileResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.GetUserProfileResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            case 2: {
                    message.profile = $root.ClientProfileProto.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GetUserProfileResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GetUserProfileResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GetUserProfileResponse} GetUserProfileResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetUserProfileResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetUserProfileResponse message.
     * @function verify
     * @memberof GetUserProfileResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetUserProfileResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        if (message.profile != null && message.hasOwnProperty("profile")) {
            let error = $root.ClientProfileProto.verify(message.profile);
            if (error)
                return "profile." + error;
        }
        return null;
    };

    /**
     * Creates a GetUserProfileResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GetUserProfileResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GetUserProfileResponse} GetUserProfileResponse
     */
    GetUserProfileResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.GetUserProfileResponse)
            return object;
        let message = new $root.GetUserProfileResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        if (object.profile != null) {
            if (typeof object.profile !== "object")
                throw TypeError(".GetUserProfileResponse.profile: object expected");
            message.profile = $root.ClientProfileProto.fromObject(object.profile);
        }
        return message;
    };

    /**
     * Creates a plain object from a GetUserProfileResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GetUserProfileResponse
     * @static
     * @param {GetUserProfileResponse} message GetUserProfileResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetUserProfileResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.okay = false;
            object.profile = null;
        }
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        if (message.profile != null && message.hasOwnProperty("profile"))
            object.profile = $root.ClientProfileProto.toObject(message.profile, options);
        return object;
    };

    /**
     * Converts this GetUserProfileResponse to JSON.
     * @function toJSON
     * @memberof GetUserProfileResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetUserProfileResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetUserProfileResponse
     * @function getTypeUrl
     * @memberof GetUserProfileResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetUserProfileResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GetUserProfileResponse";
    };

    return GetUserProfileResponse;
})();

export const UpdateUserProfileRequest = $root.UpdateUserProfileRequest = (() => {

    /**
     * Properties of an UpdateUserProfileRequest.
     * @exports IUpdateUserProfileRequest
     * @interface IUpdateUserProfileRequest
     * @property {IClientProfileProto|null} [profile] UpdateUserProfileRequest profile
     */

    /**
     * Constructs a new UpdateUserProfileRequest.
     * @exports UpdateUserProfileRequest
     * @classdesc Represents an UpdateUserProfileRequest.
     * @implements IUpdateUserProfileRequest
     * @constructor
     * @param {IUpdateUserProfileRequest=} [properties] Properties to set
     */
    function UpdateUserProfileRequest(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * UpdateUserProfileRequest profile.
     * @member {IClientProfileProto|null|undefined} profile
     * @memberof UpdateUserProfileRequest
     * @instance
     */
    UpdateUserProfileRequest.prototype.profile = null;

    /**
     * Creates a new UpdateUserProfileRequest instance using the specified properties.
     * @function create
     * @memberof UpdateUserProfileRequest
     * @static
     * @param {IUpdateUserProfileRequest=} [properties] Properties to set
     * @returns {UpdateUserProfileRequest} UpdateUserProfileRequest instance
     */
    UpdateUserProfileRequest.create = function create(properties) {
        return new UpdateUserProfileRequest(properties);
    };

    /**
     * Encodes the specified UpdateUserProfileRequest message. Does not implicitly {@link UpdateUserProfileRequest.verify|verify} messages.
     * @function encode
     * @memberof UpdateUserProfileRequest
     * @static
     * @param {IUpdateUserProfileRequest} message UpdateUserProfileRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateUserProfileRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.profile != null && Object.hasOwnProperty.call(message, "profile"))
            $root.ClientProfileProto.encode(message.profile, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified UpdateUserProfileRequest message, length delimited. Does not implicitly {@link UpdateUserProfileRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof UpdateUserProfileRequest
     * @static
     * @param {IUpdateUserProfileRequest} message UpdateUserProfileRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateUserProfileRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an UpdateUserProfileRequest message from the specified reader or buffer.
     * @function decode
     * @memberof UpdateUserProfileRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {UpdateUserProfileRequest} UpdateUserProfileRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateUserProfileRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.UpdateUserProfileRequest();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.profile = $root.ClientProfileProto.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an UpdateUserProfileRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof UpdateUserProfileRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {UpdateUserProfileRequest} UpdateUserProfileRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateUserProfileRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an UpdateUserProfileRequest message.
     * @function verify
     * @memberof UpdateUserProfileRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    UpdateUserProfileRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.profile != null && message.hasOwnProperty("profile")) {
            let error = $root.ClientProfileProto.verify(message.profile);
            if (error)
                return "profile." + error;
        }
        return null;
    };

    /**
     * Creates an UpdateUserProfileRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof UpdateUserProfileRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {UpdateUserProfileRequest} UpdateUserProfileRequest
     */
    UpdateUserProfileRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.UpdateUserProfileRequest)
            return object;
        let message = new $root.UpdateUserProfileRequest();
        if (object.profile != null) {
            if (typeof object.profile !== "object")
                throw TypeError(".UpdateUserProfileRequest.profile: object expected");
            message.profile = $root.ClientProfileProto.fromObject(object.profile);
        }
        return message;
    };

    /**
     * Creates a plain object from an UpdateUserProfileRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof UpdateUserProfileRequest
     * @static
     * @param {UpdateUserProfileRequest} message UpdateUserProfileRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    UpdateUserProfileRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.profile = null;
        if (message.profile != null && message.hasOwnProperty("profile"))
            object.profile = $root.ClientProfileProto.toObject(message.profile, options);
        return object;
    };

    /**
     * Converts this UpdateUserProfileRequest to JSON.
     * @function toJSON
     * @memberof UpdateUserProfileRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    UpdateUserProfileRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for UpdateUserProfileRequest
     * @function getTypeUrl
     * @memberof UpdateUserProfileRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    UpdateUserProfileRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/UpdateUserProfileRequest";
    };

    return UpdateUserProfileRequest;
})();

export const UpdateUserProfileResponse = $root.UpdateUserProfileResponse = (() => {

    /**
     * Properties of an UpdateUserProfileResponse.
     * @exports IUpdateUserProfileResponse
     * @interface IUpdateUserProfileResponse
     * @property {boolean|null} [okay] UpdateUserProfileResponse okay
     * @property {IClientProfileProto|null} [updatedProfile] UpdateUserProfileResponse updatedProfile
     */

    /**
     * Constructs a new UpdateUserProfileResponse.
     * @exports UpdateUserProfileResponse
     * @classdesc Represents an UpdateUserProfileResponse.
     * @implements IUpdateUserProfileResponse
     * @constructor
     * @param {IUpdateUserProfileResponse=} [properties] Properties to set
     */
    function UpdateUserProfileResponse(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * UpdateUserProfileResponse okay.
     * @member {boolean} okay
     * @memberof UpdateUserProfileResponse
     * @instance
     */
    UpdateUserProfileResponse.prototype.okay = false;

    /**
     * UpdateUserProfileResponse updatedProfile.
     * @member {IClientProfileProto|null|undefined} updatedProfile
     * @memberof UpdateUserProfileResponse
     * @instance
     */
    UpdateUserProfileResponse.prototype.updatedProfile = null;

    /**
     * Creates a new UpdateUserProfileResponse instance using the specified properties.
     * @function create
     * @memberof UpdateUserProfileResponse
     * @static
     * @param {IUpdateUserProfileResponse=} [properties] Properties to set
     * @returns {UpdateUserProfileResponse} UpdateUserProfileResponse instance
     */
    UpdateUserProfileResponse.create = function create(properties) {
        return new UpdateUserProfileResponse(properties);
    };

    /**
     * Encodes the specified UpdateUserProfileResponse message. Does not implicitly {@link UpdateUserProfileResponse.verify|verify} messages.
     * @function encode
     * @memberof UpdateUserProfileResponse
     * @static
     * @param {IUpdateUserProfileResponse} message UpdateUserProfileResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateUserProfileResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        if (message.updatedProfile != null && Object.hasOwnProperty.call(message, "updatedProfile"))
            $root.ClientProfileProto.encode(message.updatedProfile, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified UpdateUserProfileResponse message, length delimited. Does not implicitly {@link UpdateUserProfileResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof UpdateUserProfileResponse
     * @static
     * @param {IUpdateUserProfileResponse} message UpdateUserProfileResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateUserProfileResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an UpdateUserProfileResponse message from the specified reader or buffer.
     * @function decode
     * @memberof UpdateUserProfileResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {UpdateUserProfileResponse} UpdateUserProfileResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateUserProfileResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.UpdateUserProfileResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            case 2: {
                    message.updatedProfile = $root.ClientProfileProto.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an UpdateUserProfileResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof UpdateUserProfileResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {UpdateUserProfileResponse} UpdateUserProfileResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateUserProfileResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an UpdateUserProfileResponse message.
     * @function verify
     * @memberof UpdateUserProfileResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    UpdateUserProfileResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        if (message.updatedProfile != null && message.hasOwnProperty("updatedProfile")) {
            let error = $root.ClientProfileProto.verify(message.updatedProfile);
            if (error)
                return "updatedProfile." + error;
        }
        return null;
    };

    /**
     * Creates an UpdateUserProfileResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof UpdateUserProfileResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {UpdateUserProfileResponse} UpdateUserProfileResponse
     */
    UpdateUserProfileResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.UpdateUserProfileResponse)
            return object;
        let message = new $root.UpdateUserProfileResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        if (object.updatedProfile != null) {
            if (typeof object.updatedProfile !== "object")
                throw TypeError(".UpdateUserProfileResponse.updatedProfile: object expected");
            message.updatedProfile = $root.ClientProfileProto.fromObject(object.updatedProfile);
        }
        return message;
    };

    /**
     * Creates a plain object from an UpdateUserProfileResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof UpdateUserProfileResponse
     * @static
     * @param {UpdateUserProfileResponse} message UpdateUserProfileResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    UpdateUserProfileResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.okay = false;
            object.updatedProfile = null;
        }
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        if (message.updatedProfile != null && message.hasOwnProperty("updatedProfile"))
            object.updatedProfile = $root.ClientProfileProto.toObject(message.updatedProfile, options);
        return object;
    };

    /**
     * Converts this UpdateUserProfileResponse to JSON.
     * @function toJSON
     * @memberof UpdateUserProfileResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    UpdateUserProfileResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for UpdateUserProfileResponse
     * @function getTypeUrl
     * @memberof UpdateUserProfileResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    UpdateUserProfileResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/UpdateUserProfileResponse";
    };

    return UpdateUserProfileResponse;
})();

export const AcceptInvitationRequest = $root.AcceptInvitationRequest = (() => {

    /**
     * Properties of an AcceptInvitationRequest.
     * @exports IAcceptInvitationRequest
     * @interface IAcceptInvitationRequest
     * @property {string|null} [inviteCode] AcceptInvitationRequest inviteCode
     * @property {boolean|null} [accepted] AcceptInvitationRequest accepted
     */

    /**
     * Constructs a new AcceptInvitationRequest.
     * @exports AcceptInvitationRequest
     * @classdesc Represents an AcceptInvitationRequest.
     * @implements IAcceptInvitationRequest
     * @constructor
     * @param {IAcceptInvitationRequest=} [properties] Properties to set
     */
    function AcceptInvitationRequest(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * AcceptInvitationRequest inviteCode.
     * @member {string} inviteCode
     * @memberof AcceptInvitationRequest
     * @instance
     */
    AcceptInvitationRequest.prototype.inviteCode = "";

    /**
     * AcceptInvitationRequest accepted.
     * @member {boolean} accepted
     * @memberof AcceptInvitationRequest
     * @instance
     */
    AcceptInvitationRequest.prototype.accepted = false;

    /**
     * Creates a new AcceptInvitationRequest instance using the specified properties.
     * @function create
     * @memberof AcceptInvitationRequest
     * @static
     * @param {IAcceptInvitationRequest=} [properties] Properties to set
     * @returns {AcceptInvitationRequest} AcceptInvitationRequest instance
     */
    AcceptInvitationRequest.create = function create(properties) {
        return new AcceptInvitationRequest(properties);
    };

    /**
     * Encodes the specified AcceptInvitationRequest message. Does not implicitly {@link AcceptInvitationRequest.verify|verify} messages.
     * @function encode
     * @memberof AcceptInvitationRequest
     * @static
     * @param {IAcceptInvitationRequest} message AcceptInvitationRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AcceptInvitationRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.inviteCode != null && Object.hasOwnProperty.call(message, "inviteCode"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.inviteCode);
        if (message.accepted != null && Object.hasOwnProperty.call(message, "accepted"))
            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.accepted);
        return writer;
    };

    /**
     * Encodes the specified AcceptInvitationRequest message, length delimited. Does not implicitly {@link AcceptInvitationRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof AcceptInvitationRequest
     * @static
     * @param {IAcceptInvitationRequest} message AcceptInvitationRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AcceptInvitationRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AcceptInvitationRequest message from the specified reader or buffer.
     * @function decode
     * @memberof AcceptInvitationRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {AcceptInvitationRequest} AcceptInvitationRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AcceptInvitationRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.AcceptInvitationRequest();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.inviteCode = reader.string();
                    break;
                }
            case 2: {
                    message.accepted = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an AcceptInvitationRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof AcceptInvitationRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {AcceptInvitationRequest} AcceptInvitationRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AcceptInvitationRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AcceptInvitationRequest message.
     * @function verify
     * @memberof AcceptInvitationRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AcceptInvitationRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.inviteCode != null && message.hasOwnProperty("inviteCode"))
            if (!$util.isString(message.inviteCode))
                return "inviteCode: string expected";
        if (message.accepted != null && message.hasOwnProperty("accepted"))
            if (typeof message.accepted !== "boolean")
                return "accepted: boolean expected";
        return null;
    };

    /**
     * Creates an AcceptInvitationRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof AcceptInvitationRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {AcceptInvitationRequest} AcceptInvitationRequest
     */
    AcceptInvitationRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.AcceptInvitationRequest)
            return object;
        let message = new $root.AcceptInvitationRequest();
        if (object.inviteCode != null)
            message.inviteCode = String(object.inviteCode);
        if (object.accepted != null)
            message.accepted = Boolean(object.accepted);
        return message;
    };

    /**
     * Creates a plain object from an AcceptInvitationRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof AcceptInvitationRequest
     * @static
     * @param {AcceptInvitationRequest} message AcceptInvitationRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AcceptInvitationRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.inviteCode = "";
            object.accepted = false;
        }
        if (message.inviteCode != null && message.hasOwnProperty("inviteCode"))
            object.inviteCode = message.inviteCode;
        if (message.accepted != null && message.hasOwnProperty("accepted"))
            object.accepted = message.accepted;
        return object;
    };

    /**
     * Converts this AcceptInvitationRequest to JSON.
     * @function toJSON
     * @memberof AcceptInvitationRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AcceptInvitationRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AcceptInvitationRequest
     * @function getTypeUrl
     * @memberof AcceptInvitationRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AcceptInvitationRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/AcceptInvitationRequest";
    };

    return AcceptInvitationRequest;
})();

export const AcceptInvitationResponse = $root.AcceptInvitationResponse = (() => {

    /**
     * Properties of an AcceptInvitationResponse.
     * @exports IAcceptInvitationResponse
     * @interface IAcceptInvitationResponse
     * @property {boolean|null} [okay] AcceptInvitationResponse okay
     * @property {IClientProfileProto|null} [profile] AcceptInvitationResponse profile
     */

    /**
     * Constructs a new AcceptInvitationResponse.
     * @exports AcceptInvitationResponse
     * @classdesc Represents an AcceptInvitationResponse.
     * @implements IAcceptInvitationResponse
     * @constructor
     * @param {IAcceptInvitationResponse=} [properties] Properties to set
     */
    function AcceptInvitationResponse(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * AcceptInvitationResponse okay.
     * @member {boolean} okay
     * @memberof AcceptInvitationResponse
     * @instance
     */
    AcceptInvitationResponse.prototype.okay = false;

    /**
     * AcceptInvitationResponse profile.
     * @member {IClientProfileProto|null|undefined} profile
     * @memberof AcceptInvitationResponse
     * @instance
     */
    AcceptInvitationResponse.prototype.profile = null;

    /**
     * Creates a new AcceptInvitationResponse instance using the specified properties.
     * @function create
     * @memberof AcceptInvitationResponse
     * @static
     * @param {IAcceptInvitationResponse=} [properties] Properties to set
     * @returns {AcceptInvitationResponse} AcceptInvitationResponse instance
     */
    AcceptInvitationResponse.create = function create(properties) {
        return new AcceptInvitationResponse(properties);
    };

    /**
     * Encodes the specified AcceptInvitationResponse message. Does not implicitly {@link AcceptInvitationResponse.verify|verify} messages.
     * @function encode
     * @memberof AcceptInvitationResponse
     * @static
     * @param {IAcceptInvitationResponse} message AcceptInvitationResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AcceptInvitationResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        if (message.profile != null && Object.hasOwnProperty.call(message, "profile"))
            $root.ClientProfileProto.encode(message.profile, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified AcceptInvitationResponse message, length delimited. Does not implicitly {@link AcceptInvitationResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof AcceptInvitationResponse
     * @static
     * @param {IAcceptInvitationResponse} message AcceptInvitationResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AcceptInvitationResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AcceptInvitationResponse message from the specified reader or buffer.
     * @function decode
     * @memberof AcceptInvitationResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {AcceptInvitationResponse} AcceptInvitationResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AcceptInvitationResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.AcceptInvitationResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            case 2: {
                    message.profile = $root.ClientProfileProto.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an AcceptInvitationResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof AcceptInvitationResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {AcceptInvitationResponse} AcceptInvitationResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AcceptInvitationResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AcceptInvitationResponse message.
     * @function verify
     * @memberof AcceptInvitationResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AcceptInvitationResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        if (message.profile != null && message.hasOwnProperty("profile")) {
            let error = $root.ClientProfileProto.verify(message.profile);
            if (error)
                return "profile." + error;
        }
        return null;
    };

    /**
     * Creates an AcceptInvitationResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof AcceptInvitationResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {AcceptInvitationResponse} AcceptInvitationResponse
     */
    AcceptInvitationResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.AcceptInvitationResponse)
            return object;
        let message = new $root.AcceptInvitationResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        if (object.profile != null) {
            if (typeof object.profile !== "object")
                throw TypeError(".AcceptInvitationResponse.profile: object expected");
            message.profile = $root.ClientProfileProto.fromObject(object.profile);
        }
        return message;
    };

    /**
     * Creates a plain object from an AcceptInvitationResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof AcceptInvitationResponse
     * @static
     * @param {AcceptInvitationResponse} message AcceptInvitationResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AcceptInvitationResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.okay = false;
            object.profile = null;
        }
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        if (message.profile != null && message.hasOwnProperty("profile"))
            object.profile = $root.ClientProfileProto.toObject(message.profile, options);
        return object;
    };

    /**
     * Converts this AcceptInvitationResponse to JSON.
     * @function toJSON
     * @memberof AcceptInvitationResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AcceptInvitationResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AcceptInvitationResponse
     * @function getTypeUrl
     * @memberof AcceptInvitationResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AcceptInvitationResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/AcceptInvitationResponse";
    };

    return AcceptInvitationResponse;
})();

export const CheckInvitationRequest = $root.CheckInvitationRequest = (() => {

    /**
     * Properties of a CheckInvitationRequest.
     * @exports ICheckInvitationRequest
     * @interface ICheckInvitationRequest
     * @property {string|null} [networkId] CheckInvitationRequest networkId
     * @property {string|null} [inviteCode] CheckInvitationRequest inviteCode
     */

    /**
     * Constructs a new CheckInvitationRequest.
     * @exports CheckInvitationRequest
     * @classdesc Represents a CheckInvitationRequest.
     * @implements ICheckInvitationRequest
     * @constructor
     * @param {ICheckInvitationRequest=} [properties] Properties to set
     */
    function CheckInvitationRequest(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * CheckInvitationRequest networkId.
     * @member {string} networkId
     * @memberof CheckInvitationRequest
     * @instance
     */
    CheckInvitationRequest.prototype.networkId = "";

    /**
     * CheckInvitationRequest inviteCode.
     * @member {string} inviteCode
     * @memberof CheckInvitationRequest
     * @instance
     */
    CheckInvitationRequest.prototype.inviteCode = "";

    /**
     * Creates a new CheckInvitationRequest instance using the specified properties.
     * @function create
     * @memberof CheckInvitationRequest
     * @static
     * @param {ICheckInvitationRequest=} [properties] Properties to set
     * @returns {CheckInvitationRequest} CheckInvitationRequest instance
     */
    CheckInvitationRequest.create = function create(properties) {
        return new CheckInvitationRequest(properties);
    };

    /**
     * Encodes the specified CheckInvitationRequest message. Does not implicitly {@link CheckInvitationRequest.verify|verify} messages.
     * @function encode
     * @memberof CheckInvitationRequest
     * @static
     * @param {ICheckInvitationRequest} message CheckInvitationRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CheckInvitationRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.networkId != null && Object.hasOwnProperty.call(message, "networkId"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.networkId);
        if (message.inviteCode != null && Object.hasOwnProperty.call(message, "inviteCode"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.inviteCode);
        return writer;
    };

    /**
     * Encodes the specified CheckInvitationRequest message, length delimited. Does not implicitly {@link CheckInvitationRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof CheckInvitationRequest
     * @static
     * @param {ICheckInvitationRequest} message CheckInvitationRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CheckInvitationRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CheckInvitationRequest message from the specified reader or buffer.
     * @function decode
     * @memberof CheckInvitationRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {CheckInvitationRequest} CheckInvitationRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CheckInvitationRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.CheckInvitationRequest();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.networkId = reader.string();
                    break;
                }
            case 2: {
                    message.inviteCode = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a CheckInvitationRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof CheckInvitationRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {CheckInvitationRequest} CheckInvitationRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CheckInvitationRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CheckInvitationRequest message.
     * @function verify
     * @memberof CheckInvitationRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CheckInvitationRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.networkId != null && message.hasOwnProperty("networkId"))
            if (!$util.isString(message.networkId))
                return "networkId: string expected";
        if (message.inviteCode != null && message.hasOwnProperty("inviteCode"))
            if (!$util.isString(message.inviteCode))
                return "inviteCode: string expected";
        return null;
    };

    /**
     * Creates a CheckInvitationRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof CheckInvitationRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {CheckInvitationRequest} CheckInvitationRequest
     */
    CheckInvitationRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.CheckInvitationRequest)
            return object;
        let message = new $root.CheckInvitationRequest();
        if (object.networkId != null)
            message.networkId = String(object.networkId);
        if (object.inviteCode != null)
            message.inviteCode = String(object.inviteCode);
        return message;
    };

    /**
     * Creates a plain object from a CheckInvitationRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof CheckInvitationRequest
     * @static
     * @param {CheckInvitationRequest} message CheckInvitationRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CheckInvitationRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.networkId = "";
            object.inviteCode = "";
        }
        if (message.networkId != null && message.hasOwnProperty("networkId"))
            object.networkId = message.networkId;
        if (message.inviteCode != null && message.hasOwnProperty("inviteCode"))
            object.inviteCode = message.inviteCode;
        return object;
    };

    /**
     * Converts this CheckInvitationRequest to JSON.
     * @function toJSON
     * @memberof CheckInvitationRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CheckInvitationRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for CheckInvitationRequest
     * @function getTypeUrl
     * @memberof CheckInvitationRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    CheckInvitationRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/CheckInvitationRequest";
    };

    return CheckInvitationRequest;
})();

export const CheckInvitationResponse = $root.CheckInvitationResponse = (() => {

    /**
     * Properties of a CheckInvitationResponse.
     * @exports ICheckInvitationResponse
     * @interface ICheckInvitationResponse
     * @property {boolean|null} [okay] CheckInvitationResponse okay
     */

    /**
     * Constructs a new CheckInvitationResponse.
     * @exports CheckInvitationResponse
     * @classdesc Represents a CheckInvitationResponse.
     * @implements ICheckInvitationResponse
     * @constructor
     * @param {ICheckInvitationResponse=} [properties] Properties to set
     */
    function CheckInvitationResponse(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * CheckInvitationResponse okay.
     * @member {boolean} okay
     * @memberof CheckInvitationResponse
     * @instance
     */
    CheckInvitationResponse.prototype.okay = false;

    /**
     * Creates a new CheckInvitationResponse instance using the specified properties.
     * @function create
     * @memberof CheckInvitationResponse
     * @static
     * @param {ICheckInvitationResponse=} [properties] Properties to set
     * @returns {CheckInvitationResponse} CheckInvitationResponse instance
     */
    CheckInvitationResponse.create = function create(properties) {
        return new CheckInvitationResponse(properties);
    };

    /**
     * Encodes the specified CheckInvitationResponse message. Does not implicitly {@link CheckInvitationResponse.verify|verify} messages.
     * @function encode
     * @memberof CheckInvitationResponse
     * @static
     * @param {ICheckInvitationResponse} message CheckInvitationResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CheckInvitationResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        return writer;
    };

    /**
     * Encodes the specified CheckInvitationResponse message, length delimited. Does not implicitly {@link CheckInvitationResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof CheckInvitationResponse
     * @static
     * @param {ICheckInvitationResponse} message CheckInvitationResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CheckInvitationResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CheckInvitationResponse message from the specified reader or buffer.
     * @function decode
     * @memberof CheckInvitationResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {CheckInvitationResponse} CheckInvitationResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CheckInvitationResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.CheckInvitationResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a CheckInvitationResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof CheckInvitationResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {CheckInvitationResponse} CheckInvitationResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CheckInvitationResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CheckInvitationResponse message.
     * @function verify
     * @memberof CheckInvitationResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CheckInvitationResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        return null;
    };

    /**
     * Creates a CheckInvitationResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof CheckInvitationResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {CheckInvitationResponse} CheckInvitationResponse
     */
    CheckInvitationResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.CheckInvitationResponse)
            return object;
        let message = new $root.CheckInvitationResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        return message;
    };

    /**
     * Creates a plain object from a CheckInvitationResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof CheckInvitationResponse
     * @static
     * @param {CheckInvitationResponse} message CheckInvitationResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CheckInvitationResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.okay = false;
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        return object;
    };

    /**
     * Converts this CheckInvitationResponse to JSON.
     * @function toJSON
     * @memberof CheckInvitationResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CheckInvitationResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for CheckInvitationResponse
     * @function getTypeUrl
     * @memberof CheckInvitationResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    CheckInvitationResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/CheckInvitationResponse";
    };

    return CheckInvitationResponse;
})();

export const DeleteProfileRequest = $root.DeleteProfileRequest = (() => {

    /**
     * Properties of a DeleteProfileRequest.
     * @exports IDeleteProfileRequest
     * @interface IDeleteProfileRequest
     */

    /**
     * Constructs a new DeleteProfileRequest.
     * @exports DeleteProfileRequest
     * @classdesc Represents a DeleteProfileRequest.
     * @implements IDeleteProfileRequest
     * @constructor
     * @param {IDeleteProfileRequest=} [properties] Properties to set
     */
    function DeleteProfileRequest(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Creates a new DeleteProfileRequest instance using the specified properties.
     * @function create
     * @memberof DeleteProfileRequest
     * @static
     * @param {IDeleteProfileRequest=} [properties] Properties to set
     * @returns {DeleteProfileRequest} DeleteProfileRequest instance
     */
    DeleteProfileRequest.create = function create(properties) {
        return new DeleteProfileRequest(properties);
    };

    /**
     * Encodes the specified DeleteProfileRequest message. Does not implicitly {@link DeleteProfileRequest.verify|verify} messages.
     * @function encode
     * @memberof DeleteProfileRequest
     * @static
     * @param {IDeleteProfileRequest} message DeleteProfileRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    DeleteProfileRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        return writer;
    };

    /**
     * Encodes the specified DeleteProfileRequest message, length delimited. Does not implicitly {@link DeleteProfileRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof DeleteProfileRequest
     * @static
     * @param {IDeleteProfileRequest} message DeleteProfileRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    DeleteProfileRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a DeleteProfileRequest message from the specified reader or buffer.
     * @function decode
     * @memberof DeleteProfileRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {DeleteProfileRequest} DeleteProfileRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    DeleteProfileRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.DeleteProfileRequest();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a DeleteProfileRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof DeleteProfileRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {DeleteProfileRequest} DeleteProfileRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    DeleteProfileRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a DeleteProfileRequest message.
     * @function verify
     * @memberof DeleteProfileRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    DeleteProfileRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        return null;
    };

    /**
     * Creates a DeleteProfileRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof DeleteProfileRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {DeleteProfileRequest} DeleteProfileRequest
     */
    DeleteProfileRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.DeleteProfileRequest)
            return object;
        return new $root.DeleteProfileRequest();
    };

    /**
     * Creates a plain object from a DeleteProfileRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof DeleteProfileRequest
     * @static
     * @param {DeleteProfileRequest} message DeleteProfileRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    DeleteProfileRequest.toObject = function toObject() {
        return {};
    };

    /**
     * Converts this DeleteProfileRequest to JSON.
     * @function toJSON
     * @memberof DeleteProfileRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    DeleteProfileRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for DeleteProfileRequest
     * @function getTypeUrl
     * @memberof DeleteProfileRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    DeleteProfileRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/DeleteProfileRequest";
    };

    return DeleteProfileRequest;
})();

export const DeleteProfileResponse = $root.DeleteProfileResponse = (() => {

    /**
     * Properties of a DeleteProfileResponse.
     * @exports IDeleteProfileResponse
     * @interface IDeleteProfileResponse
     * @property {boolean|null} [okay] DeleteProfileResponse okay
     */

    /**
     * Constructs a new DeleteProfileResponse.
     * @exports DeleteProfileResponse
     * @classdesc Represents a DeleteProfileResponse.
     * @implements IDeleteProfileResponse
     * @constructor
     * @param {IDeleteProfileResponse=} [properties] Properties to set
     */
    function DeleteProfileResponse(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * DeleteProfileResponse okay.
     * @member {boolean} okay
     * @memberof DeleteProfileResponse
     * @instance
     */
    DeleteProfileResponse.prototype.okay = false;

    /**
     * Creates a new DeleteProfileResponse instance using the specified properties.
     * @function create
     * @memberof DeleteProfileResponse
     * @static
     * @param {IDeleteProfileResponse=} [properties] Properties to set
     * @returns {DeleteProfileResponse} DeleteProfileResponse instance
     */
    DeleteProfileResponse.create = function create(properties) {
        return new DeleteProfileResponse(properties);
    };

    /**
     * Encodes the specified DeleteProfileResponse message. Does not implicitly {@link DeleteProfileResponse.verify|verify} messages.
     * @function encode
     * @memberof DeleteProfileResponse
     * @static
     * @param {IDeleteProfileResponse} message DeleteProfileResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    DeleteProfileResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        return writer;
    };

    /**
     * Encodes the specified DeleteProfileResponse message, length delimited. Does not implicitly {@link DeleteProfileResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof DeleteProfileResponse
     * @static
     * @param {IDeleteProfileResponse} message DeleteProfileResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    DeleteProfileResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a DeleteProfileResponse message from the specified reader or buffer.
     * @function decode
     * @memberof DeleteProfileResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {DeleteProfileResponse} DeleteProfileResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    DeleteProfileResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.DeleteProfileResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a DeleteProfileResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof DeleteProfileResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {DeleteProfileResponse} DeleteProfileResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    DeleteProfileResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a DeleteProfileResponse message.
     * @function verify
     * @memberof DeleteProfileResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    DeleteProfileResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        return null;
    };

    /**
     * Creates a DeleteProfileResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof DeleteProfileResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {DeleteProfileResponse} DeleteProfileResponse
     */
    DeleteProfileResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.DeleteProfileResponse)
            return object;
        let message = new $root.DeleteProfileResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        return message;
    };

    /**
     * Creates a plain object from a DeleteProfileResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof DeleteProfileResponse
     * @static
     * @param {DeleteProfileResponse} message DeleteProfileResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    DeleteProfileResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.okay = false;
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        return object;
    };

    /**
     * Converts this DeleteProfileResponse to JSON.
     * @function toJSON
     * @memberof DeleteProfileResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    DeleteProfileResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for DeleteProfileResponse
     * @function getTypeUrl
     * @memberof DeleteProfileResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    DeleteProfileResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/DeleteProfileResponse";
    };

    return DeleteProfileResponse;
})();

export const BookAppointmentRequest = $root.BookAppointmentRequest = (() => {

    /**
     * Properties of a BookAppointmentRequest.
     * @exports IBookAppointmentRequest
     * @interface IBookAppointmentRequest
     * @property {string|null} [businessId] BookAppointmentRequest businessId
     * @property {string|null} [providerId] BookAppointmentRequest providerId
     * @property {string|null} [serviceId] BookAppointmentRequest serviceId
     * @property {string|null} [locationId] BookAppointmentRequest locationId
     * @property {number|Long|null} [appointmentTime] BookAppointmentRequest appointmentTime
     * @property {boolean|null} [virtualAppt] BookAppointmentRequest virtualAppt
     * @property {string|null} [timeZoneId] BookAppointmentRequest timeZoneId
     */

    /**
     * Constructs a new BookAppointmentRequest.
     * @exports BookAppointmentRequest
     * @classdesc Represents a BookAppointmentRequest.
     * @implements IBookAppointmentRequest
     * @constructor
     * @param {IBookAppointmentRequest=} [properties] Properties to set
     */
    function BookAppointmentRequest(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * BookAppointmentRequest businessId.
     * @member {string} businessId
     * @memberof BookAppointmentRequest
     * @instance
     */
    BookAppointmentRequest.prototype.businessId = "";

    /**
     * BookAppointmentRequest providerId.
     * @member {string} providerId
     * @memberof BookAppointmentRequest
     * @instance
     */
    BookAppointmentRequest.prototype.providerId = "";

    /**
     * BookAppointmentRequest serviceId.
     * @member {string} serviceId
     * @memberof BookAppointmentRequest
     * @instance
     */
    BookAppointmentRequest.prototype.serviceId = "";

    /**
     * BookAppointmentRequest locationId.
     * @member {string} locationId
     * @memberof BookAppointmentRequest
     * @instance
     */
    BookAppointmentRequest.prototype.locationId = "";

    /**
     * BookAppointmentRequest appointmentTime.
     * @member {number|Long} appointmentTime
     * @memberof BookAppointmentRequest
     * @instance
     */
    BookAppointmentRequest.prototype.appointmentTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * BookAppointmentRequest virtualAppt.
     * @member {boolean} virtualAppt
     * @memberof BookAppointmentRequest
     * @instance
     */
    BookAppointmentRequest.prototype.virtualAppt = false;

    /**
     * BookAppointmentRequest timeZoneId.
     * @member {string} timeZoneId
     * @memberof BookAppointmentRequest
     * @instance
     */
    BookAppointmentRequest.prototype.timeZoneId = "";

    /**
     * Creates a new BookAppointmentRequest instance using the specified properties.
     * @function create
     * @memberof BookAppointmentRequest
     * @static
     * @param {IBookAppointmentRequest=} [properties] Properties to set
     * @returns {BookAppointmentRequest} BookAppointmentRequest instance
     */
    BookAppointmentRequest.create = function create(properties) {
        return new BookAppointmentRequest(properties);
    };

    /**
     * Encodes the specified BookAppointmentRequest message. Does not implicitly {@link BookAppointmentRequest.verify|verify} messages.
     * @function encode
     * @memberof BookAppointmentRequest
     * @static
     * @param {IBookAppointmentRequest} message BookAppointmentRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    BookAppointmentRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.businessId != null && Object.hasOwnProperty.call(message, "businessId"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.businessId);
        if (message.providerId != null && Object.hasOwnProperty.call(message, "providerId"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.providerId);
        if (message.serviceId != null && Object.hasOwnProperty.call(message, "serviceId"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.serviceId);
        if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.locationId);
        if (message.appointmentTime != null && Object.hasOwnProperty.call(message, "appointmentTime"))
            writer.uint32(/* id 5, wireType 0 =*/40).int64(message.appointmentTime);
        if (message.virtualAppt != null && Object.hasOwnProperty.call(message, "virtualAppt"))
            writer.uint32(/* id 6, wireType 0 =*/48).bool(message.virtualAppt);
        if (message.timeZoneId != null && Object.hasOwnProperty.call(message, "timeZoneId"))
            writer.uint32(/* id 7, wireType 2 =*/58).string(message.timeZoneId);
        return writer;
    };

    /**
     * Encodes the specified BookAppointmentRequest message, length delimited. Does not implicitly {@link BookAppointmentRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof BookAppointmentRequest
     * @static
     * @param {IBookAppointmentRequest} message BookAppointmentRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    BookAppointmentRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a BookAppointmentRequest message from the specified reader or buffer.
     * @function decode
     * @memberof BookAppointmentRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {BookAppointmentRequest} BookAppointmentRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    BookAppointmentRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.BookAppointmentRequest();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.businessId = reader.string();
                    break;
                }
            case 2: {
                    message.providerId = reader.string();
                    break;
                }
            case 3: {
                    message.serviceId = reader.string();
                    break;
                }
            case 4: {
                    message.locationId = reader.string();
                    break;
                }
            case 5: {
                    message.appointmentTime = reader.int64();
                    break;
                }
            case 6: {
                    message.virtualAppt = reader.bool();
                    break;
                }
            case 7: {
                    message.timeZoneId = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a BookAppointmentRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof BookAppointmentRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {BookAppointmentRequest} BookAppointmentRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    BookAppointmentRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a BookAppointmentRequest message.
     * @function verify
     * @memberof BookAppointmentRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    BookAppointmentRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.businessId != null && message.hasOwnProperty("businessId"))
            if (!$util.isString(message.businessId))
                return "businessId: string expected";
        if (message.providerId != null && message.hasOwnProperty("providerId"))
            if (!$util.isString(message.providerId))
                return "providerId: string expected";
        if (message.serviceId != null && message.hasOwnProperty("serviceId"))
            if (!$util.isString(message.serviceId))
                return "serviceId: string expected";
        if (message.locationId != null && message.hasOwnProperty("locationId"))
            if (!$util.isString(message.locationId))
                return "locationId: string expected";
        if (message.appointmentTime != null && message.hasOwnProperty("appointmentTime"))
            if (!$util.isInteger(message.appointmentTime) && !(message.appointmentTime && $util.isInteger(message.appointmentTime.low) && $util.isInteger(message.appointmentTime.high)))
                return "appointmentTime: integer|Long expected";
        if (message.virtualAppt != null && message.hasOwnProperty("virtualAppt"))
            if (typeof message.virtualAppt !== "boolean")
                return "virtualAppt: boolean expected";
        if (message.timeZoneId != null && message.hasOwnProperty("timeZoneId"))
            if (!$util.isString(message.timeZoneId))
                return "timeZoneId: string expected";
        return null;
    };

    /**
     * Creates a BookAppointmentRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof BookAppointmentRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {BookAppointmentRequest} BookAppointmentRequest
     */
    BookAppointmentRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.BookAppointmentRequest)
            return object;
        let message = new $root.BookAppointmentRequest();
        if (object.businessId != null)
            message.businessId = String(object.businessId);
        if (object.providerId != null)
            message.providerId = String(object.providerId);
        if (object.serviceId != null)
            message.serviceId = String(object.serviceId);
        if (object.locationId != null)
            message.locationId = String(object.locationId);
        if (object.appointmentTime != null)
            if ($util.Long)
                (message.appointmentTime = $util.Long.fromValue(object.appointmentTime)).unsigned = false;
            else if (typeof object.appointmentTime === "string")
                message.appointmentTime = parseInt(object.appointmentTime, 10);
            else if (typeof object.appointmentTime === "number")
                message.appointmentTime = object.appointmentTime;
            else if (typeof object.appointmentTime === "object")
                message.appointmentTime = new $util.LongBits(object.appointmentTime.low >>> 0, object.appointmentTime.high >>> 0).toNumber();
        if (object.virtualAppt != null)
            message.virtualAppt = Boolean(object.virtualAppt);
        if (object.timeZoneId != null)
            message.timeZoneId = String(object.timeZoneId);
        return message;
    };

    /**
     * Creates a plain object from a BookAppointmentRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof BookAppointmentRequest
     * @static
     * @param {BookAppointmentRequest} message BookAppointmentRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    BookAppointmentRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.businessId = "";
            object.providerId = "";
            object.serviceId = "";
            object.locationId = "";
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.appointmentTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.appointmentTime = options.longs === String ? "0" : 0;
            object.virtualAppt = false;
            object.timeZoneId = "";
        }
        if (message.businessId != null && message.hasOwnProperty("businessId"))
            object.businessId = message.businessId;
        if (message.providerId != null && message.hasOwnProperty("providerId"))
            object.providerId = message.providerId;
        if (message.serviceId != null && message.hasOwnProperty("serviceId"))
            object.serviceId = message.serviceId;
        if (message.locationId != null && message.hasOwnProperty("locationId"))
            object.locationId = message.locationId;
        if (message.appointmentTime != null && message.hasOwnProperty("appointmentTime"))
            if (typeof message.appointmentTime === "number")
                object.appointmentTime = options.longs === String ? String(message.appointmentTime) : message.appointmentTime;
            else
                object.appointmentTime = options.longs === String ? $util.Long.prototype.toString.call(message.appointmentTime) : options.longs === Number ? new $util.LongBits(message.appointmentTime.low >>> 0, message.appointmentTime.high >>> 0).toNumber() : message.appointmentTime;
        if (message.virtualAppt != null && message.hasOwnProperty("virtualAppt"))
            object.virtualAppt = message.virtualAppt;
        if (message.timeZoneId != null && message.hasOwnProperty("timeZoneId"))
            object.timeZoneId = message.timeZoneId;
        return object;
    };

    /**
     * Converts this BookAppointmentRequest to JSON.
     * @function toJSON
     * @memberof BookAppointmentRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    BookAppointmentRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for BookAppointmentRequest
     * @function getTypeUrl
     * @memberof BookAppointmentRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    BookAppointmentRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/BookAppointmentRequest";
    };

    return BookAppointmentRequest;
})();

export const BookAppointmentResponse = $root.BookAppointmentResponse = (() => {

    /**
     * Properties of a BookAppointmentResponse.
     * @exports IBookAppointmentResponse
     * @interface IBookAppointmentResponse
     * @property {boolean|null} [okay] BookAppointmentResponse okay
     * @property {string|null} [error] BookAppointmentResponse error
     * @property {IClientAppointmentProto|null} [appointment] BookAppointmentResponse appointment
     * @property {IClientProfileProto|null} [updatedProfile] BookAppointmentResponse updatedProfile
     */

    /**
     * Constructs a new BookAppointmentResponse.
     * @exports BookAppointmentResponse
     * @classdesc Represents a BookAppointmentResponse.
     * @implements IBookAppointmentResponse
     * @constructor
     * @param {IBookAppointmentResponse=} [properties] Properties to set
     */
    function BookAppointmentResponse(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * BookAppointmentResponse okay.
     * @member {boolean} okay
     * @memberof BookAppointmentResponse
     * @instance
     */
    BookAppointmentResponse.prototype.okay = false;

    /**
     * BookAppointmentResponse error.
     * @member {string} error
     * @memberof BookAppointmentResponse
     * @instance
     */
    BookAppointmentResponse.prototype.error = "";

    /**
     * BookAppointmentResponse appointment.
     * @member {IClientAppointmentProto|null|undefined} appointment
     * @memberof BookAppointmentResponse
     * @instance
     */
    BookAppointmentResponse.prototype.appointment = null;

    /**
     * BookAppointmentResponse updatedProfile.
     * @member {IClientProfileProto|null|undefined} updatedProfile
     * @memberof BookAppointmentResponse
     * @instance
     */
    BookAppointmentResponse.prototype.updatedProfile = null;

    /**
     * Creates a new BookAppointmentResponse instance using the specified properties.
     * @function create
     * @memberof BookAppointmentResponse
     * @static
     * @param {IBookAppointmentResponse=} [properties] Properties to set
     * @returns {BookAppointmentResponse} BookAppointmentResponse instance
     */
    BookAppointmentResponse.create = function create(properties) {
        return new BookAppointmentResponse(properties);
    };

    /**
     * Encodes the specified BookAppointmentResponse message. Does not implicitly {@link BookAppointmentResponse.verify|verify} messages.
     * @function encode
     * @memberof BookAppointmentResponse
     * @static
     * @param {IBookAppointmentResponse} message BookAppointmentResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    BookAppointmentResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        if (message.error != null && Object.hasOwnProperty.call(message, "error"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.error);
        if (message.appointment != null && Object.hasOwnProperty.call(message, "appointment"))
            $root.ClientAppointmentProto.encode(message.appointment, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        if (message.updatedProfile != null && Object.hasOwnProperty.call(message, "updatedProfile"))
            $root.ClientProfileProto.encode(message.updatedProfile, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified BookAppointmentResponse message, length delimited. Does not implicitly {@link BookAppointmentResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof BookAppointmentResponse
     * @static
     * @param {IBookAppointmentResponse} message BookAppointmentResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    BookAppointmentResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a BookAppointmentResponse message from the specified reader or buffer.
     * @function decode
     * @memberof BookAppointmentResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {BookAppointmentResponse} BookAppointmentResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    BookAppointmentResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.BookAppointmentResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            case 2: {
                    message.error = reader.string();
                    break;
                }
            case 3: {
                    message.appointment = $root.ClientAppointmentProto.decode(reader, reader.uint32());
                    break;
                }
            case 4: {
                    message.updatedProfile = $root.ClientProfileProto.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a BookAppointmentResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof BookAppointmentResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {BookAppointmentResponse} BookAppointmentResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    BookAppointmentResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a BookAppointmentResponse message.
     * @function verify
     * @memberof BookAppointmentResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    BookAppointmentResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        if (message.error != null && message.hasOwnProperty("error"))
            if (!$util.isString(message.error))
                return "error: string expected";
        if (message.appointment != null && message.hasOwnProperty("appointment")) {
            let error = $root.ClientAppointmentProto.verify(message.appointment);
            if (error)
                return "appointment." + error;
        }
        if (message.updatedProfile != null && message.hasOwnProperty("updatedProfile")) {
            let error = $root.ClientProfileProto.verify(message.updatedProfile);
            if (error)
                return "updatedProfile." + error;
        }
        return null;
    };

    /**
     * Creates a BookAppointmentResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof BookAppointmentResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {BookAppointmentResponse} BookAppointmentResponse
     */
    BookAppointmentResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.BookAppointmentResponse)
            return object;
        let message = new $root.BookAppointmentResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        if (object.error != null)
            message.error = String(object.error);
        if (object.appointment != null) {
            if (typeof object.appointment !== "object")
                throw TypeError(".BookAppointmentResponse.appointment: object expected");
            message.appointment = $root.ClientAppointmentProto.fromObject(object.appointment);
        }
        if (object.updatedProfile != null) {
            if (typeof object.updatedProfile !== "object")
                throw TypeError(".BookAppointmentResponse.updatedProfile: object expected");
            message.updatedProfile = $root.ClientProfileProto.fromObject(object.updatedProfile);
        }
        return message;
    };

    /**
     * Creates a plain object from a BookAppointmentResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof BookAppointmentResponse
     * @static
     * @param {BookAppointmentResponse} message BookAppointmentResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    BookAppointmentResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.okay = false;
            object.error = "";
            object.appointment = null;
            object.updatedProfile = null;
        }
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        if (message.error != null && message.hasOwnProperty("error"))
            object.error = message.error;
        if (message.appointment != null && message.hasOwnProperty("appointment"))
            object.appointment = $root.ClientAppointmentProto.toObject(message.appointment, options);
        if (message.updatedProfile != null && message.hasOwnProperty("updatedProfile"))
            object.updatedProfile = $root.ClientProfileProto.toObject(message.updatedProfile, options);
        return object;
    };

    /**
     * Converts this BookAppointmentResponse to JSON.
     * @function toJSON
     * @memberof BookAppointmentResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    BookAppointmentResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for BookAppointmentResponse
     * @function getTypeUrl
     * @memberof BookAppointmentResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    BookAppointmentResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/BookAppointmentResponse";
    };

    return BookAppointmentResponse;
})();

export const space = $root.space = (() => {

    /**
     * Namespace space.
     * @exports space
     * @namespace
     */
    const space = {};

    space.kenko = (function() {

        /**
         * Namespace kenko.
         * @memberof space
         * @namespace
         */
        const kenko = {};

        kenko.proto = (function() {

            /**
             * Namespace proto.
             * @memberof space.kenko
             * @namespace
             */
            const proto = {};

            proto.BusinessProto = (function() {

                /**
                 * Properties of a BusinessProto.
                 * @memberof space.kenko.proto
                 * @interface IBusinessProto
                 * @property {string|null} [businessName] BusinessProto businessName
                 * @property {string|null} [businessDescription] BusinessProto businessDescription
                 * @property {string|null} [websiteUrl] BusinessProto websiteUrl
                 * @property {string|null} [phoneNumber] BusinessProto phoneNumber
                 * @property {string|null} [email] BusinessProto email
                 * @property {Array.<string>|null} [imageUrl] BusinessProto imageUrl
                 * @property {string|null} [shortUrl] BusinessProto shortUrl
                 * @property {space.kenko.proto.IVirtualAppointmentInfo|null} [virtualAppointmentInfo] BusinessProto virtualAppointmentInfo
                 * @property {Array.<string>|null} [servicesOffered] BusinessProto servicesOffered
                 */

                /**
                 * Constructs a new BusinessProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a BusinessProto.
                 * @implements IBusinessProto
                 * @constructor
                 * @param {space.kenko.proto.IBusinessProto=} [properties] Properties to set
                 */
                function BusinessProto(properties) {
                    this.imageUrl = [];
                    this.servicesOffered = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * BusinessProto businessName.
                 * @member {string} businessName
                 * @memberof space.kenko.proto.BusinessProto
                 * @instance
                 */
                BusinessProto.prototype.businessName = "";

                /**
                 * BusinessProto businessDescription.
                 * @member {string} businessDescription
                 * @memberof space.kenko.proto.BusinessProto
                 * @instance
                 */
                BusinessProto.prototype.businessDescription = "";

                /**
                 * BusinessProto websiteUrl.
                 * @member {string} websiteUrl
                 * @memberof space.kenko.proto.BusinessProto
                 * @instance
                 */
                BusinessProto.prototype.websiteUrl = "";

                /**
                 * BusinessProto phoneNumber.
                 * @member {string} phoneNumber
                 * @memberof space.kenko.proto.BusinessProto
                 * @instance
                 */
                BusinessProto.prototype.phoneNumber = "";

                /**
                 * BusinessProto email.
                 * @member {string} email
                 * @memberof space.kenko.proto.BusinessProto
                 * @instance
                 */
                BusinessProto.prototype.email = "";

                /**
                 * BusinessProto imageUrl.
                 * @member {Array.<string>} imageUrl
                 * @memberof space.kenko.proto.BusinessProto
                 * @instance
                 */
                BusinessProto.prototype.imageUrl = $util.emptyArray;

                /**
                 * BusinessProto shortUrl.
                 * @member {string} shortUrl
                 * @memberof space.kenko.proto.BusinessProto
                 * @instance
                 */
                BusinessProto.prototype.shortUrl = "";

                /**
                 * BusinessProto virtualAppointmentInfo.
                 * @member {space.kenko.proto.IVirtualAppointmentInfo|null|undefined} virtualAppointmentInfo
                 * @memberof space.kenko.proto.BusinessProto
                 * @instance
                 */
                BusinessProto.prototype.virtualAppointmentInfo = null;

                /**
                 * BusinessProto servicesOffered.
                 * @member {Array.<string>} servicesOffered
                 * @memberof space.kenko.proto.BusinessProto
                 * @instance
                 */
                BusinessProto.prototype.servicesOffered = $util.emptyArray;

                /**
                 * Creates a new BusinessProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.BusinessProto
                 * @static
                 * @param {space.kenko.proto.IBusinessProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.BusinessProto} BusinessProto instance
                 */
                BusinessProto.create = function create(properties) {
                    return new BusinessProto(properties);
                };

                /**
                 * Encodes the specified BusinessProto message. Does not implicitly {@link space.kenko.proto.BusinessProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.BusinessProto
                 * @static
                 * @param {space.kenko.proto.IBusinessProto} message BusinessProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BusinessProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.businessName != null && Object.hasOwnProperty.call(message, "businessName"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.businessName);
                    if (message.businessDescription != null && Object.hasOwnProperty.call(message, "businessDescription"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.businessDescription);
                    if (message.websiteUrl != null && Object.hasOwnProperty.call(message, "websiteUrl"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.websiteUrl);
                    if (message.phoneNumber != null && Object.hasOwnProperty.call(message, "phoneNumber"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.phoneNumber);
                    if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.email);
                    if (message.imageUrl != null && message.imageUrl.length)
                        for (let i = 0; i < message.imageUrl.length; ++i)
                            writer.uint32(/* id 6, wireType 2 =*/50).string(message.imageUrl[i]);
                    if (message.shortUrl != null && Object.hasOwnProperty.call(message, "shortUrl"))
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.shortUrl);
                    if (message.virtualAppointmentInfo != null && Object.hasOwnProperty.call(message, "virtualAppointmentInfo"))
                        $root.space.kenko.proto.VirtualAppointmentInfo.encode(message.virtualAppointmentInfo, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                    if (message.servicesOffered != null && message.servicesOffered.length)
                        for (let i = 0; i < message.servicesOffered.length; ++i)
                            writer.uint32(/* id 9, wireType 2 =*/74).string(message.servicesOffered[i]);
                    return writer;
                };

                /**
                 * Encodes the specified BusinessProto message, length delimited. Does not implicitly {@link space.kenko.proto.BusinessProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.BusinessProto
                 * @static
                 * @param {space.kenko.proto.IBusinessProto} message BusinessProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BusinessProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a BusinessProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.BusinessProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.BusinessProto} BusinessProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BusinessProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.BusinessProto();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.businessName = reader.string();
                                break;
                            }
                        case 2: {
                                message.businessDescription = reader.string();
                                break;
                            }
                        case 3: {
                                message.websiteUrl = reader.string();
                                break;
                            }
                        case 4: {
                                message.phoneNumber = reader.string();
                                break;
                            }
                        case 5: {
                                message.email = reader.string();
                                break;
                            }
                        case 6: {
                                if (!(message.imageUrl && message.imageUrl.length))
                                    message.imageUrl = [];
                                message.imageUrl.push(reader.string());
                                break;
                            }
                        case 7: {
                                message.shortUrl = reader.string();
                                break;
                            }
                        case 8: {
                                message.virtualAppointmentInfo = $root.space.kenko.proto.VirtualAppointmentInfo.decode(reader, reader.uint32());
                                break;
                            }
                        case 9: {
                                if (!(message.servicesOffered && message.servicesOffered.length))
                                    message.servicesOffered = [];
                                message.servicesOffered.push(reader.string());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a BusinessProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.BusinessProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.BusinessProto} BusinessProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BusinessProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a BusinessProto message.
                 * @function verify
                 * @memberof space.kenko.proto.BusinessProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BusinessProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessName != null && message.hasOwnProperty("businessName"))
                        if (!$util.isString(message.businessName))
                            return "businessName: string expected";
                    if (message.businessDescription != null && message.hasOwnProperty("businessDescription"))
                        if (!$util.isString(message.businessDescription))
                            return "businessDescription: string expected";
                    if (message.websiteUrl != null && message.hasOwnProperty("websiteUrl"))
                        if (!$util.isString(message.websiteUrl))
                            return "websiteUrl: string expected";
                    if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                        if (!$util.isString(message.phoneNumber))
                            return "phoneNumber: string expected";
                    if (message.email != null && message.hasOwnProperty("email"))
                        if (!$util.isString(message.email))
                            return "email: string expected";
                    if (message.imageUrl != null && message.hasOwnProperty("imageUrl")) {
                        if (!Array.isArray(message.imageUrl))
                            return "imageUrl: array expected";
                        for (let i = 0; i < message.imageUrl.length; ++i)
                            if (!$util.isString(message.imageUrl[i]))
                                return "imageUrl: string[] expected";
                    }
                    if (message.shortUrl != null && message.hasOwnProperty("shortUrl"))
                        if (!$util.isString(message.shortUrl))
                            return "shortUrl: string expected";
                    if (message.virtualAppointmentInfo != null && message.hasOwnProperty("virtualAppointmentInfo")) {
                        let error = $root.space.kenko.proto.VirtualAppointmentInfo.verify(message.virtualAppointmentInfo);
                        if (error)
                            return "virtualAppointmentInfo." + error;
                    }
                    if (message.servicesOffered != null && message.hasOwnProperty("servicesOffered")) {
                        if (!Array.isArray(message.servicesOffered))
                            return "servicesOffered: array expected";
                        for (let i = 0; i < message.servicesOffered.length; ++i)
                            if (!$util.isString(message.servicesOffered[i]))
                                return "servicesOffered: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a BusinessProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.BusinessProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.BusinessProto} BusinessProto
                 */
                BusinessProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.BusinessProto)
                        return object;
                    let message = new $root.space.kenko.proto.BusinessProto();
                    if (object.businessName != null)
                        message.businessName = String(object.businessName);
                    if (object.businessDescription != null)
                        message.businessDescription = String(object.businessDescription);
                    if (object.websiteUrl != null)
                        message.websiteUrl = String(object.websiteUrl);
                    if (object.phoneNumber != null)
                        message.phoneNumber = String(object.phoneNumber);
                    if (object.email != null)
                        message.email = String(object.email);
                    if (object.imageUrl) {
                        if (!Array.isArray(object.imageUrl))
                            throw TypeError(".space.kenko.proto.BusinessProto.imageUrl: array expected");
                        message.imageUrl = [];
                        for (let i = 0; i < object.imageUrl.length; ++i)
                            message.imageUrl[i] = String(object.imageUrl[i]);
                    }
                    if (object.shortUrl != null)
                        message.shortUrl = String(object.shortUrl);
                    if (object.virtualAppointmentInfo != null) {
                        if (typeof object.virtualAppointmentInfo !== "object")
                            throw TypeError(".space.kenko.proto.BusinessProto.virtualAppointmentInfo: object expected");
                        message.virtualAppointmentInfo = $root.space.kenko.proto.VirtualAppointmentInfo.fromObject(object.virtualAppointmentInfo);
                    }
                    if (object.servicesOffered) {
                        if (!Array.isArray(object.servicesOffered))
                            throw TypeError(".space.kenko.proto.BusinessProto.servicesOffered: array expected");
                        message.servicesOffered = [];
                        for (let i = 0; i < object.servicesOffered.length; ++i)
                            message.servicesOffered[i] = String(object.servicesOffered[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a BusinessProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.BusinessProto
                 * @static
                 * @param {space.kenko.proto.BusinessProto} message BusinessProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BusinessProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults) {
                        object.imageUrl = [];
                        object.servicesOffered = [];
                    }
                    if (options.defaults) {
                        object.businessName = "";
                        object.businessDescription = "";
                        object.websiteUrl = "";
                        object.phoneNumber = "";
                        object.email = "";
                        object.shortUrl = "";
                        object.virtualAppointmentInfo = null;
                    }
                    if (message.businessName != null && message.hasOwnProperty("businessName"))
                        object.businessName = message.businessName;
                    if (message.businessDescription != null && message.hasOwnProperty("businessDescription"))
                        object.businessDescription = message.businessDescription;
                    if (message.websiteUrl != null && message.hasOwnProperty("websiteUrl"))
                        object.websiteUrl = message.websiteUrl;
                    if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                        object.phoneNumber = message.phoneNumber;
                    if (message.email != null && message.hasOwnProperty("email"))
                        object.email = message.email;
                    if (message.imageUrl && message.imageUrl.length) {
                        object.imageUrl = [];
                        for (let j = 0; j < message.imageUrl.length; ++j)
                            object.imageUrl[j] = message.imageUrl[j];
                    }
                    if (message.shortUrl != null && message.hasOwnProperty("shortUrl"))
                        object.shortUrl = message.shortUrl;
                    if (message.virtualAppointmentInfo != null && message.hasOwnProperty("virtualAppointmentInfo"))
                        object.virtualAppointmentInfo = $root.space.kenko.proto.VirtualAppointmentInfo.toObject(message.virtualAppointmentInfo, options);
                    if (message.servicesOffered && message.servicesOffered.length) {
                        object.servicesOffered = [];
                        for (let j = 0; j < message.servicesOffered.length; ++j)
                            object.servicesOffered[j] = message.servicesOffered[j];
                    }
                    return object;
                };

                /**
                 * Converts this BusinessProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.BusinessProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BusinessProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for BusinessProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.BusinessProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                BusinessProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.BusinessProto";
                };

                return BusinessProto;
            })();

            proto.VirtualAppointmentInfo = (function() {

                /**
                 * Properties of a VirtualAppointmentInfo.
                 * @memberof space.kenko.proto
                 * @interface IVirtualAppointmentInfo
                 * @property {boolean|null} [acceptsVirtualAppointments] VirtualAppointmentInfo acceptsVirtualAppointments
                 */

                /**
                 * Constructs a new VirtualAppointmentInfo.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a VirtualAppointmentInfo.
                 * @implements IVirtualAppointmentInfo
                 * @constructor
                 * @param {space.kenko.proto.IVirtualAppointmentInfo=} [properties] Properties to set
                 */
                function VirtualAppointmentInfo(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * VirtualAppointmentInfo acceptsVirtualAppointments.
                 * @member {boolean} acceptsVirtualAppointments
                 * @memberof space.kenko.proto.VirtualAppointmentInfo
                 * @instance
                 */
                VirtualAppointmentInfo.prototype.acceptsVirtualAppointments = false;

                /**
                 * Creates a new VirtualAppointmentInfo instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.VirtualAppointmentInfo
                 * @static
                 * @param {space.kenko.proto.IVirtualAppointmentInfo=} [properties] Properties to set
                 * @returns {space.kenko.proto.VirtualAppointmentInfo} VirtualAppointmentInfo instance
                 */
                VirtualAppointmentInfo.create = function create(properties) {
                    return new VirtualAppointmentInfo(properties);
                };

                /**
                 * Encodes the specified VirtualAppointmentInfo message. Does not implicitly {@link space.kenko.proto.VirtualAppointmentInfo.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.VirtualAppointmentInfo
                 * @static
                 * @param {space.kenko.proto.IVirtualAppointmentInfo} message VirtualAppointmentInfo message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                VirtualAppointmentInfo.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.acceptsVirtualAppointments != null && Object.hasOwnProperty.call(message, "acceptsVirtualAppointments"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.acceptsVirtualAppointments);
                    return writer;
                };

                /**
                 * Encodes the specified VirtualAppointmentInfo message, length delimited. Does not implicitly {@link space.kenko.proto.VirtualAppointmentInfo.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.VirtualAppointmentInfo
                 * @static
                 * @param {space.kenko.proto.IVirtualAppointmentInfo} message VirtualAppointmentInfo message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                VirtualAppointmentInfo.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a VirtualAppointmentInfo message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.VirtualAppointmentInfo
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.VirtualAppointmentInfo} VirtualAppointmentInfo
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                VirtualAppointmentInfo.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.VirtualAppointmentInfo();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.acceptsVirtualAppointments = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a VirtualAppointmentInfo message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.VirtualAppointmentInfo
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.VirtualAppointmentInfo} VirtualAppointmentInfo
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                VirtualAppointmentInfo.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a VirtualAppointmentInfo message.
                 * @function verify
                 * @memberof space.kenko.proto.VirtualAppointmentInfo
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                VirtualAppointmentInfo.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.acceptsVirtualAppointments != null && message.hasOwnProperty("acceptsVirtualAppointments"))
                        if (typeof message.acceptsVirtualAppointments !== "boolean")
                            return "acceptsVirtualAppointments: boolean expected";
                    return null;
                };

                /**
                 * Creates a VirtualAppointmentInfo message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.VirtualAppointmentInfo
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.VirtualAppointmentInfo} VirtualAppointmentInfo
                 */
                VirtualAppointmentInfo.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.VirtualAppointmentInfo)
                        return object;
                    let message = new $root.space.kenko.proto.VirtualAppointmentInfo();
                    if (object.acceptsVirtualAppointments != null)
                        message.acceptsVirtualAppointments = Boolean(object.acceptsVirtualAppointments);
                    return message;
                };

                /**
                 * Creates a plain object from a VirtualAppointmentInfo message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.VirtualAppointmentInfo
                 * @static
                 * @param {space.kenko.proto.VirtualAppointmentInfo} message VirtualAppointmentInfo
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                VirtualAppointmentInfo.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults)
                        object.acceptsVirtualAppointments = false;
                    if (message.acceptsVirtualAppointments != null && message.hasOwnProperty("acceptsVirtualAppointments"))
                        object.acceptsVirtualAppointments = message.acceptsVirtualAppointments;
                    return object;
                };

                /**
                 * Converts this VirtualAppointmentInfo to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.VirtualAppointmentInfo
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                VirtualAppointmentInfo.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for VirtualAppointmentInfo
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.VirtualAppointmentInfo
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                VirtualAppointmentInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.VirtualAppointmentInfo";
                };

                return VirtualAppointmentInfo;
            })();

            /**
             * ProviderApprovalState enum.
             * @name space.kenko.proto.ProviderApprovalState
             * @enum {number}
             * @property {number} APPROVAL_STATE_UNKNOWN=0 APPROVAL_STATE_UNKNOWN value
             * @property {number} NEW_PROVIDER_MANUAL_APPROVE=10 NEW_PROVIDER_MANUAL_APPROVE value
             * @property {number} NEW_PROVIDER_AUTO_APPROVE=11 NEW_PROVIDER_AUTO_APPROVE value
             * @property {number} AWAITING_REVIEW=20 AWAITING_REVIEW value
             * @property {number} PROVIDER_APPROVED=30 PROVIDER_APPROVED value
             * @property {number} PROVIDER_REJECTED=40 PROVIDER_REJECTED value
             */
            proto.ProviderApprovalState = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "APPROVAL_STATE_UNKNOWN"] = 0;
                values[valuesById[10] = "NEW_PROVIDER_MANUAL_APPROVE"] = 10;
                values[valuesById[11] = "NEW_PROVIDER_AUTO_APPROVE"] = 11;
                values[valuesById[20] = "AWAITING_REVIEW"] = 20;
                values[valuesById[30] = "PROVIDER_APPROVED"] = 30;
                values[valuesById[40] = "PROVIDER_REJECTED"] = 40;
                return values;
            })();

            /**
             * ApprovalStatus enum.
             * @name space.kenko.proto.ApprovalStatus
             * @enum {number}
             * @property {number} UNKNOWN=0 UNKNOWN value
             * @property {number} IN_REVIEW=1 IN_REVIEW value
             * @property {number} APPROVED=2 APPROVED value
             * @property {number} REJECTED=3 REJECTED value
             * @property {number} WITHDRAWN=4 WITHDRAWN value
             */
            proto.ApprovalStatus = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "UNKNOWN"] = 0;
                values[valuesById[1] = "IN_REVIEW"] = 1;
                values[valuesById[2] = "APPROVED"] = 2;
                values[valuesById[3] = "REJECTED"] = 3;
                values[valuesById[4] = "WITHDRAWN"] = 4;
                return values;
            })();

            proto.ProfileReviewProto = (function() {

                /**
                 * Properties of a ProfileReviewProto.
                 * @memberof space.kenko.proto
                 * @interface IProfileReviewProto
                 * @property {space.kenko.proto.ApprovalStatus|null} [status] ProfileReviewProto status
                 * @property {Array.<string>|null} [messages] ProfileReviewProto messages
                 */

                /**
                 * Constructs a new ProfileReviewProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a ProfileReviewProto.
                 * @implements IProfileReviewProto
                 * @constructor
                 * @param {space.kenko.proto.IProfileReviewProto=} [properties] Properties to set
                 */
                function ProfileReviewProto(properties) {
                    this.messages = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ProfileReviewProto status.
                 * @member {space.kenko.proto.ApprovalStatus} status
                 * @memberof space.kenko.proto.ProfileReviewProto
                 * @instance
                 */
                ProfileReviewProto.prototype.status = 0;

                /**
                 * ProfileReviewProto messages.
                 * @member {Array.<string>} messages
                 * @memberof space.kenko.proto.ProfileReviewProto
                 * @instance
                 */
                ProfileReviewProto.prototype.messages = $util.emptyArray;

                /**
                 * Creates a new ProfileReviewProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.ProfileReviewProto
                 * @static
                 * @param {space.kenko.proto.IProfileReviewProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.ProfileReviewProto} ProfileReviewProto instance
                 */
                ProfileReviewProto.create = function create(properties) {
                    return new ProfileReviewProto(properties);
                };

                /**
                 * Encodes the specified ProfileReviewProto message. Does not implicitly {@link space.kenko.proto.ProfileReviewProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.ProfileReviewProto
                 * @static
                 * @param {space.kenko.proto.IProfileReviewProto} message ProfileReviewProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ProfileReviewProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.status);
                    if (message.messages != null && message.messages.length)
                        for (let i = 0; i < message.messages.length; ++i)
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.messages[i]);
                    return writer;
                };

                /**
                 * Encodes the specified ProfileReviewProto message, length delimited. Does not implicitly {@link space.kenko.proto.ProfileReviewProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.ProfileReviewProto
                 * @static
                 * @param {space.kenko.proto.IProfileReviewProto} message ProfileReviewProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ProfileReviewProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ProfileReviewProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.ProfileReviewProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.ProfileReviewProto} ProfileReviewProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ProfileReviewProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.ProfileReviewProto();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.status = reader.int32();
                                break;
                            }
                        case 2: {
                                if (!(message.messages && message.messages.length))
                                    message.messages = [];
                                message.messages.push(reader.string());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ProfileReviewProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.ProfileReviewProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.ProfileReviewProto} ProfileReviewProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ProfileReviewProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ProfileReviewProto message.
                 * @function verify
                 * @memberof space.kenko.proto.ProfileReviewProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ProfileReviewProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.status != null && message.hasOwnProperty("status"))
                        switch (message.status) {
                        default:
                            return "status: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                            break;
                        }
                    if (message.messages != null && message.hasOwnProperty("messages")) {
                        if (!Array.isArray(message.messages))
                            return "messages: array expected";
                        for (let i = 0; i < message.messages.length; ++i)
                            if (!$util.isString(message.messages[i]))
                                return "messages: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a ProfileReviewProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.ProfileReviewProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.ProfileReviewProto} ProfileReviewProto
                 */
                ProfileReviewProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.ProfileReviewProto)
                        return object;
                    let message = new $root.space.kenko.proto.ProfileReviewProto();
                    switch (object.status) {
                    default:
                        if (typeof object.status === "number") {
                            message.status = object.status;
                            break;
                        }
                        break;
                    case "UNKNOWN":
                    case 0:
                        message.status = 0;
                        break;
                    case "IN_REVIEW":
                    case 1:
                        message.status = 1;
                        break;
                    case "APPROVED":
                    case 2:
                        message.status = 2;
                        break;
                    case "REJECTED":
                    case 3:
                        message.status = 3;
                        break;
                    case "WITHDRAWN":
                    case 4:
                        message.status = 4;
                        break;
                    }
                    if (object.messages) {
                        if (!Array.isArray(object.messages))
                            throw TypeError(".space.kenko.proto.ProfileReviewProto.messages: array expected");
                        message.messages = [];
                        for (let i = 0; i < object.messages.length; ++i)
                            message.messages[i] = String(object.messages[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ProfileReviewProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.ProfileReviewProto
                 * @static
                 * @param {space.kenko.proto.ProfileReviewProto} message ProfileReviewProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ProfileReviewProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.messages = [];
                    if (options.defaults)
                        object.status = options.enums === String ? "UNKNOWN" : 0;
                    if (message.status != null && message.hasOwnProperty("status"))
                        object.status = options.enums === String ? $root.space.kenko.proto.ApprovalStatus[message.status] === undefined ? message.status : $root.space.kenko.proto.ApprovalStatus[message.status] : message.status;
                    if (message.messages && message.messages.length) {
                        object.messages = [];
                        for (let j = 0; j < message.messages.length; ++j)
                            object.messages[j] = message.messages[j];
                    }
                    return object;
                };

                /**
                 * Converts this ProfileReviewProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.ProfileReviewProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ProfileReviewProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ProfileReviewProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.ProfileReviewProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ProfileReviewProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.ProfileReviewProto";
                };

                return ProfileReviewProto;
            })();

            proto.BusinessLocationProto = (function() {

                /**
                 * Properties of a BusinessLocationProto.
                 * @memberof space.kenko.proto
                 * @interface IBusinessLocationProto
                 * @property {string|null} [locationId] BusinessLocationProto locationId
                 * @property {string|null} [locationName] BusinessLocationProto locationName
                 * @property {string|null} [address] BusinessLocationProto address
                 * @property {string|null} [phone] BusinessLocationProto phone
                 * @property {string|null} [email] BusinessLocationProto email
                 * @property {number|null} [lat] BusinessLocationProto lat
                 * @property {number|null} [lng] BusinessLocationProto lng
                 * @property {string|null} [timeZone] BusinessLocationProto timeZone
                 * @property {boolean|null} ["private"] BusinessLocationProto private
                 * @property {boolean|null} [disabled] BusinessLocationProto disabled
                 * @property {Array.<string>|null} [imageUrl] BusinessLocationProto imageUrl
                 * @property {boolean|null} [hasBeenPublished] BusinessLocationProto hasBeenPublished
                 */

                /**
                 * Constructs a new BusinessLocationProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a BusinessLocationProto.
                 * @implements IBusinessLocationProto
                 * @constructor
                 * @param {space.kenko.proto.IBusinessLocationProto=} [properties] Properties to set
                 */
                function BusinessLocationProto(properties) {
                    this.imageUrl = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * BusinessLocationProto locationId.
                 * @member {string} locationId
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @instance
                 */
                BusinessLocationProto.prototype.locationId = "";

                /**
                 * BusinessLocationProto locationName.
                 * @member {string} locationName
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @instance
                 */
                BusinessLocationProto.prototype.locationName = "";

                /**
                 * BusinessLocationProto address.
                 * @member {string} address
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @instance
                 */
                BusinessLocationProto.prototype.address = "";

                /**
                 * BusinessLocationProto phone.
                 * @member {string} phone
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @instance
                 */
                BusinessLocationProto.prototype.phone = "";

                /**
                 * BusinessLocationProto email.
                 * @member {string} email
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @instance
                 */
                BusinessLocationProto.prototype.email = "";

                /**
                 * BusinessLocationProto lat.
                 * @member {number} lat
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @instance
                 */
                BusinessLocationProto.prototype.lat = 0;

                /**
                 * BusinessLocationProto lng.
                 * @member {number} lng
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @instance
                 */
                BusinessLocationProto.prototype.lng = 0;

                /**
                 * BusinessLocationProto timeZone.
                 * @member {string} timeZone
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @instance
                 */
                BusinessLocationProto.prototype.timeZone = "";

                /**
                 * BusinessLocationProto private.
                 * @member {boolean} private
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @instance
                 */
                BusinessLocationProto.prototype["private"] = false;

                /**
                 * BusinessLocationProto disabled.
                 * @member {boolean} disabled
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @instance
                 */
                BusinessLocationProto.prototype.disabled = false;

                /**
                 * BusinessLocationProto imageUrl.
                 * @member {Array.<string>} imageUrl
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @instance
                 */
                BusinessLocationProto.prototype.imageUrl = $util.emptyArray;

                /**
                 * BusinessLocationProto hasBeenPublished.
                 * @member {boolean} hasBeenPublished
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @instance
                 */
                BusinessLocationProto.prototype.hasBeenPublished = false;

                /**
                 * Creates a new BusinessLocationProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @static
                 * @param {space.kenko.proto.IBusinessLocationProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.BusinessLocationProto} BusinessLocationProto instance
                 */
                BusinessLocationProto.create = function create(properties) {
                    return new BusinessLocationProto(properties);
                };

                /**
                 * Encodes the specified BusinessLocationProto message. Does not implicitly {@link space.kenko.proto.BusinessLocationProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @static
                 * @param {space.kenko.proto.IBusinessLocationProto} message BusinessLocationProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BusinessLocationProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.locationId);
                    if (message.locationName != null && Object.hasOwnProperty.call(message, "locationName"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.locationName);
                    if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.address);
                    if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.phone);
                    if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.email);
                    if (message.lat != null && Object.hasOwnProperty.call(message, "lat"))
                        writer.uint32(/* id 6, wireType 1 =*/49).double(message.lat);
                    if (message.lng != null && Object.hasOwnProperty.call(message, "lng"))
                        writer.uint32(/* id 7, wireType 1 =*/57).double(message.lng);
                    if (message.timeZone != null && Object.hasOwnProperty.call(message, "timeZone"))
                        writer.uint32(/* id 8, wireType 2 =*/66).string(message.timeZone);
                    if (message["private"] != null && Object.hasOwnProperty.call(message, "private"))
                        writer.uint32(/* id 9, wireType 0 =*/72).bool(message["private"]);
                    if (message.disabled != null && Object.hasOwnProperty.call(message, "disabled"))
                        writer.uint32(/* id 10, wireType 0 =*/80).bool(message.disabled);
                    if (message.imageUrl != null && message.imageUrl.length)
                        for (let i = 0; i < message.imageUrl.length; ++i)
                            writer.uint32(/* id 11, wireType 2 =*/90).string(message.imageUrl[i]);
                    if (message.hasBeenPublished != null && Object.hasOwnProperty.call(message, "hasBeenPublished"))
                        writer.uint32(/* id 12, wireType 0 =*/96).bool(message.hasBeenPublished);
                    return writer;
                };

                /**
                 * Encodes the specified BusinessLocationProto message, length delimited. Does not implicitly {@link space.kenko.proto.BusinessLocationProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @static
                 * @param {space.kenko.proto.IBusinessLocationProto} message BusinessLocationProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BusinessLocationProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a BusinessLocationProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.BusinessLocationProto} BusinessLocationProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BusinessLocationProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.BusinessLocationProto();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.locationId = reader.string();
                                break;
                            }
                        case 2: {
                                message.locationName = reader.string();
                                break;
                            }
                        case 3: {
                                message.address = reader.string();
                                break;
                            }
                        case 4: {
                                message.phone = reader.string();
                                break;
                            }
                        case 5: {
                                message.email = reader.string();
                                break;
                            }
                        case 6: {
                                message.lat = reader.double();
                                break;
                            }
                        case 7: {
                                message.lng = reader.double();
                                break;
                            }
                        case 8: {
                                message.timeZone = reader.string();
                                break;
                            }
                        case 9: {
                                message["private"] = reader.bool();
                                break;
                            }
                        case 10: {
                                message.disabled = reader.bool();
                                break;
                            }
                        case 11: {
                                if (!(message.imageUrl && message.imageUrl.length))
                                    message.imageUrl = [];
                                message.imageUrl.push(reader.string());
                                break;
                            }
                        case 12: {
                                message.hasBeenPublished = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a BusinessLocationProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.BusinessLocationProto} BusinessLocationProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BusinessLocationProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a BusinessLocationProto message.
                 * @function verify
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BusinessLocationProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        if (!$util.isString(message.locationId))
                            return "locationId: string expected";
                    if (message.locationName != null && message.hasOwnProperty("locationName"))
                        if (!$util.isString(message.locationName))
                            return "locationName: string expected";
                    if (message.address != null && message.hasOwnProperty("address"))
                        if (!$util.isString(message.address))
                            return "address: string expected";
                    if (message.phone != null && message.hasOwnProperty("phone"))
                        if (!$util.isString(message.phone))
                            return "phone: string expected";
                    if (message.email != null && message.hasOwnProperty("email"))
                        if (!$util.isString(message.email))
                            return "email: string expected";
                    if (message.lat != null && message.hasOwnProperty("lat"))
                        if (typeof message.lat !== "number")
                            return "lat: number expected";
                    if (message.lng != null && message.hasOwnProperty("lng"))
                        if (typeof message.lng !== "number")
                            return "lng: number expected";
                    if (message.timeZone != null && message.hasOwnProperty("timeZone"))
                        if (!$util.isString(message.timeZone))
                            return "timeZone: string expected";
                    if (message["private"] != null && message.hasOwnProperty("private"))
                        if (typeof message["private"] !== "boolean")
                            return "private: boolean expected";
                    if (message.disabled != null && message.hasOwnProperty("disabled"))
                        if (typeof message.disabled !== "boolean")
                            return "disabled: boolean expected";
                    if (message.imageUrl != null && message.hasOwnProperty("imageUrl")) {
                        if (!Array.isArray(message.imageUrl))
                            return "imageUrl: array expected";
                        for (let i = 0; i < message.imageUrl.length; ++i)
                            if (!$util.isString(message.imageUrl[i]))
                                return "imageUrl: string[] expected";
                    }
                    if (message.hasBeenPublished != null && message.hasOwnProperty("hasBeenPublished"))
                        if (typeof message.hasBeenPublished !== "boolean")
                            return "hasBeenPublished: boolean expected";
                    return null;
                };

                /**
                 * Creates a BusinessLocationProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.BusinessLocationProto} BusinessLocationProto
                 */
                BusinessLocationProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.BusinessLocationProto)
                        return object;
                    let message = new $root.space.kenko.proto.BusinessLocationProto();
                    if (object.locationId != null)
                        message.locationId = String(object.locationId);
                    if (object.locationName != null)
                        message.locationName = String(object.locationName);
                    if (object.address != null)
                        message.address = String(object.address);
                    if (object.phone != null)
                        message.phone = String(object.phone);
                    if (object.email != null)
                        message.email = String(object.email);
                    if (object.lat != null)
                        message.lat = Number(object.lat);
                    if (object.lng != null)
                        message.lng = Number(object.lng);
                    if (object.timeZone != null)
                        message.timeZone = String(object.timeZone);
                    if (object["private"] != null)
                        message["private"] = Boolean(object["private"]);
                    if (object.disabled != null)
                        message.disabled = Boolean(object.disabled);
                    if (object.imageUrl) {
                        if (!Array.isArray(object.imageUrl))
                            throw TypeError(".space.kenko.proto.BusinessLocationProto.imageUrl: array expected");
                        message.imageUrl = [];
                        for (let i = 0; i < object.imageUrl.length; ++i)
                            message.imageUrl[i] = String(object.imageUrl[i]);
                    }
                    if (object.hasBeenPublished != null)
                        message.hasBeenPublished = Boolean(object.hasBeenPublished);
                    return message;
                };

                /**
                 * Creates a plain object from a BusinessLocationProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @static
                 * @param {space.kenko.proto.BusinessLocationProto} message BusinessLocationProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BusinessLocationProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.imageUrl = [];
                    if (options.defaults) {
                        object.locationId = "";
                        object.locationName = "";
                        object.address = "";
                        object.phone = "";
                        object.email = "";
                        object.lat = 0;
                        object.lng = 0;
                        object.timeZone = "";
                        object["private"] = false;
                        object.disabled = false;
                        object.hasBeenPublished = false;
                    }
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        object.locationId = message.locationId;
                    if (message.locationName != null && message.hasOwnProperty("locationName"))
                        object.locationName = message.locationName;
                    if (message.address != null && message.hasOwnProperty("address"))
                        object.address = message.address;
                    if (message.phone != null && message.hasOwnProperty("phone"))
                        object.phone = message.phone;
                    if (message.email != null && message.hasOwnProperty("email"))
                        object.email = message.email;
                    if (message.lat != null && message.hasOwnProperty("lat"))
                        object.lat = options.json && !isFinite(message.lat) ? String(message.lat) : message.lat;
                    if (message.lng != null && message.hasOwnProperty("lng"))
                        object.lng = options.json && !isFinite(message.lng) ? String(message.lng) : message.lng;
                    if (message.timeZone != null && message.hasOwnProperty("timeZone"))
                        object.timeZone = message.timeZone;
                    if (message["private"] != null && message.hasOwnProperty("private"))
                        object["private"] = message["private"];
                    if (message.disabled != null && message.hasOwnProperty("disabled"))
                        object.disabled = message.disabled;
                    if (message.imageUrl && message.imageUrl.length) {
                        object.imageUrl = [];
                        for (let j = 0; j < message.imageUrl.length; ++j)
                            object.imageUrl[j] = message.imageUrl[j];
                    }
                    if (message.hasBeenPublished != null && message.hasOwnProperty("hasBeenPublished"))
                        object.hasBeenPublished = message.hasBeenPublished;
                    return object;
                };

                /**
                 * Converts this BusinessLocationProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BusinessLocationProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for BusinessLocationProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.BusinessLocationProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                BusinessLocationProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.BusinessLocationProto";
                };

                return BusinessLocationProto;
            })();

            proto.UserProfileProto = (function() {

                /**
                 * Properties of a UserProfileProto.
                 * @memberof space.kenko.proto
                 * @interface IUserProfileProto
                 * @property {string|null} [firstName] UserProfileProto firstName
                 * @property {string|null} [lastName] UserProfileProto lastName
                 * @property {string|null} [mobileNumber] UserProfileProto mobileNumber
                 * @property {boolean|null} [mobileNumberVerified] UserProfileProto mobileNumberVerified
                 * @property {string|null} [countryCode] UserProfileProto countryCode
                 * @property {boolean|null} [agreedToTerms] UserProfileProto agreedToTerms
                 */

                /**
                 * Constructs a new UserProfileProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a UserProfileProto.
                 * @implements IUserProfileProto
                 * @constructor
                 * @param {space.kenko.proto.IUserProfileProto=} [properties] Properties to set
                 */
                function UserProfileProto(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UserProfileProto firstName.
                 * @member {string} firstName
                 * @memberof space.kenko.proto.UserProfileProto
                 * @instance
                 */
                UserProfileProto.prototype.firstName = "";

                /**
                 * UserProfileProto lastName.
                 * @member {string} lastName
                 * @memberof space.kenko.proto.UserProfileProto
                 * @instance
                 */
                UserProfileProto.prototype.lastName = "";

                /**
                 * UserProfileProto mobileNumber.
                 * @member {string} mobileNumber
                 * @memberof space.kenko.proto.UserProfileProto
                 * @instance
                 */
                UserProfileProto.prototype.mobileNumber = "";

                /**
                 * UserProfileProto mobileNumberVerified.
                 * @member {boolean} mobileNumberVerified
                 * @memberof space.kenko.proto.UserProfileProto
                 * @instance
                 */
                UserProfileProto.prototype.mobileNumberVerified = false;

                /**
                 * UserProfileProto countryCode.
                 * @member {string} countryCode
                 * @memberof space.kenko.proto.UserProfileProto
                 * @instance
                 */
                UserProfileProto.prototype.countryCode = "";

                /**
                 * UserProfileProto agreedToTerms.
                 * @member {boolean} agreedToTerms
                 * @memberof space.kenko.proto.UserProfileProto
                 * @instance
                 */
                UserProfileProto.prototype.agreedToTerms = false;

                /**
                 * Creates a new UserProfileProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.UserProfileProto
                 * @static
                 * @param {space.kenko.proto.IUserProfileProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.UserProfileProto} UserProfileProto instance
                 */
                UserProfileProto.create = function create(properties) {
                    return new UserProfileProto(properties);
                };

                /**
                 * Encodes the specified UserProfileProto message. Does not implicitly {@link space.kenko.proto.UserProfileProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.UserProfileProto
                 * @static
                 * @param {space.kenko.proto.IUserProfileProto} message UserProfileProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UserProfileProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.firstName != null && Object.hasOwnProperty.call(message, "firstName"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.firstName);
                    if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.lastName);
                    if (message.mobileNumber != null && Object.hasOwnProperty.call(message, "mobileNumber"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.mobileNumber);
                    if (message.mobileNumberVerified != null && Object.hasOwnProperty.call(message, "mobileNumberVerified"))
                        writer.uint32(/* id 4, wireType 0 =*/32).bool(message.mobileNumberVerified);
                    if (message.countryCode != null && Object.hasOwnProperty.call(message, "countryCode"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.countryCode);
                    if (message.agreedToTerms != null && Object.hasOwnProperty.call(message, "agreedToTerms"))
                        writer.uint32(/* id 6, wireType 0 =*/48).bool(message.agreedToTerms);
                    return writer;
                };

                /**
                 * Encodes the specified UserProfileProto message, length delimited. Does not implicitly {@link space.kenko.proto.UserProfileProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.UserProfileProto
                 * @static
                 * @param {space.kenko.proto.IUserProfileProto} message UserProfileProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UserProfileProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a UserProfileProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.UserProfileProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.UserProfileProto} UserProfileProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UserProfileProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.UserProfileProto();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.firstName = reader.string();
                                break;
                            }
                        case 2: {
                                message.lastName = reader.string();
                                break;
                            }
                        case 3: {
                                message.mobileNumber = reader.string();
                                break;
                            }
                        case 4: {
                                message.mobileNumberVerified = reader.bool();
                                break;
                            }
                        case 5: {
                                message.countryCode = reader.string();
                                break;
                            }
                        case 6: {
                                message.agreedToTerms = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a UserProfileProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.UserProfileProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.UserProfileProto} UserProfileProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UserProfileProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a UserProfileProto message.
                 * @function verify
                 * @memberof space.kenko.proto.UserProfileProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UserProfileProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.firstName != null && message.hasOwnProperty("firstName"))
                        if (!$util.isString(message.firstName))
                            return "firstName: string expected";
                    if (message.lastName != null && message.hasOwnProperty("lastName"))
                        if (!$util.isString(message.lastName))
                            return "lastName: string expected";
                    if (message.mobileNumber != null && message.hasOwnProperty("mobileNumber"))
                        if (!$util.isString(message.mobileNumber))
                            return "mobileNumber: string expected";
                    if (message.mobileNumberVerified != null && message.hasOwnProperty("mobileNumberVerified"))
                        if (typeof message.mobileNumberVerified !== "boolean")
                            return "mobileNumberVerified: boolean expected";
                    if (message.countryCode != null && message.hasOwnProperty("countryCode"))
                        if (!$util.isString(message.countryCode))
                            return "countryCode: string expected";
                    if (message.agreedToTerms != null && message.hasOwnProperty("agreedToTerms"))
                        if (typeof message.agreedToTerms !== "boolean")
                            return "agreedToTerms: boolean expected";
                    return null;
                };

                /**
                 * Creates a UserProfileProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.UserProfileProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.UserProfileProto} UserProfileProto
                 */
                UserProfileProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.UserProfileProto)
                        return object;
                    let message = new $root.space.kenko.proto.UserProfileProto();
                    if (object.firstName != null)
                        message.firstName = String(object.firstName);
                    if (object.lastName != null)
                        message.lastName = String(object.lastName);
                    if (object.mobileNumber != null)
                        message.mobileNumber = String(object.mobileNumber);
                    if (object.mobileNumberVerified != null)
                        message.mobileNumberVerified = Boolean(object.mobileNumberVerified);
                    if (object.countryCode != null)
                        message.countryCode = String(object.countryCode);
                    if (object.agreedToTerms != null)
                        message.agreedToTerms = Boolean(object.agreedToTerms);
                    return message;
                };

                /**
                 * Creates a plain object from a UserProfileProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.UserProfileProto
                 * @static
                 * @param {space.kenko.proto.UserProfileProto} message UserProfileProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UserProfileProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.firstName = "";
                        object.lastName = "";
                        object.mobileNumber = "";
                        object.mobileNumberVerified = false;
                        object.countryCode = "";
                        object.agreedToTerms = false;
                    }
                    if (message.firstName != null && message.hasOwnProperty("firstName"))
                        object.firstName = message.firstName;
                    if (message.lastName != null && message.hasOwnProperty("lastName"))
                        object.lastName = message.lastName;
                    if (message.mobileNumber != null && message.hasOwnProperty("mobileNumber"))
                        object.mobileNumber = message.mobileNumber;
                    if (message.mobileNumberVerified != null && message.hasOwnProperty("mobileNumberVerified"))
                        object.mobileNumberVerified = message.mobileNumberVerified;
                    if (message.countryCode != null && message.hasOwnProperty("countryCode"))
                        object.countryCode = message.countryCode;
                    if (message.agreedToTerms != null && message.hasOwnProperty("agreedToTerms"))
                        object.agreedToTerms = message.agreedToTerms;
                    return object;
                };

                /**
                 * Converts this UserProfileProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.UserProfileProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UserProfileProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for UserProfileProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.UserProfileProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UserProfileProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.UserProfileProto";
                };

                return UserProfileProto;
            })();

            proto.AddressProto = (function() {

                /**
                 * Properties of an AddressProto.
                 * @memberof space.kenko.proto
                 * @interface IAddressProto
                 * @property {string|null} [formattedAddress] AddressProto formattedAddress
                 * @property {number|null} [lat] AddressProto lat
                 * @property {number|null} [lng] AddressProto lng
                 */

                /**
                 * Constructs a new AddressProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents an AddressProto.
                 * @implements IAddressProto
                 * @constructor
                 * @param {space.kenko.proto.IAddressProto=} [properties] Properties to set
                 */
                function AddressProto(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * AddressProto formattedAddress.
                 * @member {string} formattedAddress
                 * @memberof space.kenko.proto.AddressProto
                 * @instance
                 */
                AddressProto.prototype.formattedAddress = "";

                /**
                 * AddressProto lat.
                 * @member {number} lat
                 * @memberof space.kenko.proto.AddressProto
                 * @instance
                 */
                AddressProto.prototype.lat = 0;

                /**
                 * AddressProto lng.
                 * @member {number} lng
                 * @memberof space.kenko.proto.AddressProto
                 * @instance
                 */
                AddressProto.prototype.lng = 0;

                /**
                 * Creates a new AddressProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.AddressProto
                 * @static
                 * @param {space.kenko.proto.IAddressProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.AddressProto} AddressProto instance
                 */
                AddressProto.create = function create(properties) {
                    return new AddressProto(properties);
                };

                /**
                 * Encodes the specified AddressProto message. Does not implicitly {@link space.kenko.proto.AddressProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.AddressProto
                 * @static
                 * @param {space.kenko.proto.IAddressProto} message AddressProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AddressProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.formattedAddress != null && Object.hasOwnProperty.call(message, "formattedAddress"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.formattedAddress);
                    if (message.lat != null && Object.hasOwnProperty.call(message, "lat"))
                        writer.uint32(/* id 2, wireType 1 =*/17).double(message.lat);
                    if (message.lng != null && Object.hasOwnProperty.call(message, "lng"))
                        writer.uint32(/* id 3, wireType 1 =*/25).double(message.lng);
                    return writer;
                };

                /**
                 * Encodes the specified AddressProto message, length delimited. Does not implicitly {@link space.kenko.proto.AddressProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.AddressProto
                 * @static
                 * @param {space.kenko.proto.IAddressProto} message AddressProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AddressProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an AddressProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.AddressProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.AddressProto} AddressProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AddressProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.AddressProto();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.formattedAddress = reader.string();
                                break;
                            }
                        case 2: {
                                message.lat = reader.double();
                                break;
                            }
                        case 3: {
                                message.lng = reader.double();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an AddressProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.AddressProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.AddressProto} AddressProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AddressProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an AddressProto message.
                 * @function verify
                 * @memberof space.kenko.proto.AddressProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AddressProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.formattedAddress != null && message.hasOwnProperty("formattedAddress"))
                        if (!$util.isString(message.formattedAddress))
                            return "formattedAddress: string expected";
                    if (message.lat != null && message.hasOwnProperty("lat"))
                        if (typeof message.lat !== "number")
                            return "lat: number expected";
                    if (message.lng != null && message.hasOwnProperty("lng"))
                        if (typeof message.lng !== "number")
                            return "lng: number expected";
                    return null;
                };

                /**
                 * Creates an AddressProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.AddressProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.AddressProto} AddressProto
                 */
                AddressProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.AddressProto)
                        return object;
                    let message = new $root.space.kenko.proto.AddressProto();
                    if (object.formattedAddress != null)
                        message.formattedAddress = String(object.formattedAddress);
                    if (object.lat != null)
                        message.lat = Number(object.lat);
                    if (object.lng != null)
                        message.lng = Number(object.lng);
                    return message;
                };

                /**
                 * Creates a plain object from an AddressProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.AddressProto
                 * @static
                 * @param {space.kenko.proto.AddressProto} message AddressProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AddressProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.formattedAddress = "";
                        object.lat = 0;
                        object.lng = 0;
                    }
                    if (message.formattedAddress != null && message.hasOwnProperty("formattedAddress"))
                        object.formattedAddress = message.formattedAddress;
                    if (message.lat != null && message.hasOwnProperty("lat"))
                        object.lat = options.json && !isFinite(message.lat) ? String(message.lat) : message.lat;
                    if (message.lng != null && message.hasOwnProperty("lng"))
                        object.lng = options.json && !isFinite(message.lng) ? String(message.lng) : message.lng;
                    return object;
                };

                /**
                 * Converts this AddressProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.AddressProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AddressProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for AddressProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.AddressProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                AddressProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.AddressProto";
                };

                return AddressProto;
            })();

            proto.ProviderBioProto = (function() {

                /**
                 * Properties of a ProviderBioProto.
                 * @memberof space.kenko.proto
                 * @interface IProviderBioProto
                 * @property {string|null} [providerId] ProviderBioProto providerId
                 * @property {string|null} [firstName] ProviderBioProto firstName
                 * @property {string|null} [lastName] ProviderBioProto lastName
                 * @property {boolean|null} [isPractitioner] ProviderBioProto isPractitioner
                 * @property {string|null} [bio] ProviderBioProto bio
                 * @property {Array.<string>|null} [imageUrls] ProviderBioProto imageUrls
                 * @property {string|null} [email] ProviderBioProto email
                 * @property {string|null} [phone] ProviderBioProto phone
                 * @property {string|null} [jobTitle] ProviderBioProto jobTitle
                 * @property {Array.<space.kenko.proto.IEducationProto>|null} [education] ProviderBioProto education
                 * @property {Array.<space.kenko.proto.ILicenseOrCredentialsProto>|null} [credentials] ProviderBioProto credentials
                 * @property {Array.<string>|null} [languages] ProviderBioProto languages
                 * @property {boolean|null} [disabled] ProviderBioProto disabled
                 * @property {Array.<space.kenko.proto.IErrorProto>|null} [errors] ProviderBioProto errors
                 */

                /**
                 * Constructs a new ProviderBioProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a ProviderBioProto.
                 * @implements IProviderBioProto
                 * @constructor
                 * @param {space.kenko.proto.IProviderBioProto=} [properties] Properties to set
                 */
                function ProviderBioProto(properties) {
                    this.imageUrls = [];
                    this.education = [];
                    this.credentials = [];
                    this.languages = [];
                    this.errors = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ProviderBioProto providerId.
                 * @member {string} providerId
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @instance
                 */
                ProviderBioProto.prototype.providerId = "";

                /**
                 * ProviderBioProto firstName.
                 * @member {string} firstName
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @instance
                 */
                ProviderBioProto.prototype.firstName = "";

                /**
                 * ProviderBioProto lastName.
                 * @member {string} lastName
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @instance
                 */
                ProviderBioProto.prototype.lastName = "";

                /**
                 * ProviderBioProto isPractitioner.
                 * @member {boolean} isPractitioner
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @instance
                 */
                ProviderBioProto.prototype.isPractitioner = false;

                /**
                 * ProviderBioProto bio.
                 * @member {string} bio
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @instance
                 */
                ProviderBioProto.prototype.bio = "";

                /**
                 * ProviderBioProto imageUrls.
                 * @member {Array.<string>} imageUrls
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @instance
                 */
                ProviderBioProto.prototype.imageUrls = $util.emptyArray;

                /**
                 * ProviderBioProto email.
                 * @member {string} email
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @instance
                 */
                ProviderBioProto.prototype.email = "";

                /**
                 * ProviderBioProto phone.
                 * @member {string} phone
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @instance
                 */
                ProviderBioProto.prototype.phone = "";

                /**
                 * ProviderBioProto jobTitle.
                 * @member {string} jobTitle
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @instance
                 */
                ProviderBioProto.prototype.jobTitle = "";

                /**
                 * ProviderBioProto education.
                 * @member {Array.<space.kenko.proto.IEducationProto>} education
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @instance
                 */
                ProviderBioProto.prototype.education = $util.emptyArray;

                /**
                 * ProviderBioProto credentials.
                 * @member {Array.<space.kenko.proto.ILicenseOrCredentialsProto>} credentials
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @instance
                 */
                ProviderBioProto.prototype.credentials = $util.emptyArray;

                /**
                 * ProviderBioProto languages.
                 * @member {Array.<string>} languages
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @instance
                 */
                ProviderBioProto.prototype.languages = $util.emptyArray;

                /**
                 * ProviderBioProto disabled.
                 * @member {boolean} disabled
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @instance
                 */
                ProviderBioProto.prototype.disabled = false;

                /**
                 * ProviderBioProto errors.
                 * @member {Array.<space.kenko.proto.IErrorProto>} errors
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @instance
                 */
                ProviderBioProto.prototype.errors = $util.emptyArray;

                /**
                 * Creates a new ProviderBioProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @static
                 * @param {space.kenko.proto.IProviderBioProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.ProviderBioProto} ProviderBioProto instance
                 */
                ProviderBioProto.create = function create(properties) {
                    return new ProviderBioProto(properties);
                };

                /**
                 * Encodes the specified ProviderBioProto message. Does not implicitly {@link space.kenko.proto.ProviderBioProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @static
                 * @param {space.kenko.proto.IProviderBioProto} message ProviderBioProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ProviderBioProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.providerId != null && Object.hasOwnProperty.call(message, "providerId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.providerId);
                    if (message.firstName != null && Object.hasOwnProperty.call(message, "firstName"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.firstName);
                    if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.lastName);
                    if (message.bio != null && Object.hasOwnProperty.call(message, "bio"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.bio);
                    if (message.imageUrls != null && message.imageUrls.length)
                        for (let i = 0; i < message.imageUrls.length; ++i)
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.imageUrls[i]);
                    if (message.isPractitioner != null && Object.hasOwnProperty.call(message, "isPractitioner"))
                        writer.uint32(/* id 6, wireType 0 =*/48).bool(message.isPractitioner);
                    if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                        writer.uint32(/* id 8, wireType 2 =*/66).string(message.email);
                    if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                        writer.uint32(/* id 9, wireType 2 =*/74).string(message.phone);
                    if (message.jobTitle != null && Object.hasOwnProperty.call(message, "jobTitle"))
                        writer.uint32(/* id 10, wireType 2 =*/82).string(message.jobTitle);
                    if (message.education != null && message.education.length)
                        for (let i = 0; i < message.education.length; ++i)
                            $root.space.kenko.proto.EducationProto.encode(message.education[i], writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                    if (message.credentials != null && message.credentials.length)
                        for (let i = 0; i < message.credentials.length; ++i)
                            $root.space.kenko.proto.LicenseOrCredentialsProto.encode(message.credentials[i], writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                    if (message.languages != null && message.languages.length)
                        for (let i = 0; i < message.languages.length; ++i)
                            writer.uint32(/* id 13, wireType 2 =*/106).string(message.languages[i]);
                    if (message.disabled != null && Object.hasOwnProperty.call(message, "disabled"))
                        writer.uint32(/* id 100, wireType 0 =*/800).bool(message.disabled);
                    if (message.errors != null && message.errors.length)
                        for (let i = 0; i < message.errors.length; ++i)
                            $root.space.kenko.proto.ErrorProto.encode(message.errors[i], writer.uint32(/* id 101, wireType 2 =*/810).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified ProviderBioProto message, length delimited. Does not implicitly {@link space.kenko.proto.ProviderBioProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @static
                 * @param {space.kenko.proto.IProviderBioProto} message ProviderBioProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ProviderBioProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ProviderBioProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.ProviderBioProto} ProviderBioProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ProviderBioProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.ProviderBioProto();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.providerId = reader.string();
                                break;
                            }
                        case 2: {
                                message.firstName = reader.string();
                                break;
                            }
                        case 3: {
                                message.lastName = reader.string();
                                break;
                            }
                        case 6: {
                                message.isPractitioner = reader.bool();
                                break;
                            }
                        case 4: {
                                message.bio = reader.string();
                                break;
                            }
                        case 5: {
                                if (!(message.imageUrls && message.imageUrls.length))
                                    message.imageUrls = [];
                                message.imageUrls.push(reader.string());
                                break;
                            }
                        case 8: {
                                message.email = reader.string();
                                break;
                            }
                        case 9: {
                                message.phone = reader.string();
                                break;
                            }
                        case 10: {
                                message.jobTitle = reader.string();
                                break;
                            }
                        case 11: {
                                if (!(message.education && message.education.length))
                                    message.education = [];
                                message.education.push($root.space.kenko.proto.EducationProto.decode(reader, reader.uint32()));
                                break;
                            }
                        case 12: {
                                if (!(message.credentials && message.credentials.length))
                                    message.credentials = [];
                                message.credentials.push($root.space.kenko.proto.LicenseOrCredentialsProto.decode(reader, reader.uint32()));
                                break;
                            }
                        case 13: {
                                if (!(message.languages && message.languages.length))
                                    message.languages = [];
                                message.languages.push(reader.string());
                                break;
                            }
                        case 100: {
                                message.disabled = reader.bool();
                                break;
                            }
                        case 101: {
                                if (!(message.errors && message.errors.length))
                                    message.errors = [];
                                message.errors.push($root.space.kenko.proto.ErrorProto.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ProviderBioProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.ProviderBioProto} ProviderBioProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ProviderBioProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ProviderBioProto message.
                 * @function verify
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ProviderBioProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.providerId != null && message.hasOwnProperty("providerId"))
                        if (!$util.isString(message.providerId))
                            return "providerId: string expected";
                    if (message.firstName != null && message.hasOwnProperty("firstName"))
                        if (!$util.isString(message.firstName))
                            return "firstName: string expected";
                    if (message.lastName != null && message.hasOwnProperty("lastName"))
                        if (!$util.isString(message.lastName))
                            return "lastName: string expected";
                    if (message.isPractitioner != null && message.hasOwnProperty("isPractitioner"))
                        if (typeof message.isPractitioner !== "boolean")
                            return "isPractitioner: boolean expected";
                    if (message.bio != null && message.hasOwnProperty("bio"))
                        if (!$util.isString(message.bio))
                            return "bio: string expected";
                    if (message.imageUrls != null && message.hasOwnProperty("imageUrls")) {
                        if (!Array.isArray(message.imageUrls))
                            return "imageUrls: array expected";
                        for (let i = 0; i < message.imageUrls.length; ++i)
                            if (!$util.isString(message.imageUrls[i]))
                                return "imageUrls: string[] expected";
                    }
                    if (message.email != null && message.hasOwnProperty("email"))
                        if (!$util.isString(message.email))
                            return "email: string expected";
                    if (message.phone != null && message.hasOwnProperty("phone"))
                        if (!$util.isString(message.phone))
                            return "phone: string expected";
                    if (message.jobTitle != null && message.hasOwnProperty("jobTitle"))
                        if (!$util.isString(message.jobTitle))
                            return "jobTitle: string expected";
                    if (message.education != null && message.hasOwnProperty("education")) {
                        if (!Array.isArray(message.education))
                            return "education: array expected";
                        for (let i = 0; i < message.education.length; ++i) {
                            let error = $root.space.kenko.proto.EducationProto.verify(message.education[i]);
                            if (error)
                                return "education." + error;
                        }
                    }
                    if (message.credentials != null && message.hasOwnProperty("credentials")) {
                        if (!Array.isArray(message.credentials))
                            return "credentials: array expected";
                        for (let i = 0; i < message.credentials.length; ++i) {
                            let error = $root.space.kenko.proto.LicenseOrCredentialsProto.verify(message.credentials[i]);
                            if (error)
                                return "credentials." + error;
                        }
                    }
                    if (message.languages != null && message.hasOwnProperty("languages")) {
                        if (!Array.isArray(message.languages))
                            return "languages: array expected";
                        for (let i = 0; i < message.languages.length; ++i)
                            if (!$util.isString(message.languages[i]))
                                return "languages: string[] expected";
                    }
                    if (message.disabled != null && message.hasOwnProperty("disabled"))
                        if (typeof message.disabled !== "boolean")
                            return "disabled: boolean expected";
                    if (message.errors != null && message.hasOwnProperty("errors")) {
                        if (!Array.isArray(message.errors))
                            return "errors: array expected";
                        for (let i = 0; i < message.errors.length; ++i) {
                            let error = $root.space.kenko.proto.ErrorProto.verify(message.errors[i]);
                            if (error)
                                return "errors." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a ProviderBioProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.ProviderBioProto} ProviderBioProto
                 */
                ProviderBioProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.ProviderBioProto)
                        return object;
                    let message = new $root.space.kenko.proto.ProviderBioProto();
                    if (object.providerId != null)
                        message.providerId = String(object.providerId);
                    if (object.firstName != null)
                        message.firstName = String(object.firstName);
                    if (object.lastName != null)
                        message.lastName = String(object.lastName);
                    if (object.isPractitioner != null)
                        message.isPractitioner = Boolean(object.isPractitioner);
                    if (object.bio != null)
                        message.bio = String(object.bio);
                    if (object.imageUrls) {
                        if (!Array.isArray(object.imageUrls))
                            throw TypeError(".space.kenko.proto.ProviderBioProto.imageUrls: array expected");
                        message.imageUrls = [];
                        for (let i = 0; i < object.imageUrls.length; ++i)
                            message.imageUrls[i] = String(object.imageUrls[i]);
                    }
                    if (object.email != null)
                        message.email = String(object.email);
                    if (object.phone != null)
                        message.phone = String(object.phone);
                    if (object.jobTitle != null)
                        message.jobTitle = String(object.jobTitle);
                    if (object.education) {
                        if (!Array.isArray(object.education))
                            throw TypeError(".space.kenko.proto.ProviderBioProto.education: array expected");
                        message.education = [];
                        for (let i = 0; i < object.education.length; ++i) {
                            if (typeof object.education[i] !== "object")
                                throw TypeError(".space.kenko.proto.ProviderBioProto.education: object expected");
                            message.education[i] = $root.space.kenko.proto.EducationProto.fromObject(object.education[i]);
                        }
                    }
                    if (object.credentials) {
                        if (!Array.isArray(object.credentials))
                            throw TypeError(".space.kenko.proto.ProviderBioProto.credentials: array expected");
                        message.credentials = [];
                        for (let i = 0; i < object.credentials.length; ++i) {
                            if (typeof object.credentials[i] !== "object")
                                throw TypeError(".space.kenko.proto.ProviderBioProto.credentials: object expected");
                            message.credentials[i] = $root.space.kenko.proto.LicenseOrCredentialsProto.fromObject(object.credentials[i]);
                        }
                    }
                    if (object.languages) {
                        if (!Array.isArray(object.languages))
                            throw TypeError(".space.kenko.proto.ProviderBioProto.languages: array expected");
                        message.languages = [];
                        for (let i = 0; i < object.languages.length; ++i)
                            message.languages[i] = String(object.languages[i]);
                    }
                    if (object.disabled != null)
                        message.disabled = Boolean(object.disabled);
                    if (object.errors) {
                        if (!Array.isArray(object.errors))
                            throw TypeError(".space.kenko.proto.ProviderBioProto.errors: array expected");
                        message.errors = [];
                        for (let i = 0; i < object.errors.length; ++i) {
                            if (typeof object.errors[i] !== "object")
                                throw TypeError(".space.kenko.proto.ProviderBioProto.errors: object expected");
                            message.errors[i] = $root.space.kenko.proto.ErrorProto.fromObject(object.errors[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ProviderBioProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @static
                 * @param {space.kenko.proto.ProviderBioProto} message ProviderBioProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ProviderBioProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults) {
                        object.imageUrls = [];
                        object.education = [];
                        object.credentials = [];
                        object.languages = [];
                        object.errors = [];
                    }
                    if (options.defaults) {
                        object.providerId = "";
                        object.firstName = "";
                        object.lastName = "";
                        object.bio = "";
                        object.isPractitioner = false;
                        object.email = "";
                        object.phone = "";
                        object.jobTitle = "";
                        object.disabled = false;
                    }
                    if (message.providerId != null && message.hasOwnProperty("providerId"))
                        object.providerId = message.providerId;
                    if (message.firstName != null && message.hasOwnProperty("firstName"))
                        object.firstName = message.firstName;
                    if (message.lastName != null && message.hasOwnProperty("lastName"))
                        object.lastName = message.lastName;
                    if (message.bio != null && message.hasOwnProperty("bio"))
                        object.bio = message.bio;
                    if (message.imageUrls && message.imageUrls.length) {
                        object.imageUrls = [];
                        for (let j = 0; j < message.imageUrls.length; ++j)
                            object.imageUrls[j] = message.imageUrls[j];
                    }
                    if (message.isPractitioner != null && message.hasOwnProperty("isPractitioner"))
                        object.isPractitioner = message.isPractitioner;
                    if (message.email != null && message.hasOwnProperty("email"))
                        object.email = message.email;
                    if (message.phone != null && message.hasOwnProperty("phone"))
                        object.phone = message.phone;
                    if (message.jobTitle != null && message.hasOwnProperty("jobTitle"))
                        object.jobTitle = message.jobTitle;
                    if (message.education && message.education.length) {
                        object.education = [];
                        for (let j = 0; j < message.education.length; ++j)
                            object.education[j] = $root.space.kenko.proto.EducationProto.toObject(message.education[j], options);
                    }
                    if (message.credentials && message.credentials.length) {
                        object.credentials = [];
                        for (let j = 0; j < message.credentials.length; ++j)
                            object.credentials[j] = $root.space.kenko.proto.LicenseOrCredentialsProto.toObject(message.credentials[j], options);
                    }
                    if (message.languages && message.languages.length) {
                        object.languages = [];
                        for (let j = 0; j < message.languages.length; ++j)
                            object.languages[j] = message.languages[j];
                    }
                    if (message.disabled != null && message.hasOwnProperty("disabled"))
                        object.disabled = message.disabled;
                    if (message.errors && message.errors.length) {
                        object.errors = [];
                        for (let j = 0; j < message.errors.length; ++j)
                            object.errors[j] = $root.space.kenko.proto.ErrorProto.toObject(message.errors[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this ProviderBioProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ProviderBioProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ProviderBioProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.ProviderBioProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ProviderBioProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.ProviderBioProto";
                };

                return ProviderBioProto;
            })();

            proto.SpecialtyProto = (function() {

                /**
                 * Properties of a SpecialtyProto.
                 * @memberof space.kenko.proto
                 * @interface ISpecialtyProto
                 * @property {string|null} [specialty] SpecialtyProto specialty
                 * @property {number|null} [yearsExperience] SpecialtyProto yearsExperience
                 */

                /**
                 * Constructs a new SpecialtyProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a SpecialtyProto.
                 * @implements ISpecialtyProto
                 * @constructor
                 * @param {space.kenko.proto.ISpecialtyProto=} [properties] Properties to set
                 */
                function SpecialtyProto(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SpecialtyProto specialty.
                 * @member {string} specialty
                 * @memberof space.kenko.proto.SpecialtyProto
                 * @instance
                 */
                SpecialtyProto.prototype.specialty = "";

                /**
                 * SpecialtyProto yearsExperience.
                 * @member {number} yearsExperience
                 * @memberof space.kenko.proto.SpecialtyProto
                 * @instance
                 */
                SpecialtyProto.prototype.yearsExperience = 0;

                /**
                 * Creates a new SpecialtyProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.SpecialtyProto
                 * @static
                 * @param {space.kenko.proto.ISpecialtyProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.SpecialtyProto} SpecialtyProto instance
                 */
                SpecialtyProto.create = function create(properties) {
                    return new SpecialtyProto(properties);
                };

                /**
                 * Encodes the specified SpecialtyProto message. Does not implicitly {@link space.kenko.proto.SpecialtyProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.SpecialtyProto
                 * @static
                 * @param {space.kenko.proto.ISpecialtyProto} message SpecialtyProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SpecialtyProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.specialty != null && Object.hasOwnProperty.call(message, "specialty"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.specialty);
                    if (message.yearsExperience != null && Object.hasOwnProperty.call(message, "yearsExperience"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.yearsExperience);
                    return writer;
                };

                /**
                 * Encodes the specified SpecialtyProto message, length delimited. Does not implicitly {@link space.kenko.proto.SpecialtyProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.SpecialtyProto
                 * @static
                 * @param {space.kenko.proto.ISpecialtyProto} message SpecialtyProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SpecialtyProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a SpecialtyProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.SpecialtyProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.SpecialtyProto} SpecialtyProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SpecialtyProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.SpecialtyProto();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.specialty = reader.string();
                                break;
                            }
                        case 2: {
                                message.yearsExperience = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a SpecialtyProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.SpecialtyProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.SpecialtyProto} SpecialtyProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SpecialtyProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a SpecialtyProto message.
                 * @function verify
                 * @memberof space.kenko.proto.SpecialtyProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SpecialtyProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.specialty != null && message.hasOwnProperty("specialty"))
                        if (!$util.isString(message.specialty))
                            return "specialty: string expected";
                    if (message.yearsExperience != null && message.hasOwnProperty("yearsExperience"))
                        if (!$util.isInteger(message.yearsExperience))
                            return "yearsExperience: integer expected";
                    return null;
                };

                /**
                 * Creates a SpecialtyProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.SpecialtyProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.SpecialtyProto} SpecialtyProto
                 */
                SpecialtyProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.SpecialtyProto)
                        return object;
                    let message = new $root.space.kenko.proto.SpecialtyProto();
                    if (object.specialty != null)
                        message.specialty = String(object.specialty);
                    if (object.yearsExperience != null)
                        message.yearsExperience = object.yearsExperience | 0;
                    return message;
                };

                /**
                 * Creates a plain object from a SpecialtyProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.SpecialtyProto
                 * @static
                 * @param {space.kenko.proto.SpecialtyProto} message SpecialtyProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SpecialtyProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.specialty = "";
                        object.yearsExperience = 0;
                    }
                    if (message.specialty != null && message.hasOwnProperty("specialty"))
                        object.specialty = message.specialty;
                    if (message.yearsExperience != null && message.hasOwnProperty("yearsExperience"))
                        object.yearsExperience = message.yearsExperience;
                    return object;
                };

                /**
                 * Converts this SpecialtyProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.SpecialtyProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SpecialtyProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for SpecialtyProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.SpecialtyProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                SpecialtyProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.SpecialtyProto";
                };

                return SpecialtyProto;
            })();

            proto.EducationProto = (function() {

                /**
                 * Properties of an EducationProto.
                 * @memberof space.kenko.proto
                 * @interface IEducationProto
                 * @property {string|null} [school] EducationProto school
                 * @property {string|null} [degree] EducationProto degree
                 * @property {number|null} [yearCompleted] EducationProto yearCompleted
                 */

                /**
                 * Constructs a new EducationProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents an EducationProto.
                 * @implements IEducationProto
                 * @constructor
                 * @param {space.kenko.proto.IEducationProto=} [properties] Properties to set
                 */
                function EducationProto(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * EducationProto school.
                 * @member {string} school
                 * @memberof space.kenko.proto.EducationProto
                 * @instance
                 */
                EducationProto.prototype.school = "";

                /**
                 * EducationProto degree.
                 * @member {string} degree
                 * @memberof space.kenko.proto.EducationProto
                 * @instance
                 */
                EducationProto.prototype.degree = "";

                /**
                 * EducationProto yearCompleted.
                 * @member {number} yearCompleted
                 * @memberof space.kenko.proto.EducationProto
                 * @instance
                 */
                EducationProto.prototype.yearCompleted = 0;

                /**
                 * Creates a new EducationProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.EducationProto
                 * @static
                 * @param {space.kenko.proto.IEducationProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.EducationProto} EducationProto instance
                 */
                EducationProto.create = function create(properties) {
                    return new EducationProto(properties);
                };

                /**
                 * Encodes the specified EducationProto message. Does not implicitly {@link space.kenko.proto.EducationProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.EducationProto
                 * @static
                 * @param {space.kenko.proto.IEducationProto} message EducationProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EducationProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.school != null && Object.hasOwnProperty.call(message, "school"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.school);
                    if (message.degree != null && Object.hasOwnProperty.call(message, "degree"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.degree);
                    if (message.yearCompleted != null && Object.hasOwnProperty.call(message, "yearCompleted"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.yearCompleted);
                    return writer;
                };

                /**
                 * Encodes the specified EducationProto message, length delimited. Does not implicitly {@link space.kenko.proto.EducationProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.EducationProto
                 * @static
                 * @param {space.kenko.proto.IEducationProto} message EducationProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EducationProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an EducationProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.EducationProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.EducationProto} EducationProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EducationProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.EducationProto();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.school = reader.string();
                                break;
                            }
                        case 2: {
                                message.degree = reader.string();
                                break;
                            }
                        case 3: {
                                message.yearCompleted = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an EducationProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.EducationProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.EducationProto} EducationProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EducationProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an EducationProto message.
                 * @function verify
                 * @memberof space.kenko.proto.EducationProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                EducationProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.school != null && message.hasOwnProperty("school"))
                        if (!$util.isString(message.school))
                            return "school: string expected";
                    if (message.degree != null && message.hasOwnProperty("degree"))
                        if (!$util.isString(message.degree))
                            return "degree: string expected";
                    if (message.yearCompleted != null && message.hasOwnProperty("yearCompleted"))
                        if (!$util.isInteger(message.yearCompleted))
                            return "yearCompleted: integer expected";
                    return null;
                };

                /**
                 * Creates an EducationProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.EducationProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.EducationProto} EducationProto
                 */
                EducationProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.EducationProto)
                        return object;
                    let message = new $root.space.kenko.proto.EducationProto();
                    if (object.school != null)
                        message.school = String(object.school);
                    if (object.degree != null)
                        message.degree = String(object.degree);
                    if (object.yearCompleted != null)
                        message.yearCompleted = object.yearCompleted | 0;
                    return message;
                };

                /**
                 * Creates a plain object from an EducationProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.EducationProto
                 * @static
                 * @param {space.kenko.proto.EducationProto} message EducationProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                EducationProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.school = "";
                        object.degree = "";
                        object.yearCompleted = 0;
                    }
                    if (message.school != null && message.hasOwnProperty("school"))
                        object.school = message.school;
                    if (message.degree != null && message.hasOwnProperty("degree"))
                        object.degree = message.degree;
                    if (message.yearCompleted != null && message.hasOwnProperty("yearCompleted"))
                        object.yearCompleted = message.yearCompleted;
                    return object;
                };

                /**
                 * Converts this EducationProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.EducationProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                EducationProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for EducationProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.EducationProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                EducationProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.EducationProto";
                };

                return EducationProto;
            })();

            proto.LicenseOrCredentialsProto = (function() {

                /**
                 * Properties of a LicenseOrCredentialsProto.
                 * @memberof space.kenko.proto
                 * @interface ILicenseOrCredentialsProto
                 * @property {string|null} [licenseOrCredential] LicenseOrCredentialsProto licenseOrCredential
                 * @property {Array.<string>|null} [supportDocumentationUrl] LicenseOrCredentialsProto supportDocumentationUrl
                 */

                /**
                 * Constructs a new LicenseOrCredentialsProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a LicenseOrCredentialsProto.
                 * @implements ILicenseOrCredentialsProto
                 * @constructor
                 * @param {space.kenko.proto.ILicenseOrCredentialsProto=} [properties] Properties to set
                 */
                function LicenseOrCredentialsProto(properties) {
                    this.supportDocumentationUrl = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * LicenseOrCredentialsProto licenseOrCredential.
                 * @member {string} licenseOrCredential
                 * @memberof space.kenko.proto.LicenseOrCredentialsProto
                 * @instance
                 */
                LicenseOrCredentialsProto.prototype.licenseOrCredential = "";

                /**
                 * LicenseOrCredentialsProto supportDocumentationUrl.
                 * @member {Array.<string>} supportDocumentationUrl
                 * @memberof space.kenko.proto.LicenseOrCredentialsProto
                 * @instance
                 */
                LicenseOrCredentialsProto.prototype.supportDocumentationUrl = $util.emptyArray;

                /**
                 * Creates a new LicenseOrCredentialsProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.LicenseOrCredentialsProto
                 * @static
                 * @param {space.kenko.proto.ILicenseOrCredentialsProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.LicenseOrCredentialsProto} LicenseOrCredentialsProto instance
                 */
                LicenseOrCredentialsProto.create = function create(properties) {
                    return new LicenseOrCredentialsProto(properties);
                };

                /**
                 * Encodes the specified LicenseOrCredentialsProto message. Does not implicitly {@link space.kenko.proto.LicenseOrCredentialsProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.LicenseOrCredentialsProto
                 * @static
                 * @param {space.kenko.proto.ILicenseOrCredentialsProto} message LicenseOrCredentialsProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LicenseOrCredentialsProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.licenseOrCredential != null && Object.hasOwnProperty.call(message, "licenseOrCredential"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.licenseOrCredential);
                    if (message.supportDocumentationUrl != null && message.supportDocumentationUrl.length)
                        for (let i = 0; i < message.supportDocumentationUrl.length; ++i)
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.supportDocumentationUrl[i]);
                    return writer;
                };

                /**
                 * Encodes the specified LicenseOrCredentialsProto message, length delimited. Does not implicitly {@link space.kenko.proto.LicenseOrCredentialsProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.LicenseOrCredentialsProto
                 * @static
                 * @param {space.kenko.proto.ILicenseOrCredentialsProto} message LicenseOrCredentialsProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LicenseOrCredentialsProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a LicenseOrCredentialsProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.LicenseOrCredentialsProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.LicenseOrCredentialsProto} LicenseOrCredentialsProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LicenseOrCredentialsProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.LicenseOrCredentialsProto();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.licenseOrCredential = reader.string();
                                break;
                            }
                        case 2: {
                                if (!(message.supportDocumentationUrl && message.supportDocumentationUrl.length))
                                    message.supportDocumentationUrl = [];
                                message.supportDocumentationUrl.push(reader.string());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a LicenseOrCredentialsProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.LicenseOrCredentialsProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.LicenseOrCredentialsProto} LicenseOrCredentialsProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LicenseOrCredentialsProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a LicenseOrCredentialsProto message.
                 * @function verify
                 * @memberof space.kenko.proto.LicenseOrCredentialsProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                LicenseOrCredentialsProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.licenseOrCredential != null && message.hasOwnProperty("licenseOrCredential"))
                        if (!$util.isString(message.licenseOrCredential))
                            return "licenseOrCredential: string expected";
                    if (message.supportDocumentationUrl != null && message.hasOwnProperty("supportDocumentationUrl")) {
                        if (!Array.isArray(message.supportDocumentationUrl))
                            return "supportDocumentationUrl: array expected";
                        for (let i = 0; i < message.supportDocumentationUrl.length; ++i)
                            if (!$util.isString(message.supportDocumentationUrl[i]))
                                return "supportDocumentationUrl: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a LicenseOrCredentialsProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.LicenseOrCredentialsProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.LicenseOrCredentialsProto} LicenseOrCredentialsProto
                 */
                LicenseOrCredentialsProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.LicenseOrCredentialsProto)
                        return object;
                    let message = new $root.space.kenko.proto.LicenseOrCredentialsProto();
                    if (object.licenseOrCredential != null)
                        message.licenseOrCredential = String(object.licenseOrCredential);
                    if (object.supportDocumentationUrl) {
                        if (!Array.isArray(object.supportDocumentationUrl))
                            throw TypeError(".space.kenko.proto.LicenseOrCredentialsProto.supportDocumentationUrl: array expected");
                        message.supportDocumentationUrl = [];
                        for (let i = 0; i < object.supportDocumentationUrl.length; ++i)
                            message.supportDocumentationUrl[i] = String(object.supportDocumentationUrl[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a LicenseOrCredentialsProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.LicenseOrCredentialsProto
                 * @static
                 * @param {space.kenko.proto.LicenseOrCredentialsProto} message LicenseOrCredentialsProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                LicenseOrCredentialsProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.supportDocumentationUrl = [];
                    if (options.defaults)
                        object.licenseOrCredential = "";
                    if (message.licenseOrCredential != null && message.hasOwnProperty("licenseOrCredential"))
                        object.licenseOrCredential = message.licenseOrCredential;
                    if (message.supportDocumentationUrl && message.supportDocumentationUrl.length) {
                        object.supportDocumentationUrl = [];
                        for (let j = 0; j < message.supportDocumentationUrl.length; ++j)
                            object.supportDocumentationUrl[j] = message.supportDocumentationUrl[j];
                    }
                    return object;
                };

                /**
                 * Converts this LicenseOrCredentialsProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.LicenseOrCredentialsProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                LicenseOrCredentialsProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for LicenseOrCredentialsProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.LicenseOrCredentialsProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                LicenseOrCredentialsProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.LicenseOrCredentialsProto";
                };

                return LicenseOrCredentialsProto;
            })();

            proto.PracticeDetailsProto = (function() {

                /**
                 * Properties of a PracticeDetailsProto.
                 * @memberof space.kenko.proto
                 * @interface IPracticeDetailsProto
                 * @property {Array.<space.kenko.proto.IServiceProto>|null} [services] PracticeDetailsProto services
                 */

                /**
                 * Constructs a new PracticeDetailsProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a PracticeDetailsProto.
                 * @implements IPracticeDetailsProto
                 * @constructor
                 * @param {space.kenko.proto.IPracticeDetailsProto=} [properties] Properties to set
                 */
                function PracticeDetailsProto(properties) {
                    this.services = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * PracticeDetailsProto services.
                 * @member {Array.<space.kenko.proto.IServiceProto>} services
                 * @memberof space.kenko.proto.PracticeDetailsProto
                 * @instance
                 */
                PracticeDetailsProto.prototype.services = $util.emptyArray;

                /**
                 * Creates a new PracticeDetailsProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.PracticeDetailsProto
                 * @static
                 * @param {space.kenko.proto.IPracticeDetailsProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.PracticeDetailsProto} PracticeDetailsProto instance
                 */
                PracticeDetailsProto.create = function create(properties) {
                    return new PracticeDetailsProto(properties);
                };

                /**
                 * Encodes the specified PracticeDetailsProto message. Does not implicitly {@link space.kenko.proto.PracticeDetailsProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.PracticeDetailsProto
                 * @static
                 * @param {space.kenko.proto.IPracticeDetailsProto} message PracticeDetailsProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PracticeDetailsProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.services != null && message.services.length)
                        for (let i = 0; i < message.services.length; ++i)
                            $root.space.kenko.proto.ServiceProto.encode(message.services[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified PracticeDetailsProto message, length delimited. Does not implicitly {@link space.kenko.proto.PracticeDetailsProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.PracticeDetailsProto
                 * @static
                 * @param {space.kenko.proto.IPracticeDetailsProto} message PracticeDetailsProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PracticeDetailsProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a PracticeDetailsProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.PracticeDetailsProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.PracticeDetailsProto} PracticeDetailsProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PracticeDetailsProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.PracticeDetailsProto();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.services && message.services.length))
                                    message.services = [];
                                message.services.push($root.space.kenko.proto.ServiceProto.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a PracticeDetailsProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.PracticeDetailsProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.PracticeDetailsProto} PracticeDetailsProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PracticeDetailsProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a PracticeDetailsProto message.
                 * @function verify
                 * @memberof space.kenko.proto.PracticeDetailsProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PracticeDetailsProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.services != null && message.hasOwnProperty("services")) {
                        if (!Array.isArray(message.services))
                            return "services: array expected";
                        for (let i = 0; i < message.services.length; ++i) {
                            let error = $root.space.kenko.proto.ServiceProto.verify(message.services[i]);
                            if (error)
                                return "services." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a PracticeDetailsProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.PracticeDetailsProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.PracticeDetailsProto} PracticeDetailsProto
                 */
                PracticeDetailsProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.PracticeDetailsProto)
                        return object;
                    let message = new $root.space.kenko.proto.PracticeDetailsProto();
                    if (object.services) {
                        if (!Array.isArray(object.services))
                            throw TypeError(".space.kenko.proto.PracticeDetailsProto.services: array expected");
                        message.services = [];
                        for (let i = 0; i < object.services.length; ++i) {
                            if (typeof object.services[i] !== "object")
                                throw TypeError(".space.kenko.proto.PracticeDetailsProto.services: object expected");
                            message.services[i] = $root.space.kenko.proto.ServiceProto.fromObject(object.services[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a PracticeDetailsProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.PracticeDetailsProto
                 * @static
                 * @param {space.kenko.proto.PracticeDetailsProto} message PracticeDetailsProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PracticeDetailsProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.services = [];
                    if (message.services && message.services.length) {
                        object.services = [];
                        for (let j = 0; j < message.services.length; ++j)
                            object.services[j] = $root.space.kenko.proto.ServiceProto.toObject(message.services[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this PracticeDetailsProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.PracticeDetailsProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PracticeDetailsProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for PracticeDetailsProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.PracticeDetailsProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                PracticeDetailsProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.PracticeDetailsProto";
                };

                return PracticeDetailsProto;
            })();

            proto.StudioDetailsProto = (function() {

                /**
                 * Properties of a StudioDetailsProto.
                 * @memberof space.kenko.proto
                 * @interface IStudioDetailsProto
                 */

                /**
                 * Constructs a new StudioDetailsProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a StudioDetailsProto.
                 * @implements IStudioDetailsProto
                 * @constructor
                 * @param {space.kenko.proto.IStudioDetailsProto=} [properties] Properties to set
                 */
                function StudioDetailsProto(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new StudioDetailsProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.StudioDetailsProto
                 * @static
                 * @param {space.kenko.proto.IStudioDetailsProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.StudioDetailsProto} StudioDetailsProto instance
                 */
                StudioDetailsProto.create = function create(properties) {
                    return new StudioDetailsProto(properties);
                };

                /**
                 * Encodes the specified StudioDetailsProto message. Does not implicitly {@link space.kenko.proto.StudioDetailsProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.StudioDetailsProto
                 * @static
                 * @param {space.kenko.proto.IStudioDetailsProto} message StudioDetailsProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                StudioDetailsProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified StudioDetailsProto message, length delimited. Does not implicitly {@link space.kenko.proto.StudioDetailsProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.StudioDetailsProto
                 * @static
                 * @param {space.kenko.proto.IStudioDetailsProto} message StudioDetailsProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                StudioDetailsProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a StudioDetailsProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.StudioDetailsProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.StudioDetailsProto} StudioDetailsProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                StudioDetailsProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.StudioDetailsProto();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a StudioDetailsProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.StudioDetailsProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.StudioDetailsProto} StudioDetailsProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                StudioDetailsProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a StudioDetailsProto message.
                 * @function verify
                 * @memberof space.kenko.proto.StudioDetailsProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                StudioDetailsProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a StudioDetailsProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.StudioDetailsProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.StudioDetailsProto} StudioDetailsProto
                 */
                StudioDetailsProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.StudioDetailsProto)
                        return object;
                    return new $root.space.kenko.proto.StudioDetailsProto();
                };

                /**
                 * Creates a plain object from a StudioDetailsProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.StudioDetailsProto
                 * @static
                 * @param {space.kenko.proto.StudioDetailsProto} message StudioDetailsProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                StudioDetailsProto.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this StudioDetailsProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.StudioDetailsProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                StudioDetailsProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for StudioDetailsProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.StudioDetailsProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                StudioDetailsProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.StudioDetailsProto";
                };

                return StudioDetailsProto;
            })();

            proto.ServiceProto = (function() {

                /**
                 * Properties of a ServiceProto.
                 * @memberof space.kenko.proto
                 * @interface IServiceProto
                 * @property {string|null} [sku] ServiceProto sku
                 * @property {string|null} [name] ServiceProto name
                 * @property {string|null} [description] ServiceProto description
                 * @property {number|null} [lengthMinutes] ServiceProto lengthMinutes
                 * @property {string|null} [category] ServiceProto category
                 * @property {Array.<number>|null} [availableLocations] ServiceProto availableLocations
                 * @property {boolean|null} [virtualAvailable] ServiceProto virtualAvailable
                 * @property {Array.<space.kenko.proto.IPractitionerServiceProto>|null} [offeredBy] ServiceProto offeredBy
                 */

                /**
                 * Constructs a new ServiceProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a ServiceProto.
                 * @implements IServiceProto
                 * @constructor
                 * @param {space.kenko.proto.IServiceProto=} [properties] Properties to set
                 */
                function ServiceProto(properties) {
                    this.availableLocations = [];
                    this.offeredBy = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ServiceProto sku.
                 * @member {string} sku
                 * @memberof space.kenko.proto.ServiceProto
                 * @instance
                 */
                ServiceProto.prototype.sku = "";

                /**
                 * ServiceProto name.
                 * @member {string} name
                 * @memberof space.kenko.proto.ServiceProto
                 * @instance
                 */
                ServiceProto.prototype.name = "";

                /**
                 * ServiceProto description.
                 * @member {string} description
                 * @memberof space.kenko.proto.ServiceProto
                 * @instance
                 */
                ServiceProto.prototype.description = "";

                /**
                 * ServiceProto lengthMinutes.
                 * @member {number} lengthMinutes
                 * @memberof space.kenko.proto.ServiceProto
                 * @instance
                 */
                ServiceProto.prototype.lengthMinutes = 0;

                /**
                 * ServiceProto category.
                 * @member {string} category
                 * @memberof space.kenko.proto.ServiceProto
                 * @instance
                 */
                ServiceProto.prototype.category = "";

                /**
                 * ServiceProto availableLocations.
                 * @member {Array.<number>} availableLocations
                 * @memberof space.kenko.proto.ServiceProto
                 * @instance
                 */
                ServiceProto.prototype.availableLocations = $util.emptyArray;

                /**
                 * ServiceProto virtualAvailable.
                 * @member {boolean} virtualAvailable
                 * @memberof space.kenko.proto.ServiceProto
                 * @instance
                 */
                ServiceProto.prototype.virtualAvailable = false;

                /**
                 * ServiceProto offeredBy.
                 * @member {Array.<space.kenko.proto.IPractitionerServiceProto>} offeredBy
                 * @memberof space.kenko.proto.ServiceProto
                 * @instance
                 */
                ServiceProto.prototype.offeredBy = $util.emptyArray;

                /**
                 * Creates a new ServiceProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.ServiceProto
                 * @static
                 * @param {space.kenko.proto.IServiceProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.ServiceProto} ServiceProto instance
                 */
                ServiceProto.create = function create(properties) {
                    return new ServiceProto(properties);
                };

                /**
                 * Encodes the specified ServiceProto message. Does not implicitly {@link space.kenko.proto.ServiceProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.ServiceProto
                 * @static
                 * @param {space.kenko.proto.IServiceProto} message ServiceProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ServiceProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.sku != null && Object.hasOwnProperty.call(message, "sku"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.sku);
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                    if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.description);
                    if (message.lengthMinutes != null && Object.hasOwnProperty.call(message, "lengthMinutes"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.lengthMinutes);
                    if (message.category != null && Object.hasOwnProperty.call(message, "category"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.category);
                    if (message.availableLocations != null && message.availableLocations.length) {
                        writer.uint32(/* id 6, wireType 2 =*/50).fork();
                        for (let i = 0; i < message.availableLocations.length; ++i)
                            writer.int32(message.availableLocations[i]);
                        writer.ldelim();
                    }
                    if (message.virtualAvailable != null && Object.hasOwnProperty.call(message, "virtualAvailable"))
                        writer.uint32(/* id 7, wireType 0 =*/56).bool(message.virtualAvailable);
                    if (message.offeredBy != null && message.offeredBy.length)
                        for (let i = 0; i < message.offeredBy.length; ++i)
                            $root.space.kenko.proto.PractitionerServiceProto.encode(message.offeredBy[i], writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified ServiceProto message, length delimited. Does not implicitly {@link space.kenko.proto.ServiceProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.ServiceProto
                 * @static
                 * @param {space.kenko.proto.IServiceProto} message ServiceProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ServiceProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ServiceProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.ServiceProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.ServiceProto} ServiceProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ServiceProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.ServiceProto();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.sku = reader.string();
                                break;
                            }
                        case 2: {
                                message.name = reader.string();
                                break;
                            }
                        case 3: {
                                message.description = reader.string();
                                break;
                            }
                        case 4: {
                                message.lengthMinutes = reader.int32();
                                break;
                            }
                        case 5: {
                                message.category = reader.string();
                                break;
                            }
                        case 6: {
                                if (!(message.availableLocations && message.availableLocations.length))
                                    message.availableLocations = [];
                                if ((tag & 7) === 2) {
                                    let end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.availableLocations.push(reader.int32());
                                } else
                                    message.availableLocations.push(reader.int32());
                                break;
                            }
                        case 7: {
                                message.virtualAvailable = reader.bool();
                                break;
                            }
                        case 8: {
                                if (!(message.offeredBy && message.offeredBy.length))
                                    message.offeredBy = [];
                                message.offeredBy.push($root.space.kenko.proto.PractitionerServiceProto.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ServiceProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.ServiceProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.ServiceProto} ServiceProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ServiceProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ServiceProto message.
                 * @function verify
                 * @memberof space.kenko.proto.ServiceProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ServiceProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.sku != null && message.hasOwnProperty("sku"))
                        if (!$util.isString(message.sku))
                            return "sku: string expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.description != null && message.hasOwnProperty("description"))
                        if (!$util.isString(message.description))
                            return "description: string expected";
                    if (message.lengthMinutes != null && message.hasOwnProperty("lengthMinutes"))
                        if (!$util.isInteger(message.lengthMinutes))
                            return "lengthMinutes: integer expected";
                    if (message.category != null && message.hasOwnProperty("category"))
                        if (!$util.isString(message.category))
                            return "category: string expected";
                    if (message.availableLocations != null && message.hasOwnProperty("availableLocations")) {
                        if (!Array.isArray(message.availableLocations))
                            return "availableLocations: array expected";
                        for (let i = 0; i < message.availableLocations.length; ++i)
                            if (!$util.isInteger(message.availableLocations[i]))
                                return "availableLocations: integer[] expected";
                    }
                    if (message.virtualAvailable != null && message.hasOwnProperty("virtualAvailable"))
                        if (typeof message.virtualAvailable !== "boolean")
                            return "virtualAvailable: boolean expected";
                    if (message.offeredBy != null && message.hasOwnProperty("offeredBy")) {
                        if (!Array.isArray(message.offeredBy))
                            return "offeredBy: array expected";
                        for (let i = 0; i < message.offeredBy.length; ++i) {
                            let error = $root.space.kenko.proto.PractitionerServiceProto.verify(message.offeredBy[i]);
                            if (error)
                                return "offeredBy." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a ServiceProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.ServiceProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.ServiceProto} ServiceProto
                 */
                ServiceProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.ServiceProto)
                        return object;
                    let message = new $root.space.kenko.proto.ServiceProto();
                    if (object.sku != null)
                        message.sku = String(object.sku);
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.description != null)
                        message.description = String(object.description);
                    if (object.lengthMinutes != null)
                        message.lengthMinutes = object.lengthMinutes | 0;
                    if (object.category != null)
                        message.category = String(object.category);
                    if (object.availableLocations) {
                        if (!Array.isArray(object.availableLocations))
                            throw TypeError(".space.kenko.proto.ServiceProto.availableLocations: array expected");
                        message.availableLocations = [];
                        for (let i = 0; i < object.availableLocations.length; ++i)
                            message.availableLocations[i] = object.availableLocations[i] | 0;
                    }
                    if (object.virtualAvailable != null)
                        message.virtualAvailable = Boolean(object.virtualAvailable);
                    if (object.offeredBy) {
                        if (!Array.isArray(object.offeredBy))
                            throw TypeError(".space.kenko.proto.ServiceProto.offeredBy: array expected");
                        message.offeredBy = [];
                        for (let i = 0; i < object.offeredBy.length; ++i) {
                            if (typeof object.offeredBy[i] !== "object")
                                throw TypeError(".space.kenko.proto.ServiceProto.offeredBy: object expected");
                            message.offeredBy[i] = $root.space.kenko.proto.PractitionerServiceProto.fromObject(object.offeredBy[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ServiceProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.ServiceProto
                 * @static
                 * @param {space.kenko.proto.ServiceProto} message ServiceProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ServiceProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults) {
                        object.availableLocations = [];
                        object.offeredBy = [];
                    }
                    if (options.defaults) {
                        object.sku = "";
                        object.name = "";
                        object.description = "";
                        object.lengthMinutes = 0;
                        object.category = "";
                        object.virtualAvailable = false;
                    }
                    if (message.sku != null && message.hasOwnProperty("sku"))
                        object.sku = message.sku;
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.description != null && message.hasOwnProperty("description"))
                        object.description = message.description;
                    if (message.lengthMinutes != null && message.hasOwnProperty("lengthMinutes"))
                        object.lengthMinutes = message.lengthMinutes;
                    if (message.category != null && message.hasOwnProperty("category"))
                        object.category = message.category;
                    if (message.availableLocations && message.availableLocations.length) {
                        object.availableLocations = [];
                        for (let j = 0; j < message.availableLocations.length; ++j)
                            object.availableLocations[j] = message.availableLocations[j];
                    }
                    if (message.virtualAvailable != null && message.hasOwnProperty("virtualAvailable"))
                        object.virtualAvailable = message.virtualAvailable;
                    if (message.offeredBy && message.offeredBy.length) {
                        object.offeredBy = [];
                        for (let j = 0; j < message.offeredBy.length; ++j)
                            object.offeredBy[j] = $root.space.kenko.proto.PractitionerServiceProto.toObject(message.offeredBy[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this ServiceProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.ServiceProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ServiceProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ServiceProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.ServiceProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ServiceProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.ServiceProto";
                };

                return ServiceProto;
            })();

            proto.PractitionerServiceProto = (function() {

                /**
                 * Properties of a PractitionerServiceProto.
                 * @memberof space.kenko.proto
                 * @interface IPractitionerServiceProto
                 * @property {string|null} [providerId] PractitionerServiceProto providerId
                 * @property {space.kenko.proto.IPriceProto|null} [price] PractitionerServiceProto price
                 */

                /**
                 * Constructs a new PractitionerServiceProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a PractitionerServiceProto.
                 * @implements IPractitionerServiceProto
                 * @constructor
                 * @param {space.kenko.proto.IPractitionerServiceProto=} [properties] Properties to set
                 */
                function PractitionerServiceProto(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * PractitionerServiceProto providerId.
                 * @member {string} providerId
                 * @memberof space.kenko.proto.PractitionerServiceProto
                 * @instance
                 */
                PractitionerServiceProto.prototype.providerId = "";

                /**
                 * PractitionerServiceProto price.
                 * @member {space.kenko.proto.IPriceProto|null|undefined} price
                 * @memberof space.kenko.proto.PractitionerServiceProto
                 * @instance
                 */
                PractitionerServiceProto.prototype.price = null;

                /**
                 * Creates a new PractitionerServiceProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.PractitionerServiceProto
                 * @static
                 * @param {space.kenko.proto.IPractitionerServiceProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.PractitionerServiceProto} PractitionerServiceProto instance
                 */
                PractitionerServiceProto.create = function create(properties) {
                    return new PractitionerServiceProto(properties);
                };

                /**
                 * Encodes the specified PractitionerServiceProto message. Does not implicitly {@link space.kenko.proto.PractitionerServiceProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.PractitionerServiceProto
                 * @static
                 * @param {space.kenko.proto.IPractitionerServiceProto} message PractitionerServiceProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PractitionerServiceProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.providerId != null && Object.hasOwnProperty.call(message, "providerId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.providerId);
                    if (message.price != null && Object.hasOwnProperty.call(message, "price"))
                        $root.space.kenko.proto.PriceProto.encode(message.price, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified PractitionerServiceProto message, length delimited. Does not implicitly {@link space.kenko.proto.PractitionerServiceProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.PractitionerServiceProto
                 * @static
                 * @param {space.kenko.proto.IPractitionerServiceProto} message PractitionerServiceProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PractitionerServiceProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a PractitionerServiceProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.PractitionerServiceProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.PractitionerServiceProto} PractitionerServiceProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PractitionerServiceProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.PractitionerServiceProto();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.providerId = reader.string();
                                break;
                            }
                        case 2: {
                                message.price = $root.space.kenko.proto.PriceProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a PractitionerServiceProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.PractitionerServiceProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.PractitionerServiceProto} PractitionerServiceProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PractitionerServiceProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a PractitionerServiceProto message.
                 * @function verify
                 * @memberof space.kenko.proto.PractitionerServiceProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PractitionerServiceProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.providerId != null && message.hasOwnProperty("providerId"))
                        if (!$util.isString(message.providerId))
                            return "providerId: string expected";
                    if (message.price != null && message.hasOwnProperty("price")) {
                        let error = $root.space.kenko.proto.PriceProto.verify(message.price);
                        if (error)
                            return "price." + error;
                    }
                    return null;
                };

                /**
                 * Creates a PractitionerServiceProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.PractitionerServiceProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.PractitionerServiceProto} PractitionerServiceProto
                 */
                PractitionerServiceProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.PractitionerServiceProto)
                        return object;
                    let message = new $root.space.kenko.proto.PractitionerServiceProto();
                    if (object.providerId != null)
                        message.providerId = String(object.providerId);
                    if (object.price != null) {
                        if (typeof object.price !== "object")
                            throw TypeError(".space.kenko.proto.PractitionerServiceProto.price: object expected");
                        message.price = $root.space.kenko.proto.PriceProto.fromObject(object.price);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a PractitionerServiceProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.PractitionerServiceProto
                 * @static
                 * @param {space.kenko.proto.PractitionerServiceProto} message PractitionerServiceProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PractitionerServiceProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.providerId = "";
                        object.price = null;
                    }
                    if (message.providerId != null && message.hasOwnProperty("providerId"))
                        object.providerId = message.providerId;
                    if (message.price != null && message.hasOwnProperty("price"))
                        object.price = $root.space.kenko.proto.PriceProto.toObject(message.price, options);
                    return object;
                };

                /**
                 * Converts this PractitionerServiceProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.PractitionerServiceProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PractitionerServiceProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for PractitionerServiceProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.PractitionerServiceProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                PractitionerServiceProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.PractitionerServiceProto";
                };

                return PractitionerServiceProto;
            })();

            proto.LocalTimeProto = (function() {

                /**
                 * Properties of a LocalTimeProto.
                 * @memberof space.kenko.proto
                 * @interface ILocalTimeProto
                 * @property {number|null} [hour] LocalTimeProto hour
                 * @property {number|null} [minute] LocalTimeProto minute
                 */

                /**
                 * Constructs a new LocalTimeProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a LocalTimeProto.
                 * @implements ILocalTimeProto
                 * @constructor
                 * @param {space.kenko.proto.ILocalTimeProto=} [properties] Properties to set
                 */
                function LocalTimeProto(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * LocalTimeProto hour.
                 * @member {number} hour
                 * @memberof space.kenko.proto.LocalTimeProto
                 * @instance
                 */
                LocalTimeProto.prototype.hour = 0;

                /**
                 * LocalTimeProto minute.
                 * @member {number} minute
                 * @memberof space.kenko.proto.LocalTimeProto
                 * @instance
                 */
                LocalTimeProto.prototype.minute = 0;

                /**
                 * Creates a new LocalTimeProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.LocalTimeProto
                 * @static
                 * @param {space.kenko.proto.ILocalTimeProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.LocalTimeProto} LocalTimeProto instance
                 */
                LocalTimeProto.create = function create(properties) {
                    return new LocalTimeProto(properties);
                };

                /**
                 * Encodes the specified LocalTimeProto message. Does not implicitly {@link space.kenko.proto.LocalTimeProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.LocalTimeProto
                 * @static
                 * @param {space.kenko.proto.ILocalTimeProto} message LocalTimeProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LocalTimeProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.hour != null && Object.hasOwnProperty.call(message, "hour"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.hour);
                    if (message.minute != null && Object.hasOwnProperty.call(message, "minute"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.minute);
                    return writer;
                };

                /**
                 * Encodes the specified LocalTimeProto message, length delimited. Does not implicitly {@link space.kenko.proto.LocalTimeProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.LocalTimeProto
                 * @static
                 * @param {space.kenko.proto.ILocalTimeProto} message LocalTimeProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LocalTimeProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a LocalTimeProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.LocalTimeProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.LocalTimeProto} LocalTimeProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LocalTimeProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.LocalTimeProto();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.hour = reader.int32();
                                break;
                            }
                        case 2: {
                                message.minute = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a LocalTimeProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.LocalTimeProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.LocalTimeProto} LocalTimeProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LocalTimeProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a LocalTimeProto message.
                 * @function verify
                 * @memberof space.kenko.proto.LocalTimeProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                LocalTimeProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.hour != null && message.hasOwnProperty("hour"))
                        if (!$util.isInteger(message.hour))
                            return "hour: integer expected";
                    if (message.minute != null && message.hasOwnProperty("minute"))
                        if (!$util.isInteger(message.minute))
                            return "minute: integer expected";
                    return null;
                };

                /**
                 * Creates a LocalTimeProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.LocalTimeProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.LocalTimeProto} LocalTimeProto
                 */
                LocalTimeProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.LocalTimeProto)
                        return object;
                    let message = new $root.space.kenko.proto.LocalTimeProto();
                    if (object.hour != null)
                        message.hour = object.hour | 0;
                    if (object.minute != null)
                        message.minute = object.minute | 0;
                    return message;
                };

                /**
                 * Creates a plain object from a LocalTimeProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.LocalTimeProto
                 * @static
                 * @param {space.kenko.proto.LocalTimeProto} message LocalTimeProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                LocalTimeProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.hour = 0;
                        object.minute = 0;
                    }
                    if (message.hour != null && message.hasOwnProperty("hour"))
                        object.hour = message.hour;
                    if (message.minute != null && message.hasOwnProperty("minute"))
                        object.minute = message.minute;
                    return object;
                };

                /**
                 * Converts this LocalTimeProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.LocalTimeProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                LocalTimeProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for LocalTimeProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.LocalTimeProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                LocalTimeProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.LocalTimeProto";
                };

                return LocalTimeProto;
            })();

            proto.LocalDateProto = (function() {

                /**
                 * Properties of a LocalDateProto.
                 * @memberof space.kenko.proto
                 * @interface ILocalDateProto
                 * @property {number|null} [year] LocalDateProto year
                 * @property {number|null} [month] LocalDateProto month
                 * @property {number|null} [date] LocalDateProto date
                 */

                /**
                 * Constructs a new LocalDateProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a LocalDateProto.
                 * @implements ILocalDateProto
                 * @constructor
                 * @param {space.kenko.proto.ILocalDateProto=} [properties] Properties to set
                 */
                function LocalDateProto(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * LocalDateProto year.
                 * @member {number} year
                 * @memberof space.kenko.proto.LocalDateProto
                 * @instance
                 */
                LocalDateProto.prototype.year = 0;

                /**
                 * LocalDateProto month.
                 * @member {number} month
                 * @memberof space.kenko.proto.LocalDateProto
                 * @instance
                 */
                LocalDateProto.prototype.month = 0;

                /**
                 * LocalDateProto date.
                 * @member {number} date
                 * @memberof space.kenko.proto.LocalDateProto
                 * @instance
                 */
                LocalDateProto.prototype.date = 0;

                /**
                 * Creates a new LocalDateProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.LocalDateProto
                 * @static
                 * @param {space.kenko.proto.ILocalDateProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.LocalDateProto} LocalDateProto instance
                 */
                LocalDateProto.create = function create(properties) {
                    return new LocalDateProto(properties);
                };

                /**
                 * Encodes the specified LocalDateProto message. Does not implicitly {@link space.kenko.proto.LocalDateProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.LocalDateProto
                 * @static
                 * @param {space.kenko.proto.ILocalDateProto} message LocalDateProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LocalDateProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.year != null && Object.hasOwnProperty.call(message, "year"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.year);
                    if (message.month != null && Object.hasOwnProperty.call(message, "month"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.month);
                    if (message.date != null && Object.hasOwnProperty.call(message, "date"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.date);
                    return writer;
                };

                /**
                 * Encodes the specified LocalDateProto message, length delimited. Does not implicitly {@link space.kenko.proto.LocalDateProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.LocalDateProto
                 * @static
                 * @param {space.kenko.proto.ILocalDateProto} message LocalDateProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LocalDateProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a LocalDateProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.LocalDateProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.LocalDateProto} LocalDateProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LocalDateProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.LocalDateProto();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.year = reader.int32();
                                break;
                            }
                        case 2: {
                                message.month = reader.int32();
                                break;
                            }
                        case 3: {
                                message.date = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a LocalDateProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.LocalDateProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.LocalDateProto} LocalDateProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LocalDateProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a LocalDateProto message.
                 * @function verify
                 * @memberof space.kenko.proto.LocalDateProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                LocalDateProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.year != null && message.hasOwnProperty("year"))
                        if (!$util.isInteger(message.year))
                            return "year: integer expected";
                    if (message.month != null && message.hasOwnProperty("month"))
                        if (!$util.isInteger(message.month))
                            return "month: integer expected";
                    if (message.date != null && message.hasOwnProperty("date"))
                        if (!$util.isInteger(message.date))
                            return "date: integer expected";
                    return null;
                };

                /**
                 * Creates a LocalDateProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.LocalDateProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.LocalDateProto} LocalDateProto
                 */
                LocalDateProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.LocalDateProto)
                        return object;
                    let message = new $root.space.kenko.proto.LocalDateProto();
                    if (object.year != null)
                        message.year = object.year | 0;
                    if (object.month != null)
                        message.month = object.month | 0;
                    if (object.date != null)
                        message.date = object.date | 0;
                    return message;
                };

                /**
                 * Creates a plain object from a LocalDateProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.LocalDateProto
                 * @static
                 * @param {space.kenko.proto.LocalDateProto} message LocalDateProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                LocalDateProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.year = 0;
                        object.month = 0;
                        object.date = 0;
                    }
                    if (message.year != null && message.hasOwnProperty("year"))
                        object.year = message.year;
                    if (message.month != null && message.hasOwnProperty("month"))
                        object.month = message.month;
                    if (message.date != null && message.hasOwnProperty("date"))
                        object.date = message.date;
                    return object;
                };

                /**
                 * Converts this LocalDateProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.LocalDateProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                LocalDateProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for LocalDateProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.LocalDateProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                LocalDateProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.LocalDateProto";
                };

                return LocalDateProto;
            })();

            proto.PriceProto = (function() {

                /**
                 * Properties of a PriceProto.
                 * @memberof space.kenko.proto
                 * @interface IPriceProto
                 * @property {number|null} [price] PriceProto price
                 * @property {string|null} [currencyCode] PriceProto currencyCode
                 */

                /**
                 * Constructs a new PriceProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a PriceProto.
                 * @implements IPriceProto
                 * @constructor
                 * @param {space.kenko.proto.IPriceProto=} [properties] Properties to set
                 */
                function PriceProto(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * PriceProto price.
                 * @member {number} price
                 * @memberof space.kenko.proto.PriceProto
                 * @instance
                 */
                PriceProto.prototype.price = 0;

                /**
                 * PriceProto currencyCode.
                 * @member {string} currencyCode
                 * @memberof space.kenko.proto.PriceProto
                 * @instance
                 */
                PriceProto.prototype.currencyCode = "";

                /**
                 * Creates a new PriceProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.PriceProto
                 * @static
                 * @param {space.kenko.proto.IPriceProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.PriceProto} PriceProto instance
                 */
                PriceProto.create = function create(properties) {
                    return new PriceProto(properties);
                };

                /**
                 * Encodes the specified PriceProto message. Does not implicitly {@link space.kenko.proto.PriceProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.PriceProto
                 * @static
                 * @param {space.kenko.proto.IPriceProto} message PriceProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PriceProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.price != null && Object.hasOwnProperty.call(message, "price"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.price);
                    if (message.currencyCode != null && Object.hasOwnProperty.call(message, "currencyCode"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.currencyCode);
                    return writer;
                };

                /**
                 * Encodes the specified PriceProto message, length delimited. Does not implicitly {@link space.kenko.proto.PriceProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.PriceProto
                 * @static
                 * @param {space.kenko.proto.IPriceProto} message PriceProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PriceProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a PriceProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.PriceProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.PriceProto} PriceProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PriceProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.PriceProto();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.price = reader.int32();
                                break;
                            }
                        case 2: {
                                message.currencyCode = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a PriceProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.PriceProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.PriceProto} PriceProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PriceProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a PriceProto message.
                 * @function verify
                 * @memberof space.kenko.proto.PriceProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PriceProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.price != null && message.hasOwnProperty("price"))
                        if (!$util.isInteger(message.price))
                            return "price: integer expected";
                    if (message.currencyCode != null && message.hasOwnProperty("currencyCode"))
                        if (!$util.isString(message.currencyCode))
                            return "currencyCode: string expected";
                    return null;
                };

                /**
                 * Creates a PriceProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.PriceProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.PriceProto} PriceProto
                 */
                PriceProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.PriceProto)
                        return object;
                    let message = new $root.space.kenko.proto.PriceProto();
                    if (object.price != null)
                        message.price = object.price | 0;
                    if (object.currencyCode != null)
                        message.currencyCode = String(object.currencyCode);
                    return message;
                };

                /**
                 * Creates a plain object from a PriceProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.PriceProto
                 * @static
                 * @param {space.kenko.proto.PriceProto} message PriceProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PriceProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.price = 0;
                        object.currencyCode = "";
                    }
                    if (message.price != null && message.hasOwnProperty("price"))
                        object.price = message.price;
                    if (message.currencyCode != null && message.hasOwnProperty("currencyCode"))
                        object.currencyCode = message.currencyCode;
                    return object;
                };

                /**
                 * Converts this PriceProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.PriceProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PriceProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for PriceProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.PriceProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                PriceProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.PriceProto";
                };

                return PriceProto;
            })();

            proto.ErrorProto = (function() {

                /**
                 * Properties of an ErrorProto.
                 * @memberof space.kenko.proto
                 * @interface IErrorProto
                 * @property {string|null} [path] ErrorProto path
                 * @property {string|null} [error] ErrorProto error
                 */

                /**
                 * Constructs a new ErrorProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents an ErrorProto.
                 * @implements IErrorProto
                 * @constructor
                 * @param {space.kenko.proto.IErrorProto=} [properties] Properties to set
                 */
                function ErrorProto(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ErrorProto path.
                 * @member {string} path
                 * @memberof space.kenko.proto.ErrorProto
                 * @instance
                 */
                ErrorProto.prototype.path = "";

                /**
                 * ErrorProto error.
                 * @member {string} error
                 * @memberof space.kenko.proto.ErrorProto
                 * @instance
                 */
                ErrorProto.prototype.error = "";

                /**
                 * Creates a new ErrorProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.ErrorProto
                 * @static
                 * @param {space.kenko.proto.IErrorProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.ErrorProto} ErrorProto instance
                 */
                ErrorProto.create = function create(properties) {
                    return new ErrorProto(properties);
                };

                /**
                 * Encodes the specified ErrorProto message. Does not implicitly {@link space.kenko.proto.ErrorProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.ErrorProto
                 * @static
                 * @param {space.kenko.proto.IErrorProto} message ErrorProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ErrorProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.path != null && Object.hasOwnProperty.call(message, "path"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.path);
                    if (message.error != null && Object.hasOwnProperty.call(message, "error"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.error);
                    return writer;
                };

                /**
                 * Encodes the specified ErrorProto message, length delimited. Does not implicitly {@link space.kenko.proto.ErrorProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.ErrorProto
                 * @static
                 * @param {space.kenko.proto.IErrorProto} message ErrorProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ErrorProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an ErrorProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.ErrorProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.ErrorProto} ErrorProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ErrorProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.ErrorProto();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.path = reader.string();
                                break;
                            }
                        case 2: {
                                message.error = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an ErrorProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.ErrorProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.ErrorProto} ErrorProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ErrorProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an ErrorProto message.
                 * @function verify
                 * @memberof space.kenko.proto.ErrorProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ErrorProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.path != null && message.hasOwnProperty("path"))
                        if (!$util.isString(message.path))
                            return "path: string expected";
                    if (message.error != null && message.hasOwnProperty("error"))
                        if (!$util.isString(message.error))
                            return "error: string expected";
                    return null;
                };

                /**
                 * Creates an ErrorProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.ErrorProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.ErrorProto} ErrorProto
                 */
                ErrorProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.ErrorProto)
                        return object;
                    let message = new $root.space.kenko.proto.ErrorProto();
                    if (object.path != null)
                        message.path = String(object.path);
                    if (object.error != null)
                        message.error = String(object.error);
                    return message;
                };

                /**
                 * Creates a plain object from an ErrorProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.ErrorProto
                 * @static
                 * @param {space.kenko.proto.ErrorProto} message ErrorProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ErrorProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.path = "";
                        object.error = "";
                    }
                    if (message.path != null && message.hasOwnProperty("path"))
                        object.path = message.path;
                    if (message.error != null && message.hasOwnProperty("error"))
                        object.error = message.error;
                    return object;
                };

                /**
                 * Converts this ErrorProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.ErrorProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ErrorProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ErrorProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.ErrorProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ErrorProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.ErrorProto";
                };

                return ErrorProto;
            })();

            proto.ServiceSchemaProto = (function() {

                /**
                 * Properties of a ServiceSchemaProto.
                 * @memberof space.kenko.proto
                 * @interface IServiceSchemaProto
                 * @property {string|null} [sku] ServiceSchemaProto sku
                 * @property {string|null} [name] ServiceSchemaProto name
                 * @property {string|null} [description] ServiceSchemaProto description
                 * @property {number|null} [lengthMinutes] ServiceSchemaProto lengthMinutes
                 * @property {space.kenko.proto.IPriceProto|null} [price] ServiceSchemaProto price
                 * @property {string|null} [category] ServiceSchemaProto category
                 */

                /**
                 * Constructs a new ServiceSchemaProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a ServiceSchemaProto.
                 * @implements IServiceSchemaProto
                 * @constructor
                 * @param {space.kenko.proto.IServiceSchemaProto=} [properties] Properties to set
                 */
                function ServiceSchemaProto(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ServiceSchemaProto sku.
                 * @member {string} sku
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @instance
                 */
                ServiceSchemaProto.prototype.sku = "";

                /**
                 * ServiceSchemaProto name.
                 * @member {string} name
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @instance
                 */
                ServiceSchemaProto.prototype.name = "";

                /**
                 * ServiceSchemaProto description.
                 * @member {string} description
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @instance
                 */
                ServiceSchemaProto.prototype.description = "";

                /**
                 * ServiceSchemaProto lengthMinutes.
                 * @member {number} lengthMinutes
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @instance
                 */
                ServiceSchemaProto.prototype.lengthMinutes = 0;

                /**
                 * ServiceSchemaProto price.
                 * @member {space.kenko.proto.IPriceProto|null|undefined} price
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @instance
                 */
                ServiceSchemaProto.prototype.price = null;

                /**
                 * ServiceSchemaProto category.
                 * @member {string} category
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @instance
                 */
                ServiceSchemaProto.prototype.category = "";

                /**
                 * Creates a new ServiceSchemaProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @static
                 * @param {space.kenko.proto.IServiceSchemaProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.ServiceSchemaProto} ServiceSchemaProto instance
                 */
                ServiceSchemaProto.create = function create(properties) {
                    return new ServiceSchemaProto(properties);
                };

                /**
                 * Encodes the specified ServiceSchemaProto message. Does not implicitly {@link space.kenko.proto.ServiceSchemaProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @static
                 * @param {space.kenko.proto.IServiceSchemaProto} message ServiceSchemaProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ServiceSchemaProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.sku != null && Object.hasOwnProperty.call(message, "sku"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.sku);
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                    if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.description);
                    if (message.lengthMinutes != null && Object.hasOwnProperty.call(message, "lengthMinutes"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.lengthMinutes);
                    if (message.price != null && Object.hasOwnProperty.call(message, "price"))
                        $root.space.kenko.proto.PriceProto.encode(message.price, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    if (message.category != null && Object.hasOwnProperty.call(message, "category"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.category);
                    return writer;
                };

                /**
                 * Encodes the specified ServiceSchemaProto message, length delimited. Does not implicitly {@link space.kenko.proto.ServiceSchemaProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @static
                 * @param {space.kenko.proto.IServiceSchemaProto} message ServiceSchemaProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ServiceSchemaProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ServiceSchemaProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.ServiceSchemaProto} ServiceSchemaProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ServiceSchemaProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.ServiceSchemaProto();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.sku = reader.string();
                                break;
                            }
                        case 2: {
                                message.name = reader.string();
                                break;
                            }
                        case 3: {
                                message.description = reader.string();
                                break;
                            }
                        case 4: {
                                message.lengthMinutes = reader.int32();
                                break;
                            }
                        case 5: {
                                message.price = $root.space.kenko.proto.PriceProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 6: {
                                message.category = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ServiceSchemaProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.ServiceSchemaProto} ServiceSchemaProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ServiceSchemaProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ServiceSchemaProto message.
                 * @function verify
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ServiceSchemaProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.sku != null && message.hasOwnProperty("sku"))
                        if (!$util.isString(message.sku))
                            return "sku: string expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.description != null && message.hasOwnProperty("description"))
                        if (!$util.isString(message.description))
                            return "description: string expected";
                    if (message.lengthMinutes != null && message.hasOwnProperty("lengthMinutes"))
                        if (!$util.isInteger(message.lengthMinutes))
                            return "lengthMinutes: integer expected";
                    if (message.price != null && message.hasOwnProperty("price")) {
                        let error = $root.space.kenko.proto.PriceProto.verify(message.price);
                        if (error)
                            return "price." + error;
                    }
                    if (message.category != null && message.hasOwnProperty("category"))
                        if (!$util.isString(message.category))
                            return "category: string expected";
                    return null;
                };

                /**
                 * Creates a ServiceSchemaProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.ServiceSchemaProto} ServiceSchemaProto
                 */
                ServiceSchemaProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.ServiceSchemaProto)
                        return object;
                    let message = new $root.space.kenko.proto.ServiceSchemaProto();
                    if (object.sku != null)
                        message.sku = String(object.sku);
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.description != null)
                        message.description = String(object.description);
                    if (object.lengthMinutes != null)
                        message.lengthMinutes = object.lengthMinutes | 0;
                    if (object.price != null) {
                        if (typeof object.price !== "object")
                            throw TypeError(".space.kenko.proto.ServiceSchemaProto.price: object expected");
                        message.price = $root.space.kenko.proto.PriceProto.fromObject(object.price);
                    }
                    if (object.category != null)
                        message.category = String(object.category);
                    return message;
                };

                /**
                 * Creates a plain object from a ServiceSchemaProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @static
                 * @param {space.kenko.proto.ServiceSchemaProto} message ServiceSchemaProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ServiceSchemaProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.sku = "";
                        object.name = "";
                        object.description = "";
                        object.lengthMinutes = 0;
                        object.price = null;
                        object.category = "";
                    }
                    if (message.sku != null && message.hasOwnProperty("sku"))
                        object.sku = message.sku;
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.description != null && message.hasOwnProperty("description"))
                        object.description = message.description;
                    if (message.lengthMinutes != null && message.hasOwnProperty("lengthMinutes"))
                        object.lengthMinutes = message.lengthMinutes;
                    if (message.price != null && message.hasOwnProperty("price"))
                        object.price = $root.space.kenko.proto.PriceProto.toObject(message.price, options);
                    if (message.category != null && message.hasOwnProperty("category"))
                        object.category = message.category;
                    return object;
                };

                /**
                 * Converts this ServiceSchemaProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ServiceSchemaProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ServiceSchemaProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.ServiceSchemaProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ServiceSchemaProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.ServiceSchemaProto";
                };

                return ServiceSchemaProto;
            })();

            proto.InvitationProtos = (function() {

                /**
                 * Properties of an InvitationProtos.
                 * @memberof space.kenko.proto
                 * @interface IInvitationProtos
                 * @property {Array.<space.kenko.proto.IInvitationProto>|null} [invitations] InvitationProtos invitations
                 */

                /**
                 * Constructs a new InvitationProtos.
                 * @memberof space.kenko.proto
                 * @classdesc Represents an InvitationProtos.
                 * @implements IInvitationProtos
                 * @constructor
                 * @param {space.kenko.proto.IInvitationProtos=} [properties] Properties to set
                 */
                function InvitationProtos(properties) {
                    this.invitations = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * InvitationProtos invitations.
                 * @member {Array.<space.kenko.proto.IInvitationProto>} invitations
                 * @memberof space.kenko.proto.InvitationProtos
                 * @instance
                 */
                InvitationProtos.prototype.invitations = $util.emptyArray;

                /**
                 * Creates a new InvitationProtos instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.InvitationProtos
                 * @static
                 * @param {space.kenko.proto.IInvitationProtos=} [properties] Properties to set
                 * @returns {space.kenko.proto.InvitationProtos} InvitationProtos instance
                 */
                InvitationProtos.create = function create(properties) {
                    return new InvitationProtos(properties);
                };

                /**
                 * Encodes the specified InvitationProtos message. Does not implicitly {@link space.kenko.proto.InvitationProtos.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.InvitationProtos
                 * @static
                 * @param {space.kenko.proto.IInvitationProtos} message InvitationProtos message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                InvitationProtos.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.invitations != null && message.invitations.length)
                        for (let i = 0; i < message.invitations.length; ++i)
                            $root.space.kenko.proto.InvitationProto.encode(message.invitations[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified InvitationProtos message, length delimited. Does not implicitly {@link space.kenko.proto.InvitationProtos.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.InvitationProtos
                 * @static
                 * @param {space.kenko.proto.IInvitationProtos} message InvitationProtos message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                InvitationProtos.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an InvitationProtos message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.InvitationProtos
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.InvitationProtos} InvitationProtos
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                InvitationProtos.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.InvitationProtos();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.invitations && message.invitations.length))
                                    message.invitations = [];
                                message.invitations.push($root.space.kenko.proto.InvitationProto.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an InvitationProtos message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.InvitationProtos
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.InvitationProtos} InvitationProtos
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                InvitationProtos.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an InvitationProtos message.
                 * @function verify
                 * @memberof space.kenko.proto.InvitationProtos
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                InvitationProtos.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.invitations != null && message.hasOwnProperty("invitations")) {
                        if (!Array.isArray(message.invitations))
                            return "invitations: array expected";
                        for (let i = 0; i < message.invitations.length; ++i) {
                            let error = $root.space.kenko.proto.InvitationProto.verify(message.invitations[i]);
                            if (error)
                                return "invitations." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates an InvitationProtos message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.InvitationProtos
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.InvitationProtos} InvitationProtos
                 */
                InvitationProtos.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.InvitationProtos)
                        return object;
                    let message = new $root.space.kenko.proto.InvitationProtos();
                    if (object.invitations) {
                        if (!Array.isArray(object.invitations))
                            throw TypeError(".space.kenko.proto.InvitationProtos.invitations: array expected");
                        message.invitations = [];
                        for (let i = 0; i < object.invitations.length; ++i) {
                            if (typeof object.invitations[i] !== "object")
                                throw TypeError(".space.kenko.proto.InvitationProtos.invitations: object expected");
                            message.invitations[i] = $root.space.kenko.proto.InvitationProto.fromObject(object.invitations[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an InvitationProtos message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.InvitationProtos
                 * @static
                 * @param {space.kenko.proto.InvitationProtos} message InvitationProtos
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                InvitationProtos.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.invitations = [];
                    if (message.invitations && message.invitations.length) {
                        object.invitations = [];
                        for (let j = 0; j < message.invitations.length; ++j)
                            object.invitations[j] = $root.space.kenko.proto.InvitationProto.toObject(message.invitations[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this InvitationProtos to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.InvitationProtos
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                InvitationProtos.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for InvitationProtos
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.InvitationProtos
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                InvitationProtos.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.InvitationProtos";
                };

                return InvitationProtos;
            })();

            proto.InvitationProto = (function() {

                /**
                 * Properties of an InvitationProto.
                 * @memberof space.kenko.proto
                 * @interface IInvitationProto
                 * @property {string|null} [inviteCode] InvitationProto inviteCode
                 * @property {number|Long|null} [generatedTimestamp] InvitationProto generatedTimestamp
                 * @property {boolean|null} [alphaProviderAutoApprove] InvitationProto alphaProviderAutoApprove
                 */

                /**
                 * Constructs a new InvitationProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents an InvitationProto.
                 * @implements IInvitationProto
                 * @constructor
                 * @param {space.kenko.proto.IInvitationProto=} [properties] Properties to set
                 */
                function InvitationProto(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * InvitationProto inviteCode.
                 * @member {string} inviteCode
                 * @memberof space.kenko.proto.InvitationProto
                 * @instance
                 */
                InvitationProto.prototype.inviteCode = "";

                /**
                 * InvitationProto generatedTimestamp.
                 * @member {number|Long} generatedTimestamp
                 * @memberof space.kenko.proto.InvitationProto
                 * @instance
                 */
                InvitationProto.prototype.generatedTimestamp = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * InvitationProto alphaProviderAutoApprove.
                 * @member {boolean} alphaProviderAutoApprove
                 * @memberof space.kenko.proto.InvitationProto
                 * @instance
                 */
                InvitationProto.prototype.alphaProviderAutoApprove = false;

                /**
                 * Creates a new InvitationProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.InvitationProto
                 * @static
                 * @param {space.kenko.proto.IInvitationProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.InvitationProto} InvitationProto instance
                 */
                InvitationProto.create = function create(properties) {
                    return new InvitationProto(properties);
                };

                /**
                 * Encodes the specified InvitationProto message. Does not implicitly {@link space.kenko.proto.InvitationProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.InvitationProto
                 * @static
                 * @param {space.kenko.proto.IInvitationProto} message InvitationProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                InvitationProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.inviteCode != null && Object.hasOwnProperty.call(message, "inviteCode"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.inviteCode);
                    if (message.generatedTimestamp != null && Object.hasOwnProperty.call(message, "generatedTimestamp"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.generatedTimestamp);
                    if (message.alphaProviderAutoApprove != null && Object.hasOwnProperty.call(message, "alphaProviderAutoApprove"))
                        writer.uint32(/* id 1000, wireType 0 =*/8000).bool(message.alphaProviderAutoApprove);
                    return writer;
                };

                /**
                 * Encodes the specified InvitationProto message, length delimited. Does not implicitly {@link space.kenko.proto.InvitationProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.InvitationProto
                 * @static
                 * @param {space.kenko.proto.IInvitationProto} message InvitationProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                InvitationProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an InvitationProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.InvitationProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.InvitationProto} InvitationProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                InvitationProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.InvitationProto();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.inviteCode = reader.string();
                                break;
                            }
                        case 2: {
                                message.generatedTimestamp = reader.int64();
                                break;
                            }
                        case 1000: {
                                message.alphaProviderAutoApprove = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an InvitationProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.InvitationProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.InvitationProto} InvitationProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                InvitationProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an InvitationProto message.
                 * @function verify
                 * @memberof space.kenko.proto.InvitationProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                InvitationProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.inviteCode != null && message.hasOwnProperty("inviteCode"))
                        if (!$util.isString(message.inviteCode))
                            return "inviteCode: string expected";
                    if (message.generatedTimestamp != null && message.hasOwnProperty("generatedTimestamp"))
                        if (!$util.isInteger(message.generatedTimestamp) && !(message.generatedTimestamp && $util.isInteger(message.generatedTimestamp.low) && $util.isInteger(message.generatedTimestamp.high)))
                            return "generatedTimestamp: integer|Long expected";
                    if (message.alphaProviderAutoApprove != null && message.hasOwnProperty("alphaProviderAutoApprove"))
                        if (typeof message.alphaProviderAutoApprove !== "boolean")
                            return "alphaProviderAutoApprove: boolean expected";
                    return null;
                };

                /**
                 * Creates an InvitationProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.InvitationProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.InvitationProto} InvitationProto
                 */
                InvitationProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.InvitationProto)
                        return object;
                    let message = new $root.space.kenko.proto.InvitationProto();
                    if (object.inviteCode != null)
                        message.inviteCode = String(object.inviteCode);
                    if (object.generatedTimestamp != null)
                        if ($util.Long)
                            (message.generatedTimestamp = $util.Long.fromValue(object.generatedTimestamp)).unsigned = false;
                        else if (typeof object.generatedTimestamp === "string")
                            message.generatedTimestamp = parseInt(object.generatedTimestamp, 10);
                        else if (typeof object.generatedTimestamp === "number")
                            message.generatedTimestamp = object.generatedTimestamp;
                        else if (typeof object.generatedTimestamp === "object")
                            message.generatedTimestamp = new $util.LongBits(object.generatedTimestamp.low >>> 0, object.generatedTimestamp.high >>> 0).toNumber();
                    if (object.alphaProviderAutoApprove != null)
                        message.alphaProviderAutoApprove = Boolean(object.alphaProviderAutoApprove);
                    return message;
                };

                /**
                 * Creates a plain object from an InvitationProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.InvitationProto
                 * @static
                 * @param {space.kenko.proto.InvitationProto} message InvitationProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                InvitationProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.inviteCode = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.generatedTimestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.generatedTimestamp = options.longs === String ? "0" : 0;
                        object.alphaProviderAutoApprove = false;
                    }
                    if (message.inviteCode != null && message.hasOwnProperty("inviteCode"))
                        object.inviteCode = message.inviteCode;
                    if (message.generatedTimestamp != null && message.hasOwnProperty("generatedTimestamp"))
                        if (typeof message.generatedTimestamp === "number")
                            object.generatedTimestamp = options.longs === String ? String(message.generatedTimestamp) : message.generatedTimestamp;
                        else
                            object.generatedTimestamp = options.longs === String ? $util.Long.prototype.toString.call(message.generatedTimestamp) : options.longs === Number ? new $util.LongBits(message.generatedTimestamp.low >>> 0, message.generatedTimestamp.high >>> 0).toNumber() : message.generatedTimestamp;
                    if (message.alphaProviderAutoApprove != null && message.hasOwnProperty("alphaProviderAutoApprove"))
                        object.alphaProviderAutoApprove = message.alphaProviderAutoApprove;
                    return object;
                };

                /**
                 * Converts this InvitationProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.InvitationProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                InvitationProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for InvitationProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.InvitationProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                InvitationProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.InvitationProto";
                };

                return InvitationProto;
            })();

            proto.UserProfileSchemaProto = (function() {

                /**
                 * Properties of a UserProfileSchemaProto.
                 * @memberof space.kenko.proto
                 * @interface IUserProfileSchemaProto
                 * @property {Array.<space.kenko.proto.IClientAppointmentSchemaProto>|null} [appointments] UserProfileSchemaProto appointments
                 * @property {string|null} [name] UserProfileSchemaProto name
                 * @property {space.kenko.proto.IWellnessSurveySchemaProto|null} [wellnessSurvey] UserProfileSchemaProto wellnessSurvey
                 */

                /**
                 * Constructs a new UserProfileSchemaProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a UserProfileSchemaProto.
                 * @implements IUserProfileSchemaProto
                 * @constructor
                 * @param {space.kenko.proto.IUserProfileSchemaProto=} [properties] Properties to set
                 */
                function UserProfileSchemaProto(properties) {
                    this.appointments = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UserProfileSchemaProto appointments.
                 * @member {Array.<space.kenko.proto.IClientAppointmentSchemaProto>} appointments
                 * @memberof space.kenko.proto.UserProfileSchemaProto
                 * @instance
                 */
                UserProfileSchemaProto.prototype.appointments = $util.emptyArray;

                /**
                 * UserProfileSchemaProto name.
                 * @member {string} name
                 * @memberof space.kenko.proto.UserProfileSchemaProto
                 * @instance
                 */
                UserProfileSchemaProto.prototype.name = "";

                /**
                 * UserProfileSchemaProto wellnessSurvey.
                 * @member {space.kenko.proto.IWellnessSurveySchemaProto|null|undefined} wellnessSurvey
                 * @memberof space.kenko.proto.UserProfileSchemaProto
                 * @instance
                 */
                UserProfileSchemaProto.prototype.wellnessSurvey = null;

                /**
                 * Creates a new UserProfileSchemaProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.UserProfileSchemaProto
                 * @static
                 * @param {space.kenko.proto.IUserProfileSchemaProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.UserProfileSchemaProto} UserProfileSchemaProto instance
                 */
                UserProfileSchemaProto.create = function create(properties) {
                    return new UserProfileSchemaProto(properties);
                };

                /**
                 * Encodes the specified UserProfileSchemaProto message. Does not implicitly {@link space.kenko.proto.UserProfileSchemaProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.UserProfileSchemaProto
                 * @static
                 * @param {space.kenko.proto.IUserProfileSchemaProto} message UserProfileSchemaProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UserProfileSchemaProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.appointments != null && message.appointments.length)
                        for (let i = 0; i < message.appointments.length; ++i)
                            $root.space.kenko.proto.ClientAppointmentSchemaProto.encode(message.appointments[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                    if (message.wellnessSurvey != null && Object.hasOwnProperty.call(message, "wellnessSurvey"))
                        $root.space.kenko.proto.WellnessSurveySchemaProto.encode(message.wellnessSurvey, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified UserProfileSchemaProto message, length delimited. Does not implicitly {@link space.kenko.proto.UserProfileSchemaProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.UserProfileSchemaProto
                 * @static
                 * @param {space.kenko.proto.IUserProfileSchemaProto} message UserProfileSchemaProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UserProfileSchemaProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a UserProfileSchemaProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.UserProfileSchemaProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.UserProfileSchemaProto} UserProfileSchemaProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UserProfileSchemaProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.UserProfileSchemaProto();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.appointments && message.appointments.length))
                                    message.appointments = [];
                                message.appointments.push($root.space.kenko.proto.ClientAppointmentSchemaProto.decode(reader, reader.uint32()));
                                break;
                            }
                        case 2: {
                                message.name = reader.string();
                                break;
                            }
                        case 3: {
                                message.wellnessSurvey = $root.space.kenko.proto.WellnessSurveySchemaProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a UserProfileSchemaProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.UserProfileSchemaProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.UserProfileSchemaProto} UserProfileSchemaProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UserProfileSchemaProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a UserProfileSchemaProto message.
                 * @function verify
                 * @memberof space.kenko.proto.UserProfileSchemaProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UserProfileSchemaProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.appointments != null && message.hasOwnProperty("appointments")) {
                        if (!Array.isArray(message.appointments))
                            return "appointments: array expected";
                        for (let i = 0; i < message.appointments.length; ++i) {
                            let error = $root.space.kenko.proto.ClientAppointmentSchemaProto.verify(message.appointments[i]);
                            if (error)
                                return "appointments." + error;
                        }
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.wellnessSurvey != null && message.hasOwnProperty("wellnessSurvey")) {
                        let error = $root.space.kenko.proto.WellnessSurveySchemaProto.verify(message.wellnessSurvey);
                        if (error)
                            return "wellnessSurvey." + error;
                    }
                    return null;
                };

                /**
                 * Creates a UserProfileSchemaProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.UserProfileSchemaProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.UserProfileSchemaProto} UserProfileSchemaProto
                 */
                UserProfileSchemaProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.UserProfileSchemaProto)
                        return object;
                    let message = new $root.space.kenko.proto.UserProfileSchemaProto();
                    if (object.appointments) {
                        if (!Array.isArray(object.appointments))
                            throw TypeError(".space.kenko.proto.UserProfileSchemaProto.appointments: array expected");
                        message.appointments = [];
                        for (let i = 0; i < object.appointments.length; ++i) {
                            if (typeof object.appointments[i] !== "object")
                                throw TypeError(".space.kenko.proto.UserProfileSchemaProto.appointments: object expected");
                            message.appointments[i] = $root.space.kenko.proto.ClientAppointmentSchemaProto.fromObject(object.appointments[i]);
                        }
                    }
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.wellnessSurvey != null) {
                        if (typeof object.wellnessSurvey !== "object")
                            throw TypeError(".space.kenko.proto.UserProfileSchemaProto.wellnessSurvey: object expected");
                        message.wellnessSurvey = $root.space.kenko.proto.WellnessSurveySchemaProto.fromObject(object.wellnessSurvey);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a UserProfileSchemaProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.UserProfileSchemaProto
                 * @static
                 * @param {space.kenko.proto.UserProfileSchemaProto} message UserProfileSchemaProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UserProfileSchemaProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.appointments = [];
                    if (options.defaults) {
                        object.name = "";
                        object.wellnessSurvey = null;
                    }
                    if (message.appointments && message.appointments.length) {
                        object.appointments = [];
                        for (let j = 0; j < message.appointments.length; ++j)
                            object.appointments[j] = $root.space.kenko.proto.ClientAppointmentSchemaProto.toObject(message.appointments[j], options);
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.wellnessSurvey != null && message.hasOwnProperty("wellnessSurvey"))
                        object.wellnessSurvey = $root.space.kenko.proto.WellnessSurveySchemaProto.toObject(message.wellnessSurvey, options);
                    return object;
                };

                /**
                 * Converts this UserProfileSchemaProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.UserProfileSchemaProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UserProfileSchemaProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for UserProfileSchemaProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.UserProfileSchemaProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UserProfileSchemaProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.UserProfileSchemaProto";
                };

                return UserProfileSchemaProto;
            })();

            proto.WellnessSurveySchemaProto = (function() {

                /**
                 * Properties of a WellnessSurveySchemaProto.
                 * @memberof space.kenko.proto
                 * @interface IWellnessSurveySchemaProto
                 * @property {Array.<string>|null} [holisticHealthInterests] WellnessSurveySchemaProto holisticHealthInterests
                 * @property {string|null} [familiarityWithEasternDietaryPractices] WellnessSurveySchemaProto familiarityWithEasternDietaryPractices
                 * @property {Array.<string>|null} [fitnessGoals] WellnessSurveySchemaProto fitnessGoals
                 * @property {string|null} [currentSkincareRoutine] WellnessSurveySchemaProto currentSkincareRoutine
                 * @property {Array.<string>|null} [hairRelatedConcerns] WellnessSurveySchemaProto hairRelatedConcerns
                 */

                /**
                 * Constructs a new WellnessSurveySchemaProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a WellnessSurveySchemaProto.
                 * @implements IWellnessSurveySchemaProto
                 * @constructor
                 * @param {space.kenko.proto.IWellnessSurveySchemaProto=} [properties] Properties to set
                 */
                function WellnessSurveySchemaProto(properties) {
                    this.holisticHealthInterests = [];
                    this.fitnessGoals = [];
                    this.hairRelatedConcerns = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * WellnessSurveySchemaProto holisticHealthInterests.
                 * @member {Array.<string>} holisticHealthInterests
                 * @memberof space.kenko.proto.WellnessSurveySchemaProto
                 * @instance
                 */
                WellnessSurveySchemaProto.prototype.holisticHealthInterests = $util.emptyArray;

                /**
                 * WellnessSurveySchemaProto familiarityWithEasternDietaryPractices.
                 * @member {string} familiarityWithEasternDietaryPractices
                 * @memberof space.kenko.proto.WellnessSurveySchemaProto
                 * @instance
                 */
                WellnessSurveySchemaProto.prototype.familiarityWithEasternDietaryPractices = "";

                /**
                 * WellnessSurveySchemaProto fitnessGoals.
                 * @member {Array.<string>} fitnessGoals
                 * @memberof space.kenko.proto.WellnessSurveySchemaProto
                 * @instance
                 */
                WellnessSurveySchemaProto.prototype.fitnessGoals = $util.emptyArray;

                /**
                 * WellnessSurveySchemaProto currentSkincareRoutine.
                 * @member {string} currentSkincareRoutine
                 * @memberof space.kenko.proto.WellnessSurveySchemaProto
                 * @instance
                 */
                WellnessSurveySchemaProto.prototype.currentSkincareRoutine = "";

                /**
                 * WellnessSurveySchemaProto hairRelatedConcerns.
                 * @member {Array.<string>} hairRelatedConcerns
                 * @memberof space.kenko.proto.WellnessSurveySchemaProto
                 * @instance
                 */
                WellnessSurveySchemaProto.prototype.hairRelatedConcerns = $util.emptyArray;

                /**
                 * Creates a new WellnessSurveySchemaProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.WellnessSurveySchemaProto
                 * @static
                 * @param {space.kenko.proto.IWellnessSurveySchemaProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.WellnessSurveySchemaProto} WellnessSurveySchemaProto instance
                 */
                WellnessSurveySchemaProto.create = function create(properties) {
                    return new WellnessSurveySchemaProto(properties);
                };

                /**
                 * Encodes the specified WellnessSurveySchemaProto message. Does not implicitly {@link space.kenko.proto.WellnessSurveySchemaProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.WellnessSurveySchemaProto
                 * @static
                 * @param {space.kenko.proto.IWellnessSurveySchemaProto} message WellnessSurveySchemaProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                WellnessSurveySchemaProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.holisticHealthInterests != null && message.holisticHealthInterests.length)
                        for (let i = 0; i < message.holisticHealthInterests.length; ++i)
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.holisticHealthInterests[i]);
                    if (message.familiarityWithEasternDietaryPractices != null && Object.hasOwnProperty.call(message, "familiarityWithEasternDietaryPractices"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.familiarityWithEasternDietaryPractices);
                    if (message.fitnessGoals != null && message.fitnessGoals.length)
                        for (let i = 0; i < message.fitnessGoals.length; ++i)
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.fitnessGoals[i]);
                    if (message.currentSkincareRoutine != null && Object.hasOwnProperty.call(message, "currentSkincareRoutine"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.currentSkincareRoutine);
                    if (message.hairRelatedConcerns != null && message.hairRelatedConcerns.length)
                        for (let i = 0; i < message.hairRelatedConcerns.length; ++i)
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.hairRelatedConcerns[i]);
                    return writer;
                };

                /**
                 * Encodes the specified WellnessSurveySchemaProto message, length delimited. Does not implicitly {@link space.kenko.proto.WellnessSurveySchemaProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.WellnessSurveySchemaProto
                 * @static
                 * @param {space.kenko.proto.IWellnessSurveySchemaProto} message WellnessSurveySchemaProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                WellnessSurveySchemaProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a WellnessSurveySchemaProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.WellnessSurveySchemaProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.WellnessSurveySchemaProto} WellnessSurveySchemaProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                WellnessSurveySchemaProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.WellnessSurveySchemaProto();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.holisticHealthInterests && message.holisticHealthInterests.length))
                                    message.holisticHealthInterests = [];
                                message.holisticHealthInterests.push(reader.string());
                                break;
                            }
                        case 2: {
                                message.familiarityWithEasternDietaryPractices = reader.string();
                                break;
                            }
                        case 3: {
                                if (!(message.fitnessGoals && message.fitnessGoals.length))
                                    message.fitnessGoals = [];
                                message.fitnessGoals.push(reader.string());
                                break;
                            }
                        case 4: {
                                message.currentSkincareRoutine = reader.string();
                                break;
                            }
                        case 5: {
                                if (!(message.hairRelatedConcerns && message.hairRelatedConcerns.length))
                                    message.hairRelatedConcerns = [];
                                message.hairRelatedConcerns.push(reader.string());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a WellnessSurveySchemaProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.WellnessSurveySchemaProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.WellnessSurveySchemaProto} WellnessSurveySchemaProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                WellnessSurveySchemaProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a WellnessSurveySchemaProto message.
                 * @function verify
                 * @memberof space.kenko.proto.WellnessSurveySchemaProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                WellnessSurveySchemaProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.holisticHealthInterests != null && message.hasOwnProperty("holisticHealthInterests")) {
                        if (!Array.isArray(message.holisticHealthInterests))
                            return "holisticHealthInterests: array expected";
                        for (let i = 0; i < message.holisticHealthInterests.length; ++i)
                            if (!$util.isString(message.holisticHealthInterests[i]))
                                return "holisticHealthInterests: string[] expected";
                    }
                    if (message.familiarityWithEasternDietaryPractices != null && message.hasOwnProperty("familiarityWithEasternDietaryPractices"))
                        if (!$util.isString(message.familiarityWithEasternDietaryPractices))
                            return "familiarityWithEasternDietaryPractices: string expected";
                    if (message.fitnessGoals != null && message.hasOwnProperty("fitnessGoals")) {
                        if (!Array.isArray(message.fitnessGoals))
                            return "fitnessGoals: array expected";
                        for (let i = 0; i < message.fitnessGoals.length; ++i)
                            if (!$util.isString(message.fitnessGoals[i]))
                                return "fitnessGoals: string[] expected";
                    }
                    if (message.currentSkincareRoutine != null && message.hasOwnProperty("currentSkincareRoutine"))
                        if (!$util.isString(message.currentSkincareRoutine))
                            return "currentSkincareRoutine: string expected";
                    if (message.hairRelatedConcerns != null && message.hasOwnProperty("hairRelatedConcerns")) {
                        if (!Array.isArray(message.hairRelatedConcerns))
                            return "hairRelatedConcerns: array expected";
                        for (let i = 0; i < message.hairRelatedConcerns.length; ++i)
                            if (!$util.isString(message.hairRelatedConcerns[i]))
                                return "hairRelatedConcerns: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a WellnessSurveySchemaProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.WellnessSurveySchemaProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.WellnessSurveySchemaProto} WellnessSurveySchemaProto
                 */
                WellnessSurveySchemaProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.WellnessSurveySchemaProto)
                        return object;
                    let message = new $root.space.kenko.proto.WellnessSurveySchemaProto();
                    if (object.holisticHealthInterests) {
                        if (!Array.isArray(object.holisticHealthInterests))
                            throw TypeError(".space.kenko.proto.WellnessSurveySchemaProto.holisticHealthInterests: array expected");
                        message.holisticHealthInterests = [];
                        for (let i = 0; i < object.holisticHealthInterests.length; ++i)
                            message.holisticHealthInterests[i] = String(object.holisticHealthInterests[i]);
                    }
                    if (object.familiarityWithEasternDietaryPractices != null)
                        message.familiarityWithEasternDietaryPractices = String(object.familiarityWithEasternDietaryPractices);
                    if (object.fitnessGoals) {
                        if (!Array.isArray(object.fitnessGoals))
                            throw TypeError(".space.kenko.proto.WellnessSurveySchemaProto.fitnessGoals: array expected");
                        message.fitnessGoals = [];
                        for (let i = 0; i < object.fitnessGoals.length; ++i)
                            message.fitnessGoals[i] = String(object.fitnessGoals[i]);
                    }
                    if (object.currentSkincareRoutine != null)
                        message.currentSkincareRoutine = String(object.currentSkincareRoutine);
                    if (object.hairRelatedConcerns) {
                        if (!Array.isArray(object.hairRelatedConcerns))
                            throw TypeError(".space.kenko.proto.WellnessSurveySchemaProto.hairRelatedConcerns: array expected");
                        message.hairRelatedConcerns = [];
                        for (let i = 0; i < object.hairRelatedConcerns.length; ++i)
                            message.hairRelatedConcerns[i] = String(object.hairRelatedConcerns[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a WellnessSurveySchemaProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.WellnessSurveySchemaProto
                 * @static
                 * @param {space.kenko.proto.WellnessSurveySchemaProto} message WellnessSurveySchemaProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                WellnessSurveySchemaProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults) {
                        object.holisticHealthInterests = [];
                        object.fitnessGoals = [];
                        object.hairRelatedConcerns = [];
                    }
                    if (options.defaults) {
                        object.familiarityWithEasternDietaryPractices = "";
                        object.currentSkincareRoutine = "";
                    }
                    if (message.holisticHealthInterests && message.holisticHealthInterests.length) {
                        object.holisticHealthInterests = [];
                        for (let j = 0; j < message.holisticHealthInterests.length; ++j)
                            object.holisticHealthInterests[j] = message.holisticHealthInterests[j];
                    }
                    if (message.familiarityWithEasternDietaryPractices != null && message.hasOwnProperty("familiarityWithEasternDietaryPractices"))
                        object.familiarityWithEasternDietaryPractices = message.familiarityWithEasternDietaryPractices;
                    if (message.fitnessGoals && message.fitnessGoals.length) {
                        object.fitnessGoals = [];
                        for (let j = 0; j < message.fitnessGoals.length; ++j)
                            object.fitnessGoals[j] = message.fitnessGoals[j];
                    }
                    if (message.currentSkincareRoutine != null && message.hasOwnProperty("currentSkincareRoutine"))
                        object.currentSkincareRoutine = message.currentSkincareRoutine;
                    if (message.hairRelatedConcerns && message.hairRelatedConcerns.length) {
                        object.hairRelatedConcerns = [];
                        for (let j = 0; j < message.hairRelatedConcerns.length; ++j)
                            object.hairRelatedConcerns[j] = message.hairRelatedConcerns[j];
                    }
                    return object;
                };

                /**
                 * Converts this WellnessSurveySchemaProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.WellnessSurveySchemaProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                WellnessSurveySchemaProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for WellnessSurveySchemaProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.WellnessSurveySchemaProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                WellnessSurveySchemaProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.WellnessSurveySchemaProto";
                };

                return WellnessSurveySchemaProto;
            })();

            proto.ClientAppointmentSchemaProto = (function() {

                /**
                 * Properties of a ClientAppointmentSchemaProto.
                 * @memberof space.kenko.proto
                 * @interface IClientAppointmentSchemaProto
                 * @property {string|null} [appointmentId] ClientAppointmentSchemaProto appointmentId
                 * @property {number|Long|null} [startTimeMs] ClientAppointmentSchemaProto startTimeMs
                 * @property {number|Long|null} [endTimeMs] ClientAppointmentSchemaProto endTimeMs
                 * @property {string|null} [serviceName] ClientAppointmentSchemaProto serviceName
                 * @property {string|null} [serviceSku] ClientAppointmentSchemaProto serviceSku
                 * @property {string|null} [providerName] ClientAppointmentSchemaProto providerName
                 * @property {string|null} [providerId] ClientAppointmentSchemaProto providerId
                 * @property {string|null} [businessId] ClientAppointmentSchemaProto businessId
                 * @property {string|null} [address] ClientAppointmentSchemaProto address
                 * @property {space.kenko.proto.IPriceProto|null} [price] ClientAppointmentSchemaProto price
                 */

                /**
                 * Constructs a new ClientAppointmentSchemaProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a ClientAppointmentSchemaProto.
                 * @implements IClientAppointmentSchemaProto
                 * @constructor
                 * @param {space.kenko.proto.IClientAppointmentSchemaProto=} [properties] Properties to set
                 */
                function ClientAppointmentSchemaProto(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ClientAppointmentSchemaProto appointmentId.
                 * @member {string} appointmentId
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @instance
                 */
                ClientAppointmentSchemaProto.prototype.appointmentId = "";

                /**
                 * ClientAppointmentSchemaProto startTimeMs.
                 * @member {number|Long} startTimeMs
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @instance
                 */
                ClientAppointmentSchemaProto.prototype.startTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ClientAppointmentSchemaProto endTimeMs.
                 * @member {number|Long} endTimeMs
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @instance
                 */
                ClientAppointmentSchemaProto.prototype.endTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ClientAppointmentSchemaProto serviceName.
                 * @member {string} serviceName
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @instance
                 */
                ClientAppointmentSchemaProto.prototype.serviceName = "";

                /**
                 * ClientAppointmentSchemaProto serviceSku.
                 * @member {string} serviceSku
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @instance
                 */
                ClientAppointmentSchemaProto.prototype.serviceSku = "";

                /**
                 * ClientAppointmentSchemaProto providerName.
                 * @member {string} providerName
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @instance
                 */
                ClientAppointmentSchemaProto.prototype.providerName = "";

                /**
                 * ClientAppointmentSchemaProto providerId.
                 * @member {string} providerId
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @instance
                 */
                ClientAppointmentSchemaProto.prototype.providerId = "";

                /**
                 * ClientAppointmentSchemaProto businessId.
                 * @member {string} businessId
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @instance
                 */
                ClientAppointmentSchemaProto.prototype.businessId = "";

                /**
                 * ClientAppointmentSchemaProto address.
                 * @member {string} address
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @instance
                 */
                ClientAppointmentSchemaProto.prototype.address = "";

                /**
                 * ClientAppointmentSchemaProto price.
                 * @member {space.kenko.proto.IPriceProto|null|undefined} price
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @instance
                 */
                ClientAppointmentSchemaProto.prototype.price = null;

                /**
                 * Creates a new ClientAppointmentSchemaProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @static
                 * @param {space.kenko.proto.IClientAppointmentSchemaProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.ClientAppointmentSchemaProto} ClientAppointmentSchemaProto instance
                 */
                ClientAppointmentSchemaProto.create = function create(properties) {
                    return new ClientAppointmentSchemaProto(properties);
                };

                /**
                 * Encodes the specified ClientAppointmentSchemaProto message. Does not implicitly {@link space.kenko.proto.ClientAppointmentSchemaProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @static
                 * @param {space.kenko.proto.IClientAppointmentSchemaProto} message ClientAppointmentSchemaProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ClientAppointmentSchemaProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.appointmentId != null && Object.hasOwnProperty.call(message, "appointmentId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.appointmentId);
                    if (message.startTimeMs != null && Object.hasOwnProperty.call(message, "startTimeMs"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.startTimeMs);
                    if (message.endTimeMs != null && Object.hasOwnProperty.call(message, "endTimeMs"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int64(message.endTimeMs);
                    if (message.serviceName != null && Object.hasOwnProperty.call(message, "serviceName"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.serviceName);
                    if (message.providerName != null && Object.hasOwnProperty.call(message, "providerName"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.providerName);
                    if (message.providerId != null && Object.hasOwnProperty.call(message, "providerId"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.providerId);
                    if (message.businessId != null && Object.hasOwnProperty.call(message, "businessId"))
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.businessId);
                    if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                        writer.uint32(/* id 8, wireType 2 =*/66).string(message.address);
                    if (message.price != null && Object.hasOwnProperty.call(message, "price"))
                        $root.space.kenko.proto.PriceProto.encode(message.price, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                    if (message.serviceSku != null && Object.hasOwnProperty.call(message, "serviceSku"))
                        writer.uint32(/* id 10, wireType 2 =*/82).string(message.serviceSku);
                    return writer;
                };

                /**
                 * Encodes the specified ClientAppointmentSchemaProto message, length delimited. Does not implicitly {@link space.kenko.proto.ClientAppointmentSchemaProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @static
                 * @param {space.kenko.proto.IClientAppointmentSchemaProto} message ClientAppointmentSchemaProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ClientAppointmentSchemaProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ClientAppointmentSchemaProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.ClientAppointmentSchemaProto} ClientAppointmentSchemaProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ClientAppointmentSchemaProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.ClientAppointmentSchemaProto();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.appointmentId = reader.string();
                                break;
                            }
                        case 2: {
                                message.startTimeMs = reader.int64();
                                break;
                            }
                        case 3: {
                                message.endTimeMs = reader.int64();
                                break;
                            }
                        case 4: {
                                message.serviceName = reader.string();
                                break;
                            }
                        case 10: {
                                message.serviceSku = reader.string();
                                break;
                            }
                        case 5: {
                                message.providerName = reader.string();
                                break;
                            }
                        case 6: {
                                message.providerId = reader.string();
                                break;
                            }
                        case 7: {
                                message.businessId = reader.string();
                                break;
                            }
                        case 8: {
                                message.address = reader.string();
                                break;
                            }
                        case 9: {
                                message.price = $root.space.kenko.proto.PriceProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ClientAppointmentSchemaProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.ClientAppointmentSchemaProto} ClientAppointmentSchemaProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ClientAppointmentSchemaProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ClientAppointmentSchemaProto message.
                 * @function verify
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ClientAppointmentSchemaProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.appointmentId != null && message.hasOwnProperty("appointmentId"))
                        if (!$util.isString(message.appointmentId))
                            return "appointmentId: string expected";
                    if (message.startTimeMs != null && message.hasOwnProperty("startTimeMs"))
                        if (!$util.isInteger(message.startTimeMs) && !(message.startTimeMs && $util.isInteger(message.startTimeMs.low) && $util.isInteger(message.startTimeMs.high)))
                            return "startTimeMs: integer|Long expected";
                    if (message.endTimeMs != null && message.hasOwnProperty("endTimeMs"))
                        if (!$util.isInteger(message.endTimeMs) && !(message.endTimeMs && $util.isInteger(message.endTimeMs.low) && $util.isInteger(message.endTimeMs.high)))
                            return "endTimeMs: integer|Long expected";
                    if (message.serviceName != null && message.hasOwnProperty("serviceName"))
                        if (!$util.isString(message.serviceName))
                            return "serviceName: string expected";
                    if (message.serviceSku != null && message.hasOwnProperty("serviceSku"))
                        if (!$util.isString(message.serviceSku))
                            return "serviceSku: string expected";
                    if (message.providerName != null && message.hasOwnProperty("providerName"))
                        if (!$util.isString(message.providerName))
                            return "providerName: string expected";
                    if (message.providerId != null && message.hasOwnProperty("providerId"))
                        if (!$util.isString(message.providerId))
                            return "providerId: string expected";
                    if (message.businessId != null && message.hasOwnProperty("businessId"))
                        if (!$util.isString(message.businessId))
                            return "businessId: string expected";
                    if (message.address != null && message.hasOwnProperty("address"))
                        if (!$util.isString(message.address))
                            return "address: string expected";
                    if (message.price != null && message.hasOwnProperty("price")) {
                        let error = $root.space.kenko.proto.PriceProto.verify(message.price);
                        if (error)
                            return "price." + error;
                    }
                    return null;
                };

                /**
                 * Creates a ClientAppointmentSchemaProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.ClientAppointmentSchemaProto} ClientAppointmentSchemaProto
                 */
                ClientAppointmentSchemaProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.ClientAppointmentSchemaProto)
                        return object;
                    let message = new $root.space.kenko.proto.ClientAppointmentSchemaProto();
                    if (object.appointmentId != null)
                        message.appointmentId = String(object.appointmentId);
                    if (object.startTimeMs != null)
                        if ($util.Long)
                            (message.startTimeMs = $util.Long.fromValue(object.startTimeMs)).unsigned = false;
                        else if (typeof object.startTimeMs === "string")
                            message.startTimeMs = parseInt(object.startTimeMs, 10);
                        else if (typeof object.startTimeMs === "number")
                            message.startTimeMs = object.startTimeMs;
                        else if (typeof object.startTimeMs === "object")
                            message.startTimeMs = new $util.LongBits(object.startTimeMs.low >>> 0, object.startTimeMs.high >>> 0).toNumber();
                    if (object.endTimeMs != null)
                        if ($util.Long)
                            (message.endTimeMs = $util.Long.fromValue(object.endTimeMs)).unsigned = false;
                        else if (typeof object.endTimeMs === "string")
                            message.endTimeMs = parseInt(object.endTimeMs, 10);
                        else if (typeof object.endTimeMs === "number")
                            message.endTimeMs = object.endTimeMs;
                        else if (typeof object.endTimeMs === "object")
                            message.endTimeMs = new $util.LongBits(object.endTimeMs.low >>> 0, object.endTimeMs.high >>> 0).toNumber();
                    if (object.serviceName != null)
                        message.serviceName = String(object.serviceName);
                    if (object.serviceSku != null)
                        message.serviceSku = String(object.serviceSku);
                    if (object.providerName != null)
                        message.providerName = String(object.providerName);
                    if (object.providerId != null)
                        message.providerId = String(object.providerId);
                    if (object.businessId != null)
                        message.businessId = String(object.businessId);
                    if (object.address != null)
                        message.address = String(object.address);
                    if (object.price != null) {
                        if (typeof object.price !== "object")
                            throw TypeError(".space.kenko.proto.ClientAppointmentSchemaProto.price: object expected");
                        message.price = $root.space.kenko.proto.PriceProto.fromObject(object.price);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ClientAppointmentSchemaProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @static
                 * @param {space.kenko.proto.ClientAppointmentSchemaProto} message ClientAppointmentSchemaProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ClientAppointmentSchemaProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.appointmentId = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.startTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.startTimeMs = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.endTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.endTimeMs = options.longs === String ? "0" : 0;
                        object.serviceName = "";
                        object.providerName = "";
                        object.providerId = "";
                        object.businessId = "";
                        object.address = "";
                        object.price = null;
                        object.serviceSku = "";
                    }
                    if (message.appointmentId != null && message.hasOwnProperty("appointmentId"))
                        object.appointmentId = message.appointmentId;
                    if (message.startTimeMs != null && message.hasOwnProperty("startTimeMs"))
                        if (typeof message.startTimeMs === "number")
                            object.startTimeMs = options.longs === String ? String(message.startTimeMs) : message.startTimeMs;
                        else
                            object.startTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.startTimeMs) : options.longs === Number ? new $util.LongBits(message.startTimeMs.low >>> 0, message.startTimeMs.high >>> 0).toNumber() : message.startTimeMs;
                    if (message.endTimeMs != null && message.hasOwnProperty("endTimeMs"))
                        if (typeof message.endTimeMs === "number")
                            object.endTimeMs = options.longs === String ? String(message.endTimeMs) : message.endTimeMs;
                        else
                            object.endTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.endTimeMs) : options.longs === Number ? new $util.LongBits(message.endTimeMs.low >>> 0, message.endTimeMs.high >>> 0).toNumber() : message.endTimeMs;
                    if (message.serviceName != null && message.hasOwnProperty("serviceName"))
                        object.serviceName = message.serviceName;
                    if (message.providerName != null && message.hasOwnProperty("providerName"))
                        object.providerName = message.providerName;
                    if (message.providerId != null && message.hasOwnProperty("providerId"))
                        object.providerId = message.providerId;
                    if (message.businessId != null && message.hasOwnProperty("businessId"))
                        object.businessId = message.businessId;
                    if (message.address != null && message.hasOwnProperty("address"))
                        object.address = message.address;
                    if (message.price != null && message.hasOwnProperty("price"))
                        object.price = $root.space.kenko.proto.PriceProto.toObject(message.price, options);
                    if (message.serviceSku != null && message.hasOwnProperty("serviceSku"))
                        object.serviceSku = message.serviceSku;
                    return object;
                };

                /**
                 * Converts this ClientAppointmentSchemaProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ClientAppointmentSchemaProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ClientAppointmentSchemaProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.ClientAppointmentSchemaProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ClientAppointmentSchemaProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.ClientAppointmentSchemaProto";
                };

                return ClientAppointmentSchemaProto;
            })();

            return proto;
        })();

        return kenko;
    })();

    return space;
})();

export const ClientProfileProto = $root.ClientProfileProto = (() => {

    /**
     * Properties of a ClientProfileProto.
     * @exports IClientProfileProto
     * @interface IClientProfileProto
     * @property {string|null} [profileId] ClientProfileProto profileId
     * @property {string|null} [name] ClientProfileProto name
     * @property {Array.<IClientAppointmentProto>|null} [appointments] ClientProfileProto appointments
     * @property {IWellnessSurveyApiProto|null} [wellnessSurveyAnswers] ClientProfileProto wellnessSurveyAnswers
     */

    /**
     * Constructs a new ClientProfileProto.
     * @exports ClientProfileProto
     * @classdesc Represents a ClientProfileProto.
     * @implements IClientProfileProto
     * @constructor
     * @param {IClientProfileProto=} [properties] Properties to set
     */
    function ClientProfileProto(properties) {
        this.appointments = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ClientProfileProto profileId.
     * @member {string} profileId
     * @memberof ClientProfileProto
     * @instance
     */
    ClientProfileProto.prototype.profileId = "";

    /**
     * ClientProfileProto name.
     * @member {string} name
     * @memberof ClientProfileProto
     * @instance
     */
    ClientProfileProto.prototype.name = "";

    /**
     * ClientProfileProto appointments.
     * @member {Array.<IClientAppointmentProto>} appointments
     * @memberof ClientProfileProto
     * @instance
     */
    ClientProfileProto.prototype.appointments = $util.emptyArray;

    /**
     * ClientProfileProto wellnessSurveyAnswers.
     * @member {IWellnessSurveyApiProto|null|undefined} wellnessSurveyAnswers
     * @memberof ClientProfileProto
     * @instance
     */
    ClientProfileProto.prototype.wellnessSurveyAnswers = null;

    /**
     * Creates a new ClientProfileProto instance using the specified properties.
     * @function create
     * @memberof ClientProfileProto
     * @static
     * @param {IClientProfileProto=} [properties] Properties to set
     * @returns {ClientProfileProto} ClientProfileProto instance
     */
    ClientProfileProto.create = function create(properties) {
        return new ClientProfileProto(properties);
    };

    /**
     * Encodes the specified ClientProfileProto message. Does not implicitly {@link ClientProfileProto.verify|verify} messages.
     * @function encode
     * @memberof ClientProfileProto
     * @static
     * @param {IClientProfileProto} message ClientProfileProto message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ClientProfileProto.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.profileId != null && Object.hasOwnProperty.call(message, "profileId"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.profileId);
        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
        if (message.appointments != null && message.appointments.length)
            for (let i = 0; i < message.appointments.length; ++i)
                $root.ClientAppointmentProto.encode(message.appointments[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        if (message.wellnessSurveyAnswers != null && Object.hasOwnProperty.call(message, "wellnessSurveyAnswers"))
            $root.WellnessSurveyApiProto.encode(message.wellnessSurveyAnswers, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified ClientProfileProto message, length delimited. Does not implicitly {@link ClientProfileProto.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ClientProfileProto
     * @static
     * @param {IClientProfileProto} message ClientProfileProto message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ClientProfileProto.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ClientProfileProto message from the specified reader or buffer.
     * @function decode
     * @memberof ClientProfileProto
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ClientProfileProto} ClientProfileProto
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ClientProfileProto.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ClientProfileProto();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.profileId = reader.string();
                    break;
                }
            case 2: {
                    message.name = reader.string();
                    break;
                }
            case 3: {
                    if (!(message.appointments && message.appointments.length))
                        message.appointments = [];
                    message.appointments.push($root.ClientAppointmentProto.decode(reader, reader.uint32()));
                    break;
                }
            case 4: {
                    message.wellnessSurveyAnswers = $root.WellnessSurveyApiProto.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a ClientProfileProto message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ClientProfileProto
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ClientProfileProto} ClientProfileProto
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ClientProfileProto.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ClientProfileProto message.
     * @function verify
     * @memberof ClientProfileProto
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ClientProfileProto.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.profileId != null && message.hasOwnProperty("profileId"))
            if (!$util.isString(message.profileId))
                return "profileId: string expected";
        if (message.name != null && message.hasOwnProperty("name"))
            if (!$util.isString(message.name))
                return "name: string expected";
        if (message.appointments != null && message.hasOwnProperty("appointments")) {
            if (!Array.isArray(message.appointments))
                return "appointments: array expected";
            for (let i = 0; i < message.appointments.length; ++i) {
                let error = $root.ClientAppointmentProto.verify(message.appointments[i]);
                if (error)
                    return "appointments." + error;
            }
        }
        if (message.wellnessSurveyAnswers != null && message.hasOwnProperty("wellnessSurveyAnswers")) {
            let error = $root.WellnessSurveyApiProto.verify(message.wellnessSurveyAnswers);
            if (error)
                return "wellnessSurveyAnswers." + error;
        }
        return null;
    };

    /**
     * Creates a ClientProfileProto message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ClientProfileProto
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ClientProfileProto} ClientProfileProto
     */
    ClientProfileProto.fromObject = function fromObject(object) {
        if (object instanceof $root.ClientProfileProto)
            return object;
        let message = new $root.ClientProfileProto();
        if (object.profileId != null)
            message.profileId = String(object.profileId);
        if (object.name != null)
            message.name = String(object.name);
        if (object.appointments) {
            if (!Array.isArray(object.appointments))
                throw TypeError(".ClientProfileProto.appointments: array expected");
            message.appointments = [];
            for (let i = 0; i < object.appointments.length; ++i) {
                if (typeof object.appointments[i] !== "object")
                    throw TypeError(".ClientProfileProto.appointments: object expected");
                message.appointments[i] = $root.ClientAppointmentProto.fromObject(object.appointments[i]);
            }
        }
        if (object.wellnessSurveyAnswers != null) {
            if (typeof object.wellnessSurveyAnswers !== "object")
                throw TypeError(".ClientProfileProto.wellnessSurveyAnswers: object expected");
            message.wellnessSurveyAnswers = $root.WellnessSurveyApiProto.fromObject(object.wellnessSurveyAnswers);
        }
        return message;
    };

    /**
     * Creates a plain object from a ClientProfileProto message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ClientProfileProto
     * @static
     * @param {ClientProfileProto} message ClientProfileProto
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ClientProfileProto.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.appointments = [];
        if (options.defaults) {
            object.profileId = "";
            object.name = "";
            object.wellnessSurveyAnswers = null;
        }
        if (message.profileId != null && message.hasOwnProperty("profileId"))
            object.profileId = message.profileId;
        if (message.name != null && message.hasOwnProperty("name"))
            object.name = message.name;
        if (message.appointments && message.appointments.length) {
            object.appointments = [];
            for (let j = 0; j < message.appointments.length; ++j)
                object.appointments[j] = $root.ClientAppointmentProto.toObject(message.appointments[j], options);
        }
        if (message.wellnessSurveyAnswers != null && message.hasOwnProperty("wellnessSurveyAnswers"))
            object.wellnessSurveyAnswers = $root.WellnessSurveyApiProto.toObject(message.wellnessSurveyAnswers, options);
        return object;
    };

    /**
     * Converts this ClientProfileProto to JSON.
     * @function toJSON
     * @memberof ClientProfileProto
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ClientProfileProto.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ClientProfileProto
     * @function getTypeUrl
     * @memberof ClientProfileProto
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ClientProfileProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ClientProfileProto";
    };

    return ClientProfileProto;
})();

export const WellnessSurveyApiProto = $root.WellnessSurveyApiProto = (() => {

    /**
     * Properties of a WellnessSurveyApiProto.
     * @exports IWellnessSurveyApiProto
     * @interface IWellnessSurveyApiProto
     * @property {Array.<string>|null} [holisticHealthInterests] WellnessSurveyApiProto holisticHealthInterests
     * @property {string|null} [familiarityWithEasternDietaryPractices] WellnessSurveyApiProto familiarityWithEasternDietaryPractices
     * @property {Array.<string>|null} [fitnessGoals] WellnessSurveyApiProto fitnessGoals
     * @property {string|null} [currentSkincareRoutine] WellnessSurveyApiProto currentSkincareRoutine
     * @property {Array.<string>|null} [hairRelatedConcerns] WellnessSurveyApiProto hairRelatedConcerns
     */

    /**
     * Constructs a new WellnessSurveyApiProto.
     * @exports WellnessSurveyApiProto
     * @classdesc Represents a WellnessSurveyApiProto.
     * @implements IWellnessSurveyApiProto
     * @constructor
     * @param {IWellnessSurveyApiProto=} [properties] Properties to set
     */
    function WellnessSurveyApiProto(properties) {
        this.holisticHealthInterests = [];
        this.fitnessGoals = [];
        this.hairRelatedConcerns = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * WellnessSurveyApiProto holisticHealthInterests.
     * @member {Array.<string>} holisticHealthInterests
     * @memberof WellnessSurveyApiProto
     * @instance
     */
    WellnessSurveyApiProto.prototype.holisticHealthInterests = $util.emptyArray;

    /**
     * WellnessSurveyApiProto familiarityWithEasternDietaryPractices.
     * @member {string} familiarityWithEasternDietaryPractices
     * @memberof WellnessSurveyApiProto
     * @instance
     */
    WellnessSurveyApiProto.prototype.familiarityWithEasternDietaryPractices = "";

    /**
     * WellnessSurveyApiProto fitnessGoals.
     * @member {Array.<string>} fitnessGoals
     * @memberof WellnessSurveyApiProto
     * @instance
     */
    WellnessSurveyApiProto.prototype.fitnessGoals = $util.emptyArray;

    /**
     * WellnessSurveyApiProto currentSkincareRoutine.
     * @member {string} currentSkincareRoutine
     * @memberof WellnessSurveyApiProto
     * @instance
     */
    WellnessSurveyApiProto.prototype.currentSkincareRoutine = "";

    /**
     * WellnessSurveyApiProto hairRelatedConcerns.
     * @member {Array.<string>} hairRelatedConcerns
     * @memberof WellnessSurveyApiProto
     * @instance
     */
    WellnessSurveyApiProto.prototype.hairRelatedConcerns = $util.emptyArray;

    /**
     * Creates a new WellnessSurveyApiProto instance using the specified properties.
     * @function create
     * @memberof WellnessSurveyApiProto
     * @static
     * @param {IWellnessSurveyApiProto=} [properties] Properties to set
     * @returns {WellnessSurveyApiProto} WellnessSurveyApiProto instance
     */
    WellnessSurveyApiProto.create = function create(properties) {
        return new WellnessSurveyApiProto(properties);
    };

    /**
     * Encodes the specified WellnessSurveyApiProto message. Does not implicitly {@link WellnessSurveyApiProto.verify|verify} messages.
     * @function encode
     * @memberof WellnessSurveyApiProto
     * @static
     * @param {IWellnessSurveyApiProto} message WellnessSurveyApiProto message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    WellnessSurveyApiProto.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.holisticHealthInterests != null && message.holisticHealthInterests.length)
            for (let i = 0; i < message.holisticHealthInterests.length; ++i)
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.holisticHealthInterests[i]);
        if (message.familiarityWithEasternDietaryPractices != null && Object.hasOwnProperty.call(message, "familiarityWithEasternDietaryPractices"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.familiarityWithEasternDietaryPractices);
        if (message.fitnessGoals != null && message.fitnessGoals.length)
            for (let i = 0; i < message.fitnessGoals.length; ++i)
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.fitnessGoals[i]);
        if (message.currentSkincareRoutine != null && Object.hasOwnProperty.call(message, "currentSkincareRoutine"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.currentSkincareRoutine);
        if (message.hairRelatedConcerns != null && message.hairRelatedConcerns.length)
            for (let i = 0; i < message.hairRelatedConcerns.length; ++i)
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.hairRelatedConcerns[i]);
        return writer;
    };

    /**
     * Encodes the specified WellnessSurveyApiProto message, length delimited. Does not implicitly {@link WellnessSurveyApiProto.verify|verify} messages.
     * @function encodeDelimited
     * @memberof WellnessSurveyApiProto
     * @static
     * @param {IWellnessSurveyApiProto} message WellnessSurveyApiProto message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    WellnessSurveyApiProto.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a WellnessSurveyApiProto message from the specified reader or buffer.
     * @function decode
     * @memberof WellnessSurveyApiProto
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {WellnessSurveyApiProto} WellnessSurveyApiProto
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    WellnessSurveyApiProto.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.WellnessSurveyApiProto();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.holisticHealthInterests && message.holisticHealthInterests.length))
                        message.holisticHealthInterests = [];
                    message.holisticHealthInterests.push(reader.string());
                    break;
                }
            case 2: {
                    message.familiarityWithEasternDietaryPractices = reader.string();
                    break;
                }
            case 3: {
                    if (!(message.fitnessGoals && message.fitnessGoals.length))
                        message.fitnessGoals = [];
                    message.fitnessGoals.push(reader.string());
                    break;
                }
            case 4: {
                    message.currentSkincareRoutine = reader.string();
                    break;
                }
            case 5: {
                    if (!(message.hairRelatedConcerns && message.hairRelatedConcerns.length))
                        message.hairRelatedConcerns = [];
                    message.hairRelatedConcerns.push(reader.string());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a WellnessSurveyApiProto message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof WellnessSurveyApiProto
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {WellnessSurveyApiProto} WellnessSurveyApiProto
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    WellnessSurveyApiProto.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a WellnessSurveyApiProto message.
     * @function verify
     * @memberof WellnessSurveyApiProto
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    WellnessSurveyApiProto.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.holisticHealthInterests != null && message.hasOwnProperty("holisticHealthInterests")) {
            if (!Array.isArray(message.holisticHealthInterests))
                return "holisticHealthInterests: array expected";
            for (let i = 0; i < message.holisticHealthInterests.length; ++i)
                if (!$util.isString(message.holisticHealthInterests[i]))
                    return "holisticHealthInterests: string[] expected";
        }
        if (message.familiarityWithEasternDietaryPractices != null && message.hasOwnProperty("familiarityWithEasternDietaryPractices"))
            if (!$util.isString(message.familiarityWithEasternDietaryPractices))
                return "familiarityWithEasternDietaryPractices: string expected";
        if (message.fitnessGoals != null && message.hasOwnProperty("fitnessGoals")) {
            if (!Array.isArray(message.fitnessGoals))
                return "fitnessGoals: array expected";
            for (let i = 0; i < message.fitnessGoals.length; ++i)
                if (!$util.isString(message.fitnessGoals[i]))
                    return "fitnessGoals: string[] expected";
        }
        if (message.currentSkincareRoutine != null && message.hasOwnProperty("currentSkincareRoutine"))
            if (!$util.isString(message.currentSkincareRoutine))
                return "currentSkincareRoutine: string expected";
        if (message.hairRelatedConcerns != null && message.hasOwnProperty("hairRelatedConcerns")) {
            if (!Array.isArray(message.hairRelatedConcerns))
                return "hairRelatedConcerns: array expected";
            for (let i = 0; i < message.hairRelatedConcerns.length; ++i)
                if (!$util.isString(message.hairRelatedConcerns[i]))
                    return "hairRelatedConcerns: string[] expected";
        }
        return null;
    };

    /**
     * Creates a WellnessSurveyApiProto message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof WellnessSurveyApiProto
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {WellnessSurveyApiProto} WellnessSurveyApiProto
     */
    WellnessSurveyApiProto.fromObject = function fromObject(object) {
        if (object instanceof $root.WellnessSurveyApiProto)
            return object;
        let message = new $root.WellnessSurveyApiProto();
        if (object.holisticHealthInterests) {
            if (!Array.isArray(object.holisticHealthInterests))
                throw TypeError(".WellnessSurveyApiProto.holisticHealthInterests: array expected");
            message.holisticHealthInterests = [];
            for (let i = 0; i < object.holisticHealthInterests.length; ++i)
                message.holisticHealthInterests[i] = String(object.holisticHealthInterests[i]);
        }
        if (object.familiarityWithEasternDietaryPractices != null)
            message.familiarityWithEasternDietaryPractices = String(object.familiarityWithEasternDietaryPractices);
        if (object.fitnessGoals) {
            if (!Array.isArray(object.fitnessGoals))
                throw TypeError(".WellnessSurveyApiProto.fitnessGoals: array expected");
            message.fitnessGoals = [];
            for (let i = 0; i < object.fitnessGoals.length; ++i)
                message.fitnessGoals[i] = String(object.fitnessGoals[i]);
        }
        if (object.currentSkincareRoutine != null)
            message.currentSkincareRoutine = String(object.currentSkincareRoutine);
        if (object.hairRelatedConcerns) {
            if (!Array.isArray(object.hairRelatedConcerns))
                throw TypeError(".WellnessSurveyApiProto.hairRelatedConcerns: array expected");
            message.hairRelatedConcerns = [];
            for (let i = 0; i < object.hairRelatedConcerns.length; ++i)
                message.hairRelatedConcerns[i] = String(object.hairRelatedConcerns[i]);
        }
        return message;
    };

    /**
     * Creates a plain object from a WellnessSurveyApiProto message. Also converts values to other types if specified.
     * @function toObject
     * @memberof WellnessSurveyApiProto
     * @static
     * @param {WellnessSurveyApiProto} message WellnessSurveyApiProto
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    WellnessSurveyApiProto.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults) {
            object.holisticHealthInterests = [];
            object.fitnessGoals = [];
            object.hairRelatedConcerns = [];
        }
        if (options.defaults) {
            object.familiarityWithEasternDietaryPractices = "";
            object.currentSkincareRoutine = "";
        }
        if (message.holisticHealthInterests && message.holisticHealthInterests.length) {
            object.holisticHealthInterests = [];
            for (let j = 0; j < message.holisticHealthInterests.length; ++j)
                object.holisticHealthInterests[j] = message.holisticHealthInterests[j];
        }
        if (message.familiarityWithEasternDietaryPractices != null && message.hasOwnProperty("familiarityWithEasternDietaryPractices"))
            object.familiarityWithEasternDietaryPractices = message.familiarityWithEasternDietaryPractices;
        if (message.fitnessGoals && message.fitnessGoals.length) {
            object.fitnessGoals = [];
            for (let j = 0; j < message.fitnessGoals.length; ++j)
                object.fitnessGoals[j] = message.fitnessGoals[j];
        }
        if (message.currentSkincareRoutine != null && message.hasOwnProperty("currentSkincareRoutine"))
            object.currentSkincareRoutine = message.currentSkincareRoutine;
        if (message.hairRelatedConcerns && message.hairRelatedConcerns.length) {
            object.hairRelatedConcerns = [];
            for (let j = 0; j < message.hairRelatedConcerns.length; ++j)
                object.hairRelatedConcerns[j] = message.hairRelatedConcerns[j];
        }
        return object;
    };

    /**
     * Converts this WellnessSurveyApiProto to JSON.
     * @function toJSON
     * @memberof WellnessSurveyApiProto
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    WellnessSurveyApiProto.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for WellnessSurveyApiProto
     * @function getTypeUrl
     * @memberof WellnessSurveyApiProto
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    WellnessSurveyApiProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/WellnessSurveyApiProto";
    };

    return WellnessSurveyApiProto;
})();

export const ClientAppointmentProto = $root.ClientAppointmentProto = (() => {

    /**
     * Properties of a ClientAppointmentProto.
     * @exports IClientAppointmentProto
     * @interface IClientAppointmentProto
     * @property {string|null} [appointmentId] ClientAppointmentProto appointmentId
     * @property {number|Long|null} [startTimeMs] ClientAppointmentProto startTimeMs
     * @property {number|Long|null} [endTimeMs] ClientAppointmentProto endTimeMs
     * @property {string|null} [serviceName] ClientAppointmentProto serviceName
     * @property {string|null} [serviceSku] ClientAppointmentProto serviceSku
     * @property {string|null} [providerName] ClientAppointmentProto providerName
     * @property {string|null} [providerId] ClientAppointmentProto providerId
     * @property {string|null} [businessId] ClientAppointmentProto businessId
     * @property {string|null} [address] ClientAppointmentProto address
     * @property {space.kenko.proto.IPriceProto|null} [price] ClientAppointmentProto price
     */

    /**
     * Constructs a new ClientAppointmentProto.
     * @exports ClientAppointmentProto
     * @classdesc Represents a ClientAppointmentProto.
     * @implements IClientAppointmentProto
     * @constructor
     * @param {IClientAppointmentProto=} [properties] Properties to set
     */
    function ClientAppointmentProto(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ClientAppointmentProto appointmentId.
     * @member {string} appointmentId
     * @memberof ClientAppointmentProto
     * @instance
     */
    ClientAppointmentProto.prototype.appointmentId = "";

    /**
     * ClientAppointmentProto startTimeMs.
     * @member {number|Long} startTimeMs
     * @memberof ClientAppointmentProto
     * @instance
     */
    ClientAppointmentProto.prototype.startTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * ClientAppointmentProto endTimeMs.
     * @member {number|Long} endTimeMs
     * @memberof ClientAppointmentProto
     * @instance
     */
    ClientAppointmentProto.prototype.endTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * ClientAppointmentProto serviceName.
     * @member {string} serviceName
     * @memberof ClientAppointmentProto
     * @instance
     */
    ClientAppointmentProto.prototype.serviceName = "";

    /**
     * ClientAppointmentProto serviceSku.
     * @member {string} serviceSku
     * @memberof ClientAppointmentProto
     * @instance
     */
    ClientAppointmentProto.prototype.serviceSku = "";

    /**
     * ClientAppointmentProto providerName.
     * @member {string} providerName
     * @memberof ClientAppointmentProto
     * @instance
     */
    ClientAppointmentProto.prototype.providerName = "";

    /**
     * ClientAppointmentProto providerId.
     * @member {string} providerId
     * @memberof ClientAppointmentProto
     * @instance
     */
    ClientAppointmentProto.prototype.providerId = "";

    /**
     * ClientAppointmentProto businessId.
     * @member {string} businessId
     * @memberof ClientAppointmentProto
     * @instance
     */
    ClientAppointmentProto.prototype.businessId = "";

    /**
     * ClientAppointmentProto address.
     * @member {string} address
     * @memberof ClientAppointmentProto
     * @instance
     */
    ClientAppointmentProto.prototype.address = "";

    /**
     * ClientAppointmentProto price.
     * @member {space.kenko.proto.IPriceProto|null|undefined} price
     * @memberof ClientAppointmentProto
     * @instance
     */
    ClientAppointmentProto.prototype.price = null;

    /**
     * Creates a new ClientAppointmentProto instance using the specified properties.
     * @function create
     * @memberof ClientAppointmentProto
     * @static
     * @param {IClientAppointmentProto=} [properties] Properties to set
     * @returns {ClientAppointmentProto} ClientAppointmentProto instance
     */
    ClientAppointmentProto.create = function create(properties) {
        return new ClientAppointmentProto(properties);
    };

    /**
     * Encodes the specified ClientAppointmentProto message. Does not implicitly {@link ClientAppointmentProto.verify|verify} messages.
     * @function encode
     * @memberof ClientAppointmentProto
     * @static
     * @param {IClientAppointmentProto} message ClientAppointmentProto message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ClientAppointmentProto.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.appointmentId != null && Object.hasOwnProperty.call(message, "appointmentId"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.appointmentId);
        if (message.startTimeMs != null && Object.hasOwnProperty.call(message, "startTimeMs"))
            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.startTimeMs);
        if (message.endTimeMs != null && Object.hasOwnProperty.call(message, "endTimeMs"))
            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.endTimeMs);
        if (message.serviceName != null && Object.hasOwnProperty.call(message, "serviceName"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.serviceName);
        if (message.providerName != null && Object.hasOwnProperty.call(message, "providerName"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.providerName);
        if (message.providerId != null && Object.hasOwnProperty.call(message, "providerId"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.providerId);
        if (message.businessId != null && Object.hasOwnProperty.call(message, "businessId"))
            writer.uint32(/* id 7, wireType 2 =*/58).string(message.businessId);
        if (message.address != null && Object.hasOwnProperty.call(message, "address"))
            writer.uint32(/* id 8, wireType 2 =*/66).string(message.address);
        if (message.price != null && Object.hasOwnProperty.call(message, "price"))
            $root.space.kenko.proto.PriceProto.encode(message.price, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
        if (message.serviceSku != null && Object.hasOwnProperty.call(message, "serviceSku"))
            writer.uint32(/* id 10, wireType 2 =*/82).string(message.serviceSku);
        return writer;
    };

    /**
     * Encodes the specified ClientAppointmentProto message, length delimited. Does not implicitly {@link ClientAppointmentProto.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ClientAppointmentProto
     * @static
     * @param {IClientAppointmentProto} message ClientAppointmentProto message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ClientAppointmentProto.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ClientAppointmentProto message from the specified reader or buffer.
     * @function decode
     * @memberof ClientAppointmentProto
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ClientAppointmentProto} ClientAppointmentProto
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ClientAppointmentProto.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ClientAppointmentProto();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.appointmentId = reader.string();
                    break;
                }
            case 2: {
                    message.startTimeMs = reader.int64();
                    break;
                }
            case 3: {
                    message.endTimeMs = reader.int64();
                    break;
                }
            case 4: {
                    message.serviceName = reader.string();
                    break;
                }
            case 10: {
                    message.serviceSku = reader.string();
                    break;
                }
            case 5: {
                    message.providerName = reader.string();
                    break;
                }
            case 6: {
                    message.providerId = reader.string();
                    break;
                }
            case 7: {
                    message.businessId = reader.string();
                    break;
                }
            case 8: {
                    message.address = reader.string();
                    break;
                }
            case 9: {
                    message.price = $root.space.kenko.proto.PriceProto.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a ClientAppointmentProto message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ClientAppointmentProto
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ClientAppointmentProto} ClientAppointmentProto
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ClientAppointmentProto.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ClientAppointmentProto message.
     * @function verify
     * @memberof ClientAppointmentProto
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ClientAppointmentProto.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.appointmentId != null && message.hasOwnProperty("appointmentId"))
            if (!$util.isString(message.appointmentId))
                return "appointmentId: string expected";
        if (message.startTimeMs != null && message.hasOwnProperty("startTimeMs"))
            if (!$util.isInteger(message.startTimeMs) && !(message.startTimeMs && $util.isInteger(message.startTimeMs.low) && $util.isInteger(message.startTimeMs.high)))
                return "startTimeMs: integer|Long expected";
        if (message.endTimeMs != null && message.hasOwnProperty("endTimeMs"))
            if (!$util.isInteger(message.endTimeMs) && !(message.endTimeMs && $util.isInteger(message.endTimeMs.low) && $util.isInteger(message.endTimeMs.high)))
                return "endTimeMs: integer|Long expected";
        if (message.serviceName != null && message.hasOwnProperty("serviceName"))
            if (!$util.isString(message.serviceName))
                return "serviceName: string expected";
        if (message.serviceSku != null && message.hasOwnProperty("serviceSku"))
            if (!$util.isString(message.serviceSku))
                return "serviceSku: string expected";
        if (message.providerName != null && message.hasOwnProperty("providerName"))
            if (!$util.isString(message.providerName))
                return "providerName: string expected";
        if (message.providerId != null && message.hasOwnProperty("providerId"))
            if (!$util.isString(message.providerId))
                return "providerId: string expected";
        if (message.businessId != null && message.hasOwnProperty("businessId"))
            if (!$util.isString(message.businessId))
                return "businessId: string expected";
        if (message.address != null && message.hasOwnProperty("address"))
            if (!$util.isString(message.address))
                return "address: string expected";
        if (message.price != null && message.hasOwnProperty("price")) {
            let error = $root.space.kenko.proto.PriceProto.verify(message.price);
            if (error)
                return "price." + error;
        }
        return null;
    };

    /**
     * Creates a ClientAppointmentProto message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ClientAppointmentProto
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ClientAppointmentProto} ClientAppointmentProto
     */
    ClientAppointmentProto.fromObject = function fromObject(object) {
        if (object instanceof $root.ClientAppointmentProto)
            return object;
        let message = new $root.ClientAppointmentProto();
        if (object.appointmentId != null)
            message.appointmentId = String(object.appointmentId);
        if (object.startTimeMs != null)
            if ($util.Long)
                (message.startTimeMs = $util.Long.fromValue(object.startTimeMs)).unsigned = false;
            else if (typeof object.startTimeMs === "string")
                message.startTimeMs = parseInt(object.startTimeMs, 10);
            else if (typeof object.startTimeMs === "number")
                message.startTimeMs = object.startTimeMs;
            else if (typeof object.startTimeMs === "object")
                message.startTimeMs = new $util.LongBits(object.startTimeMs.low >>> 0, object.startTimeMs.high >>> 0).toNumber();
        if (object.endTimeMs != null)
            if ($util.Long)
                (message.endTimeMs = $util.Long.fromValue(object.endTimeMs)).unsigned = false;
            else if (typeof object.endTimeMs === "string")
                message.endTimeMs = parseInt(object.endTimeMs, 10);
            else if (typeof object.endTimeMs === "number")
                message.endTimeMs = object.endTimeMs;
            else if (typeof object.endTimeMs === "object")
                message.endTimeMs = new $util.LongBits(object.endTimeMs.low >>> 0, object.endTimeMs.high >>> 0).toNumber();
        if (object.serviceName != null)
            message.serviceName = String(object.serviceName);
        if (object.serviceSku != null)
            message.serviceSku = String(object.serviceSku);
        if (object.providerName != null)
            message.providerName = String(object.providerName);
        if (object.providerId != null)
            message.providerId = String(object.providerId);
        if (object.businessId != null)
            message.businessId = String(object.businessId);
        if (object.address != null)
            message.address = String(object.address);
        if (object.price != null) {
            if (typeof object.price !== "object")
                throw TypeError(".ClientAppointmentProto.price: object expected");
            message.price = $root.space.kenko.proto.PriceProto.fromObject(object.price);
        }
        return message;
    };

    /**
     * Creates a plain object from a ClientAppointmentProto message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ClientAppointmentProto
     * @static
     * @param {ClientAppointmentProto} message ClientAppointmentProto
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ClientAppointmentProto.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.appointmentId = "";
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.startTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.startTimeMs = options.longs === String ? "0" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.endTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.endTimeMs = options.longs === String ? "0" : 0;
            object.serviceName = "";
            object.providerName = "";
            object.providerId = "";
            object.businessId = "";
            object.address = "";
            object.price = null;
            object.serviceSku = "";
        }
        if (message.appointmentId != null && message.hasOwnProperty("appointmentId"))
            object.appointmentId = message.appointmentId;
        if (message.startTimeMs != null && message.hasOwnProperty("startTimeMs"))
            if (typeof message.startTimeMs === "number")
                object.startTimeMs = options.longs === String ? String(message.startTimeMs) : message.startTimeMs;
            else
                object.startTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.startTimeMs) : options.longs === Number ? new $util.LongBits(message.startTimeMs.low >>> 0, message.startTimeMs.high >>> 0).toNumber() : message.startTimeMs;
        if (message.endTimeMs != null && message.hasOwnProperty("endTimeMs"))
            if (typeof message.endTimeMs === "number")
                object.endTimeMs = options.longs === String ? String(message.endTimeMs) : message.endTimeMs;
            else
                object.endTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.endTimeMs) : options.longs === Number ? new $util.LongBits(message.endTimeMs.low >>> 0, message.endTimeMs.high >>> 0).toNumber() : message.endTimeMs;
        if (message.serviceName != null && message.hasOwnProperty("serviceName"))
            object.serviceName = message.serviceName;
        if (message.providerName != null && message.hasOwnProperty("providerName"))
            object.providerName = message.providerName;
        if (message.providerId != null && message.hasOwnProperty("providerId"))
            object.providerId = message.providerId;
        if (message.businessId != null && message.hasOwnProperty("businessId"))
            object.businessId = message.businessId;
        if (message.address != null && message.hasOwnProperty("address"))
            object.address = message.address;
        if (message.price != null && message.hasOwnProperty("price"))
            object.price = $root.space.kenko.proto.PriceProto.toObject(message.price, options);
        if (message.serviceSku != null && message.hasOwnProperty("serviceSku"))
            object.serviceSku = message.serviceSku;
        return object;
    };

    /**
     * Converts this ClientAppointmentProto to JSON.
     * @function toJSON
     * @memberof ClientAppointmentProto
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ClientAppointmentProto.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ClientAppointmentProto
     * @function getTypeUrl
     * @memberof ClientAppointmentProto
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ClientAppointmentProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ClientAppointmentProto";
    };

    return ClientAppointmentProto;
})();

export const com = $root.com = (() => {

    /**
     * Namespace com.
     * @exports com
     * @namespace
     */
    const com = {};

    com.onkensu = (function() {

        /**
         * Namespace onkensu.
         * @memberof com
         * @namespace
         */
        const onkensu = {};

        onkensu.platform = (function() {

            /**
             * Namespace platform.
             * @memberof com.onkensu
             * @namespace
             */
            const platform = {};

            platform.billing = (function() {

                /**
                 * Namespace billing.
                 * @memberof com.onkensu.platform
                 * @namespace
                 */
                const billing = {};

                billing.CreateCheckoutSessionResponse = (function() {

                    /**
                     * Properties of a CreateCheckoutSessionResponse.
                     * @memberof com.onkensu.platform.billing
                     * @interface ICreateCheckoutSessionResponse
                     * @property {string|null} [clientSecret] CreateCheckoutSessionResponse clientSecret
                     */

                    /**
                     * Constructs a new CreateCheckoutSessionResponse.
                     * @memberof com.onkensu.platform.billing
                     * @classdesc Represents a CreateCheckoutSessionResponse.
                     * @implements ICreateCheckoutSessionResponse
                     * @constructor
                     * @param {com.onkensu.platform.billing.ICreateCheckoutSessionResponse=} [properties] Properties to set
                     */
                    function CreateCheckoutSessionResponse(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * CreateCheckoutSessionResponse clientSecret.
                     * @member {string} clientSecret
                     * @memberof com.onkensu.platform.billing.CreateCheckoutSessionResponse
                     * @instance
                     */
                    CreateCheckoutSessionResponse.prototype.clientSecret = "";

                    /**
                     * Creates a new CreateCheckoutSessionResponse instance using the specified properties.
                     * @function create
                     * @memberof com.onkensu.platform.billing.CreateCheckoutSessionResponse
                     * @static
                     * @param {com.onkensu.platform.billing.ICreateCheckoutSessionResponse=} [properties] Properties to set
                     * @returns {com.onkensu.platform.billing.CreateCheckoutSessionResponse} CreateCheckoutSessionResponse instance
                     */
                    CreateCheckoutSessionResponse.create = function create(properties) {
                        return new CreateCheckoutSessionResponse(properties);
                    };

                    /**
                     * Encodes the specified CreateCheckoutSessionResponse message. Does not implicitly {@link com.onkensu.platform.billing.CreateCheckoutSessionResponse.verify|verify} messages.
                     * @function encode
                     * @memberof com.onkensu.platform.billing.CreateCheckoutSessionResponse
                     * @static
                     * @param {com.onkensu.platform.billing.ICreateCheckoutSessionResponse} message CreateCheckoutSessionResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateCheckoutSessionResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.clientSecret != null && Object.hasOwnProperty.call(message, "clientSecret"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.clientSecret);
                        return writer;
                    };

                    /**
                     * Encodes the specified CreateCheckoutSessionResponse message, length delimited. Does not implicitly {@link com.onkensu.platform.billing.CreateCheckoutSessionResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof com.onkensu.platform.billing.CreateCheckoutSessionResponse
                     * @static
                     * @param {com.onkensu.platform.billing.ICreateCheckoutSessionResponse} message CreateCheckoutSessionResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateCheckoutSessionResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a CreateCheckoutSessionResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof com.onkensu.platform.billing.CreateCheckoutSessionResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {com.onkensu.platform.billing.CreateCheckoutSessionResponse} CreateCheckoutSessionResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateCheckoutSessionResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.com.onkensu.platform.billing.CreateCheckoutSessionResponse();
                        while (reader.pos < end) {
                            let tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    message.clientSecret = reader.string();
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a CreateCheckoutSessionResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof com.onkensu.platform.billing.CreateCheckoutSessionResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {com.onkensu.platform.billing.CreateCheckoutSessionResponse} CreateCheckoutSessionResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateCheckoutSessionResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a CreateCheckoutSessionResponse message.
                     * @function verify
                     * @memberof com.onkensu.platform.billing.CreateCheckoutSessionResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CreateCheckoutSessionResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.clientSecret != null && message.hasOwnProperty("clientSecret"))
                            if (!$util.isString(message.clientSecret))
                                return "clientSecret: string expected";
                        return null;
                    };

                    /**
                     * Creates a CreateCheckoutSessionResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof com.onkensu.platform.billing.CreateCheckoutSessionResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {com.onkensu.platform.billing.CreateCheckoutSessionResponse} CreateCheckoutSessionResponse
                     */
                    CreateCheckoutSessionResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.com.onkensu.platform.billing.CreateCheckoutSessionResponse)
                            return object;
                        let message = new $root.com.onkensu.platform.billing.CreateCheckoutSessionResponse();
                        if (object.clientSecret != null)
                            message.clientSecret = String(object.clientSecret);
                        return message;
                    };

                    /**
                     * Creates a plain object from a CreateCheckoutSessionResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof com.onkensu.platform.billing.CreateCheckoutSessionResponse
                     * @static
                     * @param {com.onkensu.platform.billing.CreateCheckoutSessionResponse} message CreateCheckoutSessionResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CreateCheckoutSessionResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        let object = {};
                        if (options.defaults)
                            object.clientSecret = "";
                        if (message.clientSecret != null && message.hasOwnProperty("clientSecret"))
                            object.clientSecret = message.clientSecret;
                        return object;
                    };

                    /**
                     * Converts this CreateCheckoutSessionResponse to JSON.
                     * @function toJSON
                     * @memberof com.onkensu.platform.billing.CreateCheckoutSessionResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CreateCheckoutSessionResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for CreateCheckoutSessionResponse
                     * @function getTypeUrl
                     * @memberof com.onkensu.platform.billing.CreateCheckoutSessionResponse
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    CreateCheckoutSessionResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/com.onkensu.platform.billing.CreateCheckoutSessionResponse";
                    };

                    return CreateCheckoutSessionResponse;
                })();

                return billing;
            })();

            return platform;
        })();

        return onkensu;
    })();

    return com;
})();

export { $root as default };
