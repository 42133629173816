import React, {useEffect, useState} from "react";
import Markdown from 'react-markdown'

type Props = {
  documentPath: string
}

export const PolicyPage = (props: Props) => {

  const [text, setText] = useState('');

  useEffect(() => {
    fetch(props.documentPath)
        .then(response => response.text())
        .then(setText);
  });

  return <div style={{padding: 10, textAlign: 'left'}}>
    <Markdown>
      {text}
    </Markdown>
  </div>
}