import {Link} from "react-router-dom";
import React from "react";

interface Props {
  link: string;
  category: string;
  imgSource: any;
  title: string;
}

export const ArticlePreview = (props: Props) => {
  return <Link to={props.link} className={'ArticlePreview'}>
    <div className={'ArticlePreviewImageContainer'}>
      <img src={props.imgSource} className={'ArticlePreviewImage'}/>
    </div>
    <div className={'ArticlePreviewCaption'}>
      <p className={'ArticlePreviewTinyText'}>{props.category}</p>
      <p className={'ArticlePreviewText'}>{props.title}</p>
    </div>
  </Link>
}
