import {useState} from "react";

type Props = {
  onChange: (value: string | null | undefined) => void;
}

export const TextSearch = (props: Props) => {

  const [value, setValue] = useState('');

  return <input
      className={'LibraryTextSearch'}
      placeholder={'Search'}
      type={'text'}
      onChange={e => setValue(e.target.value)}
      onKeyPress={e => {
        // Every key press will cause a lot of searches. 
        if (e.key === 'Enter') {
          props.onChange(value)
        }
      }}/>
}