import {SendRpc} from "../../rpcSender";
import {DeleteProfileRequest, DeleteProfileResponse} from "../../compiled";
import {useAuth0} from "@auth0/auth0-react";
import {useProfile} from "../../UserProfileProvider";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import './AccountSettings.css'

export const AccountSettings = () => {


  const {getIdTokenClaims, logout} = useAuth0();
  const {profile, setProfile} = useProfile();
  const [deleteRpcError, setDeleteRpcError] = useState('');
  const navigate = useNavigate();


  const auth0Logout = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  }

  const deleteProfile = () => {
    SendRpc(getIdTokenClaims, "delete_user_profile",
        DeleteProfileRequest.encode(new DeleteProfileRequest({
          profileId: profile?.profileId
        })).finish())
        .then(bytes => {
          let response = DeleteProfileResponse.decode(bytes);
          if (response.okay) {
            setProfile(null);
            navigate('/');
          } else {
            setDeleteRpcError('Error deleting your profile');
          }
        })
        .catch(e => {
          setDeleteRpcError('Error deleting your profile. ' + e);
        });
  }


  return <div className={'AccountSettingsPage'}>

    <div className={'AccountSettingsBox'}>
      <h3>CONTACT INFORMATION</h3>
      <b>Name</b>
      {profile?.name || 'unset'}
    </div>

    <div className={'AccountSettingsBox'}>
      <h3>DEBUG STUFF</h3>
      <div style={{display: 'flex', gap: 20}}>
        <button className={'ActionButton Inverted'} onClick={auth0Logout}>log out</button>
        <button className={'ActionButton Inverted'} onClick={deleteProfile}>delete my profile</button>
      </div>
      {deleteRpcError && <div style={{color: 'red'}}>{deleteRpcError}</div>}
    </div>

    <div className={'AccountSettingsBox'}>
      <h3>VERY DEBUG STUFF</h3>
      <pre style={{fontFamily: 'Nunito', color: 'gray'}}>
      {JSON.stringify(profile, null, 2)}
      </pre>
    </div>


  </div>

}