import '../App.css';
import {App2} from "../App2";
import {Route, Routes} from "react-router";
import {Appointments} from "./Appointments";
import {AccountSettings} from "./AccountSettings";
import {DashboardNavIcon} from "./DashboardNavIcon";
import {spanToMinutes} from "../../util/CalendarUtil";
import {IClientAppointmentProto} from "../../compiled";


export const Dashboard = () => {


  return <App2>

    {/* AppSectionContent gives us the nice centered page with max-width*/}
    <div className={'AppSection'}>
      <div className={'AppSectionContent DashboardPage'}>

        {/* The left nav bar containing all the tools */}
        <div className={'DashboardNav'}>

          <DashboardNavIcon text='Appointments' link={''}
                            icon={require('../../images/icons/account_box_black.png')}/>

          <DashboardNavIcon text='Account Settings' link={'settings'}
                            icon={require('../../images/icons/settings.png')}/>

        </div>


        {/* The main body of the tool- And the right which takes up the most of the page*/}
        <Routes>
          <Route path={'/'} element={<Appointments/>}/>
          <Route path={'/settings'} element={<AccountSettings/>}/>
        </Routes>
      </div>
    </div>
  </App2>;


}