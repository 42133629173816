import {CSSProperties, useEffect, useRef, useState} from "react";
import {useMapsLibrary} from "@vis.gl/react-google-maps";
import {StylesProps} from "react-select/dist/declarations/src/styles";

interface PlaceAutocompleteProps {
  styles?: CSSProperties,
  onPlaceSelect: (place: google.maps.places.PlaceResult | null) => void;
}

export const PlaceAutocomplete = (props: PlaceAutocompleteProps) => {

  const [placeAutocomplete, setPlaceAutocomplete] =
      useState<google.maps.places.Autocomplete | null>(null);

  const inputRef = useRef<HTMLInputElement>(null);
  const places = useMapsLibrary('places');

  useEffect(() => {
    if (!places || !inputRef.current) return;

    const options = {
      fields: ['geometry', 'name', 'formatted_address']
    };

    setPlaceAutocomplete(new places.Autocomplete(inputRef.current, options));
  }, [places]);

  useEffect(() => {
    if (!placeAutocomplete) return;

    const listener = placeAutocomplete.addListener('place_changed', () => {
      props.onPlaceSelect(placeAutocomplete.getPlace());
    });
    
    return () => {
      // Important to clean up this listener if the component is unmounted
      // or else the listener stays active
      listener.remove();
    }
    
  }, [props.onPlaceSelect, placeAutocomplete]);

  return (
      <input style={{...{padding: 10, width: 250}, ...props.styles}} ref={inputRef}/>
  );
};