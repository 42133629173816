/**
 * If there's only a single practitioner, this larger bio is shown.
 */
import {space} from "../../compiled";
import React, {CSSProperties} from "react";
import './Provider.css';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {Link} from "react-router-dom";
import IProviderBioProto = space.kenko.proto.IProviderBioProto;

type Props = {
  providers: IProviderBioProto[];
  onBookButtonClicked: (providerId: string) => void;
}

const carouselConfig = {
  desktop: {
    breakpoint: {max: 3000, min: 900},
    items: 3,
    slidesToSlide: 3, // optional, default to 1.
  },
  middle: {
    breakpoint: {max: 1000, min: 700},
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: {max: 700, min: 0},
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  }
};

const carouselTwoItemMax = {
  middle: {
    breakpoint: {max: 3000, min: 700},
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: {max: 700, min: 0},
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  }
};

/**
 * Returns a single large bio if there's only one practitioner. Returns
 * a carousel if there are more.
 * @param props
 * @constructor
 */
export const ProviderBios = (props: Props) => {

  const practitioners = props.providers?.filter(provider => {
    return provider.isPractitioner
  })

  if (!practitioners?.length) {
    return <></>
  }

  if (practitioners.length > 1) {
    return <SmallBios providers={practitioners}
                      onBookButtonClicked={props.onBookButtonClicked}
    />
  }

  return <LargeBio provider={practitioners[0]}
                   onBookButtonClicked={props.onBookButtonClicked}
  />;
}

type LargeBioProps = {
  provider: IProviderBioProto,
  onBookButtonClicked: (providerId: string) => void;
}

const LargeBio = (props: LargeBioProps) => {

  const provider = props.provider;

  return <div className={'AppSection RedSection'}>
    <div className={'AppSectionContent'}>

      <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>

        <div style={{flex: '1', display: 'flex', overflow: 'hidden', justifyContent: 'center'}}>
          <img src={provider.imageUrls?.[0]}
               style={{maxHeight: 768, alignSelf: 'center'}}
               alt={'our founders'}/>
        </div>

        <div className='Flex1'>
          <div className={'ProviderSectionMargin ProviderBioSection'}>
            <h1>Hello from {provider.firstName} {provider.lastName}</h1>
            <p>
              {provider.bio}
            </p>
            <button className={'ActionButton Inverted Borderless'}
                    style={{cursor: 'pointer'}}
                    onClick={() => {
                      props.onBookButtonClicked(provider.providerId as string)
                    }}>
              Book an Appointment
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
}


type SmallBiosProps = {
  providers: IProviderBioProto[];
  onBookButtonClicked: (providerOrBusinessId: string) => void;
}

const SmallBios = (props: SmallBiosProps) => {


  return <div className={'AppSection RedSection'}>
    <div className={'AppSectionContent Flex1'}>
      <div className={'ProviderSectionMargin'}>

        <div className={'ProviderHeaderRow'}>
          <div className={'ProviderHeaderTitle'}>OUR TEAM</div>
        </div>

        <ProviderCarousel providersOrBusinesses={props.providers.map(provider => {
          return {
            id: provider.providerId as string,
            title: provider.firstName + " " + provider.lastName,
            subtitle: provider.jobTitle as string,
            description: provider.bio as string,
            imageUrl: provider.imageUrls?.length && provider.imageUrls[0] || undefined,
            buttonText : `Book with ${provider.firstName}`
          }
        })}
                          onBookButtonClicked={props.onBookButtonClicked}/>

      </div>
    </div>
  </div>
}

// Can be a carousel of businesses or individual practitioners
type ProviderCarouselProps = {
  providersOrBusinesses: SmallBioInfo[]
  onBookButtonClicked: (providerOrBusinessId: string) => void;
  overrideBioStyles?: CSSProperties, // bg color is different in home page version 
}


/**
 * This gets used in a provider's page to show all their practitioners AND
 * recommended providers on the home page.
 */
export const ProviderCarousel = (props: ProviderCarouselProps) => {
  return <Carousel
      draggable={true}
      showDots={false}
      swipeable={true}
      responsive={props.providersOrBusinesses.length <= 2 ? carouselTwoItemMax : carouselConfig}
      containerClass="carousel-container"
      dotListClass="carousel-dot-list"
      itemClass="carousel-item">
    {props.providersOrBusinesses.map(providerOrBusiness => {
      return <SmallBio key={providerOrBusiness.id}
                       bio={providerOrBusiness}
                       overrideBioStyles={props.overrideBioStyles}
                       onBookButtonClicked={props.onBookButtonClicked}
      />
    })}
  </Carousel>
}

type SmallBioInfo = {
  imageUrl?: string
  title: string // business or practitioner name
  subtitle?: string // business location or practitioner's job title
  description?: string // business description or practitioner bio
  id: string // business id or provider id
  buttonText: string // book name or practitioner's first name
  shortUrl?: string // only used for businesses to link to their home page
}

// This is used for both people (practitioners) on the provider landing page
// AND businesses (recommended providers) on the client home page.
type SmallBioProps = {

  bio: SmallBioInfo,
  onBookButtonClicked: (providerOrBusinessId: string) => void;
  overrideBioStyles?: CSSProperties
}

const SmallBio = (props: SmallBioProps) => {

  const info = props.bio;
  return <div className={'SmallBio'} style={props.overrideBioStyles}>
    <div className={'SmallBioContent'}>
      <div className={'SmallBioImageMask'}>
        <img className={'SmallBioImage'} src={info.imageUrl}/>
      </div>
      <div className={'SmallBioName'}>
        {props.bio.shortUrl &&
            <Link to={`/p/${props.bio.shortUrl}`}>
              {info.title}
            </Link> ||
            <>{info.title}</>
        }
      </div>
      <div className={''}>{info.subtitle}</div>
      <div className={'SmallBioBio'}>{info.description}</div>
      <div style={{flex: 0}}>
        <button className={'ActionButton'}
                onClick={() => props.onBookButtonClicked(info.id)}>
          {props.bio.buttonText}</button>
      </div>
    </div>
  </div>
}

