import React, {ReactElement, ReactNode} from "react";
import {Header} from "./Header";
import Footer from "./Footer";

type Props = {
  children: ReactNode
}

/**
 * This is the template for the "default" page, which consists of a fixed header at
 * the top, and then a scrolling section containing the body (passed in Props) and
 * a footer.
 */
export const App2 = (props: Props) => {

  return (
      <div className="App2">
        <Header/>

        <div className={'App2Body'}>
          {props.children}
          <Footer/>
        </div>

        <div id={'injected-react-content'}/>
      </div>
  );
}

/**
 * This variant has the usual header, and puts the content in a separate
 * div that fills the rest of the screen and does not scroll. There is no
 * footer.
 */
export const App2NoScroll = (props: Props) => {

  return (
      <div className="App2">
        <Header/>

        <div className={'App2BodyNoScroll'}>
          {props.children}
        </div>

        <div id={'injected-react-content'}/>
      </div>
  );
}