import React, {useLayoutEffect} from "react";
import {WhatIsKenkoSection} from "../App/Home/Sections/WhatIsKenkoSection";
import {EmailSignup} from "./EmailSignup";
import '../App/App.css';
import {ArticlePreviewSection} from "../App/Home/Sections/ArticlePreviewSection";
import {useLocation} from "react-router-dom";
import {FoundersSection} from "../App/Home/Sections/FoundersSection";
import './Waitlist.css';

export const Waitlist = () => {

  const location = useLocation();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]); // Trigger on route change

  return <>
    <TransformYourFutureHealth/>
    <WhatIsKenkoSection/>
    <ArticlePreviewSection
        linkToLibrary={false} // the three articles are static content
        hasGoToWellnessLibraryButton={false}/>
    <FoundersSection/>
  </>;

}

export const TransformYourFutureHealth = () => {
  return <div className={'AppSection BeigeSection'}>
    <div className={'AppSectionContent WaitlistWelcomeSection'}>

      <h1 style={{maxWidth: 600}}>
        Transform Your Future Health.
      </h1>

      <h2 style={{maxWidth: 580}}>
        Expert-led, integrative care that meets you where you’re at today, for a healthier tomorrow.
      </h2>

      <div className={'TransformFutureHealthEmailContainer'}>
        <EmailSignup/>
      </div>
    </div>
  </div>
}
