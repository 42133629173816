import '../../App.css'
import React, {ReactNode, useState} from 'react';
import {useAuth0} from "@auth0/auth0-react";
import {SendRpc} from "../../../rpcSender";
import './WellnessSurvey.css'
import {useNavigate} from "react-router-dom";
import {useProfile} from "../../../UserProfileProvider";
import {
  UpdateUserProfileRequest,
  UpdateUserProfileResponse,
  IClientProfileProto, ClientProfileProto
} from "../../../compiled";

export const WellnessSurvey = () => {

      const {profile, setProfile} = useProfile();

      const {getIdTokenClaims} = useAuth0();

      const [currentStep, setCurrentStep] = useState(0);

      // Survey results are kept as a plain JSON object and then converted to proto
      // at the end. This turned out way easier than trying to convert types and mash
      // things into a proto right away.
      const [surveyResults, setSurveyResults] = useState<{ [key: string]: any }>({});

      // Client-side error validating one of the fields
      const [validationError, setValidationError] = useState<string | null>(null);

      // If there was an RPC error sending the profile.
      const [updateProfileError, setUpdateProfileError] = useState('');

      const navigate = useNavigate();

      const sendUpdateUserProfileRequest = () => {

        return new Promise<UpdateUserProfileResponse>((resolve, reject) => {

              // Build up the thing from the survey questions
              const newProfile = ClientProfileProto.create({
                wellnessSurveyAnswers: {}
              });

              surveyPages.forEach(page => {
                if ((!page.visibleWhen || page.visibleWhen(surveyResults)) && surveyResults[page.name]) {
                  page.setter(newProfile, surveyResults[page.name])
                }
              });

              let request = new UpdateUserProfileRequest({profile: newProfile});

              console.log('creating profile with', JSON.stringify(request, null, 2));

              let encoded: Uint8Array = UpdateUserProfileRequest.encode(request).finish();

              SendRpc(getIdTokenClaims, 'update_profile', encoded)
                  .then(value => {
                    resolve(UpdateUserProfileResponse.decode(value));
                  })
                  .catch(e => {
                    reject(e);
                  });
            }
        )
      };

      type surveyPage = {
        name: string,
        title: () => string,
        subtitle?: () => string,
        type: string,
        choices?: { value: string, text: ReactNode }[],
        visibleWhen?: (results: any) => boolean,
        setter: (request: IClientProfileProto, value: any) => void,

        // Optional -- in case you need to handle something when the value changes.
        // Like setting a suggested short url from the provider name..
        onValueChange?: (value: any) => void,
        isValidValue: (value: any) => boolean,
        validationMessage: string,
        initialValue?: any,
      }

      const surveyPages: surveyPage[] = [
        {
          name: 'Name',
          title: () => 'What is your name?',
          type: 'text',
          setter: (request: IClientProfileProto, value: string) => {
            request.name = value;
          },
          isValidValue: (value) => value?.trim().length > 0,
          validationMessage: 'Please enter your name',
        },
        {
          name: 'Interests',
          title: () => 'What holistic health treatments are you most interested in?',
          subtitle: () => '(Select all that apply)',
          type: 'checklist',
          choices: [
            {
              value: "Chiropractic care",
              text: <span><b>Chiropractic care</b> - Focuses on diagnosing and treating musculoskeletal disorders, primarily through manual adjustments and manipulations of the spine to relieve pain and improve function</span>
            },
            {
              value: "Acupuncture",
              text: <span><b>Acupuncture</b> – Uses needles to stimulate specific points on the body.</span>
            },
            {
              value: "Massage therapy",
              text: <span><b>Massage therapy</b> – Involves manipulating muscles and soft tissues to relieve tension and stress</span>
            },
            {
              value: "Traditional Chinese Medicine",
              text: <span><b>Traditional Chinese Medicine</b> - Uses herbs, acupuncture, and practices like cupping and moxibustion to restore balance and harmony in the body</span>
            },
            {
              value: "Meditation and mindfulness",
              text: <span><b>Meditation and mindfulness</b> - Involves focusing the mind to reduce stress and increase mental clarity</span>
            },
            {
              value: "Mind-body practices",
              text: <span><b>Mind-body practices</b> - Integrates mental and physical aspects to enhance overall health, such as yoga or tai chi</span>
            },
            {
              value: "Homeopathy",
              text: <span><b>Homeopathy</b> – Uses small, diluted doses of natural substances</span>
            },
            {
              value: "Naturopathy",
              text: <span><b>Naturopathy</b> – Uses natural remedies to promote healing, including diet, exercise, and lifestyle changes</span>
            },
            {
              value: "Energy healing",
              text: <span><b>Energy healing (e.g., Reiki, Qi Gong)</b> – Focuses on the body’s energy fields to promote healing and balance</span>
            },
            {
              value: "Sound therapy",
              text: <span><b>Sound therapy</b> - Uses sound waves, vibrations, or music to promote healing and relaxation in the body</span>
            },
            {
              value: "Ayurveda",
              text: <span><b>Ayurveda</b> – Focuses on balancing the body’s systems through diet, herbal treatment, and yoga</span>
            },
          ],
          setter: (request: IClientProfileProto, value: string[]) => {
            request.wellnessSurveyAnswers && (request.wellnessSurveyAnswers.holisticHealthInterests = value);
          },
          isValidValue: (value) => {
            return value != null;
          },
          validationMessage: 'Please select at least one',
        },

        {
          name: 'DietInterest',
          title: () => 'How familiar are you with Eastern dietary practices, such as TCM food therapy (e.g., warming vs. cooling foods) or Ayurvedic principles?',
          type: 'radio',
          choices: [
            {value: 'Very familiar', text: 'Very familiar and I follow some practices'},
            {value: 'Somewhat familiar', text: 'Somewhat familiar'},
            {value: 'Not familiar, but interested in learning', text: 'Not familiar, but interested in learning'},
            {value: 'Not interested', text: 'Not interested'},
          ],
          setter: (request: IClientProfileProto, value: string) => {
            request.wellnessSurveyAnswers && (request.wellnessSurveyAnswers.familiarityWithEasternDietaryPractices = value);
          },
          isValidValue: (value) => {
            return value;
          },
          validationMessage: 'Please select one',
        },

        {
          name: 'Goals',
          title: () => 'What are your primary fitness goals? (Select all that apply).',
          type: 'checklist',
          choices: [
            {value: "Increase flexibility", text: 'Increase flexibility'},
            {value: "Build strength", text: 'Build strength'},
            {value: "Improve cardiovascular health", text: 'Improve cardiovascular health'},
            {value: "Lose weight", text: 'Lose weight'},
            {value: "Maintain overall health and wellness", text: 'Maintain overall health and wellness'},
          ],
          setter: (request: IClientProfileProto, value: string[]) => {
            request.wellnessSurveyAnswers && (request.wellnessSurveyAnswers.fitnessGoals = value);
          },
          isValidValue: (value) => {
            return value != null;
          },
          validationMessage: 'Please select at least one',
        },

        {
          name: 'SkinCare',
          title: () => 'How would you describe your current skincare routine?',
          type: 'radio',
          choices: [
            {
              value: 'Minimal',
              text: 'Minimal – I use little to no skincare products, or I occasionally wash my face without a set routine.'
            },
            {
              value: 'Basic',
              text: 'Basic – I wash my face daily and may use one or two products like moisturizer or sunscreen.'
            },
            {
              value: 'Moderate',
              text: 'Moderate – I follow a consistent routine with a few products (e.g., cleanser, moisturizer, sunscreen, and one treatment product like serum or toner).'
            },
            {
              value: 'Advanced',
              text: 'Advanced – I have a detailed routine that includes multiple steps (e.g., cleansing, toning, exfoliating, serums, moisturizers, sunscreen, and treatments for specific concerns).'
            },
          ],
          setter: (request: IClientProfileProto, value: string) => {
            request.wellnessSurveyAnswers && (request.wellnessSurveyAnswers.currentSkincareRoutine = value);
          },
          isValidValue: (value) => {
            return value;
          },
          validationMessage: 'Please select one',
        },

        {
          name: 'HairConcerns',
          title: () => 'Do you have any other hair-related concerns? (Select all that apply).',
          type: 'checklist',
          choices: [
            {value: "Thinning or sparse eyebrows", text: 'Thinning or sparse eyebrows'},
            {value: "Hair loss or thinning on the scalp", text: 'Hair loss or thinning on the scalp'},
            {value: "Sparse or uneven eyelashes", text: 'Sparse or uneven eyelashes'},
            {
              value: "Uneven or excessive body hair that affects appearance",
              text: 'Uneven or excessive body hair that affects appearance'
            },
            {value: "Hair graying or whitening", text: 'Hair graying or whitening'},
            {value: "None of the above", text: 'None of the above'},
          ],
          setter: (request: IClientProfileProto, value: string[]) => {
            request.wellnessSurveyAnswers && (request.wellnessSurveyAnswers.hairRelatedConcerns = value);
          },
          isValidValue: (value) => {
            return value != null;
          },
          validationMessage: 'Please select at least one',
        },

      ];

      /**
       * @param results - the current survey results. this typically uses the react state
       *                  "surveyResults", but you can also pass in a modified once, which is
       *                  necessary when you mutate state (e.g. select a radio button) AND
       *                  want to figure out the next state immediately.
       *
       * @return {int} the next visible step, or the array length if there are no further pages.
       * By returning the array length we know that the
       */
      const getNextVisibleStep = (results: any) => {
        for (let i = currentStep + 1; i < surveyPages.length; i++) {
          let surveyPage = surveyPages[i];
          if ((!surveyPage.visibleWhen) || (surveyPage.visibleWhen(results))) {
            return i;
          }
        }

        return surveyPages.length;
      };

      /**
       * @return {int} The previous step or -1 if there is no previous step
       */
      const getPreviousVisibleStep = (results: any) => {
        for (let i = currentStep - 1; i >= 0; i--) {
          let surveyPage = surveyPages[i];
          if (!surveyPage.visibleWhen || surveyPage.visibleWhen(results)) {
            return i;
          }
        }

        return -1;
      };

      const page = surveyPages[currentStep];

      /**
       *
       * @param results the most recent survey results--- make sure not to grab the state
       *                variable if you have mutated it in this frame! pass a new copy.
       *                this is important when using radio buttons
       */
      const advancePage = (results: any) => {
        const nextStep = getNextVisibleStep(results);

        if (nextStep < surveyPages.length) {
          // There's still another page to go. Advance the step

          setCurrentStep(nextStep);
          setValidationError(null);

          const nextPage = surveyPages[nextStep];

          // Set the default value! This only needs to be done in the forward direction.
          // When the page gets rendered the first time the default value (if there is one)
          // will be put in the survey results. 
          if (surveyResults[nextPage.name] == null && nextPage.initialValue != null) {
            console.log('[Survey] Populating initial value for step', nextPage.name,
                nextPage.initialValue);

            setSurveyResults({
              ...results,
              [nextPage.name]: nextPage.initialValue,
            });
          }
        } else {

          // This is the last step! Kick off the RPC. Do not advance the page.
          sendUpdateUserProfileRequest().then(profile => {

            if (profile.updatedProfile) {
              console.info('[Survey] Profile send success!');

              setProfile(profile.updatedProfile)

              navigate('/chat', {
                state: {
                  message: 'Hey Kei! I just completed the wellness survey.'
                }
              });

            } else {
              console.warn('[Survey] Profile send failure!');
              setUpdateProfileError('Error saving your profile');
            }

          }).catch(e => {
            console.warn('[Survey] Profile send failure!', e);
            setUpdateProfileError(e);
          });
        }

      };

      const goBackAPage = (results: any) => {
        // This just clears out the RPC error when going back
        setUpdateProfileError('');
        setCurrentStep(getPreviousVisibleStep(results));
        setValidationError(null);
      };

      // This is a null parameter if there is no next step available which hides the button
      const onBackPress = (getPreviousVisibleStep(surveyResults) >= 0) ? (() => {
            goBackAPage(surveyResults);
          })
          : null;


      // This gets happen on 'OK' press AND 'enter' press in text box.
      const onContinue = () => {

        if (!page.isValidValue(surveyResults[page.name])) {
          setValidationError(page.validationMessage);
        } else {

          // It's okay to use the state variable to advance a page here because
          // there is a re-render between the time the users clicks a value and
          // hits the continue button.
          advancePage(surveyResults);
        }
      };
  
      return (<>
            <div className={'SurveyProgressBar'}>
              {new Array(5).fill(0).map((_, i) => {
                return (
                    <div key={i} className={currentStep > i ?
                        'SurveyProgressBarItem SurveyProgressBarItemSelected' :
                        'SurveyProgressBarItem SurveyProgressBarItemNotSelected'}/>
                );
              })}
            </div>
            <div className={'AppSection WhiteSection'}>
              <div className={'AppSectionContent'}>

                <div className='SurveySection'>


                  <span className={'SurveyTitle'}>{page.title()}</span>

                  {page.subtitle && <div className={'SurveySubtitle'}>{page.subtitle()}</div>}

                  {
                      page.type == 'text' && (
                          <input type={'text'} className={'SurveyTextInput'}
                                 placeholder={'Enter text here'}
                                 value={surveyResults[page.name] || ''}
                                 onKeyPress={event => {
                                   if (event.key == 'Enter') {
                                     onContinue()
                                   }
                                 }}
                                 onChange={event => {
                                   
                                   // If the onValueChange callback exists then call that.
                                   page.onValueChange && page.onValueChange(event.target.value);

                                   // It's possible that the survey results get mutated multiple
                                   // times before the next render! Because the onValueChange
                                   // also can do a mutate. Therefore use a function to change it.
                                   setSurveyResults(surveyResults => {
                                         return {
                                           ...surveyResults,
                                           [page.name]: event.target.value,
                                         }
                                       }
                                   );
                                 }}
                          />
                      )
                  }

                  {
                      page.type == 'textarea' && (
                          <textarea rows={10} cols={50} style={{
                            borderWidth: 1,
                            padding: 10,
                          }}
                                    placeholder={'Enter text here'}
                                    value={surveyResults[page.name] || ''}
                                    onChange={event => {

                                      // If the onValueChange callback exists then call that.
                                      page.onValueChange && page.onValueChange(event.target.value);

                                      // It's possible that the survey results get mutated multiple
                                      // times before the next render! Because the onValueChange
                                      // also can do a mutate. Therefore use a function to change it.
                                      setSurveyResults(surveyResults => {
                                            return {
                                              ...surveyResults,
                                              [page.name]: event.target.value,
                                            }
                                          }
                                      );
                                    }}
                          />
                      )
                  }


                  {
                      page.type == 'radio' && page.choices?.map((choice, i) => {

                        // This is essentially the same as a checklist except only one is selected
                        return (
                            <div
                                key={i}
                                className={'survey-checklist ' +
                                    ((surveyResults[page.name] == choice.value) ? 'selected' : 'notSelected')}
                                onClick={() => {
                                  const newSurveyResults = {
                                    ...surveyResults,
                                    [page.name]: choice.value,
                                  };

                                  setSurveyResults(newSurveyResults);
                                }}>
                              {choice.text}
                            </div>);
                      })
                  }

                  {
                      page.type == 'checklist' && page.choices?.map((choice, i) => {
                        return (
                            <button
                                key={i}
                                className={'survey-checklist ' +
                                    ((surveyResults[page.name]?.includes(choice.value)) ? 'selected' : 'notSelected')}
                                onClick={() => {

                                  if (!surveyResults[page.name]) {
                                    // The list was empty. Create new.
                                    setSurveyResults({
                                      ...surveyResults,
                                      [page.name]: [choice.value],
                                    });

                                  } else if (surveyResults[page.name].includes(choice.value)) {
                                    // Deselecting (remove each instance from list)
                                    setSurveyResults({
                                      ...surveyResults,
                                      [page.name]: surveyResults[page.name].filter(
                                          (item: any) => {
                                            return item != choice.value;
                                          }),
                                    });

                                  } else {
                                    // Selecting (append to list)
                                    setSurveyResults({
                                      ...surveyResults,
                                      [page.name]: [
                                        ...surveyResults[page.name],
                                        (choice.value)],
                                    });
                                  }
                                }}>
                              {
                                choice.text
                              }
                            </button>);
                      })
                  }

                  {validationError && (<div className='SurveyError'>{validationError}</div>)}

                  <button className='SurveyContinueButton' style={{}} onClick={onContinue}>
                    OK
                  </button>

                  {updateProfileError &&
                      (<div className={'SurveyError'}>Error building your profile: {updateProfileError}</div>)
                  }

                  {onBackPress &&
                      <button style={{
                        width: 120,
                        height: 20,
                        marginTop: 30,
                        padding: 15,
                        backgroundColor: 'white',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: 'gray',
                        border: '1px solid lightgray',
                        borderRadius: 5,
                        fontFamily: 'Nunito',
                        fontSize: 16,
                        overflow: 'hidden',
                      }} onClick={() => {
                        onBackPress();
                      }}>
                        Back
                      </button>
                  }

                  <div style={{fontSize: 'smaller', color: '#ccc'}}>
                    {JSON.stringify(surveyResults)}
                  </div>

                </div>
              </div>
            </div>
          </>
      );
    }
;
