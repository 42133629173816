import {PlaceAutocomplete} from "./PlaceAutocomplete";

type Props = {
  onChange: (value: {lat: number, lng: number}) => void;
}

export const AskForLocation = (props: Props) => {
  return <div className={'MapAskForLocation'}>

    <PlaceAutocomplete onPlaceSelect={place => {
      if (place?.geometry?.location) {
        props.onChange({
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng()
        });
      }
    }}/>
    
    <b>Add your location to see practitioners in your area.</b>

  </div>
}