import {useParams, useSearchParams} from "react-router-dom";
import React, {ReactNode, useEffect, useState} from "react";
import {SendUnauthenticatedRpc} from "../../rpcSender";
import {GetProviderProfileRequest, GetProviderProfileResponse, IApiBusinessProfileProto, space} from "../../compiled";
import {useAuth0} from "@auth0/auth0-react";
import './Provider.css';
import '../App.css';
import {Review} from "./Review";
import Markdown from "react-markdown";
import {ProviderBios} from "./ProviderBios";
import {useProfile} from "../../UserProfileProvider";
import '../../Styles.css'
import {getPractitioners} from "./ProviderUtil";
import {ShowBookingOverlay} from "booking-overlay";
import IServiceProto = space.kenko.proto.IServiceProto;

/**
 * Loads the provider and stores that in an outlet context so subpages can use it.
 *
 * Has its own router because the appointment type is done as a url param, i guess?!
 * @constructor
 */
export const ProviderMain = () => {

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string>()
  const [business, setBusiness] = useState<IApiBusinessProfileProto>()

  const {getIdTokenClaims} = useAuth0();

  const {profile, setProfile} = useProfile()
  const {providerShortUrl} = useParams();

  // This is for viewing a draft. The businessId and version ID need
  // to be specified.
  const [searchParams] = useSearchParams()
  const businessId = searchParams.get('b') || '';
  const version = searchParams.get('v') || '';

  const [selectedCategory, setSelectedCategory] = useState<string>()

  // This is the booking overlay, if it exists!!
  const [bookingOverlay, setBookingOverlay] = useState<ReactNode>();

  const [headerPadding, setHeaderPadding] = useState(77);

  useEffect(() => {
    const handleScroll = () => {
      // Calculate padding here so we don't need to set it a zillion times.
      const newPadding = Math.round(Math.max(10, 77 - (window.scrollY)));
      if (newPadding != headerPadding) {
        setHeaderPadding(newPadding);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {

    setLoading(true);
    SendUnauthenticatedRpc('get_business_details',
        GetProviderProfileRequest.encode(new GetProviderProfileRequest({
          shortUrl: providerShortUrl,
          businessId: businessId,
          version: version,
        })).finish())
        .then(responseBytes => {
          let response = GetProviderProfileResponse.decode(responseBytes);
          if (!response.okay || !response.business) {
            setError("Error loading this provider")
            return;
          }
          setBusiness(response.business);
          console.log(response.business);
          if (response.business.practice?.services && response.business.practice.services.length > 0) {
            setSelectedCategory(response.business.practice.services[0].category || '');
          }
        })
        .catch(e => {
          setError(e);
        })
        .finally(() => {
          setLoading(false);
        });
  }, [])

  if (loading) {
    return <div>loading...</div>
  }

  if (error) {
    return <div>{error}</div>
  }

  if (!business) {
    return <div>Error loading provider!</div>
  }

  const getUniqueCategories: () => string[] = () => {
    if (!business.practice?.services) {
      return [];
    }

    return business.practice.services.map(service => service.category || '')
        .filter((value, index, array) => array.indexOf(value) === index);
  }

  const getPriceRangeString = (service: IServiceProto) => {

    let low: number | null = null;
    let high: number | null = null;

    service.offeredBy?.forEach(offeredBy => {
      const price = offeredBy.price?.price as number
      if (!low || price < low) {
        low = price;
      }

      if (!high || price > high) {
        high = price;
      }
    })

    if (low == null || high == null) {
      return 'Not available';
    }

    if (low == 0 && high == 0) {
      return `FREE`
    } else if (low == high) {
      return `$${low}`
    } else {
      return `$${low} - $${high}`
    }
  };

  let practitioners = getPractitioners(business);

  return (<div style={{display: "flex", flexDirection: 'column', alignItems: 'center'}}>

        {(businessId || version) &&
            <div className={'AppSection DraftWarningSection'}>
              <div className={'AppSectionContent DraftWarning'}>
                This is a draft version of your website. Please head to your
                provider dashboard to make changes.
              </div>
            </div>
        }

        {/* a margin above the header that goes away on scroll */}
        <div className={'AppSection BeigeSection'} style={{height: 67}}>
        </div>

        <div className={'AppSection BeigeSection ProviderHeader'} style={{
          // These are dynamic values based on scroll position
          paddingBottom: headerPadding,
        }}>
          <div className={'AppSectionContent VerticalFlexBox Flex1'}>

            <div className={'ProviderHeaderRow'}>

              <div className={'Flex1 VerticalFlexBox'}>
                <div className={'ProviderHeaderTitle'}>{business.proto?.businessName?.toUpperCase()}</div>
                <div className={'ProviderHeaderReviews'}>★ 5.0 (<a href={'#reviews'}>20 reviews</a>)</div>
              </div>

              <a href={'#about'} className={'ProviderHeaderLink'}>ABOUT</a>
              <a href={'#services'} className={'ProviderHeaderLink'}>SERVICES</a>
              <a href={'#reviews'} className={'ProviderHeaderLink'}>REVIEWS</a>
              <div>
                <button className={'ActionButton'} onClick={() => ShowBookingOverlay({
                  businessId: business?.businessId as string
                })}>
                  BOOK NOW
                </button>
              </div>
            </div>
          </div>
        </div>

        <div id={'about'} className={'AppSection'}>
          <div className={'AppSectionContent VerticalFlexBox Flex1'}>
            <div className={'ProviderDescriptionRow'}>

              <div className={'ProviderDescriptionLeft ProviderSectionMargin'}>
                <div>
                  <Markdown>{business.proto?.businessDescription}</Markdown>
                </div>
                <div>
                  <button className={'ActionButton'} onClick={() => ShowBookingOverlay({
                    businessId: business?.businessId as string
                  })}>
                    BOOK NOW
                  </button>
                </div>
              </div>

              <div className={'ProviderDescriptionRight'}>
                <img className={'ProviderDescriptionImage'}
                     src={business.proto?.imageUrl && business.proto.imageUrl[0] || undefined}/>
              </div>

            </div>
          </div>
        </div>

        <div id={'services'} className={'AppSection WhiteSection'}>
          <div className={'AppSectionContent Flex1'}>
            <div className={'ProviderSectionMargin ServicesSection'}>
              <div className={'ProviderHeaderTitle'}>SERVICES & TREATMENTS</div>

              <div style={{padding: '30px 60px 30px 60px'}}>

                <div className={'ServiceCategoryContainer'}>
                  {getUniqueCategories().map(category => {
                    return <div key={category as string}>
                      <button
                          onClick={() => setSelectedCategory(category)}
                          className={selectedCategory == category ? 'CategoryButton' : 'CategoryButton CategoryButtonInverted'}>
                        {category.toUpperCase() || 'ALL'}
                      </button>
                    </div>
                  })}
                </div>

                {business.practice?.services?.map(service => {

                  if (selectedCategory && selectedCategory != service.category) {
                    return <></>
                  }

                  return <div
                      key={service.sku}
                      className={'Flex1 HorizontalFlexBox ServiceContainer'}>

                    <div className={'Flex1 VerticalFlexBox'} style={{textAlign: 'left', gap: 10}}>
                      <div style={{fontWeight: "bold"}}>
                        {service.name} ({service.lengthMinutes} min)
                        {/*· {service.price == 0 ? 'Free' : '$' + service.price}*/}
                        &nbsp; {getPriceRangeString(service)}
                      </div>
                      <div>{service.description}</div>
                    </div>

                    <div>
                      <button className={'ActionButton'} onClick={() => {
                        ShowBookingOverlay({
                          businessId: business?.businessId as string,
                          serviceSku: service.sku as string
                        });
                      }}>BOOK
                      </button>
                    </div>
                  </div>
                })}
              </div>
            </div>
          </div>
        </div>

        <ProviderBios
            providers={business.providers || []}
            onBookButtonClicked={providerId => {
              ShowBookingOverlay({
                businessId: business?.businessId as string,
                providerId: providerId
              });
            }}/>

        {practitioners.length == 1 && (
            <div className={'AppSection BeigeSection'}>
              <div className={'AppSectionContent HorizontalFlexBox Flex1'} style={{margin: 50}}>

                <div className={'ProviderDetails'}>
                  <img className={'ProviderDetailsImage'}
                       src={require('../../images/provider/education.png')} alt={'a'}/>
                  <span className={'ProviderDetailsHeader'}>EDUCATION</span>
                  {practitioners[0].education?.map((education, idx) => {
                    return <span key={'ed-' + idx}
                                 className={'ProviderDetailsText'}>
                      + {education.degree}, {education.school} ({education.yearCompleted})</span>
                  })}
                </div>

                <div className={'ProviderDetails'}>
                  <img className={'ProviderDetailsImage'}
                       src={require('../../images/provider/patient_focus.png')} alt={'a'}/>
                  <span className={'ProviderDetailsHeader'}>CERTIFICATIONS</span>
                  {practitioners[0].credentials?.map((credential, idx) => {
                    return (<span key={'cred-' + idx}
                                  className={'ProviderDetailsText'}>+ {credential.licenseOrCredential}</span>);
                  })}
                </div>
              </div>
            </div>
        )}

        <div className={'AppSection BlackSection'}>
          <div className={'AppSectionContent HorizontalFlexBox Flex1'} style={{margin: 50}}>

            <div className={'ProviderDetails'}>
              <h3 className={'ProviderDetailsHeader'}>INSURANCE POLICY </h3>
              <span className={'ProviderDetailsText'}>
                We are in-network for these insurance plans. Please note you may first need to meet a minimum 
                deductible under you plan. See Kenko’s Insurance FAQs for more information.
              </span>
            </div>

            <div className={'ProviderDetails'}>
              <h3 className={'ProviderDetailsHeader'}>CANCELLATION POLICY</h3>
              <span className={'ProviderDetailsText'}>
                Please contact us at least 24 hours before your scheduled appointment to avoid a cancellation
                fee. See our <a href={'#'}>cancellation policy</a> for more information.
              </span>
            </div>

          </div>
        </div>

        <div id={'reviews'} className={'AppSection BeigeSection'}>
          <div className={'AppSectionContent ProviderReviewContainer'}>
            <Review image={require('../../images/6.png')} name={"Jane Smith"} date={"1 week ago"}
                    text={'My skin has never felt clearer and more balanced since starting TCM treatments focused on natural...See more.'}/>
            <Review image={require('../../images/7.png')} name={'Mary Kim'} date={'4 months ago'}
                    text={"I saw significant improvement in my skin's texture and tone through personalized herbal remedies...See more."}/>
            <Review image={require('../../images/9.png')} name={'Elias Bronstein'} date={'6 weeks ago'}
                    text={'This TCM practice transformed my skin, reducing redness and inflammation in a way that...See more.'}/>
            <Review image={require('../../images/8.png')} name={'Loryane Bilson'} date={'12 months ago'}
                    text={'Thanks to their tailored TCM approach, my chronic skin issues have dramatically improved, and...See more.'}/>
          </div>
        </div>

        <div className={'AppSection BlackSection'}>

          <div className={'AppSectionContent Flex1'}>
            <div className={'ProviderSectionMargin ProviderLocationSection'}>

              <div className={'ProviderHeaderTitle'}>LOCATIONS</div>
              <div className={'FooterLocationContainer'}>

                {business.locations?.map(location => {
                  if (location.disabled) {
                    return null;
                  }

                  return <div key={'loc-' + location.locationId}
                              className={'FooterLocation'}>

                    <h3>{location.locationName}&nbsp;
                      (<a href={`https://www.google.com/maps/search/?api=1&query=${location.address}`}
                          target={'_blank'}>Google Maps</a>)
                    </h3>
                    <span>
                  {location.address}
                </span>
                  </div>
                })}
              </div>
            </div>
          </div>
        </div>
        <div id={'injected-react-content'}/>
      </div>
  );
}
