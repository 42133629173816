import {IApiBusinessProfileProto, space} from "../../compiled";
import IProviderBioProto = space.kenko.proto.IProviderBioProto;

export const getPractitioners = (business: IApiBusinessProfileProto): IProviderBioProto[] => {

  if (!business || !business.providers) {
    return [];
  }

  return business.providers.filter(provider => {
    return provider.isPractitioner
  })
} 