import {ArticlePreview} from "../ArticlePreview";
import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {Entry} from "contentful";
import * as contentful from "contentful";
import {RpcState} from "../../../util/RpcState";
import {getArticleCategory} from "../../../util/ContentUtil";

type Props = {
  hasGoToWellnessLibraryButton: boolean,
  linkToLibrary: boolean
}

/**
 * Used in the regular homepage and waitlist.
 */
export const ArticlePreviewSection = (props: Props) => {

  const navigate = useNavigate();

  const articleIds = ['6LiYNorhqaQCobCPcKzd7b', '6oPtpIlUpKAwTI5WUqWLEo', '5Cr0r3FgYNhKI8YegnJ9tK'];

  const [articles, setArticles] = useState<Entry[]>([]);

  const [rpcState, setRpcState] = useState<RpcState>({state: 'pending'});

  const client = contentful.createClient({
    space: 'zrbrzjug66a4',
    accessToken: '9QNFQ-iadIryADYUrJbiv1llDAUVJJ6iBdT80w9ZD_c'
  });

  useEffect(() => {

    if (props.linkToLibrary) {
      client.getEntries({
        content_type: 'post',
        select: ['sys.id', 'fields.title', 'fields.coverImage', 'fields.articleCategories'],
        "sys.id[in]": articleIds
      }).then(resp => {
        console.log('success', resp)
        setRpcState({state: 'success'})
        setArticles(resp.items);
      }).catch(e => {
        console.log('error', e);
        setRpcState({state: 'error'})
      })
    }
  }, []);


  return (<div className="AppSection BeigeSection">
    <div className="AppSectionContent ArticlePreviewSection">

      <h1 style={{alignSelf: 'start'}}>Recommended Reading</h1>

      <div className={'ArticlePreviewsContainer'}>

        {!props.linkToLibrary && <>
          <ArticlePreview link={'article/investing_in_self_care'}
                          imgSource={require('../../../images/articles/22.png')}
                          category={'LONGEVITY PLANNING'}
                          title={'The Importance of Investing in Self-Care and Holistic Wellness for Women'}/>

          <ArticlePreview link={'article/healing_power_of_acupuncture'}
                          imgSource={require('../../../images/articles/23.png')}
                          category={'EASTERN MEDICINE'}
                          title={'Evidence-Based Research on the Benefits of Acupuncture'}/>

          <ArticlePreview link={'article/zhou_yue_zi'}
                          imgSource={require('../../../images/articles/24.png')}
                          category={'POSTPARTUM CARE'}
                          title={'Embracing “Zuo Yue Zi": The Benefits of Traditional Postpartum Care for Modern Mothers'}/>
        </>
        }

        {props.linkToLibrary && 
            articles.map(article => {
              // @ts-ignore
              const coverImageUrl = article.fields.coverImage?.fields?.file?.url + '?w=400';
              return <ArticlePreview link={`library/article/${article.sys.id}`}
                                     imgSource={coverImageUrl}
                                     category={getArticleCategory(article)}
                                     title={article.fields.title as string}/>
              
            })
        }

        {props.linkToLibrary && rpcState.state == 'pending' && <>Loading...</>}
        {props.linkToLibrary && rpcState.state == 'error' && <>Error loading content</>}



          </div>

      {props.hasGoToWellnessLibraryButton && <div>
        <button className={'ActionButton'}
                onClick={() => navigate('/library')}>
          GO TO WELLNESS LIBRARY
        </button>
      </div>}

    </div>
  </div>);
}