import {useProfile} from "../../UserProfileProvider";
import {SendRpc} from "../../rpcSender";
import {DeleteProfileRequest, DeleteProfileResponse, IClientAppointmentProto} from "../../compiled";
import {useAuth0} from "@auth0/auth0-react";
import {ReactElement, useMemo, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {spanToMinutes} from "../../util/CalendarUtil";
import './Appointments.css'
import {ShowBookingOverlay} from "booking-overlay";

export const Appointments = () => {


  const {getIdTokenClaims, logout} = useAuth0();
  const {profile, setProfile} = useProfile();
  const [deleteRpcError, setDeleteRpcError] = useState('');
  const navigate = useNavigate();

  const appointmentBox = (appointment: IClientAppointmentProto,
                          actionButton: ReactElement) => {

    const appointmentStart = new Date(appointment.startTimeMs as number)
    const appointmentLength = spanToMinutes(appointment.startTimeMs as number,
        appointment.endTimeMs as number)

    return (<div className={'AppointmentsItem'}>
      <div className={'AppointmentsItemLeft'}>
        <h2>{appointmentStart.getDate()}</h2>
        <span>{appointmentStart.toLocaleDateString(undefined, {month: 'short'})}</span>
      </div>
      <div className={'AppointmentsItemCenter'}>
        <span>{appointmentStart.toLocaleTimeString(undefined, {hour: "numeric", minute: 'numeric'})}</span>
        <span><b>{appointment.serviceName}</b></span>
        <span>{appointment.providerName}&nbsp;•&nbsp;{appointmentLength} min</span>
      </div>
      <div className={'AppointmentsItemRight'}>
        {actionButton}
      </div>

    </div>);
  }


  const noAppointmentsFoundBox = (text: string) => {
    return (<div className={'AppointmentsItem'}>
      {text}
    </div>);
  }

  const nowMs = new Date().getTime();

  // A predicate which returns whether the appointment is today, or not today
  const upcomingFilter = (forToday: boolean, appointment: IClientAppointmentProto) => {
    const today = new Date();
    const startDate = new Date(appointment.startTimeMs as number);
    return forToday == ((today.getDay() == startDate.getDay()) &&
        (today.getMonth() == startDate.getMonth()) &&
        (today.getFullYear() == startDate.getFullYear()));
  }

  const upcomingAppointments = useMemo(() => {
    return profile?.appointments?.filter(appointment =>
        appointment.startTimeMs && appointment.startTimeMs >= nowMs) || [];
  }, [profile, nowMs])

  const pastAppointments = useMemo(() => {
    return profile?.appointments?.filter(appointment =>
        appointment.startTimeMs && appointment.startTimeMs < nowMs) || []
  }, [profile, nowMs])


  return <div className={'AppointmentsPage'}>

    <div className={'AppointmentsBox'}>
      <div className={'AppointmentsBoxHeader'}>
        UPCOMING APPOINTMENTS
      </div>

      <div className={'AppointmentsItemsList'}>
        {upcomingAppointments.map(appointment =>
            appointmentBox(appointment,
                <button className={'ActionButton'}>Reschedule</button>)
        )}

        {upcomingAppointments.length == 0 &&
            <div className={'AppointmentsItem AppointmentsItemNoneFound'}>
              You do not currently have any upcoming appointments scheduled.
              <Link to={'/search'}>
                <button className={'ActionButton'}>Find a practitioner</button>
              </Link>
            </div>}
      </div>
    </div>

    <div className={'AppointmentsBox'}>
      <div className={'AppointmentsBoxHeader'}>
        PAST APPOINTMENTS
      </div>

      <div className={'AppointmentsItemsList'}>
        {pastAppointments.map(appointment => appointmentBox(appointment,
            <button onClick={() => {
              console.log('appt', appointment)
              ShowBookingOverlay({
              businessId: appointment.businessId as string,
              serviceSku: appointment.serviceSku as string,
              providerId: appointment.providerId as string,
            })}}
                
                className={'ActionButton'}>Book&nbsp;again</button>)
        )}
        {pastAppointments.length == 0 && noAppointmentsFoundBox('No past appointments found in the last 30 days')}
      </div>
    </div>

  </div>
}