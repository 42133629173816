import React from "react";
import {useAuth0} from "@auth0/auth0-react";
import './App.css'
import {App2} from "./App2";
import {TransformYourFutureHealth} from "../Waitlist/Waitlist";

export const PrivateAlpha = () => {

  const {loginWithRedirect} = useAuth0();

  const auth0Login = () => {
    return loginWithRedirect({
      appState: {
        returnTo: window.location.pathname
      },
      authorizationParams: {
        audience: `https://narwhal.kenko.space/`,
        scope: 'openid offline_access' // to get refresh token
      }
    });
  }

  return <App2>
    
    <TransformYourFutureHealth/>

      <div className={'AppSection WhiteSection'}>
        <div className={'AppSectionContent'}>

          <div style={{margin: 60}}>
            Kenko is currently in private Alpha. Please log in to access Kenko.
          </div>
        </div>
      </div>
  </App2>
}