import {createClient, Entry} from "contentful";
import {useEffect, useRef, useState} from "react";
import {RpcState} from "../../util/RpcState";
import Select from "react-select";

type Props = {

  // Returns a string like 'fields.title' for the field to be sorted on,
  // or null if there is no sort. 
  onChange: (sort: string | null | undefined) => void;
}

/**
 * TODO - categories seriously need to get cached
 */
export const SortDropdown = (props: Props) => {

  const availableSorts = [
    {label: 'Most popular', value: null},
    {label: 'Recently Added', value: '-sys.createdAt'},
    {label: 'All articles (A-Z)', value: 'fields.title'},
    {label: 'All articles (Z-A)', value: '-fields.title'},
  ]

  return <Select
      defaultValue={undefined}
      name="sorts"
      placeholder={"Filters"}
      options={availableSorts}
      onChange={v => {
        if (v) {
          props.onChange(v.value);
        }
      }}
      styles={{
        menu: (baseStyles, state) => ({
          ...baseStyles,
          color: 'black',
        }),
        control: (baseStyles, state) => ({
          ...baseStyles,
          flexWrap: 'nowrap',
          border: 'none',
        }),
        valueContainer: (baseStyles, state) => ({
          ...baseStyles,
          minWidth: 'min(200px, 30vw)'
        }),
      }}
  />

}