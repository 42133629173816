import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {ArticlePreviewSection} from "./Sections/ArticlePreviewSection";
import {App2} from "../App2";
import {useProfile} from "../../UserProfileProvider";
import {useAuth0} from "@auth0/auth0-react";
import './HomePage.css'
import {ProviderCarousel} from "../ProviderWebsite/ProviderBios";
import {FindProviderRequest, FindProviderResponse, IApiBusinessProfileProto, space} from "../../compiled";
import {PlaceAutocomplete} from "../Map/PlaceAutocomplete";
import {SendRpc} from "../../rpcSender";
import {ShowBookingOverlay} from "booking-overlay";

/**
 * The home page right now is only for logged in users, since it's only visible
 * to alpha participants (and thus logged in users). Eventually it's gonna have to
 * switch to handler both logged in and not-logged in users.
 */
export const HomePage = () => {

  const navigate = useNavigate();
  const {profile, setProfile} = useProfile();
  const {user, getIdTokenClaims} = useAuth0();

  const [featuredBusinesses, setFeaturedBusinesses] = useState<IApiBusinessProfileProto[]>([]);

  // Recommended providers
  useEffect(() => {

    let request = new FindProviderRequest({
      north: 40.9,
      east: -73.9,
      south: 40.6,
      west: -74.1
    });


    SendRpc(getIdTokenClaims, "find_provider", FindProviderRequest.encode(request).finish())
        .then(r => {
          let response = FindProviderResponse.decode(r);
          setFeaturedBusinesses(response.providers);
        }).catch(e => {
      console.log('error', e);
      setFeaturedBusinesses([]);
    })
  }, [])

  // useRef keeps the PlaceAutocomplete as a singleton; otherwise  from being instantiated
  // const placeAutocomplete = useRef(<PlaceAutocomplete onPlaceSelect={place => {
  //     if (place?.geometry?.location) {
  //       console.log('navigating to')
  //       navigate(`/search?lat=${place.geometry.location.lat()}&lng=${place.geometry.location.lng()}`);
  //     }
  //   }}/>);

  return (<App2>
        <div className={'AppSection BeigeSection'}>
          <div className={'AppSectionContent WelcomeSection'}>

            <h1>
              Welcome, {profile?.name || user?.given_name}!
            </h1>

            <div>
              Kensu is your personalized wellness hub—where you can easily schedule treatments, connect with expert
              practitioners, and receive tailored insights to support your well-being
            </div>

            <div className={'WelcomeSectionOptions'}>
              <WelcomeSectionOption icon_url={require('../../images/icons/account_box.png')}
                                    title={'Find a practitioner'}
                                    onClick={() => navigate('/search')}/>

              <WelcomeSectionOption icon_url={require('../../images/icons/calendar_month.png')}
                                    title={'Appointments'}
                                    onClick={() => navigate('/dashboard')}/>

              <WelcomeSectionOption icon_url={require('../../images/icons/list_alt.png')}
                                    title={'My providers'}
                                    onClick={() => navigate('/dashboard')}/>

              <WelcomeSectionOption icon_url={require('../../images/icons/favorite.png')}
                                    title={'Wellness library'}
                                    onClick={() => navigate('/library')}/>

            </div>
          </div>
        </div>

        <div className={'AppSection WhiteSection'}>
          <div className={'AppSectionContent RecommendedProvidersSection'}>

            <h1>Recommended Providers</h1>
            <div>Based on your previous treatments and feedback, curated just for you.</div>

            <ProviderCarousel providersOrBusinesses={featuredBusinesses.map(business => {
              return {
                id: business.businessId as string,
                title: business.proto?.businessName as string,
                description: business.proto?.businessDescription as string,
                imageUrl: business.proto?.imageUrl?.length && business.proto.imageUrl[0] || undefined,
                buttonText: 'BOOK NOW',
                shortUrl: business.shortUrl as string
              }
            })}
                              overrideBioStyles={{backgroundColor: '#F6F4F1'}}
                              onBookButtonClicked={businessId => {
                                ShowBookingOverlay({
                                  businessId: businessId,
                                });
                              }}/>

          </div>
        </div>


        <div className={'AppSection RedSection'}>
          <div className={'AppSectionContent MoreRecommendedProvidersSection'}>

            <h1>
              Find more recommended practitioners near you
            </h1>

            <PlaceAutocomplete onPlaceSelect={place => {
              if (place?.geometry?.location) {
                navigate(`/search?lat=${place.geometry.location.lat()}&lng=${place.geometry.location.lng()}`);
              }
            }}/>

          </div>
        </div>

        <ArticlePreviewSection
            linkToLibrary={true}
            hasGoToWellnessLibraryButton={true}/>

      </App2>
  )
}

type WelcomeSectionOptionProps = {
  icon_url: string;
  title: string;
  onClick: () => void;
}

const WelcomeSectionOption = (props: WelcomeSectionOptionProps) => {

  return <div className={'WelcomeSectionOption'} onClick={props.onClick}>
    <img src={props.icon_url} className={'WelcomeSectionOptionIcon'}/>
    <div>{props.title}</div>
  </div>

}