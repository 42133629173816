import React, {useEffect, useRef, useState} from "react";
import {App2} from "../App2";
import './Library.css';
import * as contentful from 'contentful';
import {Entry} from 'contentful';
import {getArticleCategory} from "../../util/ContentUtil";
import {RpcState} from "../../util/RpcState";
import {LibraryArticlePreview} from "./LibraryArticlePreview";
import {CategoryDropdown} from "./CategoryDropdown";
import {SortDropdown} from "./SortDropdown";
import {TextSearch} from "./TextSearch";
import {Pager} from "./Pager";

export const Library = () => {

  const [articles, setArticles] = useState<Entry[]>();
  const [articlesState, setArticlesState] = useState<RpcState>({state: 'pending'});
  const [category, setCategory] = useState<string | null>();
  const [search, setSearch] = useState<string | null>();
  const [sort, setSort] = useState<string | null>();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(12);
  const [totalItems, setTotalItems] = useState(0);
  const rpcSent = useRef(false);

  const client = contentful.createClient({
    space: 'zrbrzjug66a4',
    accessToken: '9QNFQ-iadIryADYUrJbiv1llDAUVJJ6iBdT80w9ZD_c'
  });

  useEffect(() => {

    const extraFilters: { [key: string]: string } = {};
    if (category) {
      extraFilters['links_to_entry[in]'] = category;
    }
    if (sort) {
      extraFilters['order'] = sort;
    }
    if (search) {
      extraFilters['query'] = search;
    }

    let query = {
      content_type: 'post',
      select: ['fields.title', 'fields.coverImage', 'fields.articleCategories'],
      ...extraFilters,
      skip: page * limit,
      limit: limit,
    };

    console.log(query);

    client.getEntries(query).then(resp => {
      if (resp.errors && resp.errors.length > 0) {
        console.log('error', resp.errors)
      }

      setArticlesState({state: 'success'})
      setArticles(resp.items);
      setTotalItems(resp.total);

    }).catch(e => {
      console.log('contentful error', e);
      setArticlesState({state: 'error'})
    })
  }, [category, sort, page, search])

  return <App2>
    <div className={'AppSection WhiteSection'}>
      <div className={'AppSectionContent ResourceLibraryWhiteSection'}>
        <h1>Wellness Library</h1>
        Essential tools and insights to elevate your wellness journey, blending Eastern and Western practices.
      </div>
    </div>


    <div className={'AppSection RedSection ResourceLibrarySticky'}>
      <div className={'AppSectionContent ResourceLibrarySearchBar'}>

        <TextSearch onChange={search => {
          setSearch(search);
          setPage(0);
        }}/>

        <CategoryDropdown onChange={category => {
          setCategory(category);
          setPage(0);
        }}/>
        <SortDropdown onChange={sort => {
          setSort(sort)
          setPage(0);
        }}/>

      </div>
    </div>

    <div className="AppSection WhiteSection">
      <div className="AppSectionContent ResourceLibraryContentSection">

        {articlesState.state == 'pending' && <div>Loading...</div>}
        {articlesState.state == 'error' && <div>Error loading content</div>}

        {articlesState.state == 'success' && articles?.map(article => {

          const articleId = article.sys.id;
          // @ts-ignore
          const coverImageUrl = article.fields.coverImage?.fields?.file?.url + '?w=400';

          return <LibraryArticlePreview key={articleId}
                                        link={'article/' + articleId}
                                        imgSource={coverImageUrl}
                                        category={getArticleCategory(article, category)}
                                        title={article.fields['title'] as string}/>
        }) || 'No articles found'}

      </div>
    </div>

    <div className={'AppSection WhiteSection'}>
      <Pager pages={Math.ceil(totalItems / limit)} currentPage={page} onChange={setPage}/>
    </div>
  </App2>;
}